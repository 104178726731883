import { Path } from 'history'
import { adminBasePath, entrepriseBasePath, medecinBasePath } from 'plateforme/App'
import Utilisateur, { TypePartenaire } from 'plateforme/store/types/utilisateur'

export default function redirectPath(utilisateur?: Utilisateur, from?: Path): string | undefined {
  let path
  switch (utilisateur?.profilActif?.typePartenaire) {
    case TypePartenaire.Admin:
      path = `/${adminBasePath}`
      break
    case TypePartenaire.Entreprise:
      path = `/${entrepriseBasePath}`
      break
    case TypePartenaire.Medecin:
      path = `/${medecinBasePath}`
      break
    default:
      path = undefined
  }

  const pathname = from?.pathname === '/logout' ? undefined : from?.pathname
  const params = new URLSearchParams(from?.search)

  const search =
    from && params.has('profil')
      ? decodeURIComponent(from.search).replace(`profil=${params.get('profil')}`, '')
      : from?.search
  return pathname && path && pathname?.startsWith(path) ? `${pathname}${search}` : path
}
