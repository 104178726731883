import * as React from 'react'
import { ButtonProps, Stack, styled, Typography } from '@mui/material'
import { LoadingProps } from 'plateforme/services/utils'
import { determineLibelleStatutMissionAccordion } from 'plateforme/services/mission.services'
import { MetaDataMission } from 'plateforme/store/types/mission'
import MessageChip from 'plateforme/components/chips/MessageChip'
import MessageTile from 'plateforme/components/tiles/MessageTile'

const ActionStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
}))

type MessagesMissionTileProps = {
  missionMessagerie: MetaDataMission
  selected?: boolean
  onClick: VoidFunction
}

function MessagesMissionTile(props: MessagesMissionTileProps & LoadingProps & ButtonProps) {
  const { missionMessagerie, loading, selected, onClick, ...other } = props
  const mission = missionMessagerie?.mission
  const { totalLu, totalNonLuEntreprise, totalNonLuMedecin, indexMission, indexRapport } = missionMessagerie
  const totalMessages = totalLu + totalNonLuEntreprise + totalNonLuMedecin

  return (
    <MessageTile selected={selected} variant="contained" color="tile" disabled={loading} onClick={onClick} {...other}>
      <ActionStack spacing={1}>
        <Typography variant="h2" sx={{ verticalAlign: 'top' }}>
          {mission &&
            `Mission${indexMission ? ` n°${indexMission}` : ''} ${determineLibelleStatutMissionAccordion(
              mission,
              indexRapport
            )}`}
        </Typography>
        <Typography variant="subtitle2" sx={{ verticalAlign: 'top' }}>
          {mission?.medecin?.libelle}
        </Typography>
        {mission.refMissionEntreprise && (
          <Typography variant="subtitle1" sx={{ verticalAlign: 'top' }}>
            {mission?.refMissionEntreprise}
          </Typography>
        )}
        <ActionStack direction="row">
          <MessageChip totalMessage={totalMessages} totalMessageNonLu={totalNonLuEntreprise} />
        </ActionStack>
      </ActionStack>
    </MessageTile>
  )
}

export default MessagesMissionTile
