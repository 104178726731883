import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'

interface TiercePersonnePartProps {
  readOnly?: boolean
}

export default function TiercePersonnePart({ readOnly }: TiercePersonnePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Paramétrage des dommages" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <strong>Dommages avec tierce personne</strong>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.tiercePersonnePeriodeAvecType"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="tiercePersonnePeriodeAvecType"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Périodes avec type [active/passive] pour toute les tierces personnes ('type' est obligatoire si paramètre actif sinon il est optionnel)"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.tiercePersonneDefinitivePeriodeSansFin"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="tiercePersonneDefinitivePeriodeSansFin"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Périodes sans fin pour les tierces personnes définitives ('au' est caché si paramètre actif sinon il est obligatoire)"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
