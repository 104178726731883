import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { MessageAlert, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type ExpertisePartProps = {
  dossier: DossierEntreprise | undefined
  profilEntreprise?: ProfilEntreprise
}

export default function ExpertisePart({ dossier, profilEntreprise }: ExpertisePartProps) {
  const showDomaineContractuel = profilEntreprise?.parametrage?.domainesContractuels

  return (
    <Card>
      <CardHeader title="Expertise" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} lg={showDomaineContractuel ? 6 : 12}>
            <ReadOnlyTextField
              id="cadre-expertise"
              label="Cadre d'expertise"
              value={dossier?.expertise?.labelCadreExpertise}
              fullWidth
            />
          </Grid>
          {showDomaineContractuel && (
            <Grid item xs={12} lg={6}>
              <ReadOnlyTextField
                id="domaine-contractuel"
                label="Mission spécifique"
                value={dossier?.expertise?.domaineContractuel?.libelle}
                fullWidth
              />
            </Grid>
          )}
          {dossier?.expertise?.autreMedecin && (
            <>
              <Grid item xs={12}>
                <MessageAlert id="personne-a-contacter-coordonnees-personne-a-examiner">
                  <strong>Les coordonnées de l&apos;autre médecin</strong> sont précisées ci-dessous :
                </MessageAlert>
              </Grid>
              <Grid item xs={12}>
                <ReadOnlyTextField
                  id="expertise-autre-medecin-libelle"
                  label="Identité autre médecin"
                  value={dossier?.expertise?.autreMedecin?.libelle}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ReadOnlyTextField
                  id="expertise-autre-medecin-adresse-complete"
                  label="Adresse"
                  value={dossier?.expertise?.autreMedecin?.adresseComplete}
                  fullWidth
                  minRows={3}
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ReadOnlyEmailField
                  id="expertise-autre-medecin-mail"
                  label="Adresse mail"
                  value={dossier?.expertise?.autreMedecin?.mail}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReadOnlyTextField
                  id="expertise-autre-medecin-telephone-mobile"
                  label="Tél. portable"
                  value={dossier?.expertise?.autreMedecin?.telephoneMobile}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ReadOnlyTextField
                  id="expertise-autre-medecin-telephone-fixe"
                  label="Tél. fixe"
                  value={dossier?.expertise?.autreMedecin?.telephoneFixe}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
