import React from 'react'
import { Alert, AlertTitle, Box, Stack } from '@mui/material'

interface AlertErreurProps {
  title: string
  error: string
}

function AlertErreur(props: AlertErreurProps) {
  const { title, error, ...other } = props
  const errorObject = JSON.parse(error)?.data

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Alert sx={{ padding: '10px' }} {...other} severity="error">
        <AlertTitle>
          <strong>{title}</strong>
        </AlertTitle>
        <Stack padding={2} direction="row">
          <strong>Message d&apos;erreur : </strong>
          {errorObject.message}
        </Stack>
        <Stack padding={2} direction="row">
          <strong>Code d&apos;erreur :</strong>
          {errorObject.code}
        </Stack>
      </Alert>
    </Box>
  )
}

export default AlertErreur
