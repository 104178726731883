import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Switch } from '@mui/material'
import { BluePaper, MessageAlert, SupprimerIconButton } from 'plateforme/components'
import determineSuffixeLibelleDommage from 'plateforme/services/dommage.services'
import { DOMMAGES_SEPTIEME_MARKS } from 'plateforme/constantes'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { isRapportConseilNotEmpty } from 'plateforme/services/utils'
import { determineDommageMaxPeriodes, isRapportConclusionProvisoire } from 'plateforme/services/rapport.services'
import { ReferenceParametrageEntreprise } from 'plateforme/store/types/profilEntreprise'
import { ModeDommage } from 'medecin/store/types/rapportConclusionMedecin'
import { DommageData } from '../FicheRapportMedecinForm'
import DommageFieldCommentaire from './DommageFieldCommentaire'
import DommageFieldPeriode from './DommageFieldPeriode'
import DommageFieldRadioGroup from './DommageFieldRadioGroup'
import DommageFieldPourcentage from './DommageFieldPourcentage'
import DommageFieldSlider from './DommageFieldSlider'
import DommageFieldTiercePersonne from './DommageFieldTiercePersonne'
import RapportConseilFieldPourcentageFourchette from './RapportConseilFieldPourcentageFourchette'
import RapportConseilFieldRangeSlider from './RapportConseilFieldRangeSlider'
import RapportConseilFieldCommentaire from './RapportConseilFieldCommentaire'

interface DommageComponentProps {
  dommage: DommageData
  readOnly: boolean
  typeConclusion: TypeRapport
  parametrage?: ReferenceParametrageEntreprise
  isRapportConseilActif?: boolean
}

function dommageComponent(
  code: string,
  typeConclusion: TypeRapport,
  readOnly: boolean,
  parametrage?: ReferenceParametrageEntreprise,
  disabled = false
) {
  const maxPeriodes = determineDommageMaxPeriodes(code, parametrage)
  switch (code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'PREJUDICE_ETABLISSEMENT':
    case 'PREJUDICE_ANGOISSE_MORT_IMMINENTE':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
      return <DommageFieldCommentaire disabled={disabled} name={`dommages.${code}`} code={code} readOnly={readOnly} />

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return (
        <DommageFieldPeriode
          disabled={disabled}
          name={`dommages.${code}`}
          code={code}
          readOnly={readOnly}
          max={maxPeriodes}
          parametrage={parametrage}
        />
      )

    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return (
        <DommageFieldRadioGroup
          typeConclusion={typeConclusion}
          name={`dommages.${code}`}
          code={code}
          readOnly={readOnly}
          disabled={disabled}
          max={maxPeriodes}
          parametrage={parametrage}
        />
      )

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return <DommageFieldPourcentage name={`dommages.${code}`} code={code} readOnly={readOnly} disabled={disabled} />

    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return (
        <DommageFieldSlider
          name={`dommages.${code}`}
          code={code}
          marks={DOMMAGES_SEPTIEME_MARKS}
          min={0.5}
          max={7}
          readOnly={readOnly}
          disabled={disabled}
        />
      )

    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return (
        <DommageFieldTiercePersonne
          name={`dommages.${code}`}
          code={code}
          readOnly={readOnly}
          disabled={disabled}
          max={maxPeriodes}
          parametrage={parametrage}
        />
      )

    default:
      return null
  }
}

function rapportConseilComponent(code: string, readOnly: boolean, disabled = false) {
  switch (code) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'PREJUDICE_ETABLISSEMENT':
    case 'PREJUDICE_ANGOISSE_MORT_IMMINENTE':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return (
        <RapportConseilFieldCommentaire
          name={`dommages.${code}.rapportConseil`}
          code={code}
          readOnly={readOnly}
          disabled={disabled}
        />
      )
    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return (
        <RapportConseilFieldPourcentageFourchette
          name={`dommages.${code}.rapportConseil`}
          code={code}
          readOnly={readOnly}
          disabled={disabled}
        />
      )
    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return (
        <RapportConseilFieldRangeSlider
          name={`dommages.${code}.rapportConseil`}
          code={code}
          marks={DOMMAGES_SEPTIEME_MARKS}
          min={0.5}
          max={7}
          readOnly={readOnly}
          disabled={disabled}
        />
      )

    default:
      return null
  }
}

const dommagesuffixe = (code: string, typeConclusion: TypeRapport): string => {
  if (
    ['ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE', 'DOMMAGE_ESTHETIQUE_PERMANENT', 'SOUFFRANCES_ENDUREES'].includes(
      code
    )
  ) {
    return determineSuffixeLibelleDommage(typeConclusion)
  }

  return ''
}

export default function DommageComponent({
  dommage,
  readOnly,
  typeConclusion,
  parametrage,
  isRapportConseilActif,
}: DommageComponentProps) {
  const { clearErrors, setValue, resetField, control, watch } = useFormContext()

  const removeDommages = (codeToRemove: string) => {
    const field = `dommages.${codeToRemove}`
    clearErrors(field)
    resetField(field)
    setValue(field, undefined)
  }

  const isNonEvalubale = watch(`dommages.${dommage.code}.nonEvaluable`)

  const valeurInitiale = () => (dommage.code === 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE' ? '' : null)
  const valeurRangeInitiale = () => (dommage.code === 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE' ? '' : [null, null])
  const plageDeDatesModeInitiale = () =>
    dommage.code === 'DATES_HOSPITALISATION_IMPUTABLES' || dommage.code === 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES'
      ? ModeDommage.PERIODE
      : null

  const clearConclusionDommage = () => {
    clearErrors(`dommages.${dommage.code}.commentaire`)
    setValue(`dommages.${dommage.code}.commentaire`, '', {
      shouldValidate: true,
      shouldDirty: true,
    })
    clearErrors(`dommages.${dommage.code}.plageDeDates`)
    setValue(`dommages.${dommage.code}.plageDeDates`, null, {
      shouldValidate: true,
      shouldDirty: true,
    })
    clearErrors(`dommages.${dommage.code}.plageDeDates.periodes`)
    setValue(`dommages.${dommage.code}.plageDeDates.mode`, plageDeDatesModeInitiale, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue(`dommages.${dommage.code}.plageDeDates.periodes`, [{ du: null, au: null }], {
      shouldValidate: true,
      shouldDirty: true,
    })
    clearErrors(`dommages.${dommage.code}.periodes`)
    setValue(
      `dommages.${dommage.code}.periodes`,
      [{ type: null, nombreHeures: '', par: null, periode: { du: null, au: null } }],
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    )
    clearErrors(`dommages.${dommage.code}.valeur`)
    setValue(`dommages.${dommage.code}.valeur`, valeurInitiale(), {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const clearRapportConseil = () => {
    clearErrors(`dommages.${dommage.code}.rapportConseil`)
    clearErrors(`dommages.${dommage.code}.rapportConseil.commentaire`)
    setValue(`dommages.${dommage.code}.rapportConseil.commentaire`, '', {
      shouldValidate: true,
      shouldDirty: true,
    })
    clearErrors(`dommages.${dommage.code}.rapportConseil.valeur`)
    setValue(`dommages.${dommage.code}.rapportConseil.valeur`, valeurRangeInitiale(), {
      shouldValidate: true,
      shouldDirty: true,
    })
    clearErrors(`dommages.${dommage.code}.rapportConseil.min`)
    setValue(`dommages.${dommage.code}.rapportConseil.min`, '', {
      shouldValidate: true,
      shouldDirty: true,
    })
    clearErrors(`dommages.${dommage.code}.rapportConseil.max`)
    setValue(`dommages.${dommage.code}.rapportConseil.max`, '', {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const rapportConseilRempli = isRapportConseilNotEmpty(watch(`dommages.${dommage.code}.rapportConseil`))

  return (
    <>
      <Grid item xs={isRapportConseilActif ? 6 : 12} key={`${dommage.code}-dommage`}>
        <BluePaper>
          <Grid container>
            <Grid
              item
              xs={isRapportConseilActif ? true : 12}
              lg={isRapportConseilActif ? true : 3}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    label={dommage.label + dommagesuffixe(dommage.code, typeConclusion)}
                    sx={{ minHeight: 48 }}
                    control={
                      <Checkbox
                        disabled={readOnly}
                        name={dommage.code}
                        checked
                        onClick={() => {
                          removeDommages(dommage.code)
                        }}
                        sx={{ padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {isRapportConseilActif && isRapportConclusionProvisoire(typeConclusion) && (
              <Grid item xs="auto" display="flex" alignItems="center" justifyContent="flex-end">
                <Controller
                  name={`dommages.${dommage.code}.nonEvaluable`}
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl disabled={readOnly} error={!!error} variant="standard">
                      <FormControlLabel
                        id="dommage-non-evaluable"
                        value={value}
                        control={
                          <Switch
                            checked={value}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e)
                              clearConclusionDommage()
                            }}
                          />
                        }
                        label="Non évaluable à date d'examen"
                        sx={{ maxWidth: 180, minHeight: 48 }}
                      />
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={isRapportConseilActif ? 12 : 9}>
              {dommageComponent(dommage.code, typeConclusion, readOnly, parametrage, isNonEvalubale)}
            </Grid>
          </Grid>
        </BluePaper>
      </Grid>
      {isRapportConseilActif && (
        <Grid item xs={isRapportConseilActif ? 6 : 12} key={`${dommage.code}-rc`}>
          <BluePaper>
            <Grid container>
              <Grid item xs={12} display="flex" alignItems="flex-end" justifyContent="flex-end">
                {rapportConseilRempli ? (
                  <SupprimerIconButton
                    disabled={readOnly}
                    tooltip="Supprimer la saisie"
                    onClick={clearRapportConseil}
                  />
                ) : (
                  <MessageAlert
                    textVariant="body1"
                    iconCenter
                    textCenter
                    backgroundColor="transparent"
                    elevation={0}
                    sx={{ width: 'auto', minHeight: 48 }}
                  >
                    Aucune information saisie
                  </MessageAlert>
                )}
              </Grid>

              <Grid item xs={12}>
                {rapportConseilComponent(dommage.code, readOnly)}
              </Grid>
            </Grid>
          </BluePaper>
        </Grid>
      )}
    </>
  )
}
