import { IValidationErrorRepsonse } from 'plateforme/hooks/useErrorFormMapper'

export enum UploadState {
  IDLE = 'IDLE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export interface Upload {
  key: string
  nomFichier: string
  tailleFichier: number
  formatFichier: string
  state: UploadState
  progress: number
  isValid: boolean
  error?: IValidationErrorRepsonse
}

export type UploadFileMap = {
  [key: string]: File
}

export interface UploaderState<TUpload extends Upload> {
  uploads: TUpload[]
  isRunning: boolean
  isSuccessUploads?: boolean
  isErrorUploads?: boolean
  errorUploads?: IValidationErrorRepsonse
}
