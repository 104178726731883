import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, CardContent, FormControl, FormControlLabel, FormHelperText, Grid, Stack, Switch } from '@mui/material'
import { SauvegarderButton } from 'plateforme/components'
import EnvoiNotificationsMailPart from './EnvoiNotificationsMailPart'
import NotificationsMailsPart from './NotificationsMailsPart'

interface TabNotificationsPartProps {
  onSubmit?: VoidFunction
}

export default function TabNotificationsPart({ onSubmit }: TabNotificationsPartProps) {
  const { ...methods } = useFormContext()
  const { control, watch } = useFormContext()
  const readOnly = !onSubmit
  const fieldName = 'parametrage.notifiableParMail'
  const formId = 'form-parametres-notifications'
  return (
    <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} id={formId} name={formId}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item container md={12} lg={12}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid item xs={12}>
                  <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                        <FormControlLabel
                          id="notifiableParMail"
                          value={value}
                          control={<Switch onChange={onChange} onBlur={onBlur} checked={value} />}
                          label="Envoi de notifications par mail activé "
                        />
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                {watch(fieldName) && <EnvoiNotificationsMailPart readOnly={readOnly} />}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {watch(fieldName) && <NotificationsMailsPart readOnly={readOnly} />}
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <SauvegarderButton onClick={onSubmit}>Sauvegarder</SauvegarderButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
