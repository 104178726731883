import React from 'react'
import { useParams } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { Box, Stack, Typography } from '@mui/material'
import { FallbackError } from 'plateforme/components'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { trimToUndefined } from 'plateforme/services/utils'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useGetDossierByIdQuery } from 'assureur/store/apis/dossierAssureurApi'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { determineDerniereMission } from 'assureur/services/dossierEntreprise.services'
import EditionDossierEntreprisePart from 'assureur/parts/editionDossier/EditionDossierEntreprisePart'

export default function EditionDossierEntreprisePage() {
  const { codeDossier } = useParams()
  const handleError = useErrorHandler()
  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }

  const {
    data: dossier,
    error: errorDossier,
    isError: isErrorDossier,
    isLoading: loadingDossier,
    isFetching: fetchingDossier,
  } = useGetDossierByIdQuery({ codeDossier })
  // handle error of GetDossierByIdQuery:
  if (isErrorDossier) {
    handleError({
      errorApi: errorDossier,
      title: 'Erreur chargement des données du dossier',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }

  const {
    data: profilEntreprise,
    isError: isErrorGetProfilEntreprise,
    error: errorGetProfilEntreprise,
    isLoading: loadingProfilEntreprise,
    isFetching: fetchingProfilEntreprise,
  } = useGetProfilEntrepriseQuery()

  if (isErrorGetProfilEntreprise) {
    handleError({
      errorApi: errorGetProfilEntreprise,
      title: 'Erreur chargement des données profil entreprise',
      api: 'GetProfilEntreprise',
    } as FallbackError)
  }

  const {
    data: utilisateur,
    error: utilisateurError,
    isError: isUtilisateurError,
    isLoading: utilisateurLoading,
    isFetching: utilisateurFetching,
  } = useGetUtilisateurActifQuery()

  // handle error of useGetUtilisateurActifQuery:
  if (isUtilisateurError) {
    handleError({
      errorApi: utilisateurError,
      title: 'Erreur chargement des données du profil actif',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }

  const {
    data: referentiel,
    isError: isErrorGetReferentiel,
    error: errorGetReferentiel,
    isLoading: loadingReferentiel,
    isFetching: fetchingReferentiel,
  } = useGetReferentielQuery()

  if (isErrorGetReferentiel) {
    handleError({
      errorApi: errorGetReferentiel,
      title: 'Erreur chargement des réfétentiels',
      api: 'GetReferentiel',
    } as FallbackError)
  }

  const loading =
    loadingDossier ||
    fetchingDossier ||
    loadingProfilEntreprise ||
    fetchingProfilEntreprise ||
    utilisateurLoading ||
    utilisateurFetching ||
    loadingReferentiel ||
    fetchingReferentiel

  const derniereMission = determineDerniereMission(dossier)
  const libelleReferenceDossier =
    trimToUndefined(derniereMission?.libelle) !== undefined ? derniereMission?.libelle : dossier?.libelle
  const complementReferenceDossier = dossier?.entreprise?.libelle

  const getTitre = () => {
    const { statut } = dossier || {}
    switch (statut) {
      case StatutDossier.EN_CREATION: {
        return `Création du dossier `
      }
      case StatutDossier.EN_COURS: {
        return `Modification du dossier `
      }
      default: {
        return ''
      }
    }
  }
  return (
    <>
      <NavigationPart fullWidth>
        <Stack direction="row" spacing={1} alignItems="center" overflow="clip">
          <Typography variant="subtitle1" noWrap>
            {getTitre()}
          </Typography>
          <Typography variant="h2" color="primary" whiteSpace="nowrap">
            {libelleReferenceDossier}
          </Typography>
          <Typography variant="subtitle1" noWrap>
            {complementReferenceDossier ? ` |  ${complementReferenceDossier} ` : ''}
          </Typography>
        </Stack>
      </NavigationPart>
      <Box marginTop={2}>
        <EditionDossierEntreprisePart
          dossier={dossier}
          profilEntreprise={profilEntreprise}
          profilUtilisateur={utilisateur?.profilActif}
          referentiel={referentiel}
          loading={loading}
        />
      </Box>
    </>
  )
}
