/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import { Noop } from 'react-hook-form/dist/types/utils'
import { Box, Chip, FormControl, FormHelperText, Autocomplete, SxProps, FilterOptionsState } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { BACKGROUND_COLORS, NO_OPTION_CODE } from 'plateforme/constantes'
import ReadOnlyTextField from './ReadOnlyTextField'
import EditTextField from './EditTextField'
import { SelectOption } from './SelectInput'

export const DEFAULT_OPTION = {
  code: NO_OPTION_CODE,
  label: 'Aucune sélection',
}

export interface AutocompleteSelectOption<T> extends SelectOption {
  data?: T
}

export interface AutocompleteInputProps {
  id: string
  label: string
  value?: AutocompleteSelectOption<any> | Array<AutocompleteSelectOption<any>> | null
  onBlur?: Noop
  onChange?: (value: string | AutocompleteSelectOption<any> | null) => void
  onChangeMultiple?: (value: string[] | Array<AutocompleteSelectOption<any>> | null) => void
  fullWidth?: boolean
  required?: boolean
  sx?: SxProps
  disabled?: boolean
  readOnly?: boolean
  multiple?: boolean
  error?: boolean
  fieldError?: FieldError
  helperText?: string
  options: Array<AutocompleteSelectOption<any>>
  optionsLoader?: {
    isLoading?: boolean
    isError?: boolean
  }
  placeholder?: string
  withColors?: boolean
  withNoSelectionItem?: boolean
  renderOption?: (props: object, option: AutocompleteSelectOption<any>, state: object) => ReactNode
  filterOptions?:
    | ((
        options: AutocompleteSelectOption<any>[],
        state: FilterOptionsState<AutocompleteSelectOption<any>>
      ) => AutocompleteSelectOption<any>[])
    | undefined
}

export default function AutocompleteInput({
  id,
  label,
  value,
  onBlur,
  onChange,
  onChangeMultiple,
  options,
  fullWidth,
  required,
  multiple,
  sx,
  disabled,
  readOnly,
  error,
  fieldError,
  helperText,
  optionsLoader,
  placeholder,
  withColors,
  renderOption,
  filterOptions,
}: AutocompleteInputProps) {
  const handleChange = (
    event: React.SyntheticEvent,
    val: string | AutocompleteSelectOption<any> | (string | AutocompleteSelectOption<any>)[] | null
  ) => {
    if (onChange && !multiple) {
      onChange(val as string)
    } else if (onChangeMultiple && multiple) {
      if ((val as AutocompleteSelectOption<any>[])?.some((v) => v.code === NO_OPTION_CODE)) {
        onChangeMultiple([])
      } else {
        onChangeMultiple(val as string[])
      }
    }
  }

  const isLoaderSuccess = !optionsLoader?.isLoading && !optionsLoader?.isError
  const isDisabled = !isLoaderSuccess || disabled
  const isReadOnly = !isLoaderSuccess || readOnly
  const isError = error || fieldError !== undefined || optionsLoader?.isError

  const formHelperText = fieldError?.message ?? helperText

  function getValue() {
    if (multiple) {
      return isLoaderSuccess && value ? (value as AutocompleteSelectOption<any>[]) : []
    }
    return isLoaderSuccess && value ? (value as AutocompleteSelectOption<any>) : ''
  }

  if (readOnly && !multiple) {
    return (
      <ReadOnlyTextField
        fullWidth={fullWidth}
        id={id}
        value={options.find((o) => o.code === getValue())?.label ?? ' '}
        label={label}
      />
    )
  }

  return (
    <FormControl
      variant="filled"
      fullWidth={fullWidth}
      required={required}
      sx={sx}
      disabled={isDisabled}
      error={isError}
    >
      <Autocomplete
        id={id}
        multiple={multiple}
        freeSolo
        filterSelectedOptions
        disableClearable
        clearOnEscape
        forcePopupIcon
        popupIcon={<KeyboardArrowDown />}
        value={getValue()}
        onChange={handleChange}
        onBlur={onBlur}
        readOnly={isReadOnly}
        renderOption={renderOption}
        filterOptions={filterOptions}
        getOptionLabel={(option: AutocompleteSelectOption<any> | string) => {
          if (typeof option === 'string') {
            return option
          }
          return option.label
        }}
        renderTags={(selected: AutocompleteSelectOption<any>[], getTagProps: any) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1px' }}>
              {selected.map((option, index) => {
                return (
                  <Chip
                    {...getTagProps({ index })}
                    sx={{
                      backgroundColor: withColors ? BACKGROUND_COLORS[index] : 'primary',
                      '.MuiChip-label': { padding: '5px' },
                    }}
                    size="small"
                    key={option.code}
                    label={options.find((o) => o.code === option.code)?.label}
                  />
                )
              })}
            </Box>
          )
        }}
        renderInput={(params) => (
          <EditTextField
            {...params}
            readOnly={readOnly}
            label={label}
            onBlur={onBlur}
            inputProps={{
              ...params.inputProps,
              placeholder,
            }}
            error={isError}
            variant="filled"
            fieldError={fieldError}
            value={readOnly && value === null ? ' ' : value}
          />
        )}
        options={[DEFAULT_OPTION, ...options]}
      />
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  )
}
