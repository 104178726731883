import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { toEntrepriseHref } from 'plateforme/App'
import { ErrorFallback } from 'plateforme/components'
import { PAGE_LARGE_PADDING_X, PAGE_PADDING_X, PAGE_PADDING_Y } from 'plateforme/constantes'
import NotFoundPage from 'plateforme/pages/NotFoundPage'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import {
  entrepriseRoles,
  entrepriseRolesMetiers,
  entrepriseRolesNotificationsDossiers,
  entrepriseRolesParametrage,
  Role,
} from 'plateforme/services/roles.services'
import RequireAuthInterceptor from 'plateforme/security/RequireAuthInterceptor'
import ConsultationDossierEntreprisePage from './pages/ConsultationDossierEntreprisePage'
import EditionDossierEntreprisePage from './pages/EditionDossierEntreprisePage'
import HomeEntreprisePage from './pages/HomeEntreprisePage'
import ParametresEntreprisePage from './pages/ParametresEntreprisePage'
import RechercheDossierEntreprisePage from './pages/RechercheDossierEntreprisePage'
import MenuEntreprisePart from './parts/menu/MenuEntreprisePart'
import RechercheHabilitationsEntreprisePage from './pages/RechercheHabilitationsEntreprisePage'
import RechercheMedecinsEntreprisePage from './pages/RechercheMedecinsEntreprisePage'
import FicheMedecinEntreprisePage from './pages/FicheMedecinEntreprisePage'
import RechercheFacturesEntreprisePage from './pages/RechercheFacturesEntreprisePage'
import CreationMissionEntreprisePage from './pages/CreationMissionEntreprisePage'
import RechercheFluxTechniquesEntreprisePage from './pages/RechercheFluxTechniquesEntreprisePage'
import RechercheNotificationsDossierEntreprisePage from './pages/RechercheNotificationsDossierEntreprisePage'
import RechercheNotificationsMessageEntreprisePage from './pages/RechercheNotificationsMessageEntreprisePage'

/**
 * Home Entreprise
 * Href = /extranet/entreprise/
 */
export const basePath = '/'
export const homeHref = toEntrepriseHref(basePath)

/**
 * Recherche de dossiers Entreprise
 * Href = /extranet/entreprise/dossiers
 */
export const rechercheDossiersPath = 'dossiers'
export const rechercheDossiersHref = toEntrepriseHref(rechercheDossiersPath)

/**
 * Recherche de factures Entreprise
 * Href = /extranet/entreprise/factures
 */
export const rechercheFacturesPath = 'factures'
export const rechercheFacturesHref = toEntrepriseHref(rechercheFacturesPath)

/**
 * Consultation du profil Entreprise
 * Href = /extranet/entreprise/profil
 */
export const profilPath = 'profil'
export const profilHref = toEntrepriseHref(profilPath)

/**
 * Modifier dossier
 * Href = /extranet/entreprise/dossier/:codeDossier/edit
 */
export const editionDossierPath = 'dossier/:codeDossier/edit'
export function editionDossierHref(codeDossier: string) {
  return toEntrepriseHref(editionDossierPath).replace(':codeDossier', codeDossier)
}

/**
 * Consultation de dossier Entreprise
 * Href = /extranet/entreprise/dossier/:codeDossier
 */
export const consultationDossierPath = 'dossier/:codeDossier'
export function consultationDossierHref(codeDossier: string) {
  return toEntrepriseHref(consultationDossierPath).replace(':codeDossier', codeDossier)
}

/**
 * Consultation de la fiche Médecin detailles
 * Href = /extranet/entreprise/medecin/:codeMedecin
 */
export const consultationFicheMedecinPath = 'medecin/:codeMedecin'
export function consultationFicheMedecinHref(codeMedecin: string) {
  return toEntrepriseHref(consultationFicheMedecinPath).replace(':codeMedecin', codeMedecin)
}

/**
 * Missioner médecin
 * Href = /extranet/entreprise/medecin/:codeMedecin/mission
 */
export const missionerMedecinPath = '/:codeDossier/mission'
export function missionerMedecinHref(codeDossier: string) {
  return toEntrepriseHref(missionerMedecinPath).replace(':codeDossier', codeDossier)
}

/**
 * Consultation de details des habilitation du profil Médecin
 * Href = /extranet/entreprise/habilitations
 */
export const listeHabilitationsPath = 'habilitations'
export const listeHabilitationsHref = toEntrepriseHref(listeHabilitationsPath)

/**
 * Consultation de medecins par un assureur
 * Href = /extranet/entreprise/medecins
 */
export const listeMedecinsPath = 'medecins'
export const listeMedecinsHref = toEntrepriseHref(listeMedecinsPath)

/**
 * Upload urls
 */
export function toUploadDossierDocumentUrl(codeDossier: string) {
  return `${transmedApiUrl}entreprise/dossier/${codeDossier}/document`
}

/**
 * Consultation des échanges techniques
 * Href = /extranet/entreprise/flux-technique
 */
export const listeFluxTechniquePath = 'flux-technique'
export const listeFluxTechniqueHref = toEntrepriseHref(listeFluxTechniquePath)

/**
 * Consultation des notifications dossiers
 * Href = /extranet/entreprise/notifications/dossiers
 */
export const listeNotificationsDossiersPath = 'notifications/dossiers'
export const listeNotificationsDossiersHref = toEntrepriseHref(listeNotificationsDossiersPath)

/**
 * Consultation des notifications messages
 * Href = /extranet/entreprise/notifications/messages
 */
export const listeNotificationsMessagesPath = 'notifications/messages'
export const listeNotificationsMessagesHref = toEntrepriseHref(listeNotificationsMessagesPath)

/**
 * Router Entreprise
 * Root = /extranet/entreprise/
 * par defaut tout les roles entreprises sont authorisés {@see assureurRoles}
 */
export default function EntrepriseApp() {
  return (
    <Routes>
      <Route path={basePath} element={<EntrepriseLayout />}>
        <Route
          index
          element={
            <RequireAuthInterceptor roles={entrepriseRoles}>
              <ErrorBoundary key="home-entreprise-error" FallbackComponent={ErrorFallback}>
                <HomeEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={rechercheDossiersPath}
          element={
            <RequireAuthInterceptor roles={entrepriseRolesMetiers}>
              <ErrorBoundary key="recherche-dossier-entreprise-error" FallbackComponent={ErrorFallback}>
                <RechercheDossierEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={rechercheFacturesPath}
          element={
            <RequireAuthInterceptor roles={entrepriseRolesMetiers}>
              <ErrorBoundary key="recherche-facture-entreprise-error" FallbackComponent={ErrorFallback}>
                <RechercheFacturesEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={profilPath}
          element={
            <RequireAuthInterceptor roles={entrepriseRolesParametrage}>
              <ErrorBoundary key="profil-entreprise-error" FallbackComponent={ErrorFallback}>
                <ParametresEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={missionerMedecinPath}
          element={
            <RequireAuthInterceptor roles={[Role.ASSU_GESTIONNAIRE]}>
              <ErrorBoundary key="missionner-medecin-error" FallbackComponent={ErrorFallback}>
                <CreationMissionEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={consultationDossierPath}
          element={
            <RequireAuthInterceptor roles={entrepriseRolesMetiers}>
              <ErrorBoundary key="consultation-dossier-entreprise-error" FallbackComponent={ErrorFallback}>
                <ConsultationDossierEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={editionDossierPath}
          element={
            <RequireAuthInterceptor roles={[Role.ASSU_GESTIONNAIRE]}>
              <ErrorBoundary key="edition-dossier-entreprise-error" FallbackComponent={ErrorFallback}>
                <EditionDossierEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeHabilitationsPath}
          element={
            <RequireAuthInterceptor roles={entrepriseRoles}>
              <ErrorBoundary key="liste-habilitation-entreprise-error" FallbackComponent={ErrorFallback}>
                <RechercheHabilitationsEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        {/* todo : à tester pour les medecins non habilites */}
        <Route
          path={consultationFicheMedecinPath}
          element={
            <RequireAuthInterceptor roles={entrepriseRoles}>
              <ErrorBoundary key="consultation-profil-medecin-entreprise-error" FallbackComponent={ErrorFallback}>
                <FicheMedecinEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeMedecinsPath}
          element={
            <RequireAuthInterceptor roles={[Role.ASSU_ADMIN_RESEAU_MEDECIN]}>
              <ErrorBoundary key="recherche-medecin-entreprise-error" FallbackComponent={ErrorFallback}>
                <RechercheMedecinsEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeFluxTechniquePath}
          element={
            <RequireAuthInterceptor roles={[Role.ASSU_REFERENT_TECH_FLUX]}>
              <ErrorBoundary key="recherche-flux-technique-entreprise-error" FallbackComponent={ErrorFallback}>
                <RechercheFluxTechniquesEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeNotificationsDossiersPath}
          element={
            <RequireAuthInterceptor roles={entrepriseRolesNotificationsDossiers}>
              <ErrorBoundary key="recherche-notifications-dossiers-error" FallbackComponent={ErrorFallback}>
                <RechercheNotificationsDossierEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path={listeNotificationsMessagesPath}
          element={
            <RequireAuthInterceptor roles={[Role.ASSU_ADMIN_RESEAU_MEDECIN]}>
              <ErrorBoundary key="recherche-notifications-messages-error" FallbackComponent={ErrorFallback}>
                <RechercheNotificationsMessageEntreprisePage />
              </ErrorBoundary>
            </RequireAuthInterceptor>
          }
        />
        <Route
          path="*"
          element={
            <ErrorBoundary key="not-found-entreprise-error" FallbackComponent={ErrorFallback}>
              <NotFoundPage />
            </ErrorBoundary>
          }
        />
      </Route>
    </Routes>
  )
}

/* Layout utilisé par toute les pages de la plateforme entreprise */
function EntrepriseLayout() {
  const theme = useTheme()
  const margin = useMediaQuery(theme.breakpoints.up('xl'))
    ? theme.spacing(PAGE_PADDING_Y, PAGE_LARGE_PADDING_X)
    : theme.spacing(PAGE_PADDING_X)
  return (
    <>
      {/* Nav Bar spécifique entreprise */}
      <MenuEntreprisePart />
      {/* An <Outlet> should be used in parent route elements to render their child route elements. */}
      <Box margin={margin} paddingBottom={1.5}>
        <Outlet />
      </Box>
    </>
  )
}
