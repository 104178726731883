import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyTextField } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import DossierAdmin from 'admin/store/types/dossierAdmin'

type GestionDossierEntreprisePartProps = {
  dossier?: DossierAdmin
}

export default function InformationsAssureAdminPart({
  dossier,
  loading,
}: GestionDossierEntreprisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={219.5} />
  }
  return <InformationsAssureEntreprisePartLoaded dossier={dossier} />
}

function InformationsAssureEntreprisePartLoaded({ dossier }: GestionDossierEntreprisePartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  const { assure } = dossier

  return (
    <Card>
      <CardHeader title="Informations assuré" />
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <ReadOnlyTextField id="numéro-contrat" label="Référence assuré" value={assure?.numeroContrat} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyTextField id="code-Assure" label="Code assuré" value={assure?.codeAssure} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyTextField id="nom" label="Nom assuré" value={assure?.nom} fullWidth />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
