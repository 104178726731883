import React, { useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { CheckboxInput, EditTextField, ParametrageNameEnum, SelectInputParametrage } from 'plateforme/components'
import Referentiel from 'plateforme/store/types/referentiel'
import { MSG_FIELD_REQUIRED } from 'plateforme/constantes'
import { validateRequiredWithCondition } from 'plateforme/services/utils'
import { determineConsentementRequis } from 'plateforme/services/dossier.services'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import SelectInputCadreExpertise from 'assureur/components/SelectInputCadreExpertise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type ExpertiseFormProps = {
  dossier: DossierEntreprise
  profilEntreprise: ProfilEntreprise
  referentiel: Referentiel
}

export default function ExpertiseForm({ dossier, profilEntreprise, referentiel }: ExpertiseFormProps) {
  const { control, clearErrors, getValues, resetField, watch } = useFormContext()
  const [labelDomaine, setLabelDomaine] = useState('Aucune mission spécifique pour le dossier')
  const showDomaineContractuel = profilEntreprise?.parametrage?.domainesContractuels
  const cadreExpertiseWatched = watch('expertise.cadreExpertise')
  const consentementRequis = determineConsentementRequis(cadreExpertiseWatched, referentiel)

  return (
    <Card>
      <CardHeader title="Expertise" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={showDomaineContractuel ? 6 : 12}>
            <Controller
              name="expertise.cadreExpertise"
              control={control}
              rules={{ required: MSG_FIELD_REQUIRED }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputCadreExpertise
                  id="expertise-cadre-expertise"
                  label={`Cadre de l'expertise`}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e: string) => {
                    if (!determineConsentementRequis(e, referentiel)) {
                      clearErrors(['consentement', 'refConsentement'])
                      resetField('consentement', {
                        defaultValue: getValues('consentement'),
                      })
                      resetField('refConsentement', {
                        defaultValue: getValues('refConsentement'),
                      })
                    }
                    onChange(e)
                  }}
                  referentielName="cadresExpertise"
                  readOnly={!dossier.editableMajeure}
                  fullWidth
                  withNoSelectionItem
                  fieldError={error}
                  required
                />
              )}
            />
          </Grid>
          {showDomaineContractuel && (
            <Grid item xs={12} sm={6}>
              <Controller
                name="expertise.domaineContractuel"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputParametrage
                    optionsAdded={
                      !dossier?.expertise?.domaineContractuel?.actif && dossier?.expertise?.domaineContractuel
                        ? [
                            {
                              code: dossier?.expertise?.domaineContractuel?.code ?? '',
                              label: `${dossier?.expertise?.domaineContractuel?.libelle} (inactive)` ?? '',
                            },
                          ]
                        : undefined
                    }
                    id="expertise-domaine-contractuel "
                    label={labelDomaine}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      if (e === '') {
                        setLabelDomaine('Aucune mission spécifique pour le dossier')
                      } else {
                        setLabelDomaine('Mission spécifique')
                      }
                      onChange(e)
                    }}
                    parametrageName={ParametrageNameEnum.DOMAIN_CONTRACTUEL}
                    readOnly={!dossier.editableMajeure}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Controller
              name="consentement"
              control={control}
              rules={{ required: validateRequiredWithCondition(consentementRequis) }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <CheckboxInput
                  id="consentement"
                  name="consentement"
                  label="RGPD : Avez-vous recueilli le consentement de la personne à examiner ?"
                  labelPlacement="start"
                  checked={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fieldError={error}
                  formControlSx={{ display: 'flex', flexDirection: 'row' }}
                  checkboxSx={{ padding: 0, marginX: 1.5, marginTop: 1, display: 'block' }}
                  errorSx={{
                    marginX: 2,
                    marginTop: 0,
                    lineHeight: '24px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  required={consentementRequis}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="refConsentement"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="ref-consentement"
                  label="Référence du recueil de consentement"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Controller
              name="expertise.prejudiceEnAggravation"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} variant="standard">
                  <FormControlLabel
                    id="expertise-prejudice-en-aggravation"
                    value={value}
                    control={<Switch onBlur={onBlur} onChange={onChange} checked={value} />}
                    label="Mission en aggravation"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="expertise.garantieApplicable"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-garantie-applicable"
                  label="Garanties applicables"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                  multiline
                  minRows={3}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="expertise.commentaire"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-commentaire"
                  label="Commentaire"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={3}
                  fieldError={error}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
