import React from 'react'
import { Grid, Stack } from '@mui/material'
import { useGetMedecinsPartenaireQuery, useGetMedecinsPlateformeQuery } from 'assureur/store/apis/medecinEntrepriseApi'
import RechercheMedecins from './RechercheMedecins'

interface RechercheMedecinsComponentProps {
  withHabilitationActive?: boolean
  cardsNavigable?: boolean
}

export default function RechercheMedecinsComponent({
  withHabilitationActive = false,
  cardsNavigable = true,
}: RechercheMedecinsComponentProps) {
  return (
    <Grid item xs={12}>
      <Stack>
        <RechercheMedecins
          useQuery={withHabilitationActive ? useGetMedecinsPartenaireQuery : useGetMedecinsPlateformeQuery}
          queryParam={withHabilitationActive ? { actif: 1 } : undefined}
          cardsNavigable={cardsNavigable}
          chipCardHidden={withHabilitationActive}
          withHabilitationActive={withHabilitationActive}
        />
      </Stack>
    </Grid>
  )
}
