import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { AnnulerButton, EditTextField, ValiderButton } from 'plateforme/components'
import {
  SuiviDossierAction,
  SuiviDossierRequest,
  usePostSuiviDossierMutation,
} from 'assureur/store/apis/dossierAssureurApi'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

import { useSnackbar } from 'notistack'
import { trimToUndefined } from 'plateforme/services/utils'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'

interface ActionDossierFormProps {
  dossier: DossierEntreprise
  action: SuiviDossierAction
  msgSucces: string
  msgEchec: string
  onClose: VoidFunction
  motifCloture?: string
}

type ActionDossierData = {
  motifCloture: string
  commentaire: string
}

export default function ActionDossierForm({
  dossier,
  action,
  msgSucces,
  msgEchec,
  onClose,
  motifCloture,
}: ActionDossierFormProps) {
  const { code: codeDossier } = dossier

  if (codeDossier === undefined) {
    throw new Error(`Aucun dossier  n'a été trouvé`)
  }

  const [postSuiviDossier, { error: errorPostSuiviDossier, isLoading: isLoadingSuiviDossier }] =
    usePostSuiviDossierMutation()

  const { enqueueSnackbar } = useSnackbar()

  const {
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      commentaire: '',
      motifCloture: '',
    } as ActionDossierData,
  })

  // mapping errors to form:
  useErrorFormMapper(errorPostSuiviDossier as IQueryErrorResponse, setError, getValues)

  const onSubmit = async (data: ActionDossierData) => {
    /**
     * request pour l'appel API
     */
    const request: SuiviDossierRequest = {
      codeDossier,
      action,
      commentaire: trimToUndefined(data.commentaire),
      motifCloture: motifCloture !== undefined ? motifCloture : trimToUndefined(data.motifCloture),
    }

    /**
      appel API
     */
    await postSuiviDossier(request)
      .unwrap()
      .then(() => {
        enqueueSnackbar(msgSucces, { variant: 'success' })
        onClose()
      })
      .catch(() => {
        enqueueSnackbar(msgEchec, { variant: 'error' })
      })
  }

  /* Note : A ne pas supprimer ce code, il est en revision fonctionnelle :    
    const motifFilter = (ref: ReferentielDetail) =>
    ref.code !== CodeMotifCloture.AUTOMATIQUE && ref.code !== CodeMotifCloture.RETRAIT_CONSENTEMENT */

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form-suivi-dossier" style={{ width: '100%' }}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {/* Note : A ne pas supprimer ce code, il est en revision fonctionnelle :         
          {action === SuiviDossierAction.CLOTURER && motifCloture === undefined && (
          <Grid item xs={12}>
            <Controller
              name="motifCloture"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="motifs-cloture"
                  label="Motif de Clôture"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  referentielName="motifsCloture"
                  fullWidth
                  fieldError={error}
                  dataFilter={motifFilter}
                />
              )}
            />
          </Grid>
        )} */}
        <Grid item xs={12}>
          <Controller
            name="commentaire"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                multiline
                id="commentaire"
                value={value}
                label="Commentaire"
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                minRows={3}
                fieldError={error}
              />
            )}
          />
        </Grid>
        <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
          <Grid item>
            <AnnulerButton onClick={onClose} loading={isLoadingSuiviDossier}>
              Annuler
            </AnnulerButton>
          </Grid>
          <Grid item>
            <ValiderButton disabled={!isValid} type="submit" loading={isLoadingSuiviDossier}>
              Confirmer
            </ValiderButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
