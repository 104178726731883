import React from 'react'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { ReferentielName } from 'plateforme/store/types/referentiel'

type ReferentielTextProps = {
  referentielName: ReferentielName
  referentielCode: string
}

export default function ReferentielText({ referentielName, referentielCode }: ReferentielTextProps) {
  const { data, isSuccess } = useGetReferentielQuery()

  const maybeLabel = data?.[referentielName]?.find((ref) => ref.code === referentielCode)?.label
  if (isSuccess && maybeLabel) {
    return <span>{maybeLabel}</span>
  }

  return <span>{referentielCode}</span>
}
