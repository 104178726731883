import { nanoid } from '@reduxjs/toolkit'
import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import { UploadState } from 'plateforme/store/slices/uploadSlice'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query'
import {
  AuthDownloadAction,
  AuthDownloadDocumentDossierRequest,
  AuthDownloadRequest,
} from 'plateforme/store/types/utilisateur'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { ALLOWED_UPLOAD_FORMATS } from 'plateforme/constantes'

export default function makeUpload(file: File, typeDocument?: string): DocumentUpload {
  return {
    key: nanoid(),
    state: UploadState.IDLE,
    dateDocument: null,
    nomDocument: '',
    typeDocument,
    nomFichier: file.name,
    tailleFichier: file.size,
    formatFichier: file.type,
    progress: 0,
    isValid: false,
  }
}

export const lettreMissionTelechargeable = (
  missions: [MissionEntreprise | MissionMedecin] | undefined,
  codeMission: string | undefined
) => {
  if (missions === undefined || codeMission === undefined) {
    return false
  }

  return missions?.find((m) => m.code === codeMission)?.documents?.some((d) => d.typeDocument === 'LM')
}

export const downloadLastLettreMission: (
  missions: [MissionEntreprise | MissionMedecin] | undefined,
  codeDossier: string,
  codeMission: string | undefined,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  authDownload: MutationTrigger<
    MutationDefinition<
      AuthDownloadRequest,
      // eslint-disable-next-line @typescript-eslint/ban-types
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
      'Utilisateur',
      void,
      'utilisateurApi'
    >
  >
) => Promise<void> = async (
  missions: [MissionEntreprise | MissionMedecin] | undefined,
  codeDossier: string,
  codeMission: string | undefined,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  authDownload: MutationTrigger<
    MutationDefinition<
      AuthDownloadRequest,
      // eslint-disable-next-line @typescript-eslint/ban-types
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
      'Utilisateur',
      void,
      'utilisateurApi'
    >
  >
) => {
  if (missions !== undefined) {
    const lastLettreMission = missions
      ?.find((m: MissionEntreprise | MissionMedecin) => m.code === codeMission)
      ?.documents?.filter((d: DocumentTeleverse) => d.typeDocument === 'LM')
      .pop()

    if (!codeDossier || !lastLettreMission) {
      throw new Error('Erreur lors du téléchargement de la lettre de mission')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: lastLettreMission.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, lastLettreMission.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement de la lettre de mission a échoué`, { variant: 'error' })
      })
  }
}

export function determineFormat(format?: string) {
  return Object.entries(ALLOWED_UPLOAD_FORMATS)
    .filter((e) => e[0] === format)
    .map((e) => e[1])
    .filter((v) => v && v.length > 0)
    .map((v) => v[0])
    .map((v) => v.replace('.', ''))
    .map((v) => v.toUpperCase())
}

export function toHumanReadableFileSize(sizeInBytes?: number) {
  if (sizeInBytes === undefined || Number.isNaN(sizeInBytes)) {
    return '—'
  }

  const [divisor, unit] = sizeInBytes < 102400 ? [1024, 'Ko'] : [1048576, 'Mo']
  return `${(sizeInBytes / divisor).toFixed(2)} ${unit}`
}
