import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from './transmedApi'
import Actualite from '../types/actualite'

export const listeActualitesApi = createApi({
  reducerPath: 'listeActualitesApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getListeActualites: builder.query<Actualite[], void>({
      query: () => 'utilisateur/actualite',
    }),
  }),
})

export const { useGetListeActualitesQuery } = listeActualitesApi
