import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Snackbar,
  Stack,
} from '@mui/material'
import { ButtonsStack, ChercherButton, EditTextField, RechargerButton } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatBooleanFilter } from 'plateforme/services/apis.services'
import { trimToUndefined } from 'plateforme/services/utils'
import { MedecinEntrepriseFilter } from 'assureur/store/types/medecinEntreprise'
import { useSelector } from 'react-redux'
import { getMedecinCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'

type SearchMedecinsProps = {
  search: (criteria: MedecinEntrepriseFilter) => void
  initSearch: VoidFunction
  searchError: IQueryErrorResponse
}

export interface MedecinFilterData {
  nomMedecin?: string
  codeMedecin?: string
  habilitationExistante?: boolean
  habilitationActive?: boolean
  habilitable?: boolean
  irca?: boolean
  codePostal?: string
}

export default function RechercheMedecinsEntreprisePart({ search, initSearch, searchError }: SearchMedecinsProps) {
  // get default values from localStorage
  const defaultMedecinCriteria = useSelector(getMedecinCriteria) as MedecinEntrepriseFilter

  // Les valeurs initiales du formulaire :
  const initialValues: MedecinFilterData = {
    nomMedecin: defaultMedecinCriteria?.nomMedecin,
    codeMedecin: defaultMedecinCriteria?.codeMedecin,
    habilitationExistante: defaultMedecinCriteria?.habilitationExistante === 1,
    habilitationActive: defaultMedecinCriteria?.habilitationActive === 1,
    habilitable: defaultMedecinCriteria?.habilitable === 1,
    irca: defaultMedecinCriteria?.irca === 1,
    codePostal: defaultMedecinCriteria?.codePostal,
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: MedecinFilterData) => {
    search({
      nomMedecin: trimToUndefined(data.nomMedecin),
      codeMedecin: trimToUndefined(data.codeMedecin),
      habilitationExistante: formatBooleanFilter(data.habilitationExistante),
      irca: formatBooleanFilter(data.irca),
      codePostal: trimToUndefined(data.codePostal),
    })
  }

  const handleClickInitSearch = () => {
    initSearch()
    reset({
      nomMedecin: '',
      codeMedecin: '',
      habilitationExistante: defaultMedecinCriteria?.habilitationExistante === 1,
      habilitationActive: defaultMedecinCriteria?.habilitationActive === 1,
      habilitable: defaultMedecinCriteria?.habilitable === 1,
      irca: defaultMedecinCriteria?.irca === 1,
      codePostal: '',
    })
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-medecins">
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="nomMedecin"
                control={control}
                defaultValue={initialValues.nomMedecin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="nom-medecin"
                    label="Nom du médecin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid container item xs={8} md={8} sm={12}>
                <Grid item xs={6} sm={12} md={6}>
                  <Controller
                    name="codeMedecin"
                    control={control}
                    defaultValue={initialValues.codeMedecin}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        id="id-medecin-plateforme"
                        label="Identifiant médecin plateforme"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={12} md={6}>
                  <Controller
                    name="codePostal"
                    control={control}
                    defaultValue={initialValues.codePostal}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        id="numero-agrement"
                        label="Code postal du lieu d'exercice"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} sm={6} md={4}>
                <Stack direction="column" alignItems="flex-start" justifyContent="center" spacing={0} height="100%">
                  <Controller
                    name="habilitationExistante"
                    control={control}
                    defaultValue={initialValues.habilitationExistante}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <FormControl error={error !== undefined}>
                        <FormGroup>
                          <FormControlLabel
                            label="Antérieurement habilité"
                            sx={{ height: 30 }}
                            control={
                              <Checkbox
                                name="habilitationExistante"
                                checked={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                sx={{ padding: 0, marginX: 1.5 }}
                              />
                            }
                          />
                        </FormGroup>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="irca"
                    control={control}
                    defaultValue={initialValues.irca}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <FormControl error={error !== undefined}>
                        <FormGroup>
                          <FormControlLabel
                            label="Médecin IRCA"
                            sx={{ height: 30 }}
                            control={
                              <Checkbox
                                name="typologieMedecin"
                                checked={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                              />
                            }
                          />
                        </FormGroup>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton variant="contained" type="submit" form="form-recherche-medecins" disabled={!isValid}>
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des medecins non habilités
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
