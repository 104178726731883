import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import { trimToUndefined } from 'plateforme/services/utils'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import createUploader from 'plateforme/store/slices/uploadSlice/uploadSlice'
import { toUploadDossierDocumentUrl } from 'assureur/EntrepriseApp'
import StartUploadDocumentDossierConfig from './startUploadDocumentDossierConfig'

export const documentEntrepriseUploadSliceName = 'documentUpload'
const { slice, actions, reducer } = createUploader<DocumentUpload, DocumentTeleverse>(documentEntrepriseUploadSliceName)

export const documentDossierUploadSlice = slice

export const {
  add: addDocumentEntrepriseUpload,
  change: changeDocumentEntrepriseUpload,
  remove: removeDocumentEntrepriseUpload,
  clear: clearDocumentEntrepriseUpload,
  setValid: setDocumentEntrepriseUploadValid,
} = actions

export const startUploadDocumentEntreprise = ({ codeDossier, uploadFileMap }: StartUploadDocumentDossierConfig) =>
  actions.startUpload({
    uploadUrl: toUploadDossierDocumentUrl(codeDossier),
    toFormData: (upload, file) => {
      const formData = new FormData()
      formData.append(
        'metadata',
        JSON.stringify({
          typeDocument: upload.typeDocument,
          nomDocument: trimToUndefined(upload.nomDocument),
          dateDocument: upload.dateDocument,
        })
      )
      formData.append('file', file, upload.nomFichier)
      return formData
    },
    toUploadResponse: (status, response) => {
      if (status === 201) {
        return { type: 'SUCCESS', status, body: response as DocumentTeleverse }
      }
      return {
        type: 'ERROR',
        status,
        body: {
          code: 'réponse inattendue',
          message: JSON.stringify(response),
        },
      }
    },
    uploadFileMap,
  })

export default reducer
