import React from 'react'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import ListeActualitesAdminPart from 'admin/part/ListeActualitesAdminPart'
import { useGetListeActualitesAdminQuery } from 'admin/store/apis/actualiteAdminApi'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { AjouterButton } from 'plateforme/components'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import EditerActualiteForm from 'admin/part/ListeActualitesAdminPart/EditerActualiteForm'

export default function GererActualitesPage() {
  const { data: actualites, isLoading, isFetching, isError, isSuccess } = useGetListeActualitesAdminQuery()
  const { confirm, confirmDialog, closeConfirmDialog } = useConfirmDialog()

  const onCreerActualite = () => {
    confirm({
      title: `Créer une actualité`,
      confirmMsg: '',
      withForm: true,
      maxWidth: 'md',
      form: <EditerActualiteForm onClose={closeConfirmDialog} />,
    })
  }

  return (
    <>
      {confirmDialog}
      <Stack>
        <NavigationPart label="Gérer les actualités" />
        <Card>
          <CardHeader title="Liste des actualités" />
          <CardContent>
            <Stack>
              <Stack direction="row" justifyContent="right">
                <AjouterButton onClick={onCreerActualite}>Ajouter une actualité</AjouterButton>
              </Stack>
              <ListeActualitesAdminPart
                combinedData={actualites ?? []}
                total={actualites?.length}
                nbLigneParPage={5}
                isError={isError}
                isLoading={isLoading}
                isSuccess={isSuccess}
                isFetching={isFetching}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  )
}
