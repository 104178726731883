import React from 'react'
import { Card, CardProps, styled, Typography } from '@mui/material'

interface StylesCardProps extends CardProps {
  modeError?: boolean
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'modeError',
})<StylesCardProps>(({ theme, modeError }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  wordWrap: 'break-word',
  width: '100%',
  backgroundColor: modeError ? theme.palette.error.main : theme.palette.primary.main,
  borderRadius: '10px',
}))

interface TitleChipProps {
  title: string
  modeError?: boolean
  content?: string
  onClick?: VoidFunction
}

export default function TitleChip({ title, modeError = false, content, onClick }: TitleChipProps) {
  return (
    // onClick lien Url sur le titre de la chip
    // curseur pointer si url précisé pour le onClick
    <StyledCard onClick={onClick} sx={{ cursor: onClick !== undefined ? 'pointer' : 'default' }} modeError={modeError}>
      <Typography padding={0.5} color="#fff" variant="h5">
        {title}
      </Typography>
      {content && (
        <Typography padding={0.5} color="#fff" variant="subtitle2">
          {content}
        </Typography>
      )}
    </StyledCard>
  )
}
