import * as React from 'react'

import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export default function AttacherPJButton(props: LoadingButtonProps) {
  const { color, children, variant, ...other } = props
  return (
    <LoadingButton
      variant={variant ?? 'contained'}
      color={color ?? 'primary'}
      startIcon={<AttachEmailIcon />}
      loadingPosition="start"
      {...other}
    >
      {children}
    </LoadingButton>
  )
}
