import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { useGetListeMessagesQuery } from 'assureur/store/apis/messageEntrepriseApi'
import RechercheNotificationsDossierEntreprisePart from 'assureur/parts/rechercheNotificationDossier/RechercheNotificationsDossierEntreprisePart'
import ListeNotificationsDossierEntreprisePart from 'assureur/parts/rechercheNotificationDossier/ListeNotificationsDossierEntreprisePart'
import { getNotificationCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'
import { NotificationEntrepriseFilter } from 'assureur/store/types/notificationsEntreprise'

export default function RechercheNotificationsDossierEntreprisePage() {
  const defaultNotificationCriteria = useSelector(getNotificationCriteria) as NotificationEntrepriseFilter
  const {
    combinedData,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading: listeNotificationLoading,
    isFetching,
    isError: isErrorListeNotifications,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeMessagesQuery, {
    defaultCriteria: defaultNotificationCriteria,
  })

  const isLoading = listeNotificationLoading

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isErrorListeNotifications) {
      enqueueSnackbar('La Recherche des notifications a échoué', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorListeNotifications])

  return (
    <Stack>
      <NavigationPart label="Notifications" />
      <RechercheNotificationsDossierEntreprisePart search={search} searchError={error} />
      <ListeNotificationsDossierEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeNotifications}
        isSuccess={isSuccess}
      />
    </Stack>
  )
}
