import * as React from 'react'
import { SvgIconProps, useTheme } from '@mui/material'
import { ExitToApp } from '@mui/icons-material'

export default function DeconnexionIcon(props: SvgIconProps & { withMargin?: boolean }) {
  const { withMargin, sx: otherSx, ...other } = props
  const theme = useTheme()
  const iconSx = withMargin ? { margin: theme.spacing(1.5), ...otherSx } : { ...otherSx }
  return <ExitToApp sx={iconSx} {...other} />
}
