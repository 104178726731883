import { LibelleRapport, Rapport, StatutRapport, TypeRapport } from 'plateforme/store/types/rapportConclusion'
import DocumentTeleverse from '../store/types/documentTeleverse'
import MissionPlateforme, { StatutMission } from '../store/types/mission'
import { TypeFicheConclusion } from '../store/types/referentiel'
import { ReferenceParametrageEntreprise } from '../store/types/profilEntreprise'
import { DEFAULT_MAX_PERIODES } from '../constantes'

export default function labelTypeRapport(rapport: Rapport) {
  if (rapport === undefined) {
    return null
  }

  if (isRapportConclusionProvisoire(rapport)) {
    return 'Conclusions provisoires'
  }
  if (isRapportConclusionDefinitive(rapport)) {
    return 'Conclusions définitives'
  }
  if (isRapportCarence(rapport)) {
    return 'Carence'
  }
  return 'Conclusion'
}

function getTypeRapport(value: Rapport | TypeRapport) {
  return Object.values(TypeRapport).includes(value as TypeRapport)
    ? (value as TypeRapport)
    : (value as Rapport).typeRapport
}

export function isRapportCarence(value?: Rapport | TypeRapport) {
  if (!value) {
    return false
  }
  const typeRapport = getTypeRapport(value)
  return typeRapport === TypeRapport.CARENCE
}

export function isRapportConclusionProvisoire(value?: Rapport | TypeRapport) {
  if (!value) {
    return false
  }
  const typeRapport = getTypeRapport(value)
  return typeRapport === TypeRapport.CONCLUSION_PROVISOIRE
}

export function isRapportConclusionDefinitive(value?: Rapport | TypeRapport) {
  if (!value) {
    return false
  }
  const typeRapport = getTypeRapport(value)
  return typeRapport === TypeRapport.CONCLUSION_DEFINITIVE
}

export function determineLibelleRapport(value?: TypeRapport) {
  switch (value) {
    case TypeRapport.CARENCE:
      return LibelleRapport.CARENCE

    case TypeRapport.CONCLUSION_DEFINITIVE:
      return LibelleRapport.CONCLUSION_DEFINITIVE

    case TypeRapport.CONCLUSION_PROVISOIRE:
      return LibelleRapport.CONCLUSION_PROVISOIRE

    default:
      return 'Conclusion'
  }
}

export function determineDocumentsRapport(mission?: MissionPlateforme): DocumentTeleverse[] {
  const rapport = mission?.rapport
  const modifiable = (mission && 'rapportModifiable' in mission && mission.rapportModifiable) ?? false
  return (
    rapport?.documents?.map((document) => {
      return {
        supprimable: modifiable && document.supprimable,
        ...document,
      }
    }) ?? []
  )
}

export function isValidationPrimaire(rapport?: Rapport) {
  return rapport?.statut !== StatutRapport.EN_COURS
}
export function isValideDefinitivement(rapport?: Rapport) {
  return [StatutRapport.VALIDE_DEFINITIVEMENT, StatutRapport.VALIDE_DEFINITIVEMENT_EN_COURS].includes(
    rapport?.statut as StatutRapport
  )
}
export function isFicheSimplifiee(rapport?: Rapport) {
  return rapport?.typeFicheConclusion === TypeFicheConclusion.SIMP
}

export function isRapportDemandeModification(mission: MissionPlateforme) {
  return mission.statut === StatutMission.EN_DEMANDE_DE_MODIFICATION
}

export const determineShowDommageCommentaire = (
  code?: string,
  parametrage?: ReferenceParametrageEntreprise
): boolean => {
  const dommagesCommentairesMax = parametrage?.dommagesCommentairesMax
  switch (code) {
    case 'DATES_HOSPITALISATION_IMPUTABLES':
      return (dommagesCommentairesMax?.DATES_HOSPITALISATION_IMPUTABLES ?? 0) > 0
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return (dommagesCommentairesMax?.DATES_ARRETS_DE_TRAVAIL_IMPUTABLES ?? 0) > 0
    default:
      return false
  }
}

export function determineDommageMaxPeriodes(
  code: string,
  parametrage?: ReferenceParametrageEntreprise
): number | undefined {
  const dommagesPeriodesMax = parametrage?.dommagesPeriodesMax
  switch (code) {
    case 'DATES_HOSPITALISATION_IMPUTABLES':
      return dommagesPeriodesMax?.DATES_HOSPITALISATION_IMPUTABLES ?? DEFAULT_MAX_PERIODES
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
      return dommagesPeriodesMax?.DATES_ARRETS_DE_TRAVAIL_IMPUTABLES ?? DEFAULT_MAX_PERIODES
    case 'GENE_TEMPORAIRE_TOTALE':
      return dommagesPeriodesMax?.GENE_TEMPORAIRE_TOTALE ?? DEFAULT_MAX_PERIODES
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
      return dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_1 ?? DEFAULT_MAX_PERIODES
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
      return dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_2 ?? DEFAULT_MAX_PERIODES
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
      return dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_3 ?? DEFAULT_MAX_PERIODES
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_4 ?? DEFAULT_MAX_PERIODES
    case 'TIERCE_PERSONNE_DEFINITIVE':
      return dommagesPeriodesMax?.TIERCE_PERSONNE_DEFINITIVE ?? DEFAULT_MAX_PERIODES
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return dommagesPeriodesMax?.TIERCE_PERSONNE_TEMPORAIRE ?? DEFAULT_MAX_PERIODES
    default:
      return undefined
  }
}
