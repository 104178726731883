import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormControl, FormHelperText, Grid, Slider } from '@mui/material'
import { EditTextField } from 'plateforme/components'
import { clearRapportConseilErrors } from 'plateforme/services/utils'

// Description : slider  min, max et comentaire
interface DommageFieldSliderProps {
  name: string
  code: string
  marks: Array<{ value: number; label: string }>
  min?: number
  max?: number
  readOnly?: boolean
  disabled?: boolean
}

export default function RapportConseilFieldRangeSlider({
  name,
  code,
  marks,
  min,
  max,
  readOnly,
  disabled,
}: DommageFieldSliderProps) {
  const { clearErrors, control, getFieldState, resetField, setError, watch } = useFormContext()

  const fieldName = `dommages.${code}.rapportConseil`
  const errorPath = `${fieldName}._error`
  const { error: groupError } = getFieldState(errorPath)

  // remplace l'erreur global (dommages.${code}.rapportConseil) par l'erreur sur le commentaire + min + max
  useEffect(() => {
    if (groupError?.message) {
      setError(`${fieldName}.commentaire`, groupError)
      setError(`${fieldName}.min`, groupError)
      setError(`${fieldName}.max`, groupError)
    }
  }, [clearErrors, errorPath, fieldName, groupError, setError])

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const getMessageError = (error: any) => {
    const node = '_error'
    if (error && node in error) {
      return error?.[node]?.message
    }
    return error?.message
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} md={12}>
        <Box paddingX={1.5}>
          <Controller
            name={`${name}.valeur`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Controller
                name={`${name}.min`}
                control={control}
                render={({ fieldState: { error: errorMin } }) => (
                  <Controller
                    name={`${name}.max`}
                    control={control}
                    render={({ fieldState: { error: errorMax } }) => (
                      <FormControl
                        disabled={readOnly || disabled}
                        error={!!(error ?? errorMin ?? errorMax)}
                        variant="standard"
                        sx={{ display: 'flex', alignItems: 'flex-start' }}
                      >
                        <Slider
                          defaultChecked={undefined}
                          defaultValue={undefined}
                          disabled={readOnly || disabled}
                          id={code}
                          value={value}
                          onChange={(e) => {
                            clearRapportConseilErrors(resetField, watch, clearErrors)
                            onChange(e)
                          }}
                          step={0.5}
                          min={min}
                          max={max}
                          marks={marks}
                          valueLabelDisplay="auto"
                          color={error ?? errorMin ?? errorMax ? 'error' : 'primary'}
                          sx={{
                            '& .MuiSlider-thumb': {
                              display:
                                value === null || (value instanceof Array && value.every((v) => v === null))
                                  ? 'none'
                                  : 'flex',
                            },
                          }}
                        />
                        <FormHelperText sx={{ marginLeft: '2px' }}>
                          {getMessageError(error ?? errorMin ?? errorMax)}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                )}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} display="flex" alignItems="flex-start">
        <Controller
          name={`${name}.commentaire`}
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              fullWidth
              minRows={3}
              multiline
              id={code}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              label="Commentaire"
              fieldError={error}
              readOnly={readOnly}
              disabled={disabled}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
