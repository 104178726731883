import React from 'react'
import { DialogProps } from '@mui/material'
import { AnnulerButton, ButtonsStack, SupprimerButton, ValiderButton, DialogComponent } from '../index'

type ConfirmDialogProps = DialogProps & {
  title?: string
  confirmMsg?: string | React.ReactNode
  withForm?: boolean
  form?: React.ReactNode
  onYes?: VoidFunction
  onNo?: VoidFunction
  isLocalLoading?: boolean
  variant?: 'confirm' | 'delete'
  displayNo?: boolean
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    title,
    confirmMsg,
    form,
    withForm,
    onYes = () => {
      return undefined
    },
    onNo = () => {
      return undefined
    },
    variant,
    displayNo = true,
    isLocalLoading = false,
    open,
    ...other
  } = props

  return (
    <DialogComponent
      open={open}
      title={title ?? 'Confirmation'}
      confirmMsg={confirmMsg ?? 'Êtes-vous sûr de vouloir continuer ?'}
      dialogContentNode={
        withForm ? (
          form
        ) : (
          <ButtonsStack>
            {displayNo && <AnnulerButton onClick={onNo}>Annuler</AnnulerButton>}
            {variant === 'delete' ? (
              <SupprimerButton onClick={onYes} loading={isLocalLoading} autoFocus>
                Supprimer
              </SupprimerButton>
            ) : (
              <ValiderButton onClick={onYes} loading={isLocalLoading} autoFocus>
                Confirmer
              </ValiderButton>
            )}
          </ButtonsStack>
        )
      }
      {...other}
    />
  )
}
