import React from 'react'
import { FieldError, Noop } from 'react-hook-form'
import { TextFieldProps, useMediaQuery } from '@mui/material'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import { formatDateFR, isDateValide } from 'plateforme/services/dates.services'
import theme from 'plateforme/theme'
import { EditTextField } from '../index'
import ReadOnlyTextField from './ReadOnlyTextField'

type DocumentDatePickerProps<TInputDate, TDate> = Pick<
  DatePickerProps<TInputDate, TDate>,
  'label' | 'value' | 'onChange' | 'disabled' | 'readOnly'
> & {
  onBlur?: Noop
  InputProps: TextFieldProps
  fieldError?: FieldError
}

export default function DatePickerInput({
  label,
  value,
  onBlur,
  onChange,
  disabled,
  readOnly,
  InputProps,
  fieldError,
}: DocumentDatePickerProps<Date, Date>) {
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))

  if (readOnly) {
    return (
      <ReadOnlyTextField
        fullWidth={InputProps.fullWidth}
        id={InputProps.id}
        value={formatDateFR(value) ?? ' '}
        label={label}
      />
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <DatePicker
        disableOpenPicker={!isLg}
        inputFormat="dd/MM/yyyy"
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
        readOnly={readOnly}
        renderInput={(params) => (
          <EditTextField
            readOnly={readOnly}
            label={label}
            onBlur={onBlur}
            {...params}
            {...InputProps}
            inputProps={{
              ...params.inputProps,
              placeholder: 'jj/mm/aaaa',
            }}
            error={!isDateValide(value)}
            fieldError={fieldError}
            helperText={!isDateValide(value) && 'Date invalide'}
            value={readOnly && value === null ? ' ' : value}
            sx={{
              '& .MuiInputAdornment-root': {
                marginRight: '4px',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
