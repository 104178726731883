import React from 'react'
import { Typography } from '@mui/material'
import { AlertColor } from '@mui/material/Alert/Alert'
import { MessageAlert } from 'plateforme/components'

export default function InfosContactRequisesAlert({
  type = 'default',
  severity = 'info',
}: {
  type?: 'autreMedecin' | 'default'
  severity?: AlertColor
}) {
  return (
    <MessageAlert severity={severity} withGutter sx={{ '& .MuiAlert-message': { paddingTop: '6px' } }}>
      {type === 'autreMedecin' ? (
        <>
          La saisie d&apos;un autre médecin est optionnelle.
          <br /> Vous pouvez le faire :
          <ul style={{ margin: 0, paddingLeft: 24 }}>
            <li>en sélectionnant un médecin TransMED</li>
            <li>
              ou en renseignant un libellé et les informations de contact suivantes : mail ou numéro de téléphone ou
              adresse identifiable (une ligne d&apos;adresse, un code postal, une ville et un pays)
            </li>
          </ul>
        </>
      ) : (
        <>
          La saisie des informations de contact est requise
          <Typography color="error" display="inline">
            *
          </Typography>
          :
          <ul style={{ margin: 0, paddingLeft: 24 }}>
            <li>un mail</li>
            <li>ou un numéro de téléphone</li>
            <li>ou une adresse identifiable (une ligne d&apos;adresse, un code postal, une ville et un pays)</li>
          </ul>
        </>
      )}
    </MessageAlert>
  )
}
