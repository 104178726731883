import { RootState } from 'plateforme/store/index'
import { prepareHeadersWithState } from 'plateforme/security/keycloak'

type SuccessResponse<TBody> = {
  type: 'SUCCESS'
  status: number
  body: TBody
}

type ErrorResponse = {
  type: 'ERROR'
  status: number
  body: {
    code: string
    message: string
    details?: {
      errors: {
        message: string
        fields?: string[]
      }[]
    }
  }
}

export type UploadResponse<TBody> = SuccessResponse<TBody> | ErrorResponse

type RequestConfig<TBody> = {
  url: string
  state: RootState
  onUploadProgress: (percent: number) => void
  onUploadLoad: VoidFunction
  toResponse: (status: number, response: object) => UploadResponse<TBody>
  formData: FormData
}

export default function sendRequest<TBody>({
  url,
  state,
  onUploadLoad,
  onUploadProgress,
  toResponse,
  formData,
}: RequestConfig<TBody>) {
  return new Promise<UploadResponse<TBody>>((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url)
    xhr.responseType = 'json'
    prepareHeadersWithState(new Headers(), state).forEach((value, key) => xhr.setRequestHeader(key, value))

    xhr.onload = () => {
      if (xhr.status >= 400) {
        resolve({
          type: 'ERROR',
          status: xhr.status,
          body: xhr.response,
        })
      } else {
        resolve(toResponse(xhr.status, xhr.response))
      }
    }
    xhr.onerror = () => reject(new Error('erreur inconnue'))
    xhr.upload.onprogress = (ev) => onUploadProgress((ev.loaded / ev.total) * 100)
    xhr.upload.onload = () => onUploadLoad()

    xhr.send(formData)
  })
}
