import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import { Upload, UploaderState } from '../types'
import createMultiUploaderThunk from './multiUploadThunk'

export default function createMultiUploader<TUpload extends Upload, TBody>(sliceName: string) {
  const initialState: UploaderState<TUpload> = {
    isRunning: false,
    isSuccessUploads: false,
    errorUploads: undefined,
    uploads: [],
  }

  const change = createAction<TUpload>(`${sliceName}/change`)
  const changeErrorUploads = createAction<TUpload>(`${sliceName}/changeErrorUploads`)
  const changeIsErrorUploads = createAction<TUpload>(`${sliceName}/changeIsErrorUploads`)
  const changeIsSuccessUploads = createAction<TUpload>(`${sliceName}/changeIsSuccessUploads`)
  const startMultiUpload = createMultiUploaderThunk<TUpload, TBody>({
    sliceName,
    change,
    changeErrorUploads,
    changeIsErrorUploads,
    changeIsSuccessUploads,
  })

  const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      add: (state, action: PayloadAction<TUpload[]>) => {
        state.uploads = state.uploads.concat(castDraft(action.payload))
      },
      remove: (state, action: PayloadAction<string>) => {
        state.uploads = state.uploads.filter((current) => current.key !== action.payload)
      },
      clear: (state) => {
        state.uploads = []
        state.errorUploads = undefined
        state.isSuccessUploads = false
        state.isErrorUploads = false
        state.isRunning = false
      },
      setValid: (state, { payload: { key, isValid } }: PayloadAction<{ key: string; isValid: boolean }>) => {
        const upload = state.uploads.find((current) => current.key === key)
        if (upload) {
          upload.isValid = isValid
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(change, (state, action) => {
        state.uploads = state.uploads.map((current) => (current.key === action.payload.key ? action.payload : current))
      })

      builder.addCase(changeErrorUploads, (state, action) => {
        state.errorUploads = { ...action.payload.errorUploads }
      })

      builder.addCase(changeIsErrorUploads, (state, action) => {
        state.isErrorUploads = action.payload
      })

      builder.addCase(changeIsSuccessUploads, (state, action) => {
        state.isSuccessUploads = action.payload
      })

      builder.addCase(startMultiUpload.pending, (state) => {
        state.isRunning = true
      })
      builder.addCase(startMultiUpload.fulfilled, (state) => {
        state.isRunning = false
      })
      builder.addCase(startMultiUpload.rejected, (state, action) => {
        // eslint-disable-next-line no-console
        console.error(action.error)
      })
    },
  })

  return {
    slice,
    reducer: slice.reducer,
    actions: {
      change,
      changeErrorUploads,
      changeIsErrorUploads,
      startMultiUpload,
      ...slice.actions,
    },
  }
}
