import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  EditTextField,
  RechargerButton,
  SelectInputEntreprise,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatBooleanFilter } from 'plateforme/services/apis.services'
import { formatDateISO, isDateAfterNow } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import ProfilMedecin from 'medecin/store/types/profilMedecin'
import { ReferentielDetail } from 'plateforme/store/types/referentiel'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'
import { HabilitationFilter } from 'medecin/store/types/habilitationMedecin'

type SearchHabilitationsProps = {
  medecin?: ProfilMedecin
  search: (criteria: HabilitationFilter) => void
  initSearch: VoidFunction
  searchError: IQueryErrorResponse
}

export interface HabilitationFilterData {
  numeroHabilitation?: string
  intervenantOccasionnel?: boolean
  expertiseSurPiece?: boolean
  statutHabilitation?: StatutsHabilitation | string
  dateActivation?: Date
  codeEntreprise?: string
  dateCreationDebut?: Date
  dateCreationFin?: Date
}

export default function RechercheHabilitationsMedecinPart({
  medecin,
  search,
  initSearch,
  searchError,
}: SearchHabilitationsProps) {
  const {
    handleSubmit,
    control,
    reset,
    setError,
    getValues,
    setValue,
    watch,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  // Les valeurs initiales du formulaire :
  const initialValues: HabilitationFilterData = {
    numeroHabilitation: undefined,
    codeEntreprise: undefined,
    statutHabilitation: undefined,
    dateActivation: undefined,
    intervenantOccasionnel: false,
    expertiseSurPiece: false,
    dateCreationDebut: undefined,
    dateCreationFin: undefined,
  }

  const showExpertiseSurPiece = medecin?.expertiseSurPiece ?? true

  const onSubmit = async (data: HabilitationFilterData) => {
    const statut = trimToUndefined(data?.statutHabilitation)
    const active = statut === StatutsHabilitation.ACTIVE
    const inactive = statut === StatutsHabilitation.INACTIVE
    const activeFutur = active && data.dateActivation && isDateAfterNow(data.dateActivation)

    const determinerActive = () => {
      if (inactive) {
        return 0
      }
      return activeFutur ? undefined : formatBooleanFilter(active)
    }
    search({
      numeroHabilitation: trimToUndefined(data.numeroHabilitation),
      codeEntreprise: trimToUndefined(data.codeEntreprise),
      intervenantOccasionnel: formatBooleanFilter(data.intervenantOccasionnel),
      expertiseSurPiece: formatBooleanFilter(data.expertiseSurPiece),
      active: determinerActive(),
      activeFutur: formatBooleanFilter(activeFutur),
      dateActive: active ? formatDateISO(data.dateActivation) : undefined,
      dateCreationDebut: formatDateISO(data.dateCreationDebut),
      dateCreationFin: formatDateISO(data.dateCreationFin),
    })
  }

  const handleClickInitSearch = () => {
    initSearch()
    reset({ numeroHabilitation: '' })
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const statutHabilitationFilter = (ref: ReferentielDetail) => ref.code !== 'ACTIVE_AU_FUTUR'

  const checkboxes = (
    <Stack direction="column" alignItems="flex-start" justifyContent="center" spacing={0}>
      <Controller
        name="intervenantOccasionnel"
        control={control}
        defaultValue={initialValues.intervenantOccasionnel}
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <FormControl error={error !== undefined}>
            <FormGroup>
              <FormControlLabel
                label="Intervenant occasionnel"
                sx={{ height: 30 }}
                control={
                  <Checkbox
                    name="intervenantOccasionnel"
                    checked={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    sx={{ padding: 0, marginX: 1.5 }}
                  />
                }
              />
            </FormGroup>
            {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        )}
      />
      {showExpertiseSurPiece && (
        <Controller
          name="expertiseSurPiece"
          control={control}
          defaultValue={initialValues.expertiseSurPiece}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <FormControl error={error !== undefined}>
              <FormGroup>
                <FormControlLabel
                  label="Avis technique sur pièce"
                  sx={{ height: 30 }}
                  control={
                    <Checkbox
                      name="expertiseSurPiece"
                      checked={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      sx={{ padding: 0, marginX: 1.5, display: 'block' }}
                    />
                  }
                />
              </FormGroup>
              {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>
          )}
        />
      )}
    </Stack>
  )
  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-habilitation">
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Controller
                name="numeroHabilitation"
                control={control}
                defaultValue={initialValues.numeroHabilitation}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="numero-habilitation"
                    label="Numéro d'habilitation entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    sx={{ width: '100%' }}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <Controller
                name="codeEntreprise"
                control={control}
                defaultValue={initialValues.codeEntreprise}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputEntreprise
                    id="entreprise"
                    label="Entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={0} lg={3} display={{ xs: 'none !important', lg: 'flex !important' }}>
              {checkboxes}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Controller
                name="statutHabilitation"
                control={control}
                defaultValue={initialValues.statutHabilitation}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statut-habilitation"
                    label="Statut"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      setValue('dateActivation', undefined)
                      onChange(e)
                    }}
                    referentielName="statutsHabilitation"
                    dataFilter={statutHabilitationFilter}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={5}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Typography width={100}>Actif au</Typography>
                <Controller
                  name="dateActivation"
                  control={control}
                  defaultValue={initialValues.dateActivation}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      InputProps={{
                        id: 'date-activation',
                        fullWidth: true,
                      }}
                      value={value === undefined ? null : value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        setValue('statutHabilitation', StatutsHabilitation.ACTIVE)
                        onChange(e)
                      }}
                      label="Date"
                      disabled={watch('statutHabilitation') !== StatutsHabilitation.ACTIVE}
                      fieldError={error}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} lg={0} display={{ xs: 'flex !important', lg: 'none !important' }}>
              {checkboxes}
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-habilitation"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des habilitations
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
