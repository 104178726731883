import React from 'react'
import DelaisCommonPart from './DelaisCommonPart/DelaisCommonPart'

interface DelaisNonPrecisionDatePartProps {
  readOnly?: boolean
}

export default function DelaisNonPrecisionDatePart({ readOnly }: DelaisNonPrecisionDatePartProps) {
  return (
    <DelaisCommonPart
      readOnly={readOnly}
      controllerName="parametrage.delaisRelance.DELAI_REPONSE_SUIVI_MISSION"
      title="Délai relance médecin non précision date de rendez-vous"
    />
  )
}
