import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModificationDossierRequest } from 'assureur/store/types/dossierEntreprise'
import { IValidationErrorRepsonse } from 'plateforme/hooks/useErrorFormMapper'
import { RootState } from 'plateforme/store'

export const errorDossierSliceName = 'errorDossier'

export interface IErrorDossier {
  status?: number
  data?: IValidationErrorRepsonse
  dirtyBody?: ModificationDossierRequest
}

const errorDossierSlice = createSlice({
  name: errorDossierSliceName,
  initialState: {
    errorDossier: {
      status: undefined,
      data: undefined,
      dirtyBody: undefined,
    } as IErrorDossier,
  },
  reducers: {
    setErrorDossier: (state, action: PayloadAction<IErrorDossier>) => {
      state.errorDossier = action.payload
    },
  },
})

export const { setErrorDossier } = errorDossierSlice.actions

export const getDataErrorDossier = (state: RootState) => state?.errorDossier?.errorDossier?.data
export const getErrorDossier = (state: RootState) => state?.errorDossier

export default errorDossierSlice.reducer
