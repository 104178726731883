import React from 'react'
import DelaisCommonPart from './DelaisCommonPart/DelaisCommonPart'

interface DelaisNonFournitureRapportDateRDVPartProps {
  readOnly?: boolean
}

export default function DelaisNonFournitureRapportDateRDVPart({
  readOnly,
}: DelaisNonFournitureRapportDateRDVPartProps) {
  return (
    <DelaisCommonPart
      readOnly={readOnly}
      controllerName="parametrage.delaisRelance.DELAI_VALIDATION_RAPPORT_DATE_RDV"
      title="Délai relance médecin suite non fourniture rapport si date de rdv prévisionnelle ou date de rdv réalisée dépassée"
    />
  )
}
