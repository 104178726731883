import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  AideIcon,
  AideMenuButton,
  ChangerProfilIcon,
  ChangerProfilMenuButton,
  DeconnexionIcon,
  DeconnexionMenuButton,
  MenuAppBar,
  MenuAppBarItem,
  NotificationsIcon,
  NotificationsMenuButton,
  ProfilIcon,
  ProfilMenuButton,
} from 'plateforme/components'
import { profilHref as profilUtilisateurHref } from 'plateforme/App'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import {
  setDossierMotCleCriteria,
  setNotificationCriteria,
} from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { homeHref, profilHref, rechercheDossiersHref } from 'medecin/MedecinApp'
import getUtilisataurRoles from 'plateforme/services/utilisateur.service'
import { useGetListeMessagesQuery } from 'medecin/store/apis/messageMedecinApi'
import aideEnLigneMedecinsPdf from 'assets/media/pdf/aide_en_ligne_medecins_TransMED_v1.0.pdf'
import { isRechercheDossierPageHref, normalizeHref, trimToUndefined } from 'plateforme/services/utils'
import { dernieresNotificationsNonLueFiltre } from 'medecin/services/messageMedecin.services'

export default function MenuMedecinPart() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const filter = dernieresNotificationsNonLueFiltre()
  const nbNotifications = useGetListeMessagesQuery({ filter, onlyTotal: 1 })?.data?.totalItems
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilUtilisateur = utilisateur?.profilActif
  const isUserWithMultipleRoles = getUtilisataurRoles(utilisateur).length > 1
  const libelleRole = profilUtilisateur?.role?.description
  const libelleRaisonSocial = profilUtilisateur?.raisonSociale
  const libelleService = profilUtilisateur?.libelleService

  /* menu flottant en mode tablette */
  const menuItems: MenuAppBarItem[] = [
    {
      id: 'menu_aide',
      target: '_blank',
      href: normalizeHref(aideEnLigneMedecinsPdf),
      rel: 'noopener noreferrer',
      button: <AideMenuButton />,
      mobile: (
        <>
          <AideIcon withMargin /> Aide
        </>
      ),
    },
    {
      id: 'menu_mon_profil',
      href: profilHref,
      button: <ProfilMenuButton />,
      mobile: (
        <>
          {/* Menu changer de profil */}
          <ProfilIcon withMargin /> Mon profil
        </>
      ),
    },
    {
      id: 'menu_changer_profil',
      href: profilUtilisateurHref,
      button: (
        <ChangerProfilMenuButton
          fontSize={11}
          lineHeight={1.2}
          noWrap
          disabled={!isUserWithMultipleRoles}
          withCompareArrows={isUserWithMultipleRoles}
        >
          {libelleRole}
          <br />
          {libelleRaisonSocial}
          {libelleService ? ` - ${libelleService}` : null}
        </ChangerProfilMenuButton>
      ),
      mobile: (
        <>
          <ChangerProfilIcon withMargin withCompareArrows={isUserWithMultipleRoles} /> {libelleRole}
        </>
      ),
    },
    {
      id: 'menu_notif',
      onClick: () => {
        dispatch(setNotificationCriteria(filter))
      },
      href: 'notifications/dossiers',
      button: <NotificationsMenuButton nbNotifications={nbNotifications ?? 0} />,
      mobile: (
        <>
          <NotificationsIcon nbNotifications={nbNotifications ?? 0} withMargin /> Notifications
        </>
      ),
    },
    {
      id: 'menu_deconnexion',
      isDeconnexion: true,
      button: <DeconnexionMenuButton />,
      mobile: (
        <>
          <DeconnexionIcon withMargin /> Se déconnecter
        </>
      ),
    },
  ]

  const rechercheSubmit = async (motCle?: string) => {
    await dispatch(setDossierMotCleCriteria(trimToUndefined(motCle) ?? ''))
  }

  const rechercheClick = () => {
    if (isRechercheDossierPageHref(window.location.pathname)) {
      window.location.reload()
    } else {
      navigate(rechercheDossiersHref)
    }
  }

  return (
    <MenuAppBar
      homeHref={homeHref}
      onRechercheSubmit={rechercheSubmit}
      onRechercheClick={rechercheClick}
      menuItems={menuItems}
      profilUtilisateur={profilUtilisateur}
    />
  )
}
