import React from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { Box, Grid, Stack } from '@mui/material'
import { ReferentielDommage } from 'plateforme/store/types/referentiel'
import { AnnulerButton, ButtonsStack, SelectionnerToutButton, ValiderButton } from 'plateforme/components'
import { TypeDommage } from 'medecin/store/types/rapportConclusionMedecin'
import DommageCheckbox from './DommageCheckbox'
import { DommagesData } from '../FicheRapportMedecinForm'
import { convertToDommageForm } from '../utils'

interface ParametrageDommagesFormProps {
  onClose: VoidFunction
  typeDommage: TypeDommage.DEFINITIF | TypeDommage.TEMPORAIRE
  dommagePredefini: ReferentielDommage[]
  showHandicapGrave: boolean
  handicapGrave: boolean
  isDomainContractuel: boolean
}

export default function ParametrageDommagesForm({
  onClose,
  typeDommage,
  dommagePredefini,
  showHandicapGrave,
  handicapGrave,
  isDomainContractuel,
}: ParametrageDommagesFormProps) {
  const { setValue, getValues } = useFormContext()

  const dommages: DommagesData = getValues('dommages')

  const defaultParam: { [code: string]: boolean } | undefined = dommagePredefini
    .map((dp) => ({
      [dp?.code]: Object.keys(dommages)
        .filter((code) => dommages[code])
        .includes(dp?.code),
    }))
    .reduce((next, current) => {
      return { ...next, ...current }
    }) as unknown as { [code: string]: boolean }

  const {
    getValues: getValuesParametrageDommages,
    setValue: setValueParametrage,
    control: controlParametrageDommages,
    reset: resetParametrageDommages,
    watch: watchParametrageDommages,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: defaultParam,
  })

  const dommagesSelectionnables = Object.entries(watchParametrageDommages()).filter(
    (entry) =>
      dommagePredefini &&
      dommagePredefini.length > 0 &&
      dommagePredefini
        .filter((d) => d.code === entry[0])
        .filter((d) => d.typeDommage === typeDommage)
        .some((d) => isDomainContractuel || !d.handicapGrave || (showHandicapGrave && handicapGrave))
  )

  const isAllSelected = dommagesSelectionnables.every((entry) => entry[1])

  const selectAll = () => {
    dommagesSelectionnables.forEach((entry) => setValueParametrage(entry[0], !isAllSelected))
  }

  const setParametrageDommages = () => {
    const formValuesParametrageDommages = getValuesParametrageDommages()
    setValue(
      'dommages',
      Object.keys(formValuesParametrageDommages)
        .filter((code) => formValuesParametrageDommages[code])
        .map((code) => {
          const dp = dommagePredefini.find((rd) => rd.code === code)
          const dommage = Object.keys(dommages)
            .filter((c) => code === c)
            .map((c) => dommages[c])
            .find((d) => d)
          return dommage ?? convertToDommageForm(undefined, dp)
        })
        .map((df) => ({ [df.code]: df }))
        .reduce((next, current) => {
          return { ...next, ...current }
        }, {}) as DommagesData
    )
    onClose()
  }

  const onCancel = () => {
    resetParametrageDommages()
    onClose()
  }

  return (
    <form id="form-add-conclusion" style={{ width: '100%' }}>
      <Grid container paddingTop={0}>
        <Grid item xs={12}>
          <Stack direction="column" padding={0} paddingLeft={4}>
            {dommagePredefini &&
              dommagePredefini.length > 0 &&
              dommagePredefini
                .filter((d) => d.typeDommage === typeDommage)
                .filter((d) => isDomainContractuel || !d.handicapGrave || showHandicapGrave)
                .map((d) => (
                  <DommageCheckbox
                    disabled={!isDomainContractuel && d.handicapGrave && !handicapGrave}
                    key={d.codePrejudice}
                    name={d.code}
                    label={d.label}
                    control={controlParametrageDommages}
                  />
                ))}
          </Stack>
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <Box display="flex" justifyContent="space-between">
            <SelectionnerToutButton variant="outlined" isAllSelected={isAllSelected} onClick={selectAll} />
            <ButtonsStack>
              <AnnulerButton onClick={onCancel}>Annuler</AnnulerButton>
              <ValiderButton onClick={setParametrageDommages}>Appliquer</ValiderButton>
            </ButtonsStack>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
