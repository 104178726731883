import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { EditTextField } from 'plateforme/components'

// Description : input number min, max et comentaire
interface ReadOnlyDommagePourcentageFourchetteProps {
  min?: string | number
  max?: string | number
  commentaire?: string
  title?: string
}

export default function ReadOnlyDommagePourcentageFourchette({
  min,
  max,
  commentaire,
  title,
}: ReadOnlyDommagePourcentageFourchetteProps) {
  return (
    <Grid item display="flex" alignItems="center">
      <Typography marginLeft={2} sx={{ width: '30%' }} color="primary" variant="subtitle2">
        {`${title} :`}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={6} md={6} display="flex" alignItems="flex-start">
            <EditTextField value={min} type="number" label="Valeur minimum numérique entière (%)" readOnly fullWidth />
          </Grid>
          <Grid item xs={6} md={6} display="flex" alignItems="flex-start">
            <EditTextField value={max} type="number" label="Valeur maximum numérique entière (%)" readOnly fullWidth />
          </Grid>
          <Grid item xs={12} md={12} display="flex" alignItems="flex-start">
            <EditTextField fullWidth minRows={3} multiline label="Commentaire" value={commentaire} readOnly />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
