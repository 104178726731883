import React from 'react'
import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { AnnulerButton, ButtonsStack, SauvegarderButton } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import { StatutDossier } from 'plateforme/store/types/dossier'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type EditionDossierButtonsEntreprisePartProps = {
  dossier: DossierEntreprise
  onLeave?: VoidFunction
  onSubmitAndLeave?: VoidFunction
  onSubmitAndContinue?: VoidFunction
  loading?: boolean
  variant?: 'onlyLeave' | 'all'
}

export default function EditionDossierEntrepriseButtons({
  dossier,
  onLeave,
  onSubmitAndLeave,
  onSubmitAndContinue,
  loading,
  variant = 'all',
}: EditionDossierButtonsEntreprisePartProps) {
  const { statut: statutDossier } = dossier
  if (!dossier.editable || (statutDossier !== StatutDossier.EN_CREATION && statutDossier !== StatutDossier.EN_COURS)) {
    throw new Error(`Erreur de chargement des données`)
  }

  const isAll = variant === 'all'
  const isOnlyLeave = variant === 'onlyLeave'
  const labelSaveButton =
    statutDossier === StatutDossier.EN_CREATION ? 'Finaliser la création du dossier' : 'Sauvegarder et quitter'

  return (
    <Alert
      severity="info"
      elevation={1}
      sx={{
        backgroundColor: 'white',
        '& .MuiAlert-icon': {
          marginTop: 1,
        },
        '& .MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <AlertTitle sx={{ display: 'block', marginBottom: 0 }}>
        <Box display="flex" flexWrap="wrap" rowGap={1} columnGap={2}>
          <Typography display="flex" marginTop={1}>
            {statutDossier === StatutDossier.EN_CREATION && (
              <>
                <strong>Le dossier est en création</strong>&nbsp;: depuis le {formatDateFR(dossier?.dateCreation)}
              </>
            )}
            {statutDossier === StatutDossier.EN_COURS && (
              <>
                <strong>Le dossier est en cours</strong>&nbsp;: depuis le {formatDateFR(dossier?.dateEnCours)}
              </>
            )}
          </Typography>
          <ButtonsStack alignSelf="flex-end" marginLeft="auto" marginTop="2px">
            {isOnlyLeave && onLeave && (
              <SauvegarderButton id="quitter" onClick={() => onLeave()}>
                {labelSaveButton}
              </SauvegarderButton>
            )}
            {isAll && onLeave && (
              <AnnulerButton id="quitter" color="error" onClick={() => onLeave()}>
                Quitter la saisie
              </AnnulerButton>
            )}
            {isAll && onSubmitAndLeave && (
              <SauvegarderButton
                id="sauvegarder-quitter"
                color="secondary"
                onClick={onSubmitAndLeave}
                loading={loading}
              >
                Sauvegarder et quitter
              </SauvegarderButton>
            )}
            {isAll && onSubmitAndContinue && (
              <SauvegarderButton id="sauvegarder-continuer" onClick={onSubmitAndContinue} loading={loading}>
                Sauvegarder et continuer
              </SauvegarderButton>
            )}
          </ButtonsStack>
        </Box>
      </AlertTitle>
    </Alert>
  )
}
