import React from 'react'
import { Fab, FabProps } from '@mui/material'
import { Check } from '@mui/icons-material'

export default function AccepterRoundButton(props: FabProps) {
  const { ...other } = props

  return (
    <Fab color="tile" aria-label="Accepter" size="small" {...other}>
      <Check color="secondary" />
    </Fab>
  )
}
