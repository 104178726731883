import React from 'react'
import { Stack, StackProps, useTheme } from '@mui/material'

export default function ButtonsStack(props: StackProps & { withGutter?: boolean }) {
  const { alignItems, children, direction, justifyContent, spacing, sx: otherSx, withGutter, ...other } = props
  const theme = useTheme()
  const stackSx = withGutter ? { paddingBottom: theme.spacing(2.5), ...otherSx } : { ...otherSx }
  return (
    <Stack
      direction={direction ?? 'row'}
      justifyContent={justifyContent ?? 'flex-end'}
      alignItems={alignItems ?? 'center'}
      spacing={spacing ?? 2}
      sx={stackSx}
      {...other}
    >
      {children}
    </Stack>
  )
}
