import * as React from 'react'
import { Card, CardContent, CardHeader, useTheme } from '@mui/material'
import { AreaLoading, TabsComponent } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'
import LieuExerciceMedecinTab from './LieuExerciceMedecinTab'

export interface LieuxExerciceMedecinPartProps {
  medecin?: MedecinEntreprise
}

export interface LieuxExerciceMedecinPartLoadedProps {
  medecin: MedecinEntreprise
}

export default function LieuxExerciceMedecinPart({ medecin, loading }: LieuxExerciceMedecinPartProps & LoadingProps) {
  if (loading || medecin === undefined) {
    return <AreaLoading height={351} />
  }
  return <LieuxExerciceMedecinLoadedPart medecin={medecin} />
}

function LieuxExerciceMedecinLoadedPart({ medecin }: LieuxExerciceMedecinPartLoadedProps) {
  const theme = useTheme()

  const lieuxExercice = medecin.lieuxExercice.map((lieuExercice, index) => ({
    id: index.toString(),
    title: index === 0 ? 'Étab. principal' : `Étab. secondaire ${index}`,
    component: <LieuExerciceMedecinTab index={index} lieuExercice={lieuExercice} />,
  }))

  return (
    <Card>
      <CardHeader title="Lieux d'exercice" />
      <CardContent sx={{ height: '300px' }}>
        <TabsComponent tabs={lieuxExercice} marginX={theme.spacing(2, 0)} />
      </CardContent>
    </Card>
  )
}
