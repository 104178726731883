import * as React from 'react'
import { DeleteOutline } from '@mui/icons-material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export default function SupprimerButton(props: LoadingButtonProps) {
  const { color, children, disabled, variant, ...other } = props
  return (
    <LoadingButton
      variant={variant ?? 'contained'}
      color={color ?? 'error'}
      startIcon={<DeleteOutline />}
      loadingPosition="start"
      disabled={disabled}
      {...other}
    >
      {children}
    </LoadingButton>
  )
}
