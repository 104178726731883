import * as React from 'react'
import { Grid, GridProps } from '@mui/material'

export default function TableauDeBordGridItem(props: GridProps) {
  const { children, ...other } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} {...other}>
      {children}
    </Grid>
  )
}
