import { createAsyncThunk, PayloadActionCreator } from '@reduxjs/toolkit'
import type { RootState } from 'plateforme/store'
import sendRequest, { UploadResponse } from '../http'
import { Upload, UploaderState, UploadFileMap, UploadState } from '../types'

type UploaderThunkConfig<TUpload> = {
  sliceName: string
  change: PayloadActionCreator<TUpload>
  changeErrorUploads: PayloadActionCreator<TUpload>
  changeIsErrorUploads: PayloadActionCreator<TUpload>
  changeIsSuccessUploads: PayloadActionCreator<TUpload>
}

export type StartUploadPayload<TUpload, TBody> = {
  uploadUrl: string
  uploadFileMap: UploadFileMap
  toFormData: (uploads: TUpload[], file1?: File, file2?: File, file3?: File) => FormData
  toUploadResponse: (status: number, response: object) => UploadResponse<TBody>
}

function getFileByIndex(index: number, uploadFileMap: UploadFileMap, uploads: Upload[]) {
  if (uploads && uploads.length > 0) {
    return uploadFileMap?.[uploads[index]?.key]
  }
  return undefined
}

export default function createMultiUploaderThunk<TUpload extends Upload, TBody>({
  sliceName,
  change,
  changeErrorUploads,
  changeIsErrorUploads,
  changeIsSuccessUploads,
}: UploaderThunkConfig<TUpload>) {
  return createAsyncThunk<void, StartUploadPayload<TUpload, TBody>>(
    `${sliceName}/startMultiUpload`,
    async ({ uploadFileMap, toFormData, toUploadResponse, uploadUrl }, thunkApi) => {
      const { [sliceName as keyof RootState]: state } = thunkApi.getState() as RootState
      const { uploads } = state as UploaderState<TUpload>

      const firstIdx = uploads.findIndex((u) => u.state !== UploadState.DONE)
      for (let i = firstIdx; i >= 0 && i < uploads.length; i += 1) {
        const upload = uploads[i]
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { error, ...uploadWithoutErr } = upload

        if (!upload.isValid) {
          return
        }
        thunkApi.dispatch(change({ ...uploadWithoutErr, progress: 0, state: UploadState.IN_PROGRESS }))
      }

      const response = await sendRequest<TBody>({
        url: uploadUrl,
        state: thunkApi.getState() as RootState,
        onUploadProgress(progress) {
          thunkApi.dispatch(change({ uploads, progress, state: UploadState.IN_PROGRESS }))
        },
        onUploadLoad() {
          thunkApi.dispatch(change({ uploads, progress: 100, state: UploadState.IN_PROGRESS }))
        },
        formData: toFormData(
          uploads,
          getFileByIndex(0, uploadFileMap, uploads),
          getFileByIndex(1, uploadFileMap, uploads),
          getFileByIndex(2, uploadFileMap, uploads)
        ),
        toResponse: toUploadResponse,
      })

      if (response.type === 'SUCCESS') {
        thunkApi.dispatch(changeIsSuccessUploads(true))
        thunkApi.dispatch(changeIsErrorUploads(false))
        thunkApi.dispatch(changeErrorUploads(undefined))
      } else {
        thunkApi.dispatch(changeIsSuccessUploads(false))
        thunkApi.dispatch(changeIsErrorUploads(true))
        thunkApi.dispatch(
          changeErrorUploads({
            errorUploads: { ...response.body, status: response.status },
          })
        )
      }
    }
  )
}
