import Referentiel from 'plateforme/store/types/referentiel'
import DossierPlateforme from 'plateforme/store/types/dossier'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { formatDateFR } from './dates.services'
import { trimToUndefined } from './utils'

export enum CodeMotifCloture {
  MANUELLE = '01',
  AUTOMATIQUE = '02',
  RETRAIT_CONSENTEMENT = '03',
}

export function determineDocumentsDossierReadOnly(dossier?: DossierPlateforme) {
  return determineDocumentsDossierInternal(true, dossier)
}

export function determineDocumentsDossier(dossier?: DossierPlateforme) {
  return determineDocumentsDossierInternal(false, dossier)
}

function determineDocumentsDossierInternal(readOnly: boolean, dossier?: DossierPlateforme): DocumentTeleverse[] {
  const { documents, missions, documentDeposable } = dossier || {}
  const modifiable = !readOnly && (documentDeposable ?? false)

  const documentsDossier = documents ?? []
  const documentsMission = (missions ?? []).flatMap((mission) => mission.documents ?? [])
  const documentsRapport = (missions ?? []).flatMap((mission) => mission.rapport?.documents ?? [])

  return documentsDossier
    .concat(documentsMission)
    .concat(documentsRapport)
    .map((document) => {
      return {
        ...document,
        supprimable: modifiable && document.supprimable,
      } as DocumentTeleverse
    })
    .sort((d1, d2) => {
      return new Date(d2.dateDepot).getTime() - new Date(d1.dateDepot).getTime()
    })
}

export function determineConsentementRequis(cadreExpertise?: string | null, referentiel?: Referentiel): boolean {
  const found = referentiel?.cadresExpertise.find((c) => c.code === cadreExpertise)
  return found?.tags.includes('CONSENTEMENT_REQUIS') ?? false
}

export function determineAutreMedecinPossible(cadreExpertise?: string | null, referentiel?: Referentiel): boolean {
  const found = referentiel?.cadresExpertise.find((c) => c.code === cadreExpertise)
  return found?.tags.includes('AUTRE_MEDECIN_POSSIBLE') ?? false
}

export function syntheseEvenement(dossier?: DossierPlateforme): string {
  const evenement = dossier?.evenement
  if (!evenement) {
    return ''
  }
  const datePrefix = evenement.labelNature ? 'survenu le' : 'Survenu le'
  const tokens = [evenement.labelNature, evenement.date ? `${datePrefix} ${formatDateFR(evenement.date)}` : undefined]
  return tokens.filter((token) => token !== undefined).join(' ')
}

export function syntheseExpertise(dossier?: DossierPlateforme): string {
  const expertise = dossier?.expertise

  if (!expertise) {
    return ''
  }
  const tokens =
    expertise.typeLieuExpertise === 'CABINET'
      ? [expertise.labelCadreExpertise, trimToUndefined(expertise.labelTypeLieuExpertise)]
      : [expertise.labelCadreExpertise, trimToUndefined(expertise.libelleAdresse)]
  return tokens.filter((token) => token !== undefined).join(' - ')
}

export function syntheseLibellePersonnneAExaminer(dossier?: DossierPlateforme): string {
  const personneAExaminer = dossier?.personneAExaminer
  if (!personneAExaminer) {
    return ''
  }
  const adresseSimplePrefix = personneAExaminer.prenom || personneAExaminer.nom || personneAExaminer.sexe ? '- ' : ''
  const tokens = [
    personneAExaminer.prenom,
    personneAExaminer.nom,
    personneAExaminer.sexe ? `(${personneAExaminer?.sexe})` : undefined,
    personneAExaminer.adresseSimple ? `${adresseSimplePrefix} ${personneAExaminer?.adresseSimple}` : undefined,
  ]
  return tokens.filter((token) => token !== undefined).join(' ')
}

export function syntheseTelephonePersonnneAExaminer(dossier?: DossierPlateforme): string {
  const personneAExaminer = dossier?.personneAExaminer
  if (!personneAExaminer) {
    return ''
  }
  const tokens = [personneAExaminer.telephoneFixe, personneAExaminer.telephoneMobile]
  return tokens.filter((token) => token !== undefined).join(' / ')
}
