import React from 'react'
import { Alert, Grid } from '@mui/material'
import { MSG_ERROR_CHARGEMENT_DES_DONNEES } from 'plateforme/constantes'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import Facture, { FactureSortType } from 'medecin/store/types/factureMedecin'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import { toTelechargementExportFactureHref } from 'plateforme/App'
import { AuthDownloadAction } from 'plateforme/store/types/utilisateur'
import FactureMedecinCard from './FactureMedecinCard'

type ListeFacturesPartProps = {
  combinedData: Array<Facture>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<FactureSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  exportParams?: string
}

export default function ListeFacturesMedecinPart({
  combinedData,
  exportParams,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeFacturesPartProps) {
  // les options de tri disponibles a passer au composant SortComponentMenu
  const sortOptions: SortOption<FactureSortType>[] = [
    { sortType: FactureSortType.NUMERO_FACTURE_SORT, label: 'Numéro Facture' },
    { sortType: FactureSortType.REFERENCE_MISSION_SORT, label: 'Référence Mission' },
    { sortType: FactureSortType.DATE_FACTURE_SORT, label: 'Date Facture' },
    { sortType: FactureSortType.ENTREPRISE_SORT, label: 'Entreprise' },
    { sortType: FactureSortType.STATUT_SORT, label: 'Statut' },
  ]

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des factures"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV
          hrefExportCSV={toTelechargementExportFactureHref(exportParams)}
          requestExportCSV={{ action: AuthDownloadAction.TELECHARGER_CSV_FACTURE }}
        />
      </Grid>
      {isError && !isSuccess && !isLoading && !isFetching ? (
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
          {MSG_ERROR_CHARGEMENT_DES_DONNEES}
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={combinedData?.length}
          next={readMore}
          hasMore={combinedData.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
          lg={6}
        >
          <Grid item container xs={12}>
            {combinedData?.map((facture: Facture) => (
              <Grid item xs={12} key={facture.code}>
                <FactureMedecinCard facture={facture} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}
