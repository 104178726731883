import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import {
  AcquitterMessageMedecinRequest,
  MessageMedecin,
  MessageMedecinFilter,
  MessageMedecinSortType,
  MessageMissionMedecin,
  MessageMissionMedecinFilter,
  MessageMissionRequest,
  PageRechercheMessageMissionRequest,
} from '../types/messageMedecin'

export const messageMedecinApi = createApi({
  reducerPath: 'messageMedecinApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['MessageMedecin', 'MessageMissionMedecin'],
  endpoints: (builder) => ({
    getListeMessages: builder.query<
      PageRecherche<MessageMedecin>,
      PageRechercheRequest<MessageMedecinFilter, MessageMedecinSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => {
        return {
          url: 'medecin/message',
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
            onlyTotal,
          },
        }
      },
      providesTags: ['MessageMedecin'],
    }),
    getListMessageMission: builder.query<
      PageRecherche<MessageMissionMedecin>,
      PageRechercheMessageMissionRequest<MessageMissionMedecinFilter, MessageMedecinSortType>
    >({
      query: ({ codeDossier, codeMission, perPage, page, filter, sort }) => {
        return {
          url: `medecin/dossier/${codeDossier}/mission/${codeMission}/message`,
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
          },
        }
      },
      providesTags: ['MessageMissionMedecin'],
    }),

    postMessageMission: builder.mutation<void, MessageMissionRequest>({
      query: ({ codeDossier, codeMission, ...body }) => {
        return {
          url: `medecin/dossier/${codeDossier}/mission/${codeMission}/message`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['MessageMissionMedecin', 'MessageMedecin']),
    }),
    postAcquitterMessage: builder.mutation<void, AcquitterMessageMedecinRequest>({
      query: (body) => {
        return {
          url: `medecin/message/acquittement`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['MessageMedecin']),
    }),
  }),
})

export const {
  useGetListeMessagesQuery,
  useGetListMessageMissionQuery,
  usePostMessageMissionMutation,
  usePostAcquitterMessageMutation,
} = messageMedecinApi
