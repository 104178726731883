import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Snackbar,
  Stack,
} from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  EditTextField,
  RechargerButton,
  SelectInputEntreprise,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatBooleanFilter } from 'plateforme/services/apis.services'
import { trimToUndefined } from 'plateforme/services/utils'
import { formatDateISO } from 'plateforme/services/dates.services'
import { MedecinAdminFilter } from 'admin/store/types/profilMedecinAdmin'
import {
  getMedecinReferenceCriteria,
  setMedecinReferenceCriteria,
} from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'

type SearchMedecinsAdminProps = {
  search: (criteria: MedecinAdminFilter) => void
  searchError: IQueryErrorResponse
}

export interface MedecinFilterData {
  motCle?: string
  nomMedecin?: string
  statut?: string
  codeEntreprise?: string
  dateCreationDebut: Date | null
  irca?: boolean
  expertiseSurPiece?: boolean
}

export default function RechercheMedecinsAdminPart({ search, searchError }: SearchMedecinsAdminProps) {
  // Les valeurs initiales du formulaire :
  const defaultCriteria = useSelector(getMedecinReferenceCriteria)
  const initialValues: MedecinFilterData = {
    motCle: defaultCriteria?.motCle?.length === 0 ? '' : defaultCriteria?.motCle ?? '',
    nomMedecin: defaultCriteria?.nomMedecin,
    statut: defaultCriteria?.statut,
    codeEntreprise: defaultCriteria?.codeEntreprise,
    dateCreationDebut: defaultCriteria?.dateCreationDebut ? new Date(defaultCriteria?.dateCreationDebut) : null,
    irca: defaultCriteria?.irca === 1,
    expertiseSurPiece: defaultCriteria?.expertiseSurPiece === 1,
  }

  const resetValues: MedecinFilterData = {
    motCle: '',
    nomMedecin: undefined,
    statut: undefined,
    codeEntreprise: undefined,
    dateCreationDebut: null,
    irca: undefined,
    expertiseSurPiece: undefined,
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)
  const dispatch = useDispatch()

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: MedecinFilterData) => {
    const filtre = {
      motCle: trimToUndefined(data.motCle),
      nomMedecin: trimToUndefined(data.nomMedecin),
      statut: trimToUndefined(data.statut),
      codeEntreprise: trimToUndefined(data.codeEntreprise),
      irca: formatBooleanFilter(data.irca),
      expertiseSurPiece: formatBooleanFilter(data.expertiseSurPiece),
      dateCreationDebut: formatDateISO(data.dateCreationDebut),
    }
    search(filtre)
    dispatch(setMedecinReferenceCriteria(filtre))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      motCle: undefined,
      nomMedecin: undefined,
      statut: undefined,
      codeEntreprise: undefined,
      dateCreationDebut: undefined,
      irca: undefined,
      expertiseSurPiece: undefined,
    }
    reset(resetValues)
    search(filtre)
    dispatch(setMedecinReferenceCriteria(filtre))
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-medecins">
          <Grid container>
            <Grid item md={4} xs={12}>
              <Controller
                name="motCle"
                control={control}
                defaultValue={initialValues.motCle}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="motCle"
                    label="Mots clés"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Controller
                name="nomMedecin"
                control={control}
                defaultValue={initialValues.motCle}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="nomMedecin"
                    label="Nom médecin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack direction="column" alignItems="flex-start" justifyContent="top" spacing={0} height="100%">
                <Controller
                  name="irca"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl error={error !== undefined}>
                      <FormGroup>
                        <FormControlLabel
                          label="Médecin IRCA"
                          sx={{ height: 30 }}
                          control={
                            <Checkbox
                              name="irca"
                              checked={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                            />
                          }
                        />
                      </FormGroup>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
                <Controller
                  name="expertiseSurPiece"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl error={error !== undefined}>
                      <FormGroup>
                        <FormControlLabel
                          label="Avis technique sur pièces"
                          sx={{ height: 30 }}
                          control={
                            <Checkbox
                              name="expertiseSurPiece"
                              checked={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              sx={{ padding: 0, marginX: 1.5 }}
                            />
                          }
                        />
                      </FormGroup>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={12}>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="statut"
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <SelectInputReferentiel
                        id="statut"
                        label="Statut"
                        referentielName="statutsMedecin"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        fullWidth
                        withNoSelectionItem
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="codeEntreprise"
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <SelectInputEntreprise
                        isAdmin
                        id="codeEntreprise"
                        label="Entreprise"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fullWidth
                        withNoSelectionItem
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="dateCreationDebut"
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <DatePickerInput
                        InputProps={{
                          id: 'date-creation',
                          fullWidth: true,
                        }}
                        label="Date d'inscription"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton variant="contained" type="submit" form="form-recherche-medecins" disabled={!isValid}>
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des medecins référencés
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
