import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import { Gestionnaire } from '../types/dossierEntreprise'

interface ListerGestionnairesRequest {
  codeService: string
}

export const gestionnaireEntrepriseApi = createApi({
  reducerPath: 'gestionnaireApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['Gestionnaire'],
  endpoints: (builder) => ({
    getGestionnaires: builder.query<Gestionnaire[], ListerGestionnairesRequest>({
      query: ({ codeService }) => ({
        url: 'entreprise/gestionnaire',
        params: {
          codeService,
        },
      }),
      providesTags: (result, error, { codeService }) => [{ type: 'Gestionnaire', id: codeService }],
    }),
  }),
})

export const { useGetGestionnairesQuery } = gestionnaireEntrepriseApi
