import * as React from 'react'
import { SvgIconProps, useTheme } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

export default function CheckedIcon(props: SvgIconProps & { withMargin?: boolean }) {
  const { withMargin, sx: otherSx, ...other } = props
  const theme = useTheme()
  const iconSx = withMargin ? { margin: theme.spacing(1.5) } : { ...otherSx }
  return <CheckCircleOutlineIcon sx={iconSx} {...other} />
}
