import MissionMedecin from 'medecin/store/types/missionMedecin'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import MissionAdmin from 'admin/store/types/missionAdmin'

type MissionPlateforme = MissionMedecin | MissionEntreprise | MissionAdmin

export enum StatutMission {
  PROPOSEE = 'PROPOSEE',
  EN_COURS = 'EN_COURS',
  EN_DEMANDE_DE_MODIFICATION = 'EN_DEMANDE_DE_MODIFICATION',
  VALIDEE_MEDECIN = 'VALIDEE_MEDECIN',
  REFUSEE = 'REFUSEE',
  ARRETEE = 'ARRETEE',
  ANNULEE = 'ANNULEE',
  CLOTUREE = 'CLOTUREE',
  PROPOSEE_SANS_SUITE = 'PROPOSEE_SANS_SUITE',
}

export enum MotifBlocage {
  COORDONNEES_PERSONNE_A_CONTACTER_INCORRECTES = '01',
  PAS_REPONSE_PERSONNE_A_CONTACTER = '02',
  INDISPONIBILITE_PERSONNE_A_EXAMINER = '03',
  INDISPONIBILITE_MEDECIN_PLANNING = '04',
  INDISPONIBILITE_MEDECIN_CONFLIT_INTERET = '05',
  DECES = '06',
  AUTRE = '07',
}

export enum StatutSuiviMission {
  NON_DEFINI = 'NON_DEFINI',
  EN_COURS = 'EN_COURS',
  BLOQUE = 'BLOQUE',
  EXAMEN_PREVU = 'EXAMEN_PREVU',
}

export enum StatutMessagerie {
  ACTIVE = 'ACTIVE',
  DESACTIVE = 'DESACTIVE',
  LECTURE = 'LECTURE',
}

export default MissionPlateforme

export type MetaDataMission = {
  mission: MissionPlateforme
  totalLu: number
  totalNonLuMedecin: number
  totalNonLuEntreprise: number
  indexMission?: number
  indexRapport?: number
}
