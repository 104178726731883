import React from 'react'
import { Alert, Grid } from '@mui/material'
import { InfiniteScrollComponent, ResultatRechercheBarCard } from 'plateforme/components'
import { SortParam, SortOption } from 'plateforme/store/types/pageRecherche'
import { AuthDownloadAction } from 'plateforme/store/types/utilisateur'
import { toTelechargementExportDossierHref } from 'plateforme/App'
import DossierMedecin, { DossierMedecinSortType } from 'medecin/store/types/dossierMedecin'
import DossierMedecinCard from './DossierMedecinCard'

type ListeDossierMedecinPartProps = {
  dossiers: DossierMedecin[]
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<DossierMedecinSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  exportParams?: string
}

const sortOptions: SortOption<DossierMedecinSortType>[] = [
  { sortType: DossierMedecinSortType.DATE_REPONSE_MEDECIN_SORT, label: `Date d'acceptation / de refus de la mission` },
  { sortType: DossierMedecinSortType.CADRE_EXPERTISE_SORT, label: `Cadre de l'expertise` },
  { sortType: DossierMedecinSortType.NATURE_EVENEMENT_SORT, label: `Nature de l'événement` },
  { sortType: DossierMedecinSortType.REFERENCE_DOSSIER_SORT, label: 'Référence dossier entreprise' },
  { sortType: DossierMedecinSortType.ENTREPRISE_SORT, label: 'Entreprise' },
  { sortType: DossierMedecinSortType.STATUT_SORT, label: 'Statut' },
]

export default function ListeDossierMedecinPart({
  dossiers,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
  exportParams,
}: ListeDossierMedecinPartProps) {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        <ResultatRechercheBarCard
          title="Liste des dossiers"
          total={total}
          loading={isLoading || isFetching}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV
          hrefExportCSV={toTelechargementExportDossierHref(exportParams)}
          requestExportCSV={{ action: AuthDownloadAction.TELECHARGER_CSV_DOSSIER }}
        />
      </Grid>
      {(isError && !isSuccess && !isLoading && !isFetching) || total === 0 ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          {isLoading ? 'En cours de chargement' : 'Aucun dossier remonté'}
        </Alert>
      ) : (
        <InfiniteScrollComponent
          dataLength={dossiers?.length}
          next={readMore}
          hasMore={dossiers.length < (total ?? 0)}
          isLoading={isLoading || isFetching}
          xs={12}
        >
          <Grid container item xs={12}>
            {dossiers?.map((dossier) => (
              <Grid item xs={12} key={dossier.code}>
                <DossierMedecinCard dossier={dossier} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScrollComponent>
      )}
    </Grid>
  )
}
