import DossierAdmin from 'admin/store/types/dossierAdmin'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import DossierMedecin from 'medecin/store/types/dossierMedecin'

// TODO: ici on va mettre le modele generique dossier
type DossierPlateforme = DossierMedecin | DossierEntreprise | DossierAdmin

export enum CadreExpertise {
  EXPERTISE_UNILATERAL = '01',
  EXPERTISE_CONJOINTE = '02',
  ASSISTANCE_A_EXPERTISE = '03',
  ASSISTANCE_A_ARBITRAGE = '04',
  AVIS_TECHNIQUE_SUR_PIECE = '05',
}

export enum StatutDossier {
  EN_CREATION = 'EN_CREATION',
  EN_COURS = 'EN_COURS',
  CLOTURE = 'CLOTURE',
  ANNULE = 'ANNULE',
  PURGE = 'PURGE',
}

export interface RevisionDossierRequest {
  codeDossier: string
  codeMission: string
}

export default DossierPlateforme
