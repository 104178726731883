import React from 'react'
import { Box } from '@mui/material'
import TabsParametresEntreprisePart from 'assureur/parts/parametresEntreprise/TabsParametresEntreprisePart'
import { AreaLoading } from 'plateforme/components'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'

export default function ParametresEntreprisePage() {
  const { data: entreprise, isLoading, isFetching } = useGetProfilEntrepriseQuery()

  const {
    data: referentiel,
    isLoading: isLoadingReferentiel,
    isFetching: isFetchingReferentiel,
  } = useGetReferentielQuery()

  if (isLoading || isFetching || isLoadingReferentiel || isFetchingReferentiel) {
    return <AreaLoading height={350} />
  }

  return (
    <Box marginTop={2}>
      <TabsParametresEntreprisePart entreprise={entreprise} referentiel={referentiel} />
    </Box>
  )
}
