/* Page d'accueil : Aucune habilitation active pour le médecin. */

import { TransparentCard, TransparentCardContent } from 'plateforme/components'
import { Alert } from '@mui/material'
import * as React from 'react'

export default function MessageAucuneHabilitation() {
  return (
    <TransparentCard>
      <TransparentCardContent>
        <Alert severity="error">
          Aucun assureur ne vous a ajouté à son réseau. Veuillez-vous rapprocher des entreprises avec lesquelles vous
          travaillez ou contacter notre support pour plus d’information{' '}
          <a href="mailto:assistance-transmed@agira.asso.fr">assistance-transmed@agira.asso.fr</a>
        </Alert>
      </TransparentCardContent>
    </TransparentCard>
  )
}
