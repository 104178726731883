import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import {
  syntheseEvenement,
  syntheseExpertise,
  syntheseLibellePersonnneAExaminer,
  syntheseTelephonePersonnneAExaminer,
} from 'plateforme/services/dossier.services'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'

type InformationSyntheseProps = {
  dossier?: DossierEntreprise
  mission?: MissionEntreprise
}

export default function InformationSyntheseEntreprisePart({
  dossier,
  mission,
  loading,
}: InformationSyntheseProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={548} />
  }
  return <InformationSyntheseEntreprisePartLoaded dossier={dossier} mission={mission} />
}

function InformationSyntheseEntreprisePartLoaded({ dossier, mission }: InformationSyntheseProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { libelle: libelleDossier, personneAExaminer, contactEntreprise } = dossier
  const { libelle: libelleMission } = mission || {}

  // format:
  const libelleEvenement = syntheseEvenement(dossier)
  const libelleExpertise = syntheseExpertise(dossier)
  const libellePersonnneAExaminer = syntheseLibellePersonnneAExaminer(dossier)
  const telephonePersonnneAExaminer = syntheseTelephonePersonnneAExaminer(dossier)

  return (
    <Card>
      <CardHeader title="Informations" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <ReadOnlyTextField
              id="id-ref-entreprise"
              label="Identifiants du dossier"
              value={libelleMission ?? libelleDossier}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField fullWidth id="evenement-libelle" value={libelleEvenement} label="Événement" />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField fullWidth id="expertise-libelle" value={libelleExpertise} label="Expertise" />
          </Grid>

          <Grid item xs={12}>
            <ReadOnlyTextField
              id="personne-a-examiner-libelle"
              label="Personne à examiner"
              value={libellePersonnneAExaminer}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <ReadOnlyEmailField
              id="personne-a-examiner-mail"
              label="Adresse mail personne à examiner"
              value={personneAExaminer?.mail}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="personne-a-examiner-telephones"
              label="Téléphone(s) personne à examiner"
              value={telephonePersonnneAExaminer}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              id="contact-entreprise-libelle"
              label="Libellé contact dossier"
              value={contactEntreprise?.libelle}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyEmailField
              id="contact-entreprise-mail"
              label="Adresse mail contact dossier"
              value={contactEntreprise?.mail}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="contact-entreprise-telephone"
              label="Téléphone contact dossier"
              value={contactEntreprise?.telephone}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
