import { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { StatutMission } from 'plateforme/store/types/mission'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import DossierEntreprise, {
  DossierEntrepriseFilter,
  DossierEntrepriseSortType,
} from 'assureur/store/types/dossierEntreprise'
import { FactureFilter, FactureSortType, StatutFacture } from 'assureur/store/types/factureEntreprise'

export function determineDerniereMission(dossier?: DossierEntreprise): MissionEntreprise | undefined {
  return dossier?.missions?.at(0)
}

// Nombre de mes dossier en cours
export const totalMesDossierEnCoursRequest = (gestionnaireId?: string) =>
  ({
    filter: {
      statutsDossier: [StatutDossier.EN_COURS],
      statutsMission: [
        StatutMission.ANNULEE,
        StatutMission.ARRETEE,
        StatutMission.EN_COURS,
        StatutMission.EN_DEMANDE_DE_MODIFICATION,
        StatutMission.PROPOSEE_SANS_SUITE,
        StatutMission.REFUSEE,
        StatutMission.VALIDEE_MEDECIN,
      ],
      gestionnaire: gestionnaireId,
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>)

// Nombre de dossier en cours
export const totalDossierEnCoursRequest = () =>
  ({
    filter: {
      statutsDossier: [StatutDossier.EN_COURS],
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>)

// Nombre de dossier Gestionnaire connecte en cours
export const totalMesDossierEnCreationRequest = (gestionnaireId?: string) =>
  ({
    filter: {
      statutsDossier: [StatutDossier.EN_CREATION, StatutDossier.EN_COURS],
      aucuneMission: 1,
      gestionnaire: gestionnaireId,
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>)

// Nombre de dossier en Attente d'acceptation
export const totalMesDossiersEnAttenteAcceptationRequest = (gestionnaireId?: string) =>
  ({
    filter: {
      statutsDossier: [StatutDossier.EN_COURS],
      statutsMission: [StatutMission.PROPOSEE],
      gestionnaire: gestionnaireId,
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>)

// Nombre de dossier en Attente d'acceptation
export const totalDossiersEnAttenteAcceptationRequest = () =>
  ({
    filter: { statutsDossier: [StatutDossier.EN_COURS], statutsMission: [StatutMission.PROPOSEE] },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>)

// Nombre de dossiers Hors Delai pour un gestionnaire
export const totalMesDossiersHorsDelaiRequest = (gestionnaireId?: string) =>
  ({
    filter: {
      horsDelai: 1,
      statutsDossier: [StatutDossier.EN_COURS],
      gestionnaire: gestionnaireId,
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>)

// Nombre total de dossiers Hors delais
export const totalTousLesDossiersHorsDelaisRequest = () =>
  ({
    filter: {
      horsDelai: 1,
      statutsDossier: [StatutDossier.EN_COURS],
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierEntrepriseFilter, DossierEntrepriseSortType>)

// Nombre des factures a deposee
export const totalFactureADeposeeRequest = (gestionnaireId?: string) =>
  ({
    filter: { statuts: [StatutFacture.DEPOSEE], gestionnaire: gestionnaireId },

    onlyTotal: 1,
  } as PageRechercheRequest<FactureFilter, FactureSortType>)
