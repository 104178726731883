import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { HandicapGraveAlert, MessageAlert, TitleChip } from 'plateforme/components'
import {
  determineTitleHandicapGrave,
  determineShowHandicapGrave,
  determineShowPrecisionsMission,
} from 'plateforme/services/mission.services'
import { formatDurationFromNow, formatDurationFromNowInMonths } from 'plateforme/services/dates.services'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import SuiviMissionForm from './SuiviMissionForm'

interface SuiviDossierSyntheseProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
}

export default function SuiviDossierMedecinPart({ dossier, mission }: SuiviDossierSyntheseProps) {
  // TODO: à voir cette régle Actuellement le délais d’acceptation est calculé en ( seconde  ou minute ou  heurs ou semaines ) si la durée < un mois
  const delaisAcceptationInMonths = formatDurationFromNowInMonths(mission.dateReponseMedecin)
  const delaisAcceptation = mission.dateReponseMedecin ? formatDurationFromNow(mission.dateReponseMedecin) : ''

  const delaisAcceptationTitle = ` depuis ${
    // eslint-disable-next-line quotes
    delaisAcceptationInMonths > 0 ? delaisAcceptation : " moins d'un mois"
  }`

  const titleSuiviDossier = `Mission acceptée ${delaisAcceptationTitle}`
  const showHandicapGrave = determineShowHandicapGrave(mission)
  const titleHandicapGrave = determineTitleHandicapGrave(mission)
  const showMissionPrecisions = determineShowPrecisionsMission(mission)

  return (
    <Card>
      <CardHeader title="Suivi de la mission" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TitleChip title={titleSuiviDossier} />
          </Grid>
          {showMissionPrecisions && (
            <Grid item xs={12}>
              <MessageAlert title="Précisions sur la mission">
                {mission?.precisions?.split('\n').map((line) => (
                  <p style={{ margin: 0 }}>{line}</p>
                ))}
              </MessageAlert>
            </Grid>
          )}
          {showHandicapGrave && (
            <Grid item xs={12}>
              <HandicapGraveAlert title={titleHandicapGrave} />
            </Grid>
          )}
          <Grid item xs={12}>
            <SuiviMissionForm dossier={dossier} mission={mission} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
