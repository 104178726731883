import { Card, CardContent, FormControl, FormControlLabel, FormHelperText, Grid, Switch } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface ReglesMetiersPartProps {
  readOnly?: boolean
}

export default function ReglesMetiersPart({ readOnly }: ReglesMetiersPartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="parametrage.refVictimeEntreprise"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="ref-victime-entreprise"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Utilisation du n° de victime dans la saisie des dossiers"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
