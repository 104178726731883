import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import FluxTechniqueEntreprise, {
  FluxTechniqueEntrepriseFilter,
  FluxTechniqueEntrepriseSortType,
} from '../types/fluxTechniqueEntreprise'

export const fluxTechniqueEntrepriseApi = createApi({
  reducerPath: 'fluxTechniqueEntrepriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['FluxTechniqueEntreprise'],
  endpoints: (builder) => ({
    getListeFluxTechniquesEntreprise: builder.query<
      PageRecherche<FluxTechniqueEntreprise>,
      PageRechercheRequest<FluxTechniqueEntrepriseFilter, FluxTechniqueEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'entreprise/flux-technique',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
  }),
})

export const { useGetListeFluxTechniquesEntrepriseQuery } = fluxTechniqueEntrepriseApi
