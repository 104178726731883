import * as React from 'react'
import { NotificationsIcon, MenuBaseButton, MenuBaseButtonProps } from 'plateforme/components'

export default function NotificationsMenuButton(props: MenuBaseButtonProps & { nbNotifications: number }) {
  const { nbNotifications, icon, tooltip, ...other } = props
  return (
    <MenuBaseButton
      icon={icon ?? <NotificationsIcon color="primary" nbNotifications={nbNotifications} />}
      tooltip={tooltip ?? 'Notifications'}
      {...other}
    />
  )
}
