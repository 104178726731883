import React, { useState } from 'react'

import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { NoRowsOverlay } from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { makeEllipsis } from 'plateforme/services/utils'
import CloseIcon from '@mui/icons-material/Close'
import PageRecherche from 'plateforme/store/types/pageRecherche'
import MessagePlateforme from 'plateforme/store/types/message'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'

type TableauNotificationsPartProps = {
  notifications: PageRecherche<MessagePlateforme>
  typePartenaire: TypePartenaire
  onClose: VoidFunction
}

export default function TableauNotificationsPart({
  notifications,
  typePartenaire,
  onClose,
}: TableauNotificationsPartProps) {
  const total = notifications.totalItems ?? 0
  const nbLigneParPage = 10

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(nbLigneParPage)
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '30%' }}>Type de notification</TableCell>
                <TableCell sx={{ width: '20%' }}>Dossier</TableCell>
                <TableCell sx={{ width: '20%' }}>
                  {typePartenaire === TypePartenaire.Medecin ? 'Entreprise' : 'Médecin'}
                </TableCell>
                <TableCell sx={{ width: '10%' }}>Statut</TableCell>
                <TableCell sx={{ width: '20%' }}>Date/Heure</TableCell>
              </TableRow>
            </TableHead>
          </Table>
          {total && total > 0 ? (
            notifications?.items?.map((na: MessagePlateforme) => (
              <Table key={na.code}>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ width: '30%' }}>{na.labelType}</TableCell>
                    <TableCell sx={{ width: '20%' }}>{makeEllipsis(na.dossier?.libelle, 40)}</TableCell>
                    <TableCell sx={{ width: '20%' }}>
                      {typePartenaire === TypePartenaire.Medecin ? na.entreprise?.libelle : na.medecin?.libelle}
                    </TableCell>
                    <TableCell sx={{ width: '10%' }}>{na.lu ? 'Lue' : 'Non lue'}</TableCell>
                    <TableCell sx={{ width: '20%' }}>{formatDateTimeFR(na.date)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))
          ) : (
            <Table>
              <NoRowsOverlay />
            </Table>
          )}
        </TableContainer>
        {total > 0 && (
          <TablePagination
            component="div"
            count={total}
            rowsPerPageOptions={[nbLigneParPage]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Grid>

      <Grid justifyContent="flex-end" container item xs={12}>
        <Grid item>
          <Button variant="outlined" startIcon={<CloseIcon />} onClick={onClose}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
