import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { AnnulerButton, EditTextField, ValiderButton } from 'plateforme/components'
import { usePostSuiviMissionMutation } from 'assureur/store/apis/dossierAssureurApi'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise, {
  SuiviMissionEntrepriseAction,
  SuiviMissionEntrepriseRequest,
} from 'assureur/store/types/missionEntreprise'
import { useSnackbar } from 'notistack'
import { trimToUndefined } from 'plateforme/services/utils'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'

interface ActionMissionFormProps {
  dossier: DossierEntreprise
  derniereMission: MissionEntreprise
  action: SuiviMissionEntrepriseAction
  msgSucces: string
  msgEchec: string
  onClose: VoidFunction
}

type ActionMissionData = {
  commentaire: string
}

export default function ActionMissionForm({
  dossier,
  derniereMission,
  action,
  msgSucces,
  msgEchec,
  onClose,
}: ActionMissionFormProps) {
  const { code: codeMission } = derniereMission
  const { code: codeDossier } = dossier

  if (codeDossier === undefined || codeMission === undefined) {
    throw new Error(`Aucun mission associée n'a été trouvée`)
  }
  const [postSuiviMission, { error: errorPostSuiviMission, isLoading: isLoadingSuiviMission }] =
    usePostSuiviMissionMutation()
  const { enqueueSnackbar } = useSnackbar()

  const {
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      commentaire: '',
    } as ActionMissionData,
  })

  // mapping errors to form:
  useErrorFormMapper(errorPostSuiviMission as IQueryErrorResponse, setError, getValues)

  // const errorPost = errorAPI as IQueryErrorResponse

  /**
     * Attention, les erreurs possibles sont sur la taille du champs commentaire et sa présence obligatoire.
     * Le bouton n'est par défaut pas proposé si la demande de modification sur la même mission est supérieur à 3
     * l'erreur remontée pour le champ commentaire non renseigné sera :
     *     "code": "TM-400-REQUETE_NON_VALIDE",
            "message": "Échec de validation",
              "details": [
                  {
                      "message": "Doit être renseigné",
                      "fields": [
                          "demandesModifications[0].commentaire"
                      ]
                  }
              ],
     * demandesModifications est le tableau dans lequel sera stocké l'ensemble des commentaires pour l'action
     * DEMANDER_MODIFICATION. Le compteur pourra être de 0 à 2
     * si on a "demandesModifications" dans l'erreur, c'est une erreur sur la présence du champ.
     * */

  /* useEffect(() => {
    if (errorPost?.data?.details instanceof Array && errorPost.status === 400 && errorPost.data?.details?.length > 0) {
      setError('commentaire', {
        type: 'server',
        message: errorPost.data?.details?.find((detail) =>
          detail?.fields?.some((field) => field?.startsWith('demandesModifications'))
        )?.message,
      })
    }
  }, [errorPost, setError]) */

  const onSubmit = async (data: ActionMissionData) => {
    /**
     * request pour l'appel API
     */
    const request: SuiviMissionEntrepriseRequest = {
      codeDossier,
      codeMission,
      action,
      commentaire: trimToUndefined(data.commentaire),
    }

    /**
      appel API
     */
    await postSuiviMission(request)
      .unwrap()
      .then(() => {
        enqueueSnackbar(msgSucces, { variant: 'success' })
        onClose()
      })
      .catch(() => {
        enqueueSnackbar(msgEchec, { variant: 'error' })
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form-add-conclusion-pro" style={{ width: '100%' }}>
      <Grid container rowSpacing={2} columnSpacing={2} padding={2}>
        {/**
         * champ commentaire (obligatoire pour l'action DEMANDER_MODIFICATION
         */}
        <Grid item padding={2} xs={12}>
          <Controller
            name="commentaire"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                multiline
                id="commentaire"
                value={value}
                label="Commentaire"
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                minRows={3}
                fieldError={error}
              />
            )}
          />
        </Grid>
        {/**
         * bouton Annuler & Confirmer
         */}
        <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
          <Grid item>
            <AnnulerButton onClick={onClose} loading={isLoadingSuiviMission}>
              Annuler
            </AnnulerButton>
          </Grid>
          <Grid item>
            <ValiderButton disabled={!isValid} type="submit" loading={isLoadingSuiviMission}>
              Confirmer
            </ValiderButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
