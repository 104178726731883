import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Alert, Card, CardContent, CardHeader, Grid, Snackbar, Stack, Typography } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  RechargerButton,
  SelectInputEntreprise,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { getFactureCriteria, setFactureCriteria } from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateISO } from 'plateforme/services/dates.services'
import { FactureFilter, StatutFacture } from 'medecin/store/types/factureMedecin'
import { emptyToUndefined, trimToUndefined } from 'plateforme/services/utils'

type SearchFacturesProps = {
  search: (criteria: FactureFilter) => void
  searchError: IQueryErrorResponse
}

type FactureFilterData = {
  statuts?: StatutFacture[] | null
  codeEntreprise?: string
  dateDebut?: Date | null
  dateFin?: Date | null
}

export default function RechercheFacturesMedecinPart({ search, searchError }: SearchFacturesProps) {
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  // get default values from localStorage
  const defaultFactureCriteria = useSelector(getFactureCriteria)
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: FactureFilterData = {
    statuts: defaultFactureCriteria?.statuts,
    codeEntreprise: defaultFactureCriteria?.codeEntreprise,
    dateDebut: defaultFactureCriteria?.dateDebut ? new Date(defaultFactureCriteria.dateDebut) : null,
    dateFin: defaultFactureCriteria?.dateFin ? new Date(defaultFactureCriteria.dateFin) : null,
  }

  const resetValues: FactureFilterData = {
    statuts: null,
    codeEntreprise: undefined,
    dateDebut: null,
    dateFin: null,
  }

  const onSubmit = async (data: FactureFilterData) => {
    const filtre = {
      statuts: emptyToUndefined(data.statuts),
      codeEntreprise: trimToUndefined(data.codeEntreprise),
      dateDebut: formatDateISO(data.dateDebut),
      dateFin: formatDateISO(data.dateFin),
    }
    search(filtre)
    dispatch(setFactureCriteria(filtre))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      statuts: undefined,
      codeEntreprise: undefined,
      dateDebut: undefined,
      dateFin: undefined,
    }
    reset(resetValues)
    search(filtre)
    dispatch(setFactureCriteria(filtre))
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-facture">
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="codeEntreprise"
                control={control}
                defaultValue={initialValues.codeEntreprise}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputEntreprise
                    id="entreprise"
                    label="Entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="statuts"
                control={control}
                defaultValue={initialValues.statuts}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statuts"
                    label="Statuts"
                    value={value}
                    onBlur={onBlur}
                    onChangeMultiple={onChange}
                    referentielName="statutsFacture"
                    multiple
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Typography minWidth={100} maxWidth={100}>
                  Facture créée entre
                </Typography>
                <Controller
                  name="dateDebut"
                  control={control}
                  defaultValue={initialValues.dateDebut}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      InputProps={{
                        id: 'date-debut',
                        fullWidth: true,
                      }}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      label="Date de début"
                      fieldError={error}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Typography minWidth={100} maxWidth={100}>
                  Et
                </Typography>
                <Controller
                  name="dateFin"
                  control={control}
                  defaultValue={initialValues.dateFin}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      InputProps={{
                        id: 'date-fin',
                        fullWidth: true,
                      }}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      label="Date de fin"
                      fieldError={error}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton variant="contained" type="submit" form="form-recherche-facture" disabled={!isValid}>
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des factures
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
