import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import DocumentsTablePart from 'plateforme/parts/DocumentsTablePart'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { determineDocumentsDossierReadOnly } from 'plateforme/services/dossier.services'
import { LoadingProps } from 'plateforme/services/utils'
import { dossierConsultable } from 'assureur/services/rolesEntreprise.services'

type DerniersDocumentsSyntheseProps = {
  dossier?: DossierEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
}

export default function DocumentsSyntheseEntreprisePart({
  dossier,
  profilUtilisateur,
  loading,
}: DerniersDocumentsSyntheseProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={391} />
  }
  return <DocumentsSyntheseEntreprisePartLoaded dossier={dossier} profilUtilisateur={profilUtilisateur} />
}

function DocumentsSyntheseEntreprisePartLoaded({ dossier, profilUtilisateur }: DerniersDocumentsSyntheseProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // format:
  const documents = determineDocumentsDossierReadOnly(dossier)

  // rendu:
  if (!dossierConsultable(profilUtilisateur)) {
    return null
  }
  return (
    <Card>
      <CardHeader title="Derniers documents" />
      <CardContent>
        <DocumentsTablePart documents={documents} dossier={dossier} nbLigneParPage={5} readOnly compact />
      </CardContent>
    </Card>
  )
}
