import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import MissionMedecin from 'medecin/store/types/missionMedecin'

import { RootState } from 'plateforme/store'

export interface SelectedMessagesMissionMedecin {
  codeDossier?: string
  mission?: MissionMedecin
  indexMission?: number
  indexRapport?: number
  totalLu?: number
  totalNonLu?: number
}

export const selectedMessagesMissionMedecinSliceName = 'selectedMessagesMissionMedecin'

const selectedMessagesMissionMedecinSlice = createSlice({
  name: selectedMessagesMissionMedecinSliceName,
  initialState: {
    selectedMessagesMission: undefined,
  } as { selectedMessagesMission?: SelectedMessagesMissionMedecin },
  reducers: {
    setSelectedMessagesMissionMedecin: (state, action: PayloadAction<SelectedMessagesMissionMedecin | undefined>) => {
      state.selectedMessagesMission = action.payload
    },
  },
})

export const { setSelectedMessagesMissionMedecin } = selectedMessagesMissionMedecinSlice.actions

export const getSelectedMessagesMission = (state: RootState) =>
  state?.selectedMessagesMissionMedecin.selectedMessagesMission

export default selectedMessagesMissionMedecinSlice.reducer
