export default interface EntrepriseAdmin {
  code: string
  libelle: string
  actif: boolean
  codeACPR?: string
  codeSIREN?: string
  pageIndex?: number
  total?: number
  next?: string
  motCle?: string
  statuts?: statutsEnterprise
  dateCreationDebut?: string
  adresse1: string
  adresse2: string
  adresse3: string
  codePostal: string
  commune: string
  pays: string
  labelPays: string
  mail: string
  telephone: string
  labelStatut: string
  dateCreation: Date
  dateMiseAJour: string
  dateSuppression: string
}

export interface ReferentielEntrepriseFilter {
  motCle?: string
  statuts?: statutsEnterprise[]
  dateCreationDebut?: string
}

export enum ReferentielEntrepriseSortType {
  IDENTIFIANT_PLATEFORME_SORT = 'code_entreprise',
  RAISON_SOCIALE_SORT = 'libelle_entreprise',
  DATE_INSCRIPTION_SORT = 'date_creation',
}

export enum statutsEnterprise {
  INSCRIPTION = 'INSCRIPTION',
  ACTIVE = 'ACTIVE',
  INACTIF = 'INACTIVE',
}

export interface InscrireEntrepriseRequest {
  codeACPR?: string
  codeSIREN?: string
  libelle?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
  mail?: string
  telephone?: string
  parametrage?: ParametrageEntrepriseRequest
}

export interface ParametrageEntrepriseRequest {
  mailReseauMedecin?: string
}
