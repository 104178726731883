import React, { useState } from 'react'

import { AreaLoading, AjouterButton } from 'plateforme/components'
import { Stack } from '@mui/material'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import { isValideDefinitivement } from 'plateforme/services/rapport.services'
import FactureForm from './FactureForm'
import FactureMissionCard from './FactureMissionCard'

type TabFacturePartProps = {
  dossier?: DossierMedecin
  mission?: MissionEntreprise
  loading?: boolean
}

export default function TabFactureMedecinPart({ dossier, mission, loading }: TabFacturePartProps) {
  const [afficherFormulaire, setAfficherFormulaire] = useState(
    !dossier?.missions
      ?.filter((missionMedecin) => missionMedecin.associable)
      .some((m) => m.associable === true && (m.factures?.length ?? 0) > 0)
  )

  const readOnly = !(
    dossier?.missions
      ?.filter((missionMedecin) => missionMedecin.associable)
      .some((m) => m.facturable || isValideDefinitivement(m?.rapport)) ?? false
  )
  return (
    <Stack>
      {!readOnly && (
        <Stack direction="row" justifyContent="right">
          <AjouterButton disabled={afficherFormulaire} onClick={() => setAfficherFormulaire(true)}>
            Ajouter une facture
          </AjouterButton>
        </Stack>
      )}
      {!readOnly && afficherFormulaire && dossier && mission && (
        <FactureForm cacherFormulaire={() => setAfficherFormulaire(false)} dossier={dossier} />
      )}
      {loading ? (
        <AreaLoading height={540} />
      ) : (
        dossier &&
        dossier?.missions
          ?.filter((m) => m.associable && (m.factures?.length ?? 0) > 0)
          .map((m) => (
            <Stack key={m.code}>
              <FactureMissionCard dossier={dossier} mission={m} />
            </Stack>
          ))
      )}
    </Stack>
  )
}
