import React from 'react'
import uuid from 'react-uuid'
import { Divider, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { ReadOnlyDatePicker, ReadOnlyTextField } from 'plateforme/components'
import { TiercePersonne, TypePersonne, UniteEstimation } from 'assureur/store/types/dommagesEntreprise'

interface ReadOnlyDommagePersonBlockProps {
  label?: string
  periodes?: TiercePersonne[]
}

export default function ReadOnlyDommagePersonBlock({ label, periodes }: ReadOnlyDommagePersonBlockProps) {
  return (
    <Grid container>
      <Grid item xs={12} lg={3}>
        <Typography marginLeft={2} marginTop={1} color="primary" variant="subtitle2">
          {`${label} :`}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={9}>
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {periodes &&
            periodes.map((item, index) => {
              return (
                <li key={uuid()}>
                  <Stack
                    direction="row"
                    alignItems="flex-end"
                    spacing={2}
                    paddingBottom={index === periodes.length - 1 ? 0 : 3.5}
                  >
                    <Grid container direction="row" alignItems="flex-start">
                      {item?.type && (
                        <Grid item xs={12} display="flex">
                          <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle2" color="primary">
                              Type :
                            </Typography>
                            <RadioGroup row aria-labelledby="demo-error-radios" value={item.type}>
                              <FormControlLabel
                                disabled
                                value={TypePersonne.ACTIVE}
                                control={<Radio />}
                                label="Active"
                              />
                              <FormControlLabel
                                disabled
                                value={TypePersonne.PASSIVE}
                                control={<Radio />}
                                label="Passive"
                              />
                            </RadioGroup>
                          </Stack>
                        </Grid>
                      )}
                      <Grid item xs={12} md={5} display="flex" alignItems="flex-start">
                        <ReadOnlyTextField
                          id={`id.${index}`}
                          label="Nombre d'heure :"
                          fullWidth
                          value={item.nombreHeures}
                        />
                      </Grid>
                      <Grid item xs={12} md={7} display="flex" alignItems="flex-start">
                        <Stack direction="row" alignItems="center">
                          <Typography variant="subtitle2" color="primary">
                            Par :
                          </Typography>
                          <RadioGroup row aria-labelledby="demo-error-radios" value={item.par}>
                            <FormControlLabel disabled value={UniteEstimation.JOUR} control={<Radio />} label="Jours" />
                            <FormControlLabel
                              disabled
                              value={UniteEstimation.SEMAINE}
                              control={<Radio />}
                              label="Semaines"
                            />
                            <FormControlLabel disabled value={UniteEstimation.MOIS} control={<Radio />} label="Mois" />
                          </RadioGroup>
                        </Stack>
                      </Grid>
                      <Grid item xs={6} display="flex" alignItems="flex-start">
                        <ReadOnlyDatePicker
                          InputProps={{
                            id: 'date-debut',
                            fullWidth: true,
                          }}
                          value={item.periode.du === undefined ? null : item.periode.du}
                          label="Du"
                        />
                      </Grid>
                      <Grid item xs={6} display="flex" alignItems="flex-start">
                        <ReadOnlyDatePicker
                          InputProps={{
                            id: 'date-fin',
                            fullWidth: true,
                          }}
                          value={item.periode.au === undefined ? null : item.periode.au}
                          label="Au"
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Divider hidden={index === periodes.length - 1} />
                </li>
              )
            })}
        </ul>
      </Grid>
    </Grid>
  )
}
