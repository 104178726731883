import { Button, ButtonProps, darken, styled } from '@mui/material'

interface MessageTileProps extends ButtonProps {
  selected?: boolean
}

const MessageTile = styled(Button)<MessageTileProps>(({ theme, selected }) => ({
  width: '100%',
  height: 'max-content',
  textAlign: 'center',
  alignItems: 'flex-end',
  padding: theme.spacing(2, 1, 2, 1),
  '&:hover': {
    backgroundColor: darken('#b7a4cbde', 0.2),
  },
  backgroundColor: selected ? '#bdadd0ba' : '',
}))

export default MessageTile
