import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Switch,
} from '@mui/material'
import { EditTextField, MedecinImage } from 'plateforme/components'

type InformationsAdminMedecinPartProps = {
  readOnly: boolean
  codeRPPSReadOnly?: boolean
  codeSIRENReadOnly?: boolean
}

export default function InformationsAdminMedecinPart({
  readOnly,
  codeRPPSReadOnly = false,
  codeSIRENReadOnly = false,
}: InformationsAdminMedecinPartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Informations médecin" />
      <CardContent>
        <Stack direction="row" spacing={0} padding={2}>
          <Stack>
            <MedecinImage />
          </Stack>
          <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <Controller
                name="code"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldError={error}
                    label="Identifiant médecin plateforme"
                    fullWidth
                    readOnly
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="codeRPPS"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldError={error}
                    label="Numéro de RPPS"
                    fullWidth
                    readOnly={codeRPPSReadOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="codeSIREN"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldError={error}
                    label="Code SIREN"
                    fullWidth
                    readOnly={codeSIRENReadOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="libelle"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldError={error}
                    label="Identité médecin"
                    fullWidth
                    readOnly={readOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="mailContact"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <EditTextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fieldError={error}
                    label="Adresse mail"
                    fullWidth
                    readOnly={readOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="irca"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error} variant="standard">
                    <FormControlLabel
                      id="irca"
                      value={value}
                      control={<Switch onChange={onChange} onBlur={onBlur} checked={value} disabled={readOnly} />}
                      label="IRCA"
                    />
                    <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="expertiseSurPiece"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error} variant="standard">
                    <FormControlLabel
                      id="expertiseSurPiece"
                      value={value}
                      control={<Switch onChange={onChange} onBlur={onBlur} checked={value} disabled={readOnly} />}
                      label="Avis technique sur pièces"
                    />
                    <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  )
}
