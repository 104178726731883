import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, CardActionArea, CardContent, Checkbox, Grid, Stack, Typography } from '@mui/material'
import { FactureIcon, HandicapGraveIcon, RapportIcon } from 'plateforme/components'
import { determineShowHandicapGrave } from 'plateforme/services/mission.services'
import { consultationDossierHref } from 'admin/AdminApp'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import { StatutRapport } from 'admin/store/types/missionAdmin'
import { StatutFacture } from 'admin/store/types/factureAdmin'
import { determineDerniereMission } from 'admin/services/dossierAdmin.services'
import StatutDossierChip from './StatutDossierChip'

export interface CardDossierAdminProp {
  dossier: DossierAdmin
}

function ValueLabel({ label, value }: { label?: string; value?: string }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {value}
      </Typography>
    </Box>
  )
}

export default React.memo(DossierAdminCard)

function DossierAdminCard({ dossier }: CardDossierAdminProp) {
  // props:
  const { code: codeDossier, entreprise, expertise, evenement } = dossier

  // hooks:
  const navigate = useNavigate()

  // format:
  const mission = determineDerniereMission(dossier)
  const { rapport, factures: facturesMission } = mission || {}
  const displayReferenceDossier = mission?.libelle ? mission?.libelle : `Dossier ${dossier.libelle}`
  let factures = [...(facturesMission ?? [])]
  factures = factures.filter((f) => f.statut !== StatutFacture.ANNULEE)

  const hasHandicapGrave = determineShowHandicapGrave(mission)
  const hasRapport = rapport?.statut === StatutRapport.VALIDE_DEFINITIVEMENT
  const hasFacture = (factures?.length ?? 0) > 0

  factures?.sort((a, b) => {
    return a.code.localeCompare(b.code)
  })

  const derniereFacture = factures?.at(0)

  // rendu:
  return (
    <Grid item xs={12} sm={12} md={12} key={mission?.code}>
      <Card sx={{ width: '100%' }}>
        <CardActionArea>
          <CardContent sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '80px', alignItems: 'center' }}>
              <Checkbox color="default" />
            </Box>
            <Grid
              container
              spacing={2}
              width="100%"
              onClick={() => {
                navigate(consultationDossierHref(codeDossier))
              }}
            >
              <Grid item md={8} xs={6} sx={{ paddingLeft: 0, marginLeft: 0 }}>
                <Stack direction="column" spacing={0}>
                  <Typography variant="h1" color="text.primary" gutterBottom>
                    {displayReferenceDossier}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={4} xs={6}>
                <StatutDossierChip dossier={dossier} mission={mission} />
              </Grid>
              <Grid item md={3} xs={4}>
                <ValueLabel label="Cadre de l'expertise" value={expertise?.labelCadreExpertise} />
              </Grid>
              <Grid item md={3} xs={4}>
                <ValueLabel label="Nature de l'événement" value={evenement?.labelNature} />
              </Grid>
              <Grid item md={2} xs={4}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ height: '100%' }}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {hasHandicapGrave ? <HandicapGraveIcon mission={mission} /> : <Box minWidth={32} />}
                  {hasRapport ? <RapportIcon /> : <Box minWidth={32} />}
                  {hasFacture ? <FactureIcon facture={derniereFacture} /> : <Box minWidth={32} />}
                </Stack>
              </Grid>
              <Grid item md={2} xs={6} textAlign="left" sx={{ alignSelf: 'flex-end' }}>
                <Typography variant="h2" color="text.primary" gutterBottom>
                  {mission?.medecin?.libelle}
                </Typography>
              </Grid>
              <Grid item md={2} xs={6} textAlign="right" sx={{ alignSelf: 'flex-end' }}>
                <Typography variant="h2" color="text.primary" gutterBottom>
                  {entreprise?.libelle}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
