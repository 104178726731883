import React from 'react'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import { AreaLoading, ButtonsStack, RechargerButton, StyledDropzone, ValiderButton } from 'plateforme/components'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { ReferentielTypeDocument } from 'plateforme/store/types/referentiel'
import { DocumentPanelType } from 'plateforme/store/types/documentUpload'
import DossierPlateforme from 'plateforme/store/types/dossier'
import useDocumentActions from 'plateforme/store/hooks/useDocumentActions'
import MissionPlateforme from 'plateforme/store/types/mission'
import { ALLOWED_UPLOAD_FORMATS } from 'plateforme/constantes'
import FormUploadRow from './FormUploadRow'
import DocumentsTablePart from '../DocumentsTablePart'

type DocumentsPanelProps = {
  title: string
  typeDocumentPanel: DocumentPanelType
  dossier?: DossierPlateforme
  mission?: MissionPlateforme
  documents: DocumentTeleverse[]
  loading?: boolean
  readOnly?: boolean
  documentDownloadable?: boolean
  buttonText: string
  typeDocumentFilter?: (ref: ReferentielTypeDocument) => boolean
  onDeleteDocument?: (document: DocumentTeleverse, typeDocument: DocumentPanelType) => Promise<void>
  isLoadingDelete?: boolean
}

export default function DocumentsPart({
  title,
  typeDocumentPanel,
  dossier,
  mission,
  documents,
  loading,
  readOnly = true,
  documentDownloadable = true,
  buttonText,
  typeDocumentFilter,
  onDeleteDocument,
  isLoadingDelete,
}: DocumentsPanelProps) {
  const {
    styledDropzoneProps,
    uploaderState,
    onStartUpload,
    changeDocumentUploadAction,
    setDocumentUploadValidAction,
    removeDocumentUploadAction,
    onClear,
  } = useDocumentActions({ typeDocumentPanel, dossier, mission })

  const isDossier = [DocumentPanelType.DOSSIER_MEDECIN, DocumentPanelType.DOSSIER_ENTREPRISE].includes(
    typeDocumentPanel
  )

  if (loading) {
    return <AreaLoading height={350} />
  }

  return (
    <Stack>
      {(!readOnly || !isDossier) && (
        <Card>
          <CardHeader title={title} />
          <CardContent>
            <Stack>
              {!readOnly && (
                <>
                  <StyledDropzone {...styledDropzoneProps} accept={ALLOWED_UPLOAD_FORMATS} buttonText={buttonText} />
                  <Stack>
                    {uploaderState.uploads.map((upload) => (
                      <FormUploadRow
                        key={upload.key}
                        changeDocumentUpload={changeDocumentUploadAction}
                        removeDocumentUpload={removeDocumentUploadAction}
                        setDocumentUploadValid={setDocumentUploadValidAction}
                        documentUpload={upload}
                        disabled={uploaderState.isRunning}
                        typeDocumentFilter={typeDocumentFilter}
                      />
                    ))}
                  </Stack>
                  {!uploaderState.isRunning && uploaderState.uploads.length > 0 && (
                    <ButtonsStack>
                      <RechargerButton variant="outlined" onClick={onClear}>
                        Nettoyer
                      </RechargerButton>
                      <ValiderButton onClick={onStartUpload}>Valider l&apos;ajout</ValiderButton>
                    </ButtonsStack>
                  )}
                </>
              )}
              {!isDossier && (
                <DocumentsTablePart
                  dossier={dossier}
                  documents={documents}
                  readOnly={readOnly}
                  typeDocumentsTable={typeDocumentPanel}
                  documentDownloadable={documentDownloadable}
                  onDeleteDocument={onDeleteDocument}
                  isLoadingDelete={isLoadingDelete}
                  nbLigneParPage={5}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      )}
      {isDossier && (
        <Card>
          <CardHeader title="Liste de documents" />
          <CardContent>
            <DocumentsTablePart
              dossier={dossier}
              documents={documents}
              readOnly={readOnly}
              documentDownloadable={documentDownloadable}
              typeDocumentsTable={typeDocumentPanel}
              onDeleteDocument={onDeleteDocument}
              isLoadingDelete={isLoadingDelete}
              nbLigneParPage={15}
            />
          </CardContent>
        </Card>
      )}
    </Stack>
  )
}
