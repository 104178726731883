import React from 'react'
import {
  AideIcon,
  AideMenuButton,
  ChangerProfilIcon,
  ChangerProfilMenuButton,
  DeconnexionIcon,
  DeconnexionMenuButton,
  MenuAppBar,
  MenuAppBarItem,
} from 'plateforme/components'
import { profilHref } from 'plateforme/App'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { homeHref } from 'admin/AdminApp'
import getUtilisataurRoles from 'plateforme/services/utilisateur.service'

export default function MenuAdminPart() {
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilUtilisateur = utilisateur?.profilActif
  const isUserWithMultipleRoles = getUtilisataurRoles(utilisateur).length > 1
  const libelleRole = profilUtilisateur?.role?.description
  const libelleRaisonSocial = profilUtilisateur?.raisonSociale
  const libelleService = profilUtilisateur?.libelleService
  const menuItems: MenuAppBarItem[] = [
    {
      id: 'menu_aide',
      href: '#todo_aide_href',
      button: <AideMenuButton />,
      mobile: (
        <>
          <AideIcon withMargin /> Aide
        </>
      ),
    },
    {
      id: 'menu_changer_profil',
      href: profilHref,
      button: (
        <ChangerProfilMenuButton
          fontSize={11}
          lineHeight={1.2}
          noWrap
          disabled={!isUserWithMultipleRoles}
          withCompareArrows={isUserWithMultipleRoles}
        >
          {libelleRole}
          <br />
          {libelleRaisonSocial}
          {libelleService ? ` - ${libelleService}` : null}
        </ChangerProfilMenuButton>
      ),
      mobile: (
        <>
          <ChangerProfilIcon withCompareArrows={isUserWithMultipleRoles} withMargin /> {libelleRole}
        </>
      ),
    },
    {
      id: 'menu_deconnexion',
      isDeconnexion: true,
      button: <DeconnexionMenuButton />,
      mobile: (
        <>
          <DeconnexionIcon withMargin /> Se déconnecter
        </>
      ),
    },
  ]

  return <MenuAppBar homeHref={homeHref} menuItems={menuItems} profilUtilisateur={profilUtilisateur} />
}
