import React from 'react'

import { useGetTop10HistoriqueConsultationsQuery } from 'plateforme/store/apis/historiqueConsultationApi'
import { toMedecinHref } from 'plateforme/App'
import { consultationDossierPath } from 'medecin/MedecinApp'
import TableauDerniersDossiersPart from 'plateforme/parts/TableauDerniersDossiersPart'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'

/* Page d'acceuil médecin, tableau des derniers dossiers consultés */

export default function DerniersDossiersMedecinPart() {
  const { data: historiqueConsultations, isLoading, isFetching } = useGetTop10HistoriqueConsultationsQuery()

  const hrefConsultationDossierMedecin = (codeDossier?: string) => {
    return toMedecinHref(consultationDossierPath).replace(':codeDossier', codeDossier ?? '')
  }

  return (
    <TableauDerniersDossiersPart
      historiqueConsultations={historiqueConsultations}
      loading={isLoading || isFetching}
      hrefConsultationDossier={hrefConsultationDossierMedecin}
      typePartenaire={TypePartenaire.Medecin}
    />
  )
}
