import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import DossierPlateforme from 'plateforme/store/types/dossier'
import LieuExpertiseCommon from './LieuExpertiseCommon'

interface LieuExpertiseFormProps {
  dossier: DossierPlateforme
}

export default function LieuExpertiseForm({ dossier }: LieuExpertiseFormProps) {
  return (
    <Card>
      <CardHeader title="Lieu expertise" />
      <CardContent>
        <LieuExpertiseCommon dossier={dossier} />
      </CardContent>
    </Card>
  )
}
