import React from 'react'
import {
  Alert,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import {
  ButtonsStack,
  NoRowsOverlay,
  ModifierIconButton,
  SupprimerIconButton,
  VoirIconButton,
} from 'plateforme/components'
import { MSG_ERROR_CHARGEMENT_DES_DONNEES } from 'plateforme/constantes'
import { addSeconds, isAfter } from 'date-fns'
import Actualite from 'plateforme/store/types/actualite'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import EditerActualiteForm from './EditerActualiteForm'
import ConsulterActualitePart from './ConsulterActualitePart'

type ListeActualitesPartProps = {
  combinedData: Array<Actualite>
  total?: number
  nbLigneParPage: number
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
}

enum StatutActualite {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  FUTUR = 'FUTUR',
}

export default function ListeActualitesAdminPart({
  combinedData,
  total,
  nbLigneParPage,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeActualitesPartProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(nbLigneParPage)
  const { confirm, confirmDialog, closeConfirmDialog } = useConfirmDialog()

  const onModifierActualite = (actualite: Actualite, desactivation: boolean) => () => {
    confirm({
      title: desactivation ? `Désactiver l'actualité` : `Modifier l'actualité`,
      confirmMsg: '',
      withForm: true,
      maxWidth: 'md',
      form: <EditerActualiteForm actualite={actualite} onClose={closeConfirmDialog} desactivation={desactivation} />,
    })
  }

  const onConsulterActualite = (actualite: Actualite) => () => {
    confirm({
      maxWidth: 'lg',
      fullWidth: true,
      withForm: true,
      form: <ConsulterActualitePart actualite={actualite} onClose={closeConfirmDialog} />,
      confirmMsg: '',
      title: `${actualite.titre}`,
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const statutFromActualite = (actualite: Actualite): string => {
    const now = addSeconds(new Date(), 1)
    const isDebutAuFutur = isAfter(new Date(actualite.dateDebut), now)

    if (!isDebutAuFutur && (actualite?.dateFin === undefined || new Date(actualite?.dateFin) > now)) {
      return StatutActualite.ACTIVE
    }

    if (isDebutAuFutur) {
      return StatutActualite.FUTUR
    }

    return StatutActualite.INACTIVE
  }

  return (
    <>
      {confirmDialog}
      {isError && !isSuccess && !isLoading && !isFetching ? (
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
          {MSG_ERROR_CHARGEMENT_DES_DONNEES}
        </Alert>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table key="table-actualites-id">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '40%' }}>Titre</TableCell>
                  <TableCell sx={{ width: '10%' }}>Date de début</TableCell>
                  <TableCell sx={{ width: '10%' }}>Date de fin</TableCell>
                  <TableCell sx={{ width: '10%' }}>Accès</TableCell>
                  <TableCell sx={{ width: '10%' }}>Statut</TableCell>
                  <TableCell sx={{ width: '20%' }} align="right" />
                </TableRow>
              </TableHead>

              {total && total > 0 ? (
                <TableBody>
                  {combinedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((actu: Actualite) => (
                    <TableRow key={actu.id}>
                      <TableCell sx={{ width: '40%' }}>{actu.titre}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(actu.dateDebut)}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{formatDateTimeFR(actu.dateFin)}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{actu.typePartenaire ?? 'TOUS'}</TableCell>
                      <TableCell sx={{ width: '10%' }}>{statutFromActualite(actu)}</TableCell>
                      <TableCell sx={{ width: '20%', paddingRight: 4 }} align="right">
                        <ButtonsStack>
                          <VoirIconButton
                            onClick={onConsulterActualite(actu)}
                            tooltip="Voir l'actualité"
                            tooltipPlacement="left"
                          />
                          {statutFromActualite(actu) === StatutActualite.ACTIVE ||
                          statutFromActualite(actu) === StatutActualite.FUTUR ? (
                            <ModifierIconButton onClick={onModifierActualite(actu, false)} tooltip="Modifier" />
                          ) : (
                            <Box width={46}>&nbsp;</Box>
                          )}

                          {statutFromActualite(actu) === StatutActualite.ACTIVE ||
                          statutFromActualite(actu) === StatutActualite.FUTUR ? (
                            <SupprimerIconButton onClick={onModifierActualite(actu, true)} tooltip="Désactiver" />
                          ) : (
                            <Box width={46}>&nbsp;</Box>
                          )}
                        </ButtonsStack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <NoRowsOverlay />
              )}
            </Table>
          </TableContainer>
          {total && total > 0 && (
            <TablePagination
              component="div"
              count={total}
              rowsPerPageOptions={[5, 10]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
    </>
  )
}
