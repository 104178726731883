import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Box, Stack, Typography } from '@mui/material'
import { TabsComponent, TabDefinition } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { trimToUndefined } from 'plateforme/services/utils'
import ProfilEntreprise, {
  DomaineContractuelEntreprise,
  ServiceEntreprise,
  TypeRemissionementAutoCarence,
  TypeRemissionnementAutoConclusionProvisoire,
} from 'plateforme/store/types/profilEntreprise'
import Referentiel, { TypeMessageNotification, TypeMessageNotificationTag } from 'plateforme/store/types/referentiel'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { usePutAdminEntrepriseMutation } from 'admin/store/apis/gestionEntrepriseApi'
import TabDelaisPart from '../TabDelaisPart'
import TabNotificationsPart from '../TabNotificationsPart'
import TabOrganisationPart from '../TabOrganisationPart'
import TabReglesMetiersPart from '../TabReglesMetiersPart'
import {
  addStatutAndRegisteredToForm,
  ProfilEntrepriseForm,
  getContactsForm,
  getContactsFormToRequest,
  getDelaisForm,
  getDelaisFormToRequest,
  getDomainesFormToRequest,
  getFicheConclusionForm,
  getFicheConclusionRequest,
  getNatureEvenementsForm,
  getNotificationsFormFromReferentiel,
  getServicesFormToRequest,
  getRapportConseilConclusionRequest,
  getTypesRapportConseilForm,
  getDommagesPeriodesMax,
  getDommagesCommentairesMax,
} from './ParametrageEntrepriseForm'

export interface TabsParametresEntreprisePartProps {
  entreprise?: ProfilEntreprise
  habilitationTab?: TabDefinition
  referentiel?: Referentiel
  readOnly?: boolean
}

export default function TabsParametresEntreprisePart({
  entreprise,
  habilitationTab,
  referentiel,
  readOnly = true,
}: TabsParametresEntreprisePartProps) {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      code: entreprise?.code ?? '',
      codeACPR: entreprise?.codeACPR ?? '',
      libelle: entreprise?.libelle ?? '',
      adresse1: entreprise?.adresse1 ?? '',
      adresse2: entreprise?.adresse2 ?? '',
      adresse3: entreprise?.adresse3 ?? '',
      codePostal: entreprise?.codePostal ?? '',
      commune: entreprise?.commune ?? '',
      pays: entreprise?.pays ?? '',
      mail: entreprise?.mail ?? '',
      telephone: entreprise?.telephone ?? '',
      statut: entreprise?.statut ?? '',
      labelStatut: entreprise?.labelStatut ?? '',
      parametrage: {
        canalWs: entreprise?.parametrage?.canalWs ?? false,
        clientId: entreprise?.parametrage?.clientId ?? '',
        canalExtranet: entreprise?.parametrage?.canalExtranet ?? false,
        lettreMission: entreprise?.parametrage?.lettreMission ?? false,
        notifiableParMail: entreprise?.parametrage?.notifiableParMail ?? false,
        mailFormatable: entreprise?.parametrage?.mailFormatable ?? false,
        mailFormatObjet:
          entreprise?.parametrage?.mailFormatObjet ?? 'Dossier $num_dossier_plateforme - $code_action $libelle_action',
        mailReseauMedecin: entreprise?.parametrage?.mailReseauMedecin ?? '',
        cloisonnementService: entreprise?.parametrage?.cloisonnementService ?? false,
        handicapGrave: entreprise?.parametrage?.handicapGrave ?? false,
        precisionsMissionMax: entreprise?.parametrage?.precisionsMissionMax ?? 0,
        commentaireDemandeModificationMissionMax:
          entreprise?.parametrage?.commentaireDemandeModificationMissionMax ?? 255,
        commentaireEvenementMax: entreprise?.parametrage?.commentaireEvenementMax ?? 255,
        commentaireExpertiseMax: entreprise?.parametrage?.commentaireExpertiseMax ?? 255,
        rdv: entreprise?.parametrage?.rdv ?? false,
        lieuExpertiseAvecADefinir: entreprise?.parametrage?.lieuExpertiseAvecADefinir ?? false,
        tiercePersonneDefinitivePeriodeSansFin:
          entreprise?.parametrage?.tiercePersonneDefinitivePeriodeSansFin ?? false,
        tiercePersonnePeriodeAvecType: entreprise?.parametrage?.tiercePersonnePeriodeAvecType ?? false,
        dommagesPeriodesMax: getDommagesPeriodesMax(entreprise),
        dommagesCommentairesMax: getDommagesCommentairesMax(entreprise),
        services:
          entreprise?.parametrage?.services?.map((service: ServiceEntreprise) =>
            addStatutAndRegisteredToForm(service)
          ) || [],
        naturesEvenements: getNatureEvenementsForm(entreprise),
        refVictimeEntreprise: entreprise?.parametrage?.refVictimeEntreprise ?? false,
        assure: entreprise?.parametrage?.assure ?? false,
        expertiseSurPiece: entreprise?.parametrage?.expertiseSurPiece ?? false,
        intervenantOccasionnel: entreprise?.parametrage?.intervenantOccasionnel ?? false,
        messagerieMission: entreprise?.parametrage?.messagerieMission ?? false,
        fichesConclusions: getFicheConclusionForm(entreprise),
        typesRapportConseilPro: getTypesRapportConseilForm(TypeRapport.CONCLUSION_PROVISOIRE, entreprise),
        typesRapportConseilDef: getTypesRapportConseilForm(TypeRapport.CONCLUSION_DEFINITIVE, entreprise),
        domainesContractuels:
          entreprise?.parametrage?.domainesContractuels?.map((domaine: DomaineContractuelEntreprise) =>
            addStatutAndRegisteredToForm(domaine)
          ) || [],
        contacts: getContactsForm(entreprise),
        delaisRelance: getDelaisForm(entreprise),
        notifications: getNotificationsFormFromReferentiel(
          entreprise,
          referentiel,
          'ENTREPRISE' as TypeMessageNotificationTag,
          ['MEL_MED' as TypeMessageNotification]
        ),
        refMissionEntrepriseObligatoire: entreprise?.parametrage?.refMissionEntrepriseObligatoire ?? false,
        remissionnementCarence: {
          enabled:
            (entreprise?.parametrage?.remissionnementCarence?.typeAuto ?? TypeRemissionementAutoCarence.JAMAIS) !==
            TypeRemissionementAutoCarence.JAMAIS,
          typeAuto: entreprise?.parametrage?.remissionnementCarence?.typeAuto ?? null,
        },
        remissionnementConclusionProvisoire: {
          enabled:
            (entreprise?.parametrage?.remissionnementConclusionProvisoire?.typeAuto ??
              TypeRemissionnementAutoConclusionProvisoire.JAMAIS) !==
            TypeRemissionnementAutoConclusionProvisoire.JAMAIS,
          typeAuto: entreprise?.parametrage?.remissionnementConclusionProvisoire?.typeAuto ?? null,
          tauxAIPP: entreprise?.parametrage?.remissionnementConclusionProvisoire?.tauxAIPP ?? '',
        },
        plagesIpAutoriseesExtranet: entreprise?.parametrage?.plagesIpAutoriseesExtranet ?? [' '],
        plagesIpAutoriseesWs: entreprise?.parametrage?.plagesIpAutoriseesWs ?? [' '],
      },
    } as ProfilEntrepriseForm,
  })

  const [putAdminEntreprise, { error: errorPut }] = usePutAdminEntrepriseMutation()
  const { enqueueSnackbar } = useSnackbar()

  // Mapping Errors in form:
  useErrorFormMapper(errorPut as IQueryErrorResponse, methods.setError, methods.getValues)

  const displaySnackBarError = () => {
    enqueueSnackbar(`La modification du paramétrage entreprise a échoué`, { variant: 'error' })
  }

  const onSubmit = readOnly
    ? undefined
    : async () => {
        // si erreur dans le formulaire coté front
        if (Object.keys(methods.formState.errors).length !== 0) {
          displaySnackBarError()
          return
        }

        const formValues = methods.getValues() as ProfilEntrepriseForm

        const restrictionIpExtranet =
          (formValues.parametrage?.plagesIpAutoriseesExtranet?.filter((p) => p && p !== ' ').length ?? 0) > 0

        const restrictionIpWs =
          (formValues.parametrage?.plagesIpAutoriseesWs?.filter((p) => p && p !== ' ').length ?? 0) > 0

        await putAdminEntreprise({
          code: trimToUndefined(formValues.code),
          codeACPR: trimToUndefined(formValues.codeACPR),
          libelle: trimToUndefined(formValues.libelle),
          adresse1: trimToUndefined(formValues.adresse1),
          adresse2: trimToUndefined(formValues.adresse2),
          adresse3: trimToUndefined(formValues.adresse3),
          codePostal: trimToUndefined(formValues.codePostal),
          commune: trimToUndefined(formValues.commune),
          pays: formValues.pays,
          mail: trimToUndefined(formValues.mail),
          telephone: trimToUndefined(formValues.telephone),
          statut: trimToUndefined(formValues.statut),
          labelStatut: trimToUndefined(formValues.labelStatut),
          parametrage: {
            canalWs: formValues.parametrage?.canalWs,
            clientId: formValues.parametrage?.canalWs ? trimToUndefined(formValues.parametrage?.clientId) : undefined,
            canalExtranet: formValues.parametrage?.canalExtranet,
            lettreMission: formValues.parametrage?.lettreMission,
            cloisonnementService: formValues.parametrage?.cloisonnementService,
            handicapGrave: formValues.parametrage?.handicapGrave,
            precisionsMissionMax: formValues.parametrage?.precisionsMissionMax,
            commentaireDemandeModificationMissionMax: formValues.parametrage?.commentaireDemandeModificationMissionMax,
            commentaireEvenementMax: formValues.parametrage?.commentaireEvenementMax,
            commentaireExpertiseMax: formValues.parametrage?.commentaireExpertiseMax,
            tiercePersonneDefinitivePeriodeSansFin: formValues.parametrage?.tiercePersonneDefinitivePeriodeSansFin,
            tiercePersonnePeriodeAvecType: formValues.parametrage?.tiercePersonnePeriodeAvecType,
            dommagesPeriodesMax: formValues.parametrage?.dommagesPeriodesMax,
            dommagesCommentairesMax: formValues.parametrage?.dommagesCommentairesMax,
            typesRapportConseilPro: getRapportConseilConclusionRequest(TypeRapport.CONCLUSION_PROVISOIRE, formValues),
            typesRapportConseilDef: getRapportConseilConclusionRequest(TypeRapport.CONCLUSION_DEFINITIVE, formValues),
            rdv: formValues.parametrage?.rdv,
            lieuExpertiseAvecADefinir: formValues.parametrage?.lieuExpertiseAvecADefinir,
            services: getServicesFormToRequest(formValues),
            notifiableParMail: formValues.parametrage?.notifiableParMail,
            mailFormatable: formValues.parametrage?.notifiableParMail ? formValues.parametrage?.mailFormatable : false,
            mailFormatObjet: trimToUndefined(formValues.parametrage?.mailFormatObjet),
            mailReseauMedecin: trimToUndefined(formValues.parametrage?.mailReseauMedecin),
            expertiseSurPiece: formValues.parametrage?.expertiseSurPiece,
            refVictimeEntreprise: formValues.parametrage?.refVictimeEntreprise,
            assure: formValues.parametrage?.assure,
            intervenantOccasionnel: formValues.parametrage?.intervenantOccasionnel,
            messagerieMission: formValues.parametrage?.messagerieMission,
            refMissionEntrepriseObligatoire: formValues.parametrage?.refMissionEntrepriseObligatoire,
            remissionnementCarence: {
              typeAuto:
                formValues.parametrage?.remissionnementCarence?.enabled &&
                formValues.parametrage?.remissionnementCarence?.typeAuto
                  ? formValues.parametrage?.remissionnementCarence?.typeAuto
                  : TypeRemissionementAutoCarence.JAMAIS,
            },
            remissionnementConclusionProvisoire: {
              typeAuto:
                formValues.parametrage?.remissionnementConclusionProvisoire?.enabled &&
                formValues.parametrage?.remissionnementConclusionProvisoire?.typeAuto
                  ? formValues.parametrage?.remissionnementConclusionProvisoire?.typeAuto
                  : TypeRemissionnementAutoConclusionProvisoire.JAMAIS,
              tauxAIPP:
                formValues.parametrage?.remissionnementConclusionProvisoire?.enabled &&
                formValues.parametrage?.remissionnementConclusionProvisoire?.typeAuto ===
                  TypeRemissionnementAutoConclusionProvisoire.TAUX_AIPP
                  ? formValues.parametrage?.remissionnementConclusionProvisoire?.tauxAIPP
                  : undefined,
            },
            typesFichesConclusions: getFicheConclusionRequest(formValues),
            domainesContractuels: getDomainesFormToRequest(formValues),
            contacts: getContactsFormToRequest(formValues),
            delaisRelance: getDelaisFormToRequest(formValues),
            notifications: formValues.parametrage?.notifications,
            plagesIpAutoriseesExtranet: restrictionIpExtranet
              ? formValues.parametrage?.plagesIpAutoriseesExtranet?.filter((p) => trimToUndefined(p))
              : undefined,
            plagesIpAutoriseesWs: restrictionIpWs
              ? formValues.parametrage?.plagesIpAutoriseesWs?.filter((p) => trimToUndefined(p))
              : undefined,
          },
        } as ProfilEntreprise)
          .unwrap()
          .then(() => {
            enqueueSnackbar(`Le paramétrage entreprise a été modifié`, { variant: 'success' })
          })
          .catch(() => {
            displaySnackBarError()
          })
      }

  let propsTab = ([] as TabDefinition[])
    .concat([
      {
        id: 'organisation',
        title: 'Organisation',
        component: <TabOrganisationPart onSubmit={onSubmit} />,
      },
      {
        id: 'regles-metiers',
        title: 'Règles métiers',
        component: <TabReglesMetiersPart onSubmit={onSubmit} />,
      },
      {
        id: 'delais',
        title: 'Délais',
        component: <TabDelaisPart onSubmit={onSubmit} />,
      },
      {
        id: 'notifications',
        title: 'Notifications',
        component: <TabNotificationsPart onSubmit={onSubmit} />,
      },
    ] as TabDefinition[])
    .filter((t) => t !== undefined)

  if (habilitationTab) {
    propsTab = propsTab.concat(habilitationTab)
  }

  return (
    <>
      <NavigationPart fullWidth>
        <Stack direction="row" spacing={1} alignItems="center" overflow="clip">
          <Typography variant="subtitle1" noWrap>
            Paramétrage
          </Typography>
          {entreprise?.libelle && entreprise?.code && (
            <Typography variant="h2" color="primary" whiteSpace="nowrap">
              {entreprise?.libelle} - {entreprise?.code}
            </Typography>
          )}
        </Stack>
      </NavigationPart>
      <Box marginTop={2}>
        <FormProvider {...methods}>
          <TabsComponent withNavigation tabs={propsTab} />
        </FormProvider>
      </Box>
    </>
  )
}
