import { hasRoles, Role } from 'plateforme/services/roles.services'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'

export default function determineAccesModificationParametrage(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_TECH])
}
export function determineAccesInscriptionMedecin(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesConsultationMedecin(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesInscriptionEntreprise(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_TECH])
}

export function determineAccesConsultationEntreprise(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesGestionNomenclature(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesGestionActualites(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_TECH])
}

export function determineAccesConsultationDossier(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesConsultationNotification(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesConsultationTraceFonctionnelle(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesConsultationIndicateurActivite(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}

export function determineAccesTableauDeBord(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH])
}
