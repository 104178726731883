import React from 'react'
import { Box, TableCell, TableFooter, TableRow, Typography } from '@mui/material'

export default function NoRowsOverlay() {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={100}>
          <Box padding={4} display="flex" alignItems="center" justifyContent="center">
            <Typography variant="body2" fontStyle="italic" color="readOnly.main">
              Aucune donnée
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}
