import { FormControl, FormControlLabel, FormHelperText, Grid, Switch } from '@mui/material'
import { EditTextField } from 'plateforme/components'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface EnvoiNotificationsMailPartProps {
  readOnly?: boolean
}

export default function EnvoiNotificationsMailPart({ readOnly }: EnvoiNotificationsMailPartProps) {
  const { control, watch, clearErrors } = useFormContext()

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={6}>
          <Controller
            name="parametrage.mailFormatable"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                <FormControlLabel
                  id="mailFormatable"
                  value={value}
                  control={
                    <Switch
                      onClick={() => clearErrors('parametrage.mailFormatObjet')}
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value}
                    />
                  }
                  label="Formatage objet du mail / Préfixe ligne "
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        {watch('parametrage.mailFormatable') && (
          <Grid item xs={6}>
            <Controller
              name="parametrage.mailFormatObjet"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label="Syntaxe objet du mail"
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
