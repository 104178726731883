import React from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { SerializedError } from '@reduxjs/toolkit'
import { Box, Stack, styled, Typography } from '@mui/material'
import { AccueilButton, TransmedLogo } from 'plateforme/components'
import errorMessage from './errorMessage'

const BoxStyled = styled(Box)(() => ({
  backgroundColor: '#ffffff',
}))

export default function ErrorPage({ cause }: { cause?: FetchBaseQueryError | SerializedError }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="95vh">
      <BoxStyled>
        <Stack direction="row" alignItems="center" justifyContent="center" padding={4}>
          <TransmedLogo />
        </Stack>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Une erreur s&apos;est produite.
        </Typography>
        <Typography sx={{ textAlign: 'center' }} padding={4}>
          {errorMessage(cause)}
        </Typography>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Si le problème persiste, merci de contacter notre assistance :
          <a href="mailto:assistance-transmed@agira.asso.fr">assistance-transmed@agira.asso.fr</a>
        </Typography>
        <Box display="flex" justifyContent="center" padding={4}>
          <AccueilButton href="/"> Retour à la page d&apos;accueil</AccueilButton>
        </Box>
      </BoxStyled>
    </Box>
  )
}
