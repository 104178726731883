import { CardContent, styled } from '@mui/material'

export const TransparentCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0),
  '&:last-child': {
    padding: theme.spacing(0),
  },
}))

export default TransparentCardContent
