import React from 'react'
import { Badge, BadgeProps, Chip } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import { Stack } from '@mui/system'
import styled from '@emotion/styled'

export interface MessageChipProps {
  totalMessage: number
  totalMessageNonLu: number
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 4,
    top: 3,
  },
}))

export default function MessageChip({ totalMessage, totalMessageNonLu }: MessageChipProps) {
  const textInfo =
    totalMessage > 0
      ? `${totalMessage} message${totalMessage > 1 ? 's' : ''} \n dont ${totalMessageNonLu} non lus`
      : 'aucun message'
  return (
    <Stack direction="row" spacing={1}>
      <Chip
        sx={{
          height: 'auto',
          minHeight: '32px',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'pre-line',
          },
          backgroundColor: '#e4ddec',
          borderColor: '#a68cbe !important',
          border: '0.1px solid',
        }}
        icon={
          <StyledBadge color="error" variant={totalMessageNonLu > 0 ? 'dot' : undefined}>
            <MailIcon fontSize="small" sx={{ marginLeft: 1, marginRight: '1px' }} />
          </StyledBadge>
        }
        label={textInfo}
        variant="outlined"
      />
    </Stack>
  )
}
