import React from 'react'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import { AreaLoading, ReadOnlyEmailField, ReadOnlyTextField, LabelIconField, MedecinImage } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'
import { useGetListeHabilitationsEntrepriseQuery } from 'assureur/store/apis/habilitationEntrepriseApi'
import { habilitationActiveWithCodeMedecin } from 'assureur/store/types/habilitationEntreprise'
import MedecinEntreprise from 'assureur/store/types/medecinEntreprise'

export interface InformationsMedecinPartProps {
  medecin?: MedecinEntreprise
}

export interface InformationsMedecinPartLoadedProps {
  medecin: MedecinEntreprise
}

export default function InformationsMedecinPart({ medecin, loading }: InformationsMedecinPartProps & LoadingProps) {
  if (loading || medecin === undefined) {
    return <AreaLoading height={351} />
  }
  return <InformationsMedecinPartLoaded medecin={medecin} />
}

function InformationsMedecinPartLoaded({ medecin }: InformationsMedecinPartLoadedProps) {
  const {
    data: habilitationResponse,
    isLoading: isLoadingHabilitation,
    isFetching: isFetchingHabilitation,
  } = useGetListeHabilitationsEntrepriseQuery(habilitationActiveWithCodeMedecin(medecin.code))

  if (isLoadingHabilitation || isFetchingHabilitation) {
    return <AreaLoading height={351} />
  }

  const habilitationActive = habilitationResponse?.items.find((h) => h.statut === StatutsHabilitation.ACTIVE)

  const intervenantOccasionnel = habilitationActive?.intervenantOccasionnel
  const expertiseSurPiece =
    (habilitationActive && habilitationActive.expertiseSurPiece) || (!habilitationActive && medecin?.expertiseSurPiece)

  return (
    <Card>
      <CardHeader title="Informations médecin" />
      <CardContent sx={{ maxheight: '350px' }}>
        <Stack direction="row" spacing={0}>
          <Stack>
            <MedecinImage />
          </Stack>
          <Stack width="100%">
            <ReadOnlyTextField
              id="id-medecin-plateforme"
              label="Identifiant médecin plateforme"
              value={medecin?.codeRPPS ?? medecin?.codeSIREN}
              fullWidth
            />
            <ReadOnlyTextField fullWidth id="id-medecin" value={medecin?.libelle} label="Identité médecin" />
            <ReadOnlyEmailField id="medecin-mail" label="Adresse mail" value={medecin?.mailContact} fullWidth />
            {intervenantOccasionnel && (
              <Stack direction="row" alignItems="center">
                <LabelIconField iconType="check" label="Intervenant occasionnel" />
              </Stack>
            )}
            {medecin?.irca && (
              <Stack direction="row" alignItems="center">
                <LabelIconField iconType="check" label="Médecin expert IRCA" />
              </Stack>
            )}
            {expertiseSurPiece && (
              <Stack direction="row" alignItems="center">
                <LabelIconField iconType="check" label="Avis techniques sur pièce" />
              </Stack>
            )}
            <ReadOnlyTextField
              id="medecin-commentaireActivite"
              label="Commentaire activité"
              value={medecin?.commentaireActivite}
              fullWidth
              multiline
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
