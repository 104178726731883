import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { BluePaper, Loading, TitleChip } from 'plateforme/components'
import { LibelleRapport, TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { capitalizeFirst } from 'plateforme/services/utils'
import { ReferenceParametrageEntreprise } from 'plateforme/store/types/profilEntreprise'
import { TypeDommage } from 'medecin/store/types/rapportConclusionMedecin'
import { DommageData, DommagesData } from '../FicheRapportMedecinForm'
import DommageComponent from './DommageComponent'

interface DommagesFieldsProps {
  typeDommage: TypeDommage.DEFINITIF | TypeDommage.TEMPORAIRE
  readOnly: boolean
  typeConclusion: TypeRapport
  parametrage?: ReferenceParametrageEntreprise
  handicapGraveActive?: boolean
  handicapGrave?: boolean
  isRapportConseilActive?: boolean
  libelleConclusion:
    | LibelleRapport.CONCLUSION_PROVISOIRE
    | LibelleRapport.CONCLUSION_DEFINITIVE
    | LibelleRapport.CARENCE
}

export default function DommagesFields({
  typeDommage,
  readOnly,
  typeConclusion,
  parametrage,
  handicapGraveActive = false,
  handicapGrave = false,
  isRapportConseilActive,
  libelleConclusion,
}: DommagesFieldsProps) {
  const { getValues } = useFormContext()

  const dommagesForm = getValues('dommages') as DommagesData

  if (!dommagesForm) {
    return <Loading />
  }

  const dommages: DommageData[] = Object.keys(dommagesForm)
    .filter((code) => dommagesForm[code] as DommageData)
    .map((code) => dommagesForm[code] as DommageData)
    .filter((dommage) => dommage.typeDommage === typeDommage)
    .filter((dommage) => (handicapGraveActive && handicapGrave ? !dommage.handicapGrave : true))
    .filter((dommage) => dommage.code && dommage.label)

  if (dommages.length === 0) {
    return (
      <Grid container marginBottom={3}>
        <Grid item xs={12}>
          <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>Aucun dommage</BluePaper>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container marginBottom={3}>
      {isRapportConseilActive && (
        <>
          <Grid item xs={6}>
            <TitleChip title={capitalizeFirst(libelleConclusion)} />
          </Grid>
          <Grid item xs={6}>
            <TitleChip title="Rapport de conseil" />
          </Grid>
        </>
      )}
      {dommages.map((dommage) => (
        <DommageComponent
          isRapportConseilActif={isRapportConseilActive}
          key={`${dommage.code}`}
          dommage={dommage}
          readOnly={readOnly}
          typeConclusion={typeConclusion}
          parametrage={parametrage}
        />
      ))}
    </Grid>
  )
}
