import { TextFieldProps } from '@mui/material'
import React from 'react'
import { DatePickerInput } from '..'

interface ReadOnlyDatePickerProps {
  InputProps: TextFieldProps
  value: Date | null
  label: string
}

export default function ReadOnlyDatePicker(props: ReadOnlyDatePickerProps) {
  return (
    <DatePickerInput
      {...props}
      readOnly
      onChange={() => {
        return null
      }}
    />
  )
}
