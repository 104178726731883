import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { EditTextField, PhoneNumberInput, SelectInputReferentiel } from 'plateforme/components'
import { validateRequiredWithCondition } from 'plateforme/services/utils'
import InfosContactRequisesAlert from '../InfosContactRequisesAlert'

export default function PersonneAContacter() {
  const { clearErrors, control, formState, setValue, watch } = useFormContext()

  const contactUniqueWatched = watch('personneAExaminer.contactUnique')
  const coordonneesIdentiquesPersonneAExaminerWatched = watch(
    'personneAContacter.coordonneesIdentiquesPersonneAExaminer'
  )

  const isPersonneAContacterRequired = !contactUniqueWatched
  const personneAContacterError = 'personneAContacter._error'

  const severity =
    formState.errors.personneAContacter && '_error' in formState.errors.personneAContacter ? 'error' : 'info'

  function clearPersonneAContacterError() {
    clearErrors(personneAContacterError)
    setValue(personneAContacterError, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <Card>
      <CardHeader title="Personne à contacter" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAContacter.nom"
              control={control}
              rules={{ required: validateRequiredWithCondition(isPersonneAContacterRequired) }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-contacter-nom"
                  label="Nom"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                  required={isPersonneAContacterRequired}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="personneAContacter.prenom"
              control={control}
              rules={{ required: validateRequiredWithCondition(isPersonneAContacterRequired) }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="personne-a-contacter-prenom"
                  label="Prénom"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                  required={!contactUniqueWatched}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Controller
              name="personneAContacter.qualite"
              control={control}
              rules={{ required: validateRequiredWithCondition(isPersonneAContacterRequired) }}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="personne-a-contacter-qualite"
                  label="Qualité"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  referentielName="qualitesPersonneAContacter"
                  fullWidth
                  withNoSelectionItem
                  fieldError={error}
                  required={!contactUniqueWatched}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="personneAContacter.coordonneesIdentiquesPersonneAExaminer"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} variant="standard">
                  <FormControlLabel
                    id="personne-a-contacter-coordonnees-identiques-personne-a-examiner"
                    value={value}
                    control={
                      <Switch
                        onBlur={onBlur}
                        onChange={(e) => {
                          clearPersonneAContacterError()
                          onChange(e)
                        }}
                        checked={value}
                      />
                    }
                    label="Les coordonnées de la personne à contacter sont celles de la personne à examiner"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {!coordonneesIdentiquesPersonneAExaminerWatched && (
            <>
              <Grid item xs={12}>
                <InfosContactRequisesAlert severity={severity} />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="personneAContacter.adresse1"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="personne-a-contacter-adresse1"
                      label="Adresse 1"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="personneAContacter.adresse2"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="personne-a-contacter-adresse2"
                      label="Adresse 2"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="personneAContacter.adresse3"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="personne-a-contacter-adresse3"
                      label="Adresse 3"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="personneAContacter.codePostal"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="personne-a-contacter-code-postal"
                      label="Code postal"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="personneAContacter.commune"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="personne-a-contacter-commune"
                      label="Ville"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="personneAContacter.pays"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <SelectInputReferentiel
                      id="personne-a-contacter-pays"
                      label="Pays"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      referentielName="pays"
                      fullWidth
                      withNoSelectionItem
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="personneAContacter.mail"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="personne-a-contacter-mail"
                      label="Adresse mail"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="personneAContacter.telephoneMobile"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <PhoneNumberInput
                      id="personne-a-contacter-telephone-mobile"
                      label="Tél. portable"
                      value={value}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      fieldError={error}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="personneAContacter.telephoneFixe"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <PhoneNumberInput
                      id="personne-a-contacter-telephone-fixe"
                      label="Tél. fixe"
                      value={value}
                      onChange={(e) => {
                        clearPersonneAContacterError()
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      fieldError={error}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
