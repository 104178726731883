import React from 'react'
import DelaisCommonPart from './DelaisCommonPart/DelaisCommonPart'

interface DelaisNonFournitureRapportPartProps {
  readOnly?: boolean
}

export default function DelaisNonFournitureRapportPart({ readOnly }: DelaisNonFournitureRapportPartProps) {
  return (
    <DelaisCommonPart
      readOnly={readOnly}
      controllerName="parametrage.delaisRelance.DELAI_VALIDATION_RAPPORT"
      title="Délai relance médecin non fourniture de rapport"
    />
  )
}
