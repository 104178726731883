import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { NoRowsOverlay, ReferentielText } from 'plateforme/components'

import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { formatDateFR, formatDateTimeFR } from 'plateforme/services/dates.services'
import { toHumanReadableFileSize } from 'plateforme/services/document.services'
import styled from '@emotion/styled'
import { SelectedDocument } from 'plateforme/store/types/selectedDocument'
import { useSelector } from 'react-redux'
import { RootState } from 'plateforme/store'

type DocumentsTableProps = {
  documents: DocumentTeleverse[]
  documentDownloadable?: boolean
  nbLigneParPage: number
  compact?: boolean
  onSelectRow: (document: SelectedDocument) => void
  getSelectedDocument: (state: RootState) => SelectedDocument | undefined
}

const StyledTableRow = styled(TableRow)(({ selected }) => ({
  cursor: 'pointer',
  color: 'red',
  backgroundColor: selected ? '#d0c3dd !important' : 'inherit',
  '&:hover ': {
    backgroundColor: '#e4ddec !important',
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  color: 'white',
}))

export default function DocumentsTableSelection({
  documents,
  documentDownloadable,
  nbLigneParPage,
  compact,
  onSelectRow,
  getSelectedDocument,
}: DocumentsTableProps) {
  const selectedDocument = useSelector(getSelectedDocument)
  const nonModifiableGlobal = !documents?.some((document) => document.supprimable) ?? true

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(nbLigneParPage)
  const theme = useTheme()

  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const isLg = useMediaQuery(theme.breakpoints.only('lg'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const showFormatEtTaille = !isXs && !isSm
  const showDateDepot = (compact && (isXl || isMd)) || (!compact && (isXl || isLg || isMd))
  const showTypeDocument = !compact && !isXs && !isSm && !isMd
  const showDeposePar = !compact && !isXs

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const total = documents?.length ?? 0

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#a68cbe' }}>
            <TableRow>
              <StyledTableCell>Nom du document</StyledTableCell>
              {showTypeDocument && <StyledTableCell>Type du document</StyledTableCell>}
              <StyledTableCell>Date du document</StyledTableCell>
              {showDeposePar && <StyledTableCell>Déposé par</StyledTableCell>}
              {showDateDepot && <StyledTableCell>Date de dépôt</StyledTableCell>}
              {showFormatEtTaille && <StyledTableCell>Format / Taille</StyledTableCell>}
              {documentDownloadable && <StyledTableCell sx={{ width: nonModifiableGlobal ? 50 : 100 }} />}
            </TableRow>
          </TableHead>
          {total > 0 ? (
            <TableBody>
              {documents
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((document: DocumentTeleverse) => (
                  <StyledTableRow
                    onClick={() => {
                      onSelectRow({ code: document.code } as SelectedDocument)
                    }}
                    selected={document?.code === selectedDocument?.code}
                    hover
                    key={document.code}
                  >
                    <TableCell component="th" scope="row">
                      {document.nomDocument}
                    </TableCell>
                    {showTypeDocument && (
                      <TableCell component="th" scope="row">
                        <ReferentielText referentielName="typesDocument" referentielCode={document.typeDocument} />
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {formatDateFR(document.dateDocument)}
                    </TableCell>
                    {showDeposePar && (
                      <TableCell component="th" scope="row">
                        {document.libelleCreateur}
                      </TableCell>
                    )}
                    {showDateDepot && (
                      <TableCell component="th" scope="row">
                        {formatDateTimeFR(document.dateDepot)}
                      </TableCell>
                    )}
                    {showFormatEtTaille && (
                      <TableCell component="th" scope="row">
                        {`${document.formatFichier ?? '--'} / ${toHumanReadableFileSize(document.tailleFichier)}`}
                      </TableCell>
                    )}
                  </StyledTableRow>
                ))}
            </TableBody>
          ) : (
            <NoRowsOverlay />
          )}
        </Table>
      </TableContainer>
      {total > 0 && (
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[nbLigneParPage]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  )
}
