import React from 'react'
import { AreaLoading } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import { StatutMission } from 'plateforme/store/types/mission'
import SuiviDossierPart from 'plateforme/parts/SuiviDossierPart'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import { isValideDefinitivement } from 'plateforme/services/rapport.services'
import SuiviDossierMedecinPart from './SuiviDossierMedecinPart'
import PropositionMissionMedecinPart from './PropositionMissionMedecinPart'
import MissionRefuseeMedecinPart from './MissionRefuseeMedecinPart'
import SuiviMissionMedecinPart from './SuiviMissionMedecinPart'

interface SuiviSyntheseMedecinPartProps {
  dossier?: DossierMedecin
  mission?: MissionMedecin
}

export default function SuiviSyntheseMedecinPart({
  dossier,
  mission,
  loading,
}: SuiviSyntheseMedecinPartProps & LoadingProps) {
  // chargement:
  if (loading) {
    return <AreaLoading height={360} />
  }
  return <SuiviSyntheseMedecinPartLoaded dossier={dossier} mission={mission} />
}

function SuiviSyntheseMedecinPartLoaded({ dossier, mission }: SuiviSyntheseMedecinPartProps) {
  // error:
  if (!dossier || !mission || !mission?.statut) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { statut: statutMission } = mission

  // format:
  const canDisplaySuiviDossierMedecin =
    [StatutMission.EN_COURS].includes(statutMission) && !isValideDefinitivement(mission?.rapport)
  const canDisplayPropositionMission = [StatutMission.PROPOSEE].includes(statutMission)
  const canDisplayMissionRefusee = [StatutMission.REFUSEE].includes(statutMission)
  const canDisplaySuiviMission =
    !canDisplaySuiviDossierMedecin && !canDisplayPropositionMission && !canDisplayMissionRefusee
  const canDisplaySuiviDossier = !canDisplayPropositionMission && !canDisplayMissionRefusee

  // render:
  return (
    <>
      {canDisplaySuiviDossier && <SuiviDossierPart dossier={dossier} masqueEnCours />}
      {canDisplaySuiviDossierMedecin && <SuiviDossierMedecinPart dossier={dossier} mission={mission} />}
      {canDisplayPropositionMission && <PropositionMissionMedecinPart dossier={dossier} mission={mission} />}
      {canDisplayMissionRefusee && <MissionRefuseeMedecinPart dossier={dossier} mission={mission} />}
      {canDisplaySuiviMission && <SuiviMissionMedecinPart mission={mission} />}
    </>
  )
}
