import { UploadFileMap } from 'plateforme/store/slices/uploadSlice'
import { formatDateISO } from 'plateforme/services/dates.services'
import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import createUploader from 'plateforme/store/slices/uploadSlice/uploadSlice'
import { FactureRequest } from 'medecin/store/types/factureMedecin'
import { toUploadFactureUrl } from 'medecin/MedecinApp'

export interface FactureUploadSuccess {
  id: string
}

export const documentFactureUploadSliceName = 'documentFactureUpload'
const { slice, actions, reducer } = createUploader<DocumentUpload, FactureUploadSuccess>(documentFactureUploadSliceName)

export const documentFactureUploadSlice = slice

export const {
  add: addDocumentFactureUpload,
  change: changeDocumentFactureUpload,
  remove: removeDocumentFactureUpload,
  clear: clearDocumentFactureUpload,
  setValid: setDocumentFactureUploadValid,
} = actions

type StartUploadDocumentConfig = {
  facture: FactureRequest
  uploadFileMap: UploadFileMap
}
export function startUploadDocumentFacture({ facture, uploadFileMap }: StartUploadDocumentConfig) {
  const { codeDossier, codeMission, dateFacture, ...factureFields } = facture
  const informationsFacture = {
    dateFacture: formatDateISO(dateFacture),
    ...factureFields,
  }
  return actions.startUpload({
    uploadUrl: toUploadFactureUrl(codeDossier, codeMission),
    toFormData: (upload, file) => {
      const formData = new FormData()
      formData.append('form', JSON.stringify(informationsFacture))
      formData.append(
        'metadata',
        JSON.stringify({
          typeDocument: upload.typeDocument,
          nomDocument: upload.nomDocument,
          dateDocument: informationsFacture.dateFacture ?? formatDateISO(new Date()),
        })
      )
      formData.append('file', file, upload.nomFichier)
      return formData
    },
    toUploadResponse: (status, response) => {
      if (status === 201) {
        return { type: 'SUCCESS', status, body: response as FactureUploadSuccess }
      }
      return {
        type: 'ERROR',
        status,
        body: {
          code: 'réponse inattendue',
          message: JSON.stringify(response),
        },
      }
    },
    uploadFileMap,
  })
}

export default reducer
