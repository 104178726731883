import React from 'react'
import { useSelector } from 'react-redux'
import { useErrorHandler } from 'react-error-boundary'
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { AreaLoading } from 'plateforme/components'
import MessageBulleList from 'plateforme/parts/MessageriePart/MessageBulleList'
import { determineDocumentsDossier } from 'plateforme/services/dossier.services'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { determineLibelleStatutMissionAccordion } from 'plateforme/services/mission.services'
import { capitalizeFirst } from 'plateforme/services/utils'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import { getSelectedMessagesMission } from 'medecin/store/slices/selectedMessagesMission/selectedMessagesMissionSlice'
import { useGetListMessageMissionQuery } from 'medecin/store/apis/messageMedecinApi'
import { StatutMessagerie } from 'plateforme/store/types/mission'
import MessageForm from './MessageMedecinForm'

type MessagerieMedecinPartProps = {
  dossier?: DossierMedecin
}

export default function MessagerieMedecinPart({ dossier }: MessagerieMedecinPartProps) {
  const selectedMessagesMission = useSelector(getSelectedMessagesMission)
  const handleError = useErrorHandler()
  const {
    data: messages,
    isLoading: isLoadingMessages,
    isFetching: isFetchingMessages,
    isError: isErrorMessages,
    error: errorMessages,
  } = useGetListMessageMissionQuery({
    codeDossier: dossier?.code,
    codeMission: selectedMessagesMission?.mission?.code,
    perPage: 100,
  })

  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des messages',
      api: 'useGetListeMessagesQuery',
    } as FallbackError)
  }
  // chargement:
  if (isLoadingMessages || isFetchingMessages || !dossier || !selectedMessagesMission) {
    return <AreaLoading height="calc(100vh - 242px)" />
  }

  const title = `${
    capitalizeFirst(
      determineLibelleStatutMissionAccordion(selectedMessagesMission?.mission, selectedMessagesMission?.indexRapport) ??
        ''
    ) ?? ''
  } : ${selectedMessagesMission?.mission?.refMissionEntreprise ?? selectedMessagesMission?.mission?.code} ${
    selectedMessagesMission?.mission?.refMissionMedecin
      ? ` / ${selectedMessagesMission?.mission.refMissionMedecin}`
      : ''
  }`

  const documents = determineDocumentsDossier(dossier)

  const mission = dossier.missions?.find((m) => m.code === selectedMessagesMission?.mission?.code)

  return (
    <Grid item>
      <Card>
        <CardHeader title={title} />
        {/* On prend au max la hauteur de la fenetre mais on met une taille minimum tout de meme */}
        <CardContent sx={{ height: 'calc(100vh - 500px)', minHeight: '215px' }}>
          <MessageBulleList
            typeUtilisateur={TypePartenaire.Medecin}
            dossier={dossier}
            mission={mission}
            messages={messages?.items}
            documents={documents}
          />
        </CardContent>
        {mission?.messagerieActive === StatutMessagerie.ACTIVE && (
          <CardContent
            sx={{
              overflow: 'hidden',
              borderRadius: 0.5,
              margin: '12px 16px 16px 16px',
              padding: '0px !important',
              backgroundColor: '#ddd3e666',
            }}
          >
            <Stack justifyContent="end">
              <MessageForm dossier={dossier} mission={mission} documents={documents} />
            </Stack>
          </CardContent>
        )}
      </Card>
    </Grid>
  )
}
