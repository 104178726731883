/* style de la card */
import { Card, styled } from '@mui/material'

export const TransparentCard = styled(Card)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  width: 'auto',
}))

export default TransparentCard
