import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, Stack } from '@mui/material'
import { SauvegarderButton } from 'plateforme/components'
import CloisonnementPart from './CloisonnementPart/CloisonnementPart'
import ContactsGenerauxPart from './ContactsGenerauxPart'
import OrganisationPart from './OrganisationPart'
import RestrictionAccesIpPart from './RestrictionAccesIpPart'

interface TabOrganisationPartProps {
  onSubmit?: VoidFunction
}

export default function TabOrganisationPart({ onSubmit }: TabOrganisationPartProps) {
  const { ...methods } = useFormContext()
  const readOnly = !onSubmit
  const formId = 'form-parametres-organisation'
  return (
    <form onSubmit={onSubmit && methods.handleSubmit(onSubmit)} id={formId} name={formId}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item container md={12} lg={12}>
          <Grid item xs={12}>
            <OrganisationPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <CloisonnementPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <ContactsGenerauxPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <RestrictionAccesIpPart type="extranet" readOnly={readOnly} />
          </Grid>
          <Grid item xs={12}>
            <RestrictionAccesIpPart type="ws" readOnly={readOnly} />
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="right">
                <SauvegarderButton onClick={onSubmit}>Sauvegarder</SauvegarderButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
