import * as React from 'react'
import { Button, ButtonProps } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'

export default function RechargerButton(props: ButtonProps) {
  const { children, variant, ...other } = props
  return (
    <Button color="primary" variant={variant ?? 'contained'} startIcon={<ReplayIcon />} {...other}>
      {children}
    </Button>
  )
}
