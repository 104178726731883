import React from 'react'
import { Alert, AlertProps, Stack, Typography, useTheme } from '@mui/material'
import { AdresseRoundedIcon } from '../index'

export default function AdresseAlert(props: AlertProps & { title?: string }) {
  const { title = 'Adresse du rendez-vous', children, sx } = props
  const theme = useTheme()
  const alertSx = {
    paddingY: 0,
    paddingLeft: 1,
    paddingRight: 2,
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
    },
    ...sx,
  }
  return (
    <Alert icon={false} severity="info" elevation={1} sx={alertSx}>
      <Stack direction="row" alignItems="center" spacing={0}>
        <AdresseRoundedIcon />
        <Typography variant="body2" fontWeight="bold" color={theme.palette.tile.contrastText} marginLeft={1.5}>
          {title}
        </Typography>
      </Stack>
      {children && (
        <Typography
          variant="body2"
          color={theme.palette.tile.contrastText}
          sx={{ paddingLeft: 4.5, wordWrap: 'break-word' }}
          component="div"
        >
          {children}
        </Typography>
      )}
    </Alert>
  )
}
