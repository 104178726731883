import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { EditTextField } from 'plateforme/components'

interface HandicapGravePartProps {
  readOnly?: boolean
}

export default function ParametrageDossierPart({ readOnly }: HandicapGravePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Paramétrage des dossiers" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Controller
              name="parametrage.commentaireEvenementMax"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="commentaireEvenementMax"
                  type="number"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label={`Taille max du champ "commentaire" d'un événement`}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Controller
              name="parametrage.commentaireExpertiseMax"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="commentaireExpertiseMax"
                  type="number"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label={`Taille max du champ "commentaire" d'une expertise`}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
