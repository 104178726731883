import React, { SyntheticEvent, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import { Create, ReportProblem } from '@mui/icons-material'
import { PAGE_LARGE_PADDING_X, PAGE_PADDING_X } from 'plateforme/constantes'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import ErrorFallback from '../dialog/ErrorFallback'
import PlusIcon from '../icons/PlusIcon'

const generateDynamicProps = (id: string) => {
  return {
    key: `full-width-tab-${id}`,
    id: `full-width-tab-${id}`,
    'aria-controls': `full-width-tabpanel-${id}`,
  }
}

type TabContentType = boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined

export type TabDefinition = {
  id: string
  title: string
  component: TabContentType
  isError?: boolean
  isEdition?: boolean
  iconTab?: React.ReactElement
}

interface TabsComponentProps {
  marginX?: string
  tabs: TabDefinition[]
  withNavigation?: boolean
  withAddTab?: boolean
  handleAdd?: () => void
  maxElements?: number
}

export default function TabsComponent({
  marginX,
  tabs,
  withNavigation = false,
  withAddTab = false,
  handleAdd,
  maxElements,
}: TabsComponentProps) {
  const theme = useTheme()
  const paddingX = useMediaQuery(theme.breakpoints.up('xl')) ? PAGE_LARGE_PADDING_X : PAGE_PADDING_X
  const { currentTab, navigateTab } = useTabNavigate()
  const [indexTab, setIndexTab] = useState<string | null>('0')
  const setCurrentTabByIndex = (idx: number) => navigateTab(tabs[idx].id)

  const onChangeTab = (_: SyntheticEvent, idx: number) => {
    if (withNavigation) {
      setCurrentTabByIndex(idx)
    } else {
      setIndexTab(idx.toString())
    }
  }

  let currentIdx = tabs.findIndex((t) => (!withNavigation ? t.id === indexTab : t.id === currentTab))
  if (currentIdx === -1) {
    currentIdx = 0
  }

  const determineIcon = (isError?: boolean, isEdition?: boolean, iconTab?: React.ReactElement) => {
    if (isError) {
      return <ReportProblem sx={{ fontSize: '18px' }} />
    }
    if (isEdition) {
      return <Create sx={{ fontSize: '18px' }} />
    }
    return iconTab
  }

  return (
    <Box marginX={marginX ? '' : theme.spacing(-paddingX)}>
      <TabContext value={currentIdx.toString()}>
        <Tabs value={currentIdx} onChange={onChangeTab} variant="scrollable" scrollButtons="auto" aria-label="onglets">
          {tabs.map(({ title, id, isError, isEdition, iconTab }) => (
            <Tab
              label={title}
              icon={determineIcon(isError, isEdition, iconTab)}
              iconPosition="end"
              {...generateDynamicProps(id)}
            />
          ))}
          {withAddTab && tabs.length !== maxElements && <Tab onClick={handleAdd} icon={<PlusIcon />} />}
        </Tabs>
        {tabs.map(({ component, id }, index) => (
          <TabPanel key={id} value={index.toString()} sx={{ padding: marginX ?? theme.spacing(2, paddingX) }}>
            <ErrorBoundary key={`tab-${id}-error`} FallbackComponent={ErrorFallback}>
              {component}
            </ErrorBoundary>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  )
}
