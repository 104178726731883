import { Card, CardContent, CardHeader, Grid, useTheme } from '@mui/material'
import { LieuExerciceAdmin } from 'admin/store/types/profilMedecinAdmin'
import { TabsComponent } from 'plateforme/components'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import LieuExerciceAdminMedecinTab from '../LieuExerciceAdminMedecinTab'

type LieuxExerciceAdminMedecinPartProps = {
  readOnly: boolean
}

export default function LieuxExerciceAdminMedecinPart({ readOnly }: LieuxExerciceAdminMedecinPartProps) {
  const theme = useTheme()
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lieuxExercice',
  })

  const handleAdd = () => {
    return () => {
      append({
        principal: false,
        codePostal: '',
        commune: '',
        codeSIRET: '',
        telephone: '',
        adresse1: '',
        adresse2: '',
        adresse3: '',
        pays: 'FR',
      } as LieuExerciceAdmin)
    }
  }

  const lieuxExercice = fields.map((item, index) => ({
    id: index.toString(),
    title: index === 0 ? 'Étab. principal' : `Étab. secondaire ${index}`,
    component: <LieuExerciceAdminMedecinTab index={index} readOnly={readOnly} remove={remove} />,
  }))

  return (
    <Card>
      <CardHeader title="Lieux d'exercice" />
      <CardContent>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={2}
          columnSpacing={2}
          padding={2}
        >
          <Grid item xs={12}>
            <TabsComponent
              marginX={theme.spacing(2, 0)}
              tabs={lieuxExercice}
              handleAdd={handleAdd()}
              maxElements={5}
              withAddTab
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
