import React from 'react'
import { Button, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReadOnlyDatePicker, ReadOnlyTextField } from 'plateforme/components'
import { MessageVersAssureur } from 'medecin/store/types/notificationsMedecin'
import PiecesJointesTable from './PiecesJointesTable'

interface MessageMedecinDetailsProps {
  messageMedecin: MessageVersAssureur
  onClose: VoidFunction
}

export default function MessageMedecinDetails({ messageMedecin, onClose }: MessageMedecinDetailsProps) {
  return (
    <Grid padding={2} container direction="row">
      <Grid item xs={12}>
        <ReadOnlyDatePicker
          InputProps={{
            id: 'date-envoi',
            fullWidth: true,
          }}
          value={messageMedecin.date}
          label="Date d'envoi"
        />
      </Grid>
      <Grid item xs={12}>
        <ReadOnlyTextField id="objet" label="Objet" value={messageMedecin.objet} fullWidth multiline />
      </Grid>
      <Grid item xs={12}>
        <ReadOnlyTextField id="message" label="Message" value={messageMedecin.message} fullWidth multiline />
      </Grid>
      {(messageMedecin?.documents?.length ?? 0) > 0 && (
        <Grid item xs={12}>
          <PiecesJointesTable nbLigneParPage={5} messageMedecin={messageMedecin} />
        </Grid>
      )}
      <Grid justifyContent="flex-end" container item xs={12}>
        <Grid item>
          <Button variant="outlined" startIcon={<CloseIcon />} onClick={onClose}>
            Fermer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
