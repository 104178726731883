import FactureMedecin from 'medecin/store/types/factureMedecin'
import FactureEntreprise from 'assureur/store/types/factureEntreprise'
import FactureAdmin from 'admin/store/types/factureAdmin'

type FacturePlateforme = FactureMedecin | FactureEntreprise | FactureAdmin

export enum StatutFacture {
  DEPOSEE = 'DEPOSEE',
  ANNULEE = 'ANNULEE',
  ACCEPTEE = 'ACCEPTEE',
  DEMANDE_MODIFICATION = 'DEMANDE_MODIFICATION',
}

export default FacturePlateforme
