import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'

import Referentiel from '../types/referentiel'

export const referentielApi = createApi({
  reducerPath: 'referentielApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['Referentiel'],
  endpoints: (builder) => ({
    getReferentiel: builder.query<Referentiel, void>({
      query: () => 'utilisateur/referentiel',
      providesTags: ['Referentiel'],
    }),
  }),
})

export const { useGetReferentielQuery } = referentielApi
