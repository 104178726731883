import React from 'react'
import { ReferentielEntreprise } from 'plateforme/store/types/profilEntreprise'
import { useGetReferentielEntrepriseQuery } from 'medecin/store/apis/referentielEntrepriseMedecinApi'
import { useGetReferentielEntrepriseAdminQuery } from 'admin/store/apis/gestionEntrepriseApi'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

type SelectReferentielProps = Omit<SelectInputWithQueryProps<ReferentielEntreprise[]>, 'useQuery' | 'dataTransformer'>

export default function SelectInputEntreprise({
  isAdmin = false,
  isIdPourCodeOption = false,
  ...restProps
}: SelectReferentielProps) {
  const dataTransformer = (data: ReferentielEntreprise[]) => {
    return data.map((ref) => {
      return { code: isIdPourCodeOption ? ref.id : ref.code, label: ref.libelle } as SelectOption
    })
  }

  return (
    <SelectInputWithQuery
      {...restProps}
      orderBy="label"
      useQuery={isAdmin ? useGetReferentielEntrepriseAdminQuery : useGetReferentielEntrepriseQuery}
      dataTransformer={dataTransformer}
    />
  )
}
