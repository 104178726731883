import React from 'react'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import Referentiel, { ReferentielName } from 'plateforme/store/types/referentiel'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

// utilitaire pour extraire le type d'élément d'un Array
type ArrayElement<AR> = AR extends Array<infer Item> ? Item : never

export type SelectReferentielProps<K extends ReferentielName> = Omit<
  SelectInputWithQueryProps<Referentiel>,
  'useQuery' | 'dataTransformer'
> & {
  referentielName: K
  codeFilter?: string[]
  dataFilter?: (el: ArrayElement<Referentiel[K]>) => boolean
  dataFormatter?: (code: string, label: string) => string
}

export default function SelectInputReferentiel<K extends ReferentielName>({
  referentielName,
  codeFilter,
  dataFilter,
  dataFormatter,
  ...restProps
}: SelectReferentielProps<K>) {
  const dataTransformer = (data: Referentiel) => {
    const ret = data[referentielName] as ArrayElement<Referentiel[K]>[]

    return ret
      .filter((e) => (codeFilter?.includes(e.code) ?? true) && (dataFilter?.(e) ?? true))
      .map(
        (e) =>
          ({
            code: e.code,
            label: dataFormatter ? dataFormatter(e.code, e.label) : e.label,
          } as SelectOption)
      )
  }
  return <SelectInputWithQuery {...restProps} useQuery={useGetReferentielQuery} dataTransformer={dataTransformer} />
}
