/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react'
import equal from 'fast-deep-equal/es6/react'
import { UseQuery, UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { SortParam } from 'plateforme/store/types/pageRecherche'
import { removeUndefined } from 'plateforme/services/utils'
import { IQueryErrorResponse } from '../useErrorFormMapper'

export interface IListQueryResponse {
  items: any[]
  totalItems?: number
  currentToken: string
  next: string
}

const useInfiniteScroll = (
  useGetDataListQuery: UseQuery<any> | UseQueryHookResult<any, any>,
  { ...queryParameters }
) => {
  const [pageState, setPageState] = useState<string | undefined>()
  const [nextState, setNextState] = useState<string | undefined | null>(null)
  const [combinedDataState, setCombinedDataState] = useState<any[]>([])

  const [filterState, setFilterState] = useState<any>(queryParameters?.defaultCriteria)
  const [hideDefaultResult, setHideDefaultResult] = useState<boolean>(queryParameters?.hideDefaultResult)
  const [sortState, setSortState] = useState<SortParam<any>>(queryParameters?.defaultSort ?? {})
  const [remoteTotalState, setRemoteTotalState] = useState<number | undefined>()

  const queryResponse = useGetDataListQuery(
    {
      page: pageState,
      filter: filterState,
      sort: sortState,
      ...queryParameters,
    },
    { skip: hideDefaultResult }
  )

  const convertFilterStateToParams = (): string => {
    let searchParams = ''
    if (filterState) {
      searchParams = searchParams.concat(
        Object.keys(filterState)
          .filter((key) => filterState[key] !== undefined)
          .map((key) => `${key}=${encodeURIComponent(filterState[key])}`)
          .join('&')
      )
    }
    return searchParams
  }

  const createExportParams = (): string => {
    const initParams = convertFilterStateToParams()
    const exportParams = new URLSearchParams(initParams)

    if (pageState) {
      exportParams.append('page', pageState)
    }

    if (sortState.sortOrder) {
      exportParams.append('sortOrder', sortState.sortOrder)
    }

    if (sortState.sortType) {
      exportParams.append('sortType', sortState.sortType)
    }
    return exportParams.toString()
  }

  useEffect(() => {
    const {
      items: fetchedData = [],
      next,
      totalItems,
    } = (!queryResponse?.isError && (queryResponse?.data as IListQueryResponse)) || {}
    setCombinedDataState((previousData) => (pageState ? [...previousData, ...fetchedData] : fetchedData))
    setNextState(next)
    setRemoteTotalState(totalItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResponse?.data, queryResponse?.isError])

  const refresh = useCallback(() => {
    reset()
  }, [])

  const initSort = useCallback(() => {
    if (sortState === undefined || equal(sortState, {})) {
      return
    }
    reset()
    setSortState({})
  }, [sortState])

  const initSearch = useCallback(() => {
    if (filterState === undefined || equal(filterState, {})) {
      return
    }
    reset()
    setFilterState({})
  }, [filterState])

  const search = useCallback(
    (criteria: any) => {
      setHideDefaultResult(false)
      if (
        equal(removeUndefined(filterState), removeUndefined(criteria)) ||
        (Object.values(criteria).every((el) => el === undefined) &&
          (filterState === undefined || equal(filterState, {})))
      ) {
        queryResponse.refetch()
        return
      }
      reset()
      setFilterState(criteria)
    },
    [filterState, queryResponse]
  )

  const sortBy = useCallback(
    (param: any) => {
      setHideDefaultResult(false)
      if (equal(sortState, param)) {
        return
      }
      reset()
      setSortState(param)
    },
    [sortState]
  )

  const readMore = () => {
    if (nextState) {
      setPageState(decodeURI(nextState))
    }
  }

  function reset(): void {
    setCombinedDataState([])
    setRemoteTotalState(undefined)
    setPageState(undefined)
    setNextState(undefined)
  }

  return {
    combinedData: combinedDataState,
    page: pageState,
    exportParams: createExportParams(),
    readMore,
    refresh,
    initSearch,
    search,
    sortBy,
    initSort,
    remoteTotal: remoteTotalState,
    isLoading: queryResponse?.isLoading,
    isFetching: queryResponse?.isFetching,
    isError: queryResponse?.isError,
    isSuccess: queryResponse?.isSuccess,
    error: queryResponse?.error as IQueryErrorResponse,
  }
}

export default useInfiniteScroll
