import * as React from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { AutoFixHigh } from '@mui/icons-material'

export default function SelectionnerToutButton(props: LoadingButtonProps & { isAllSelected: boolean }) {
  const { isAllSelected, color, children, variant, ...other } = props
  return (
    <LoadingButton
      variant={variant ?? 'contained'}
      color={color ?? 'primary'}
      startIcon={<AutoFixHigh />}
      loadingPosition="start"
      {...other}
    >
      {children || null}
      {isAllSelected ? `Tout désélectionner` : `Tout sélectionner`}
    </LoadingButton>
  )
}
