import React from 'react'
import { Skeleton, SkeletonProps, Theme } from '@mui/material'

export default function AreaLoading(props: SkeletonProps) {
  const { height, sx: otherSx, ...others } = props
  const skeletonSx = otherSx
    ? {
        bgcolor: (theme: Theme) => theme.palette.background.paper,
        boxShadow: (theme: Theme) => theme.shadows[2],
        ...otherSx,
      }
    : { bgcolor: (theme: Theme) => theme.palette.background.paper, boxShadow: (theme: Theme) => theme.shadows[2] }

  return (
    <Skeleton animation="wave" variant="rectangular" width="100%" height={height ?? 180} {...others} sx={skeletonSx} />
  )
}
