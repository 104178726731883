import * as React from 'react'
import { Button, ButtonProps } from '@mui/material'
import Close from '@mui/icons-material/Close'

export default function RefuserButton(props: ButtonProps) {
  const { children, variant, ...other } = props
  return (
    <Button color="error" variant={variant ?? 'contained'} startIcon={<Close />} {...other}>
      {children}
    </Button>
  )
}
