import * as React from 'react'
import { Card, CardContent, CardHeader, useTheme } from '@mui/material'
import { AreaLoading, TabsComponent } from 'plateforme/components'
import { useGetProfilMedecinQuery } from 'medecin/store/apis/profilMedecinApi'
import { useErrorHandler } from 'react-error-boundary'
import LieuExerciceMedecinTab from './LieuExerciceMedecinTab'

export default function LieuxExerciceMedecinPart() {
  const { data: medecin, isLoading, isFetching, isError, error: errorGetProfil } = useGetProfilMedecinQuery()
  const handleError = useErrorHandler()
  const theme = useTheme()

  if (isLoading || isFetching) {
    return <AreaLoading height={351} />
  }

  // handle error of useGetReferentielQuery
  if (!medecin || isError) {
    handleError({
      errorApi: errorGetProfil,
      api: 'GetProfilMedecin',
      title: 'Erreur chargement des données de profil médecin',
    })

    return null
  }

  const lieuxExercice = medecin.lieuxExercice.map((lieuExercice, index) => ({
    id: index.toString(),
    title: index === 0 ? 'Étab. principal' : `Étab. secondaire ${index}`,
    component: <LieuExerciceMedecinTab index={index} lieuExercice={lieuExercice} />,
  }))

  return (
    <Card>
      <CardHeader title="Mes lieux d'exercice" />
      <CardContent sx={{ height: '300px' }}>
        <TabsComponent tabs={lieuxExercice} marginX={theme.spacing(2, 0)} />
      </CardContent>
    </Card>
  )
}
