import React from 'react'
import { Backdrop, Box } from '@mui/material'
import { TransmedLogo } from 'plateforme/components'

const cssLoader = {
  animation: 'rotate 2s linear infinite, opacityChange 3s infinite alternate',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '360px',
  height: '180px',
  borderRadius: '10px',
  padding: '2rem',
  placeContent: 'center',
  textAlign: 'center',
  fontSize: '1.5em',
  '--borderSize': '0.3rem',
  '--angle': '45deg',
  '--opacity': '0.5',
  border: 'var(--borderSize) solid transparent',
  borderImage:
    'conic-gradient(\n      from var(--angle),\n      #3556a7 0deg 90deg,\n      transparent 90deg 180deg,\n      transparent 180deg 270deg,\n      transparent 270deg 360deg\n    )\n    1 stretch',
  background: '#fff',
  '@property --opacity': {
    syntax: '"<number>"',
    initialValue: '0.5',
    inherits: 'false',
  },
  '@property --angle': {
    syntax: '"<angle>"',
    initialValue: '0deg',
    inherits: 'false',
  },
  '@keyframes opacityChange': { to: { '--opacity': '1' } },
  '@keyframes rotate': { to: { '--angle': '360deg' } },
}

export default React.memo(BackdropComponent)

function BackdropComponent() {
  return (
    <Backdrop sx={{ color: '#fff', backgroundColor: '#eae9f1', zIndex: 100 }} open>
      <Box sx={cssLoader}>
        <TransmedLogo />
      </Box>
    </Backdrop>
  )
}
