import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  EditTextField,
  RechargerButton,
  SelectInputEntreprise,
  SelectInputMedecin,
  SelectInputReferentiel,
  SelectInputNatureEvenement,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO } from 'plateforme/services/dates.services'
import { emptyToUndefined, trimToUndefined } from 'plateforme/services/utils'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { StatutMission } from 'plateforme/store/types/mission'
import { getDossierCriteria, setDossierCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { DossierAdminFilter } from 'admin/store/types/dossierAdmin'

type RechercheDossierAdminPartProps = {
  search: (criteria: DossierAdminFilter) => void
  searchError: IQueryErrorResponse
}

type DossierFilterData = {
  motCle?: string
  codeMedecin?: string | null
  codeEntreprise?: string | null
  statutsDossier: StatutDossier[] | null
  statutsMission: StatutMission[] | null
  natureEvenement?: string | null
  cadreExpertise?: string | null
  dateCreationDebut: Date | null
  dateCreationFin: Date | null
}

export default function RechercheDossierAdminPart({ search, searchError }: RechercheDossierAdminPartProps) {
  // get default values from localStorage
  const defaultDossierCriteria = useSelector(getDossierCriteria)
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: DossierFilterData = {
    motCle: defaultDossierCriteria?.motCle?.length === 0 ? '' : defaultDossierCriteria?.motCle ?? '',
    codeEntreprise: defaultDossierCriteria?.codeEntreprise ?? null,
    codeMedecin: defaultDossierCriteria?.codeMedecin ?? null,
    statutsDossier: defaultDossierCriteria?.statutsDossier ?? null,
    statutsMission: defaultDossierCriteria?.statutsMission ?? null,
    natureEvenement: defaultDossierCriteria?.natureEvenement ?? null,
    cadreExpertise: defaultDossierCriteria?.cadreExpertise ?? null,
    dateCreationDebut: defaultDossierCriteria?.dateCreationDebut
      ? new Date(defaultDossierCriteria.dateCreationDebut)
      : null,
    dateCreationFin: defaultDossierCriteria?.dateCreationFin ? new Date(defaultDossierCriteria.dateCreationFin) : null,
  }

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  const resetValues: DossierFilterData = {
    motCle: '',
    codeEntreprise: undefined,
    codeMedecin: undefined,
    statutsDossier: null,
    statutsMission: null,
    natureEvenement: undefined,
    cadreExpertise: undefined,
    dateCreationDebut: null,
    dateCreationFin: null,
  }

  const onSubmit = async (data: DossierFilterData) => {
    const filtre = {
      motCle: trimToUndefined(data.motCle),
      codeMedecin: trimToUndefined(data.codeMedecin),
      codeEntreprise: trimToUndefined(data.codeEntreprise),
      statutsDossier: emptyToUndefined(data.statutsDossier),
      statutsMission: emptyToUndefined(data.statutsMission),
      natureEvenement: trimToUndefined(data.natureEvenement),
      cadreExpertise: trimToUndefined(data.cadreExpertise),
      dateCreationDebut: formatDateISO(data.dateCreationDebut),
      dateCreationFin: formatDateISO(data.dateCreationFin),
    } as DossierAdminFilter
    search(filtre)
    dispatch(setDossierCriteria(filtre))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      motCle: undefined,
      codeEntreprise: undefined,
      codeMedecin: undefined,
      statutsDossier: undefined,
      statutsMission: undefined,
      natureEvenement: undefined,
      cadreExpertise: undefined,
      dateCreationDebut: undefined,
      dateCreationFin: undefined,
    }

    reset(resetValues)
    search(filtre)
    dispatch(setDossierCriteria(filtre))
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-habilitation">
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <Controller
                name="motCle"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="keywords"
                    label="Mots clés"
                    value={value}
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setValue('motCle', undefined)
                      }
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="codeEntreprise"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputEntreprise
                    isAdmin
                    id="codeEntreprise"
                    label="Entreprise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="codeMedecin"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputMedecin
                    isAdmin
                    id="medecin-facture"
                    label="Médecin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="statutsDossier"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statutsDossier"
                    label="Statuts Dossier"
                    referentielName="statutsDossier"
                    multiple
                    value={value}
                    onChangeMultiple={onChange}
                    onBlur={onBlur}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="natureEvenement"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputNatureEvenement
                    id="input-nature"
                    label="Nature d'événement"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    referentielName="naturesEvenement"
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="cadreExpertise"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="input-cadre"
                    label="Cadre de l'expertise"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    referentielName="cadresExpertise"
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="statutsMission"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statutsMission"
                    label="Statuts Mission"
                    referentielName="statutsMission"
                    multiple
                    value={value}
                    onChangeMultiple={onChange}
                    onBlur={onBlur}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Typography width={100}>Création dossier entre</Typography>
                <Controller
                  name="dateCreationDebut"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      InputProps={{
                        id: 'date-debut',
                        fullWidth: true,
                      }}
                      label="Date de début"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fieldError={error}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Typography width={100}>Et</Typography>
                <Controller
                  name="dateCreationFin"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <DatePickerInput
                      InputProps={{
                        id: 'date-creation-fin',
                        fullWidth: true,
                      }}
                      label="Date de fin"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      fieldError={error}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-habilitation"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}
