import * as React from 'react'
import { ButtonProps, Stack, styled, Typography } from '@mui/material'
import { capitalizeFirst, LoadingProps } from 'plateforme/services/utils'
import { MetaDataMission, StatutMission } from 'plateforme/store/types/mission'
import { determineLibelleStatutMissionAccordion } from 'plateforme/services/mission.services'
import MessageChip from 'plateforme/components/chips/MessageChip'
import MessageTile from 'plateforme/components/tiles/MessageTile'

const ActionStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
}))

type MessagesMissionTileProps = {
  missionMessagerie: MetaDataMission
  selected?: boolean
  onClick: VoidFunction
}

function MessageMissionTile(props: MessagesMissionTileProps & LoadingProps & ButtonProps) {
  const { missionMessagerie, loading, selected, onClick, ...other } = props
  const mission = missionMessagerie?.mission
  const { totalLu, totalNonLuMedecin, totalNonLuEntreprise, indexRapport } = missionMessagerie

  const totalMessages = totalLu + totalNonLuMedecin + totalNonLuEntreprise
  const title = `${
    mission?.statut !== StatutMission.VALIDEE_MEDECIN || !mission?.rapport?.typeRapport ? 'Mission' : ''
  } ${capitalizeFirst(determineLibelleStatutMissionAccordion(mission, indexRapport) ?? '') ?? ''}`

  return (
    <MessageTile selected={selected} variant="contained" color="tile" disabled={loading} {...other} onClick={onClick}>
      <ActionStack spacing={1}>
        <Typography variant="h2" sx={{ verticalAlign: 'top' }}>
          {title}
        </Typography>
        {(mission.refMissionEntreprise || mission.refMissionMedecin) && (
          <Typography variant="subtitle1" sx={{ verticalAlign: 'top' }}>
            {`${mission?.refMissionEntreprise ?? ''}${
              mission.refMissionEntreprise && mission.refMissionMedecin ? ' / ' : ''
            }
            ${mission?.refMissionMedecin ?? ''}`}
          </Typography>
        )}
        <ActionStack direction="row">
          <MessageChip totalMessage={totalMessages} totalMessageNonLu={totalNonLuMedecin} />
        </ActionStack>
      </ActionStack>
    </MessageTile>
  )
}

export default MessageMissionTile
