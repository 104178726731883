import React from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { TransmedLogo } from '../index'
import AccueilButton from '../menu/AccueilButton'

export interface FallbackError {
  resetError?: VoidFunction
  title?: string
  api?: string
  /* eslint-disable @typescript-eslint/no-explicit-any */
  errorApi?: any
}

interface ErrorFallbackProps {
  error?: any
}
const BoxStyled = styled(Box)(() => ({
  backgroundColor: '#ffffff',
}))

export default function ErrorFallback({ error }: ErrorFallbackProps) {
  const data = error && error?.errorApi?.data
  const statutServeur = error && error?.errorApi?.status
  const navigate = useNavigate()
  const location = useLocation()

  if (statutServeur === 401 || statutServeur === 403) {
    navigate('/', { replace: true, state: { from: location } })
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight="95vh">
      <BoxStyled>
        <Stack direction="row" alignItems="center" justifyContent="center" padding={4}>
          <TransmedLogo />
        </Stack>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Une erreur s&apos;est produite.
        </Typography>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          {data?.message}
        </Typography>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Si le problème persiste, merci de contacter notre assistance :
          <a href="mailto:assistance-transmed@agira.asso.fr">assistance-transmed@agira.asso.fr</a>
        </Typography>
        <Box display="flex" justifyContent="center" padding={4}>
          <AccueilButton href="/"> Retour à la page d&apos;accueil</AccueilButton>
        </Box>
      </BoxStyled>
    </Box>
  )
}
