import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'

export interface SelectedDocumentEntreprise {
  code?: string
}

export const selectedDocumentSliceName = 'selectedDocument'

const selectedDocumentSlice = createSlice({
  name: selectedDocumentSliceName,
  initialState: {
    selectedDocument: undefined,
  } as { selectedDocument: SelectedDocumentEntreprise | undefined },
  reducers: {
    setSelectedDocument: (state, action: PayloadAction<SelectedDocumentEntreprise | undefined>) => {
      state.selectedDocument = action.payload
    },
  },
})

export const { setSelectedDocument } = selectedDocumentSlice.actions

export const getSelectedDocumentEntreprise = (state: RootState) => state?.selectedDocument.selectedDocument

export default selectedDocumentSlice.reducer
