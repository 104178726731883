import * as React from 'react'
import { Typography } from '@mui/material'
import { TransparentCard, TransparentCardContent } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import Utilisateur from 'plateforme/store/types/utilisateur'

/* Page d'accueil : on considere le message "Bonjour xxxxx" comme une card */
export interface MessageAccueilPartProps {
  utilisateur?: Utilisateur
}

export default function MessageAccueilPart({ utilisateur, loading }: MessageAccueilPartProps & LoadingProps) {
  const messageAccueil = loading ? (
    <>&nbsp;</>
  ) : (
    `Bonjour ${utilisateur?.titre} ${utilisateur?.prenom} ${utilisateur?.nom}`
  )
  return (
    <TransparentCard>
      <TransparentCardContent>
        <Typography color="primary" variant="h2">
          {messageAccueil}
        </Typography>
      </TransparentCardContent>
    </TransparentCard>
  )
}
