import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import ListeHabilitationsEntrepriseAdminPart from 'admin/part/HabilitationsEntrepriseAdminPart/ListeHabilitationsEntrepriseAdminPart'
import RechercheHabilitationsEntrepriseAdminPart from 'admin/part/HabilitationsEntrepriseAdminPart/RechercheHabilitationsEntrepriseAdminPart'
import { useSnackbar } from 'notistack'
import { useGetListeHabilitationEntrepriseAdminQuery } from 'admin/store/apis/habilitationEntrepriseAdminApi'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll'

interface TabHabilitationsEntrepriseAdminProps {
  codeEntreprise?: string
}

export default function TabHabilitationsEntrepriseAdminPart({ codeEntreprise }: TabHabilitationsEntrepriseAdminProps) {
  if (codeEntreprise === undefined || codeEntreprise.length === 0) {
    throw new Error('Aucun code entreprise défini')
  }

  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading: listeHabilitationLoading,
    isFetching,
    isError: isErrorListeHabilitations,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeHabilitationEntrepriseAdminQuery, {
    defaultCriteria: { codeEntreprise },
  })

  const isLoading = listeHabilitationLoading

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isErrorListeHabilitations) {
      enqueueSnackbar('La Recherche des habilitations a échoué', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorListeHabilitations])

  return (
    <Stack>
      <RechercheHabilitationsEntrepriseAdminPart codeEntreprise={codeEntreprise} search={search} searchError={error} />
      <ListeHabilitationsEntrepriseAdminPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeHabilitations}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}
