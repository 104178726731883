import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import { AFFECTATION_CENTRALE_LABEL } from 'plateforme/constantes'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type GestionDossierEntreprisePartProps = {
  dossier?: DossierEntreprise
}

export default function GestionDossierEntreprisePart({
  dossier,
  loading,
}: GestionDossierEntreprisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={335} />
  }
  return <GestionDossierEntreprisePartLoaded dossier={dossier} />
}

function GestionDossierEntreprisePartLoaded({ dossier }: GestionDossierEntreprisePartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // render:
  return (
    <Card>
      <CardHeader title="Gestion entreprise du dossier" />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="gestionnaire-libelle"
              label="Gestionnaire"
              value={dossier.gestionnaire?.libelle}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="service-entreprise-libelle"
              label="Service"
              value={dossier.libelleServiceEntreprise ?? AFFECTATION_CENTRALE_LABEL}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              id="contact-entreprise-libelle"
              label="Libellé contact dossier"
              value={dossier.contactEntreprise?.libelle}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyEmailField
              id="contact-entreprise-mail"
              label="Adresse mail contact dossier"
              value={dossier.contactEntreprise?.mail}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="contact-entreprise-telephone"
              label="Téléphone contact dossier"
              value={dossier.contactEntreprise?.telephone}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
