import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'

export interface SelectedMedecin {
  code?: string
  codeSIREN?: string
  codeRPPS?: string
  libelle?: string
  mailContact?: string
  adresseComplete?: string
  telephoneFixe?: string
  telephoneMobile?: string
}

export const selectedMedecinSliceName = 'selectedMedecin'

const selectedMedecinSlice = createSlice({
  name: selectedMedecinSliceName,
  initialState: {
    selectedMedecin: { code: undefined },
    showSelectedMedecin: false,
  } as { selectedMedecin: SelectedMedecin; showSelectedMedecin: boolean },
  reducers: {
    setSelectedMedecin: (state, action: PayloadAction<SelectedMedecin>) => {
      state.selectedMedecin = action.payload
    },
    setShowSelectedMedecin: (state, action: PayloadAction<boolean>) => {
      state.showSelectedMedecin = action.payload
    },
  },
})

export const { setSelectedMedecin, setShowSelectedMedecin } = selectedMedecinSlice.actions

export const getSelectedMedecin = (state: RootState) => state?.selectedMedecin.selectedMedecin

export const getShowSelectedMedecin = (state: RootState) => state?.selectedMedecin.showSelectedMedecin

export default selectedMedecinSlice.reducer
