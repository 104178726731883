import { trimToUndefined } from 'plateforme/services/utils'
import Referentiel, { TypeMessageNotification, TypeMessageNotificationTag } from 'plateforme/store/types/referentiel'
import { TypeRapport, TypeRapportConseil } from 'plateforme/store/types/rapportConclusion'
import { DEFAULT_MAX_PERIODES } from 'plateforme/constantes'
import ProfilEntreprise, {
  CodeContact,
  CodeFichesConclusions,
  ContactInformations,
  DomaineContractuelEntreprise,
  EnumTypesFichesConclusions,
  ParametrageEntreprise,
  RemissionnementCarence,
  RemissionnementConclusionProvisoire,
  ServiceEntreprise,
} from 'plateforme/store/types/profilEntreprise'

export interface ProfilEntrepriseForm extends Omit<ProfilEntreprise, 'parametrage'> {
  parametrage?: ParametrageEntrepriseForm
}

export interface ParametrageEntrepriseForm extends Omit<ParametrageEntreprise, 'typesFichesConclusions'> {
  naturesEvenements: {
    BIRC?: boolean
    RCGE?: boolean
    BNIR?: boolean
    RCMD?: boolean
    ASSC?: boolean
    PREV?: boolean
    ACCV?: boolean
    FAUT?: boolean
    AUTR?: boolean
  }
  fichesConclusions?: FichesConclusionsForm
  remissionnementCarence?: RemissionnementCarenceForm
  remissionnementConclusionProvisoire?: RemissionnementConclusionProvisoireForm
}

export interface FichesConclusionsForm {
  BIRC?: TypeFicheConclusionForm
  BNIR?: TypeFicheConclusionForm
  RCGE?: TypeFicheConclusionForm
  RCMD?: TypeFicheConclusionForm
  ASSC?: TypeFicheConclusionForm
  PREV?: TypeFicheConclusionForm
  ACCV?: TypeFicheConclusionForm
  FAUT?: TypeFicheConclusionForm
  AUTR?: TypeFicheConclusionForm
}

export interface TypeFicheConclusionForm {
  expertiseUnilaterale: string
  expertiseConjointe: string
  expertiseJudiciaire: string
  arbitrage: string
  avisTechnique: string
}

export interface RemissionnementCarenceForm extends RemissionnementCarence {
  enabled?: boolean
}

export interface RemissionnementConclusionProvisoireForm extends RemissionnementConclusionProvisoire {
  enabled?: boolean
}

// REQUEST TO FORM
export const getFicheConclusionForm = (entreprise: ProfilEntreprise | undefined): FichesConclusionsForm | undefined => {
  return {
    BIRC: ficheConclusionRequestToForm('BIRC', entreprise),
    BNIR: ficheConclusionRequestToForm('BNIR', entreprise),
    RCGE: ficheConclusionRequestToForm('RCGE', entreprise),
    RCMD: ficheConclusionRequestToForm('RCMD', entreprise),
    ASSC: ficheConclusionRequestToForm('ASSC', entreprise),
    PREV: ficheConclusionRequestToForm('PREV', entreprise),
    ACCV: ficheConclusionRequestToForm('ACCV', entreprise),
    FAUT: ficheConclusionRequestToForm('FAUT', entreprise),
    AUTR: ficheConclusionRequestToForm('AUTR', entreprise),
  } as FichesConclusionsForm
}

export const getTypesRapportConseilForm = (
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE,
  entreprise: ProfilEntreprise | undefined
): FichesConclusionsForm | undefined => {
  return {
    BIRC: typesRapportConseilRequestToForm(typeConclusion, 'BIRC', entreprise),
    BNIR: typesRapportConseilRequestToForm(typeConclusion, 'BNIR', entreprise),
    RCGE: typesRapportConseilRequestToForm(typeConclusion, 'RCGE', entreprise),
    RCMD: typesRapportConseilRequestToForm(typeConclusion, 'RCMD', entreprise),
    ASSC: typesRapportConseilRequestToForm(typeConclusion, 'ASSC', entreprise),
    PREV: typesRapportConseilRequestToForm(typeConclusion, 'PREV', entreprise),
    ACCV: typesRapportConseilRequestToForm(typeConclusion, 'ACCV', entreprise),
    FAUT: typesRapportConseilRequestToForm(typeConclusion, 'FAUT', entreprise),
    AUTR: typesRapportConseilRequestToForm(typeConclusion, 'AUTR', entreprise),
  } as FichesConclusionsForm
}

const typesRapportConseilRequestToForm = (
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE,
  nature: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entreprise?: any
) => {
  const conclusionField =
    typeConclusion === TypeRapport.CONCLUSION_PROVISOIRE ? 'typesRapportConseilPro' : 'typesRapportConseilDef'

  const param = entreprise?.parametrage[conclusionField]?.[nature]
  return {
    expertiseUnilaterale: convertTypeRapportConseilValueToText(param?.[CodeFichesConclusions.expertiseUnilaterale]),
    expertiseConjointe: convertTypeRapportConseilValueToText(param?.[CodeFichesConclusions.expertiseConjointe]),
    expertiseJudiciaire: convertTypeRapportConseilValueToText(param?.[CodeFichesConclusions.expertiseJudiciaire]),
    arbitrage: convertTypeRapportConseilValueToText(param?.[CodeFichesConclusions.arbitrage]),
    avisTechnique: convertTypeRapportConseilValueToText(param?.[CodeFichesConclusions.avisTechnique]),
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [nature]
const ficheConclusionRequestToForm = (nature: string, entreprise?: any) => {
  const param = entreprise?.parametrage?.typesFichesConclusions[nature]
  return {
    expertiseUnilaterale: convertFicheConclusionValueToText(param?.[CodeFichesConclusions.expertiseUnilaterale]),
    expertiseConjointe: convertFicheConclusionValueToText(param?.[CodeFichesConclusions.expertiseConjointe]),
    expertiseJudiciaire: convertFicheConclusionValueToText(param?.[CodeFichesConclusions.expertiseJudiciaire]),
    arbitrage: convertFicheConclusionValueToText(param?.[CodeFichesConclusions.arbitrage]),
    avisTechnique: convertFicheConclusionValueToText(param?.[CodeFichesConclusions.avisTechnique]),
  }
}

const convertFicheConclusionValueToText = (ficheConclusionValue: EnumTypesFichesConclusions | undefined) => {
  return ficheConclusionValue === EnumTypesFichesConclusions.CONV
    ? EnumTypesFichesConclusions.CONV.valueOf()
    : EnumTypesFichesConclusions.SIMP.valueOf()
}

const convertTypeRapportConseilValueToText = (typeRapportConseilValue: TypeRapportConseil | undefined) => {
  switch (typeRapportConseilValue) {
    case TypeRapportConseil.DESACTIVE:
      return TypeRapportConseil.DESACTIVE.valueOf()
    case TypeRapportConseil.OBLIGATOIRE:
      return TypeRapportConseil.OBLIGATOIRE.valueOf()
    case TypeRapportConseil.FACULTATIF:
      return TypeRapportConseil.FACULTATIF.valueOf()
    default:
      return TypeRapportConseil.DESACTIVE.valueOf()
  }
}

export const getNatureEvenementsForm = (entreprise: ProfilEntreprise | undefined) => {
  const typesFichesConclusions = entreprise?.parametrage?.typesFichesConclusions
  return {
    BIRC: typesFichesConclusions?.BIRC !== undefined,
    BNIR: typesFichesConclusions?.BNIR !== undefined,
    RCGE: typesFichesConclusions?.RCGE !== undefined,
    RCMD: typesFichesConclusions?.RCMD !== undefined,
    ASSC: typesFichesConclusions?.ASSC !== undefined,
    PREV: typesFichesConclusions?.PREV !== undefined,
    ACCV: typesFichesConclusions?.ACCV !== undefined,
    FAUT: typesFichesConclusions?.FAUT !== undefined,
    AUTR: typesFichesConclusions?.AUTR !== undefined,
  }
}

export const getContactsForm = (entreprise: ProfilEntreprise | undefined) => {
  return {
    BIRC: getContactsValues('BIRC', entreprise),
    RCGE: getContactsValues('RCGE', entreprise),
    BNIR: getContactsValues('BNIR', entreprise),
    RCMD: getContactsValues('RCMD', entreprise),
    ASSC: getContactsValues('ASSC', entreprise),
    PREV: getContactsValues('PREV', entreprise),
    ACCV: getContactsValues('ACCV', entreprise),
    FAUT: getContactsValues('FAUT', entreprise),
    AUTR: getContactsValues('AUTR', entreprise),
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [nature]
const getContactsValues = (nature: string, entreprise: any) => {
  return {
    code: entreprise?.parametrage?.contacts?.[nature]?.code ?? CodeContact.CODE_BAL_GESTIONNAIRE,
    libelle: entreprise?.parametrage?.contacts?.[nature]?.libelle ?? '',
    mail: entreprise?.parametrage?.contacts?.[nature]?.mail ?? '',
    telephone: entreprise?.parametrage?.contacts?.[nature]?.telephone ?? '',
  }
}

export const getDelaisForm = (entreprise: ProfilEntreprise | undefined) => {
  return {
    DELAI_REPONSE_MISSION: getDelaisFormByTypeDelais('DELAI_REPONSE_MISSION', entreprise),
    DELAI_REPONSE_SUIVI_MISSION: getDelaisFormByTypeDelais('DELAI_REPONSE_SUIVI_MISSION', entreprise),
    DELAI_VALIDATION_RAPPORT: getDelaisFormByTypeDelais('DELAI_VALIDATION_RAPPORT', entreprise),
    DELAI_VALIDATION_RAPPORT_DATE_RDV: getDelaisFormByTypeDelais('DELAI_VALIDATION_RAPPORT_DATE_RDV', entreprise),
    DELAI_VALIDATION_DEMANDE_MODIFICATION: getDelaisFormByTypeDelais(
      'DELAI_VALIDATION_DEMANDE_MODIFICATION',
      entreprise
    ),
  }
}

const getDelaisFormByTypeDelais = (typeDelais: string, entreprise: ProfilEntreprise | undefined) => {
  return {
    BIRC: delaisRequestToForm(typeDelais, 'BIRC', entreprise),
    BNIR: delaisRequestToForm(typeDelais, 'BNIR', entreprise),
    RCGE: delaisRequestToForm(typeDelais, 'RCGE', entreprise),
    RCMD: delaisRequestToForm(typeDelais, 'RCMD', entreprise),
    ASSC: delaisRequestToForm(typeDelais, 'ASSC', entreprise),
    PREV: delaisRequestToForm(typeDelais, 'PREV', entreprise),
    ACCV: delaisRequestToForm(typeDelais, 'ACCV', entreprise),
    FAUT: delaisRequestToForm(typeDelais, 'FAUT', entreprise),
    AUTR: delaisRequestToForm(typeDelais, 'AUTR', entreprise),
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [typeDelais] et [nature]
const delaisRequestToForm = (typeDelais: string, nature: string, entreprise: any) => {
  const delaisRelance = entreprise?.parametrage?.delaisRelance?.[typeDelais]?.[nature]
  return {
    [CodeFichesConclusions.expertiseUnilaterale]: delaisRelance?.[CodeFichesConclusions.expertiseUnilaterale] ?? 0,
    [CodeFichesConclusions.expertiseConjointe]: delaisRelance?.[CodeFichesConclusions.expertiseConjointe] ?? 0,
    [CodeFichesConclusions.expertiseJudiciaire]: delaisRelance?.[CodeFichesConclusions.expertiseJudiciaire] ?? 0,
    [CodeFichesConclusions.arbitrage]: delaisRelance?.[CodeFichesConclusions.arbitrage] ?? 0,
    [CodeFichesConclusions.avisTechnique]: delaisRelance?.[CodeFichesConclusions.avisTechnique] ?? 0,
  }
}

// Tranform referentiel list to object
export const getNotificationsFormFromReferentiel = (
  entreprise: ProfilEntreprise | undefined,
  referentiel: Referentiel | undefined,
  partenaire: TypeMessageNotificationTag,
  typesExclus: TypeMessageNotification[] = [] // type de message a exclure
) => {
  return referentiel?.typeMessageNotification
    .filter((typeMessage) => !typesExclus.includes(typeMessage.code))
    .filter((t) => t.partenaires.includes(partenaire))
    .reduce((acc: any, item) => {
      if (
        entreprise?.parametrage?.notifications !== undefined &&
        entreprise?.parametrage?.notifications[item.code] !== undefined
      ) {
        acc[item.code] = entreprise?.parametrage?.notifications[item.code]
      } else {
        acc[item.code] = {
          CG: '00',
          SV: '00',
          GE: '00',
        }
      }
      return acc
    }, {})
}

export const getDommagesPeriodesMax = (entreprise: ProfilEntreprise | undefined) => {
  const dommagesPeriodesMax = entreprise?.parametrage?.dommagesPeriodesMax
  return {
    DATES_HOSPITALISATION_IMPUTABLES: dommagesPeriodesMax?.DATES_HOSPITALISATION_IMPUTABLES ?? DEFAULT_MAX_PERIODES,
    GENE_TEMPORAIRE_TOTALE: dommagesPeriodesMax?.GENE_TEMPORAIRE_TOTALE ?? DEFAULT_MAX_PERIODES,
    DATES_ARRETS_DE_TRAVAIL_IMPUTABLES: dommagesPeriodesMax?.DATES_ARRETS_DE_TRAVAIL_IMPUTABLES ?? DEFAULT_MAX_PERIODES,
    TIERCE_PERSONNE_TEMPORAIRE: dommagesPeriodesMax?.TIERCE_PERSONNE_TEMPORAIRE ?? DEFAULT_MAX_PERIODES,
    TIERCE_PERSONNE_DEFINITIVE: dommagesPeriodesMax?.TIERCE_PERSONNE_DEFINITIVE ?? DEFAULT_MAX_PERIODES,
    GENE_TEMPORAIRE_PARTIELLE_CLASSE_1: dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_1 ?? DEFAULT_MAX_PERIODES,
    GENE_TEMPORAIRE_PARTIELLE_CLASSE_2: dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_2 ?? DEFAULT_MAX_PERIODES,
    GENE_TEMPORAIRE_PARTIELLE_CLASSE_3: dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_3 ?? DEFAULT_MAX_PERIODES,
    GENE_TEMPORAIRE_PARTIELLE_CLASSE_4: dommagesPeriodesMax?.GENE_TEMPORAIRE_PARTIELLE_CLASSE_4 ?? DEFAULT_MAX_PERIODES,
  }
}

export const getDommagesCommentairesMax = (entreprise: ProfilEntreprise | undefined) => {
  const dommagesCommentairesMax = entreprise?.parametrage?.dommagesCommentairesMax
  return {
    DATES_HOSPITALISATION_IMPUTABLES: dommagesCommentairesMax?.DATES_HOSPITALISATION_IMPUTABLES ?? 0,
    DATES_ARRETS_DE_TRAVAIL_IMPUTABLES: dommagesCommentairesMax?.DATES_ARRETS_DE_TRAVAIL_IMPUTABLES ?? 0,
  }
}

// FORM TO REQUEST
export const getFicheConclusionRequest = (formValues: ProfilEntrepriseForm) => {
  const naturesEvenements = formValues.parametrage?.naturesEvenements
  return {
    BIRC: naturesEvenements?.BIRC ? ficheConclusionFormToRequest('BIRC', formValues) : undefined,
    BNIR: naturesEvenements?.BNIR ? ficheConclusionFormToRequest('BNIR', formValues) : undefined,
    RCGE: naturesEvenements?.RCGE ? ficheConclusionFormToRequest('RCGE', formValues) : undefined,
    RCMD: naturesEvenements?.RCMD ? ficheConclusionFormToRequest('RCMD', formValues) : undefined,
    ASSC: naturesEvenements?.ASSC ? ficheConclusionFormToRequest('ASSC', formValues) : undefined,
    PREV: naturesEvenements?.PREV ? ficheConclusionFormToRequest('PREV', formValues) : undefined,
    ACCV: naturesEvenements?.ACCV ? ficheConclusionFormToRequest('ACCV', formValues) : undefined,
    FAUT: naturesEvenements?.FAUT ? ficheConclusionFormToRequest('FAUT', formValues) : undefined,
    AUTR: naturesEvenements?.AUTR ? ficheConclusionFormToRequest('AUTR', formValues) : undefined,
  }
}

export const getRapportConseilConclusionRequest = (
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE,
  formValues: ProfilEntrepriseForm
) => {
  const naturesEvenements = formValues.parametrage?.naturesEvenements
  return {
    BIRC: naturesEvenements?.BIRC ? rapportConseilConclusionToRequest('BIRC', typeConclusion, formValues) : undefined,
    BNIR: naturesEvenements?.BNIR ? rapportConseilConclusionToRequest('BNIR', typeConclusion, formValues) : undefined,
    RCGE: naturesEvenements?.RCGE ? rapportConseilConclusionToRequest('RCGE', typeConclusion, formValues) : undefined,
    RCMD: naturesEvenements?.RCMD ? rapportConseilConclusionToRequest('RCMD', typeConclusion, formValues) : undefined,
    ASSC: naturesEvenements?.ASSC ? rapportConseilConclusionToRequest('ASSC', typeConclusion, formValues) : undefined,
    PREV: naturesEvenements?.PREV ? rapportConseilConclusionToRequest('PREV', typeConclusion, formValues) : undefined,
    ACCV: naturesEvenements?.ACCV ? rapportConseilConclusionToRequest('ACCV', typeConclusion, formValues) : undefined,
    FAUT: naturesEvenements?.FAUT ? rapportConseilConclusionToRequest('FAUT', typeConclusion, formValues) : undefined,
    AUTR: naturesEvenements?.AUTR ? rapportConseilConclusionToRequest('AUTR', typeConclusion, formValues) : undefined,
  }
}

const rapportConseilConclusionToRequest = (
  nature: string,
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE,
  formValues: any
) => {
  const conclusionField =
    typeConclusion === TypeRapport.CONCLUSION_PROVISOIRE ? 'typesRapportConseilPro' : 'typesRapportConseilDef'

  const param = formValues?.parametrage[conclusionField]?.[nature]

  return {
    [CodeFichesConclusions.expertiseUnilaterale]: convertTypeRapportConseilTextToValue(param?.expertiseUnilaterale),
    [CodeFichesConclusions.expertiseConjointe]: convertTypeRapportConseilTextToValue(param?.expertiseConjointe),
    [CodeFichesConclusions.expertiseJudiciaire]: convertTypeRapportConseilTextToValue(param?.expertiseJudiciaire),
    [CodeFichesConclusions.arbitrage]: convertTypeRapportConseilTextToValue(param?.arbitrage),
    [CodeFichesConclusions.avisTechnique]: formValues.parametrage?.expertiseSurPiece
      ? convertTypeRapportConseilTextToValue(param?.avisTechnique)
      : undefined,
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [nature]
const ficheConclusionFormToRequest = (nature: string, formValues: any) => {
  const fichesConclusion = formValues?.parametrage?.fichesConclusions[nature]
  return {
    [CodeFichesConclusions.expertiseUnilaterale]: convertFicheConclusionTextToValue(
      fichesConclusion?.expertiseUnilaterale
    ),
    [CodeFichesConclusions.expertiseConjointe]: convertFicheConclusionTextToValue(fichesConclusion?.expertiseConjointe),
    [CodeFichesConclusions.expertiseJudiciaire]: convertFicheConclusionTextToValue(
      fichesConclusion?.expertiseJudiciaire
    ),
    [CodeFichesConclusions.arbitrage]: convertFicheConclusionTextToValue(fichesConclusion?.arbitrage),
    [CodeFichesConclusions.avisTechnique]: formValues.parametrage?.expertiseSurPiece
      ? convertFicheConclusionTextToValue(fichesConclusion?.avisTechnique)
      : undefined,
  }
}

const convertFicheConclusionTextToValue = (ficheConclusionText: string) => {
  return ficheConclusionText === EnumTypesFichesConclusions.CONV.valueOf()
    ? EnumTypesFichesConclusions.CONV
    : EnumTypesFichesConclusions.SIMP
}

const convertTypeRapportConseilTextToValue = (typeRapportConseil?: string) => {
  switch (typeRapportConseil) {
    case TypeRapportConseil.DESACTIVE.valueOf():
      return TypeRapportConseil.DESACTIVE
    case TypeRapportConseil.OBLIGATOIRE.valueOf():
      return TypeRapportConseil.OBLIGATOIRE
    case TypeRapportConseil.FACULTATIF.valueOf():
      return TypeRapportConseil.FACULTATIF
    default:
      return undefined
  }
}

export const addStatutAndRegisteredToForm = (object: ServiceEntreprise | DomaineContractuelEntreprise) => {
  return {
    ...object,
    statutLabel: object.actif ? 'Actif' : 'Inactif',
    registered: true,
  }
}

export const addStatutToRequest = (object: ServiceEntreprise | DomaineContractuelEntreprise) => {
  return {
    ...object,
    actif: object.statutLabel === 'Actif',
  }
}

export const getServicesFormToRequest = (formValues: ProfilEntrepriseForm) => {
  return formValues?.parametrage?.services?.map((service: ServiceEntreprise) =>
    addStatutToRequest({
      ...service,
      telephone: trimToUndefined(service.telephone),
    })
  )
}

export const getDomainesFormToRequest = (formValues: ProfilEntrepriseForm) => {
  return formValues?.parametrage?.domainesContractuels?.map((domaine: DomaineContractuelEntreprise) =>
    addStatutToRequest(domaine)
  )
}

export const getContactInformationRequest = (contact?: ContactInformations) => {
  return {
    code: contact?.code,
    libelle: trimToUndefined(contact?.libelle),
    telephone: trimToUndefined(contact?.telephone),
    mail: trimToUndefined(contact?.mail),
  } as ContactInformations
}

export const getContactsFormToRequest = (formValues: ProfilEntrepriseForm) => {
  const naturesEvenements = formValues.parametrage?.naturesEvenements
  return {
    BIRC: naturesEvenements?.BIRC ? getContactInformationRequest(formValues.parametrage?.contacts?.BIRC) : undefined,
    RCGE: naturesEvenements?.RCGE ? getContactInformationRequest(formValues.parametrage?.contacts?.RCGE) : undefined,
    BNIR: naturesEvenements?.BNIR ? getContactInformationRequest(formValues.parametrage?.contacts?.BNIR) : undefined,
    RCMD: naturesEvenements?.RCMD ? getContactInformationRequest(formValues.parametrage?.contacts?.RCMD) : undefined,
    ASSC: naturesEvenements?.ASSC ? getContactInformationRequest(formValues.parametrage?.contacts?.ASSC) : undefined,
    PREV: naturesEvenements?.PREV ? getContactInformationRequest(formValues.parametrage?.contacts?.PREV) : undefined,
    ACCV: naturesEvenements?.ACCV ? getContactInformationRequest(formValues.parametrage?.contacts?.ACCV) : undefined,
    FAUT: naturesEvenements?.FAUT ? getContactInformationRequest(formValues.parametrage?.contacts?.FAUT) : undefined,
    AUTR: naturesEvenements?.AUTR ? getContactInformationRequest(formValues.parametrage?.contacts?.AUTR) : undefined,
  }
}

export const getDelaisFormToRequest = (formValues: ProfilEntrepriseForm) => {
  return {
    DELAI_REPONSE_MISSION: getDelaisFormToRequestByTypeDelais('DELAI_REPONSE_MISSION', formValues),
    DELAI_REPONSE_SUIVI_MISSION: getDelaisFormToRequestByTypeDelais('DELAI_REPONSE_SUIVI_MISSION', formValues),
    DELAI_VALIDATION_RAPPORT: getDelaisFormToRequestByTypeDelais('DELAI_VALIDATION_RAPPORT', formValues),
    DELAI_VALIDATION_RAPPORT_DATE_RDV: getDelaisFormToRequestByTypeDelais(
      'DELAI_VALIDATION_RAPPORT_DATE_RDV',
      formValues
    ),
    DELAI_VALIDATION_DEMANDE_MODIFICATION: getDelaisFormToRequestByTypeDelais(
      'DELAI_VALIDATION_DEMANDE_MODIFICATION',
      formValues
    ),
  }
}

const getDelaisFormToRequestByTypeDelais = (typeDelais: string, formValues: ProfilEntrepriseForm) => {
  const naturesEvenements = formValues.parametrage?.naturesEvenements
  return {
    BIRC: naturesEvenements?.BIRC ? getDelaisValues('BIRC', typeDelais, formValues) : undefined,
    BNIR: naturesEvenements?.BNIR ? getDelaisValues('BNIR', typeDelais, formValues) : undefined,
    RCGE: naturesEvenements?.RCGE ? getDelaisValues('RCGE', typeDelais, formValues) : undefined,
    RCMD: naturesEvenements?.RCMD ? getDelaisValues('RCMD', typeDelais, formValues) : undefined,
    ASSC: naturesEvenements?.ASSC ? getDelaisValues('ASSC', typeDelais, formValues) : undefined,
    PREV: naturesEvenements?.PREV ? getDelaisValues('PREV', typeDelais, formValues) : undefined,
    ACCV: naturesEvenements?.ACCV ? getDelaisValues('ACCV', typeDelais, formValues) : undefined,
    FAUT: naturesEvenements?.FAUT ? getDelaisValues('FAUT', typeDelais, formValues) : undefined,
    AUTR: naturesEvenements?.AUTR ? getDelaisValues('AUTR', typeDelais, formValues) : undefined,
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// any necessaire pour utiliser [typeEvenement] et [typeDelais]
const getDelaisValues = (typeEvenement: string, typeDelais: string, formValues: any) => {
  const delaisRelance = formValues.parametrage?.delaisRelance?.[typeDelais]?.[typeEvenement]
  return {
    [CodeFichesConclusions.expertiseUnilaterale]: delaisRelance?.[CodeFichesConclusions.expertiseUnilaterale],
    [CodeFichesConclusions.expertiseConjointe]: delaisRelance?.[CodeFichesConclusions.expertiseConjointe],
    [CodeFichesConclusions.expertiseJudiciaire]: delaisRelance?.[CodeFichesConclusions.expertiseJudiciaire],
    [CodeFichesConclusions.arbitrage]: delaisRelance?.[CodeFichesConclusions.arbitrage],
    [CodeFichesConclusions.avisTechnique]: formValues.parametrage?.expertiseSurPiece
      ? delaisRelance?.[CodeFichesConclusions.avisTechnique]
      : undefined,
  }
}
