import React from 'react'
import { Box } from '@mui/material'
import medecinImage from 'assets/media/img/medecin.jpg'

export default function MedecinImage() {
  return (
    <Box
      component="img"
      loading="lazy"
      alt="medecin-icon"
      src={medecinImage}
      width={120}
      height={120}
      marginRight={1}
    />
  )
}
