import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Grid } from '@mui/material'
import {
  ArrowCircleRight,
  Block,
  Cancel,
  CheckCircle,
  DeleteForever,
  Description,
  LiveHelp,
  NoteAdd,
  PersonAdd,
  PlaylistAddCheckCircle,
  SwapHorizontalCircle,
  FileOpen,
  MarkEmailRead,
} from '@mui/icons-material'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import useConfirmDialog, { DialogArgs } from 'plateforme/hooks/useConfirmDialog'
import { ActionTile, ActionTilesSyntheseLoading } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import { downloadLastLettreMission, lettreMissionTelechargeable } from 'plateforme/services/document.services'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import {
  SuiviDossierAction,
  SuiviDossierRequest,
  usePostSuiviDossierMutation,
} from 'assureur/store/apis/dossierAssureurApi'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise, { SuiviMissionEntrepriseAction } from 'assureur/store/types/missionEntreprise'
import { editionDossierHref, homeHref, missionerMedecinHref } from 'assureur/EntrepriseApp'
import { setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import {
  dossierAnnulable,
  dossierCloturable,
  dossierDocumentDeposable,
  dossierMissionnable,
  dossierPurgeable,
  dossierValidable,
  gestionnaireAffectable,
  missionAnnulable,
  missionArretable,
  missionDeclarableSansSuite,
  missionModificationDemandable,
  missionCloturable,
  dossierConsentementRetirable,
  dossierReouvrable,
} from 'assureur/services/rolesEntreprise.services'

import { CodeMotifCloture } from 'plateforme/services/dossier.services'
import { StatutMessagerie } from 'plateforme/store/types/mission'
import AffectationGestionnaireForm from './AffectationGestionnaireForm'
import ActionMissionForm from './ActionMissionForm'
import ActionDossierForm from './ActionDossierForm'

type ActionTilesSyntheseEntreprisePartProps = {
  dossier?: DossierEntreprise
  derniereMission?: MissionEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
  profilEntreprise?: ProfilEntreprise
}

export default function ActionTilesSyntheseEntreprisePart({
  dossier,
  derniereMission,
  profilUtilisateur,
  profilEntreprise,
  loading,
}: ActionTilesSyntheseEntreprisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <ActionTilesSyntheseLoading />
  }
  return (
    <ActionTilesSyntheseEntreprisePartLoaded
      dossier={dossier}
      derniereMission={derniereMission}
      profilUtilisateur={profilUtilisateur}
      profilEntreprise={profilEntreprise}
    />
  )
}

function ActionTilesSyntheseEntreprisePartLoaded({
  dossier,
  derniereMission,
  profilUtilisateur,
  profilEntreprise,
}: ActionTilesSyntheseEntreprisePartProps) {
  // error:
  if (!dossier || !profilUtilisateur || !profilEntreprise || !dossier?.code) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { code: codeDossier, missions } = dossier

  // hooks:
  const [authDownload, { isLoading: isAuthDownloadLoading }] = usePostAuthDownloadMutation()
  const [postSuiviDossier, { isLoading: loadingSauvegarder }] = usePostSuiviDossierMutation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const navigate = useNavigate()
  const { navigateTab } = useTabNavigate()

  // format:
  const isDossierSansMission = !missions || missions.length < 0
  const messagerieActive = dossier?.missions?.some((m) => m.messagerieActive !== StatutMessagerie.DESACTIVE)
  const navigateTabDocuments = () => navigateTab('documents')
  const navigateTabMessagerie = () => navigateTab('messages')
  const navigateCreationMission = () => navigate(missionerMedecinHref(dossier.code))

  // open dialog form suivi dossier:
  const openDialogSuiviDossier = (action: SuiviDossierAction, motifCloture?: string) => {
    let distinctDialogArgs: DialogArgs
    let msgSucces: string
    let msgEchec: string
    switch (action) {
      case SuiviDossierAction.VALIDER:
        msgSucces = 'Le dossier a été validé avec succès'
        msgEchec = 'La validation du dossier a échoué'
        distinctDialogArgs = {
          title: 'Valider le dossier',
          confirmMsg: `Vous allez valider la création du dossier, et pourrez ensuite missionner un médecin.
          Merci de confirmer votre choix`,
          withForm: false,
        }

        break
      case SuiviDossierAction.ANNULER:
        msgSucces = 'Le dossier a été annulé avec succès'
        msgEchec = `L'annulation du dossier a échoué`
        distinctDialogArgs = {
          title: 'Annuler le dossier',
          confirmMsg: `Vous allez annuler le dossier. Merci de confirmer votre choix`,
          withForm: true,
          form: (
            <ActionDossierForm
              dossier={dossier}
              action={SuiviDossierAction.ANNULER}
              msgSucces={msgSucces}
              msgEchec={msgEchec}
              onClose={closeConfirmDialog}
            />
          ),
        }

        break
      case SuiviDossierAction.ROUVRIR:
        msgSucces = 'Le dossier a été réouvert avec succès'
        msgEchec = `La réouverture du dossier a échoué`
        distinctDialogArgs = {
          title: 'Rouvrir le dossier',
          confirmMsg: `Vous allez rouvrir le dossier. Vous pourrez par la suite créer une nouvelle mission sur ce dossier. Merci de confirmer votre choix.`,
          withForm: true,
          form: (
            <ActionDossierForm
              dossier={dossier}
              action={SuiviDossierAction.ROUVRIR}
              msgSucces={msgSucces}
              msgEchec={msgEchec}
              onClose={closeConfirmDialog}
            />
          ),
        }

        break
      case SuiviDossierAction.CLOTURER:
        msgSucces = 'Le dossier a été clôturé avec succès'
        msgEchec = 'La clôture du dossier a échoué'
        distinctDialogArgs = {
          title:
            motifCloture === CodeMotifCloture.RETRAIT_CONSENTEMENT
              ? 'Clôture du dossier suite retrait du consentement assuré'
              : 'Clôturer le dossier',
          confirmMsg:
            motifCloture === CodeMotifCloture.RETRAIT_CONSENTEMENT
              ? `Vous allez clôturer le dossier en cours suite au retrait du consentement de la personne à examiner.
           Suite à la clôture, vous ne pourrez plus créer de nouvelle mission sur le dossier et le médecin ne pourra plus déposer de rapport sur les éventuelles missions en cours. Merci de confirmer votre choix.`
              : `Vous allez clôturer le dossier, suite à la clôture,
          vous ne pourrez plus créer de nouvelle mission pour le dossier. Merci de confirmer votre choix`,
          withForm: true,
          form: (
            <ActionDossierForm
              dossier={dossier}
              action={SuiviDossierAction.CLOTURER}
              msgSucces={msgSucces}
              msgEchec={msgEchec}
              onClose={closeConfirmDialog}
              motifCloture={motifCloture}
            />
          ),
        }
        break
      case SuiviDossierAction.PURGER:
        msgSucces = 'La demande de purge a été enregistrée avec succès'
        msgEchec = 'La demande de purge du dossier a échoué'
        distinctDialogArgs = {
          title: 'Purger le dossier',
          confirmMsg: `Vous allez demander la purge du dossier, qui sera effective le lendemain de votre demande
          et les données et documents du dossier ne seront plus accessibles. Merci de confirmer votre choix`,

          withForm: false,
        }

        break
      default:
        msgSucces = ''
        msgEchec = ''
        distinctDialogArgs = {
          title: '',
          confirmMsg: '',
          withForm: false,
        }
    }
    const request: SuiviDossierRequest = { codeDossier, action }
    confirm({
      ...distinctDialogArgs,
      maxWidth: 'md',
      fullWidth: true,
      onYes: async () => {
        await postSuiviDossier(request)
          .unwrap()
          .then(() => {
            enqueueSnackbar(msgSucces, { variant: 'success' })
            dispatch(setErrorDossier({ status: 200, data: undefined }))
            if (request.action === SuiviDossierAction.PURGER) {
              navigate(homeHref)
            }
          })
          .catch((error) => {
            dispatch(setErrorDossier(error))
            enqueueSnackbar(msgEchec, { variant: 'error' })
            navigate(editionDossierHref(codeDossier))
          })
      },
    })
  }

  // open dialog form suivi mission:
  const openDialogSuiviMission = (action: SuiviMissionEntrepriseAction) => {
    if (!derniereMission) {
      return
    }

    let title: string
    let confirmMsg: string
    let msgSucces: string
    let msgEchec: string
    switch (action) {
      case SuiviMissionEntrepriseAction.ARRETER: {
        title = 'Arrêter la mission'
        confirmMsg = `Vous allez arrêter cette mission avec le médecin ${derniereMission.medecin?.libelle} :
          il ne pourra pas déposer de conclusions,
          néanmoins il pourra déposer une facture pour la mission. Vous pourrez par la suite,
          si vous le souhaitez, remissionner un nouveau médecin sur la mission. Merci de confirmer votre choix`
        msgSucces = 'La mission a été arrêtée avec succès'
        msgEchec = `L'arrêt de la mission a échoué`
        break
      }
      case SuiviMissionEntrepriseAction.ANNULER: {
        title = 'Annuler la mission'
        confirmMsg = `Vous allez annuler cette mission, cette mission ne sera plus visible dans la liste des missions du dossier.
          Vous pourrez par la suite en créer une nouvelle sur ce dossier. Merci de confirmer votre choix`
        msgSucces = 'La mission a été annulée avec succès'
        msgEchec = `L'annulation de la mission a échoué`
        break
      }
      case SuiviMissionEntrepriseAction.CLOTURER: {
        title = 'Clôturer la mission'
        confirmMsg = `Vous allez clôturer la mission en cours. Suite à la clôture, le médecin ne pourra plus déposer de nouveau rapport sur cette mission. Merci de confirmer votre choix`
        msgSucces = 'La mission a été clôturée avec succès'
        msgEchec = `La clôture de la mission a échoué`
        break
      }
      case SuiviMissionEntrepriseAction.DECLARER_SANS_SUITE: {
        title = 'Déclarer sans suite médecin'
        confirmMsg = `Vous allez déclarer cette mission "sans suite médecin",
          le médecin à qui vous avez proposé la mission ne pourra plus l'accepter.
          Vous pourrez par la suite, si vous le souhaitez, remissionner un nouveau médecin sur la mission.
          Merci de confirmer votre choix`
        msgSucces = 'La mission a été déclarée sans suite avec succès'
        msgEchec = 'La déclaration sans suite de la mission a échoué'
        break
      }
      case SuiviMissionEntrepriseAction.DEMANDER_MODIFICATION: {
        title = 'Demander des compléments ou ajustements'
        confirmMsg = `Vous allez demander des compléments ou ajustements au médecin sur les conclusions qu’il a transmises pour la mission.
          Il pourra alors modifier le formulaire de fiche de conclusions,
          déposer de nouveaux documents associés à ces conclusions.
          Merci de confirmer votre choix`
        msgSucces = 'La demande de modification a été réalisée avec succès'
        msgEchec = 'La demande de modification de la mission a échoué'
        break
      }
      default: {
        title = ''
        confirmMsg = ''
        msgSucces = ''
        msgEchec = ''
        break
      }
    }
    confirm({
      title,
      confirmMsg,
      maxWidth: 'md',
      fullWidth: true,
      withForm: true,
      form: (
        <ActionMissionForm
          dossier={dossier}
          derniereMission={derniereMission}
          action={action}
          msgSucces={msgSucces}
          msgEchec={msgEchec}
          onClose={closeConfirmDialog}
        />
      ),
    })
  }

  // open dialog form suivi mission:
  const openDialogAffectationDossier = () => {
    confirm({
      title: 'Affecter à un autre gestionnaire',
      confirmMsg: '',
      maxWidth: 'lg',
      fullWidth: true,
      withForm: true,
      form: (
        <AffectationGestionnaireForm
          onClose={closeConfirmDialog}
          dossier={dossier}
          profilEntreprise={profilEntreprise}
          profilUtilisateur={profilUtilisateur}
          closeConfirmDialog={closeConfirmDialog}
        />
      ),
    })
  }

  // render:
  return (
    <>
      {confirmDialog}
      <Grid container marginTop="0 !important" marginLeft="-28px !important">
        {dossierValidable(dossier, profilUtilisateur) && dossier.validable && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Valider le dossier"
              icon={<CheckCircle />}
              onClick={() => openDialogSuiviDossier(SuiviDossierAction.VALIDER)}
              loading={loadingSauvegarder}
            />
          </Grid>
        )}
        {dossierMissionnable(dossier, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            {isDossierSansMission ? (
              <ActionTile
                label="Missionner un médecin"
                icon={<ArrowCircleRight />}
                onClick={navigateCreationMission}
                loading={false}
              />
            ) : (
              <ActionTile
                label="Remissionner un médecin"
                icon={<SwapHorizontalCircle />}
                onClick={navigateCreationMission}
                loading={false}
              />
            )}
          </Grid>
        )}
        {lettreMissionTelechargeable(dossier.missions, derniereMission?.code) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Télécharger la lettre de mission"
              icon={<Description />}
              onClick={() =>
                downloadLastLettreMission(
                  dossier.missions,
                  codeDossier,
                  derniereMission?.code,
                  enqueueSnackbar,
                  authDownload
                )
              }
              loading={isAuthDownloadLoading}
            />
          </Grid>
        )}
        {dossierAnnulable(dossier, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Annuler le dossier"
              icon={<Cancel />}
              onClick={() => openDialogSuiviDossier(SuiviDossierAction.ANNULER)}
              loading={loadingSauvegarder}
            />
          </Grid>
        )}
        {missionCloturable(derniereMission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Clôturer la mission"
              icon={<Cancel />}
              onClick={() => openDialogSuiviMission(SuiviMissionEntrepriseAction.CLOTURER)}
              loading={false}
            />
          </Grid>
        )}
        {dossierCloturable(dossier, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Clôturer le dossier"
              icon={<PlaylistAddCheckCircle />}
              onClick={() => openDialogSuiviDossier(SuiviDossierAction.CLOTURER, CodeMotifCloture.MANUELLE)}
              loading={loadingSauvegarder}
            />
          </Grid>
        )}
        {dossierReouvrable(dossier, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Rouvrir le dossier"
              icon={<FileOpen />}
              onClick={() => openDialogSuiviDossier(SuiviDossierAction.ROUVRIR, CodeMotifCloture.MANUELLE)}
              loading={loadingSauvegarder}
            />
          </Grid>
        )}
        {dossierConsentementRetirable(dossier, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Clôturer le dossier suite retrait consentement personne à examiner"
              icon={<Cancel />}
              onClick={() => openDialogSuiviDossier(SuiviDossierAction.CLOTURER, CodeMotifCloture.RETRAIT_CONSENTEMENT)}
              loading={loadingSauvegarder}
            />
          </Grid>
        )}
        {dossierPurgeable(dossier, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Demander la purge du dossier"
              icon={<DeleteForever />}
              onClick={() => openDialogSuiviDossier(SuiviDossierAction.PURGER)}
              loading={false}
            />
          </Grid>
        )}
        {missionAnnulable(derniereMission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Annuler la mission"
              icon={<Cancel />}
              onClick={() => openDialogSuiviMission(SuiviMissionEntrepriseAction.ANNULER)}
              loading={false}
            />
          </Grid>
        )}
        {missionDeclarableSansSuite(derniereMission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Déclarer sans suite médecin"
              icon={<Cancel />}
              onClick={() => openDialogSuiviMission(SuiviMissionEntrepriseAction.DECLARER_SANS_SUITE)}
              loading={false}
            />
          </Grid>
        )}
        {missionArretable(derniereMission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Arrêter la mission"
              icon={<Block />}
              onClick={() => openDialogSuiviMission(SuiviMissionEntrepriseAction.ARRETER)}
              loading={false}
            />
          </Grid>
        )}
        {missionModificationDemandable(derniereMission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Demander des compléments ou ajustements"
              icon={<LiveHelp />}
              onClick={() => openDialogSuiviMission(SuiviMissionEntrepriseAction.DEMANDER_MODIFICATION)}
              loading={false}
            />
          </Grid>
        )}
        {dossierDocumentDeposable(dossier, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile label="Ajouter un document" icon={<NoteAdd />} onClick={navigateTabDocuments} loading={false} />
          </Grid>
        )}
        {gestionnaireAffectable(profilUtilisateur) && dossier.affectable && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Affecter à un nouveau gestionnaire"
              icon={<PersonAdd />}
              onClick={openDialogAffectationDossier}
              loading={false}
            />
          </Grid>
        )}
        {messagerieActive && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile label="Messagerie" icon={<MarkEmailRead />} onClick={navigateTabMessagerie} loading={false} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
