import { TypeRapport } from 'plateforme/store/types/rapportConclusion'

export default function determineSuffixeLibelleDommage(typeRapport?: TypeRapport): string {
  switch (typeRapport) {
    case TypeRapport.CONCLUSION_PROVISOIRE: {
      return ` (non inférieur à)`
    }
    case TypeRapport.CONCLUSION_DEFINITIVE: {
      return ` (valeur)`
    }
    case TypeRapport.CARENCE: {
      return ''
    }
    default: {
      return ''
    }
  }
}
