import React, { ReactElement } from 'react'

import { Card, CardContent, Grid, Typography, Box, Tooltip } from '@mui/material'
import { FichierIcon } from 'plateforme/components'
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff'

export interface CardDocumentProp {
  codeDocument: string
}

function ValueLabled({ label, value }: { label?: string; value?: string | ReactElement }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="body2" color="text.primary">
        {label}
      </Typography>
      <Typography textOverflow="ellipsis" overflow="hidden" variant="body1" color="text.secondary">
        {value}
      </Typography>
    </Box>
  )
}

export default function DocumentDeletedCard({ codeDocument }: CardDocumentProp) {
  // rendu:
  return (
    <Card sx={{ width: '100%' }}>
      <CardContent sx={{ padding: '4px !important', backgroundColor: '#d0c3dd91' }}>
        <Grid padding={0} container width="100%">
          <Grid display="flex" alignItems="center" item md={0.5} xs={0.5}>
            <FichierIcon />
          </Grid>
          <Grid item md={11} xs={11}>
            <ValueLabled label="Code document" value={codeDocument} />
          </Grid>
          <Grid display="flex" alignItems="center" item md={0.5} xs={0.5}>
            <Tooltip title="Impossible de télécharger un document supprimé  du dossier" placement="right">
              <FileDownloadOffIcon color="error" />
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
