import React from 'react'
import { FieldError } from 'react-hook-form'
import {
  FormControl,
  FormControlProps,
  FormControlLabelProps,
  FormHelperText,
  FormHelperTextProps,
  InputAdornment,
} from '@mui/material'
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input'
import { Create } from '@mui/icons-material'
import ReadOnlyTextField from './ReadOnlyTextField'

export type PhoneNumberInputProps = Omit<FormControlProps, 'control' | 'sx'> &
  Omit<FormControlLabelProps, 'control' | 'sx'> &
  Omit<MuiTelInputProps, 'control' | 'sx'> &
  Omit<FormHelperTextProps, 'control' | 'sx'> & {
    fieldError?: FieldError
    withCountryFlag?: boolean
    readOnly?: boolean
  }

export default function PhoneNumberInput({
  id,
  value,
  label,
  defaultCountry = 'FR',
  onChange,
  onBlur,
  fieldError,
  withCountryFlag,
  readOnly,
  fullWidth,
  required,
}: PhoneNumberInputProps) {
  if (readOnly) {
    return <ReadOnlyTextField fullWidth={fullWidth} id={id} value={value} label={label} />
  }

  return (
    <FormControl variant="filled" fullWidth error={fieldError !== undefined}>
      <MuiTelInput
        required={required}
        error={fieldError !== undefined}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        fullWidth={fullWidth}
        variant="filled"
        disableDropdown
        defaultCountry={defaultCountry}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Create sx={{ fontSize: '22px' }} />
            </InputAdornment>
          ),
        }}
        // continents={['EU']} note: it's possible to set the continents
        langOfCountryName="fr"
        sx={{
          '& .MuiTelInput-IconButton': {
            display: withCountryFlag ? 'flex' : 'none',
          },
        }}
      />
      {fieldError?.message && <FormHelperText>{fieldError?.message}</FormHelperText>}
    </FormControl>
  )
}
