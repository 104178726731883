import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { AnnulerButton, ValiderButton, EditTextField, ButtonsStack } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { trimToUndefined } from 'plateforme/services/utils'
import { usePostSuiviMissionMutation } from 'medecin/store/apis/dossierMedecinApi'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise, { SuiviMissionMedecinAction, SuiviMissionRequest } from 'medecin/store/types/missionMedecin'

interface AccepterMissionFormProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
  onClose: VoidFunction
}

type AccepterMissionFormValues = {
  refMissionMedecin: string
}

export default function AccepterMissionForm({ dossier, mission, onClose }: AccepterMissionFormProps) {
  // props:
  const { code: codeDossier } = dossier
  const { code: codeMission, refMissionMedecin: initRefMissionMedecin } = mission

  // hooks:
  const [postSuiviMission, { error: errorAccepterMission, isLoading: loadingAccepterMission }] =
    usePostSuiviMissionMutation()
  const {
    control,
    getValues,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      refMissionMedecin: '',
    } as AccepterMissionFormValues,
  })
  const { enqueueSnackbar } = useSnackbar()
  useErrorFormMapper(errorAccepterMission as IQueryErrorResponse, setError, getValues)

  // erreur:
  if (!codeDossier || !codeMission) {
    throw new Error(`Erreur de chargement des données`)
  }

  // format:
  const formId = 'form-accepter-mission'

  // envoi du formulaire:
  const onConfirmAccepterMission = async () => {
    const formValues = getValues() as AccepterMissionFormValues
    const suiviMissionRequest: SuiviMissionRequest = {
      codeDossier,
      codeMission,
      action: SuiviMissionMedecinAction.ACCEPTER,
      refMissionMedecin: trimToUndefined(formValues.refMissionMedecin),
    }

    await postSuiviMission(suiviMissionRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar('La mission a été acceptée', { variant: 'success' })
      })
      .catch((response: IQueryErrorResponse) => {
        if (response.status === 500) {
          enqueueSnackbar(`Erreur serveur lors de l'acceptation de la mission`, { variant: 'error' })
        } else {
          enqueueSnackbar(response.data?.message, { variant: 'error' })
        }
      })
  }

  // rendu:
  return (
    <form id={formId} name={formId}>
      <Stack paddingTop={2}>
        <Controller
          name="refMissionMedecin"
          control={control}
          defaultValue={initRefMissionMedecin}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="ref-mission-medecin"
              label="Ma référence pour la mission (facultatif)"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              fullWidth
              fieldError={error}
            />
          )}
        />
        <ButtonsStack>
          <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
          <ValiderButton onClick={onConfirmAccepterMission} loading={loadingAccepterMission} disabled={!isValid}>
            Confirmer mon choix
          </ValiderButton>
        </ButtonsStack>
      </Stack>
    </form>
  )
}
