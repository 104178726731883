import React from 'react'
import uuid from 'react-uuid'
import { Grid, Stack } from '@mui/material'
import { BluePaper, DividerFormButton, SelectInputReferentiel } from 'plateforme/components'

interface ReadOnlyLesionsProps {
  lesions: string[]
}

export default function ReadOnlyLesionsEntreprise({ lesions }: ReadOnlyLesionsProps) {
  return (
    <>
      <Grid item xs={12}>
        <DividerFormButton title="Codes lésions" readOnly />
      </Grid>
      <Grid item xs={12}>
        {lesions.length === 0 ? (
          <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>Aucune lésion</BluePaper>
        ) : (
          <BluePaper>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {lesions.map((item, index) => {
                return (
                  <li key={uuid()}>
                    <Stack direction="row" spacing={3.5} marginBottom={index === lesions.length - 1 ? 0 : 2.5}>
                      <SelectInputReferentiel
                        readOnly
                        id="codes-lesion"
                        label="Code Lésion"
                        value={item}
                        referentielName="lesion"
                        fullWidth
                      />
                    </Stack>
                  </li>
                )
              })}
            </ul>
          </BluePaper>
        )}
      </Grid>
    </>
  )
}
