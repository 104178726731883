import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import { trimToUndefined } from 'plateforme/services/utils'
import createUploader from 'plateforme/store/slices/uploadSlice/uploadSlice'
import { toUploadDossierDocumentUrl } from 'medecin/MedecinApp'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import StartUploadDocumentDossierConfig from './startUploadDocumentDossierConfig'

export const documentUploadSliceName = 'documentUpload'
const { slice, actions, reducer } = createUploader<DocumentUpload, DocumentTeleverse>(documentUploadSliceName)

export const documentDossierUploadSlice = slice

export const {
  add: addDocumentUpload,
  change: changeDocumentUpload,
  changeIsErrorUploads,
  changeIsSuccessUploads,
  remove: removeDocumentUpload,
  clear: clearDocumentUpload,
  setValid: setDocumentUploadValid,
} = actions

export const startUploadDocument = ({ codeDossier, uploadFileMap }: StartUploadDocumentDossierConfig) =>
  actions.startUpload({
    uploadUrl: toUploadDossierDocumentUrl(codeDossier),
    toFormData: (upload, file) => {
      const formData = new FormData()
      formData.append(
        'metadata',
        JSON.stringify({
          typeDocument: upload.typeDocument,
          nomDocument: trimToUndefined(upload.nomDocument),
          dateDocument: upload.dateDocument,
        })
      )
      formData.append('file', file, upload.nomFichier)
      return formData
    },
    toUploadResponse: (status, response) => {
      if (status === 201) {
        return { type: 'SUCCESS', status, body: response as DocumentTeleverse }
      }
      return {
        type: 'ERROR',
        status,
        body: {
          code: 'réponse inattendue',
          message: JSON.stringify(response),
        },
      }
    },
    uploadFileMap,
  })

export default reducer
