import * as React from 'react'
import { Avatar, SvgIconProps, useTheme } from '@mui/material'
import AccessibleIcon from '@mui/icons-material/Accessible'

export default function HandicapGraveRoundedIcon(props: SvgIconProps) {
  const { sx: otherSx, ...other } = props
  const theme = useTheme()

  const iconSx = { color: theme.palette.primary.contrastText, fontSize: 16, ...otherSx }

  return (
    <Avatar sx={{ margin: '6px 2px 6px 2px', width: 20, height: 20, bgcolor: theme.palette.info.main }}>
      <AccessibleIcon sx={iconSx} {...other} />
    </Avatar>
  )
}
