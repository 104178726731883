import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { RevisionDossierRequest } from 'plateforme/store/types/dossier'
import DossierAdmin, {
  DossierAdminFilter,
  DossierAdminList,
  DossierAdminSortType,
  DossierRequest,
  HistoriqueDossierAdmin,
} from '../types/dossierAdmin'

export const dossierAdminApi = createApi({
  reducerPath: 'dossierAdminApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['Dossier', 'Mission'],
  endpoints: (builder) => ({
    getListeDossier: builder.query<DossierAdminList, void>({
      query: () => 'admin/dossier/',
    }),
    getDossierById: builder.query<DossierAdmin, DossierRequest>({
      query: ({ codeDossier }) => `admin/dossier/${codeDossier}`,
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
    getHistoriqueDossier: builder.query<HistoriqueDossierAdmin[], DossierRequest>({
      query: ({ codeDossier }) => `admin/dossier/${codeDossier}/historique`,
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
    getRechercheDossierAdmin: builder.query<
      PageRecherche<DossierAdmin>,
      PageRechercheRequest<DossierAdminFilter, DossierAdminSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'admin/dossier',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    getRevisionDossier: builder.query<DossierAdmin, RevisionDossierRequest>({
      query: ({ codeDossier, codeMission }) => ({
        url: `admin/dossier/${codeDossier}/revision`,
        params: {
          codeMission,
        },
      }),
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
  }),
})

export const {
  useGetRechercheDossierAdminQuery,
  useGetDossierByIdQuery,
  useGetHistoriqueDossierQuery,
  useGetRevisionDossierQuery,
} = dossierAdminApi
