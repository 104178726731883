import React from 'react'
import { FieldError, Noop } from 'react-hook-form'
import { TextFieldProps } from '@mui/material'
import { DesktopDateTimePicker, DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { EditTextField } from '../index'
import ReadOnlyTextField from './ReadOnlyTextField'

type DocumentDateTimePickerProps<TInputDate, TDate> = Pick<
  DateTimePickerProps<TInputDate, TDate>,
  'label' | 'value' | 'onChange' | 'disabled' | 'readOnly'
> & {
  onBlur?: Noop
  InputProps: TextFieldProps
  fieldError?: FieldError
}

export default function DateTimePickerInput({
  label,
  value,
  onBlur,
  onChange,
  disabled,
  readOnly,
  InputProps,
  fieldError,
}: DocumentDateTimePickerProps<Date, Date>) {
  if (readOnly) {
    return (
      <ReadOnlyTextField
        fullWidth={InputProps.fullWidth}
        id={InputProps.id}
        value={formatDateTimeFR(value) ?? ' '}
        label={label}
      />
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <DesktopDateTimePicker
        inputFormat="dd/MM/yyyy HH:mm:ss"
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
        readOnly={readOnly}
        views={['year', 'day', 'hours', 'minutes', 'seconds']}
        renderInput={(params) => (
          <EditTextField
            readOnly={readOnly}
            label={label}
            onBlur={onBlur}
            {...params}
            {...InputProps}
            inputProps={{
              ...params.inputProps,
              placeholder: 'jj/MM/aaaa hh:mm:ss',
            }}
            fieldError={fieldError}
            value={readOnly && value === null ? ' ' : value}
            sx={{
              '& .MuiInputAdornment-root': {
                marginRight: '4px',
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
