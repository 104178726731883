import React from 'react'
import { Stack, Typography } from '@mui/material'
import { CheckCircle, Cancel, BuildCircle, DeleteForever, PlaylistAddCheckCircle } from '@mui/icons-material'

interface LabelIconFieldProps {
  iconType?: 'edit' | 'cancel' | 'check' | 'doubleCheck' | 'delete'
  label?: string
  iconSize?: number
  iconColor?: string
}

export default function LabelIconField({ label, iconType, iconSize, iconColor }: LabelIconFieldProps) {
  function icon() {
    const fontSize = iconSize
    const color = iconColor ?? 'secondary.main'
    const bgcolor = 'secondary.contrastText'
    switch (iconType) {
      case 'edit':
        return <BuildCircle sx={{ color, bgcolor, fontSize }} />
      case 'check':
        return <CheckCircle sx={{ color, bgcolor, fontSize }} />
      case 'doubleCheck':
        return <PlaylistAddCheckCircle sx={{ color, bgcolor, fontSize }} />
      case 'delete':
        return <DeleteForever sx={{ color, bgcolor, fontSize }} />
      case 'cancel':
      default:
        return <Cancel sx={{ color, bgcolor, fontSize }} />
    }
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {icon()}
      <Typography>{label}</Typography>
    </Stack>
  )
}
