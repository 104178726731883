import * as React from 'react'
import { Edit } from '@mui/icons-material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export default function ModifierButton(props: LoadingButtonProps) {
  const { color, children, variant, ...other } = props
  return (
    <LoadingButton
      variant={variant ?? 'contained'}
      color={color ?? 'primary'}
      startIcon={<Edit />}
      loadingPosition="start"
      {...other}
    >
      {children}
    </LoadingButton>
  )
}
