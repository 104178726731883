import React, { useMemo } from 'react'

import { useErrorHandler } from 'react-error-boundary'
import { Grid } from '@mui/material'

import { Dommages, DommageField, TypeDommage } from 'admin/store/types/dommagesAdmin'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { capitalizeFirst, toArray } from 'plateforme/services/utils'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { BluePaper, TitleChip } from 'plateforme/components'
import { determineLibelleRapport } from 'plateforme/services/rapport.services'
import ReadOnlyDommageConclusions from './ReadOnlyDommageConclusions'
import ReadOnlyDommageRapportConseil from './ReadOnlyDommageRapportConseil'

interface DommagesFieldsProps {
  dommages: Dommages
  typeDommage: TypeDommage
  typeRapport: TypeRapport
  isRapportConseilActive?: boolean
}

export default function ReadOnlyDommagesComponent({
  dommages,
  typeDommage,
  typeRapport,
  isRapportConseilActive,
}: DommagesFieldsProps) {
  const { data, error, isSuccess, isError } = useGetReferentielQuery()

  // handle error of useGetReferentielQuery:

  const handleError = useErrorHandler()
  if (isError) {
    handleError({
      errorApi: error,
      title: 'Erreur chargement des données référentiel',
      api: 'GetReferentiel',
    } as FallbackError)
  }

  const libelleConclusion = determineLibelleRapport(typeRapport)

  const formatedDommages = useMemo(() => {
    const dommagePredefini = isSuccess && data.dommagePredefini ? data.dommagePredefini : []
    return toArray(dommages)
      .map((formatedDommage) => ({
        ...formatedDommage,
        label: dommagePredefini.find((dp) => dp.code === formatedDommage.fieldName)?.label,
        typeDommage: dommagePredefini.find((dp) => dp.code === formatedDommage.fieldName)?.typeDommage,
      }))
      .filter((d) => d.typeDommage === typeDommage)
  }, [data?.dommagePredefini, dommages, isSuccess, typeDommage])

  if (formatedDommages.length === 0) {
    return (
      <Grid container marginBottom={3}>
        <Grid item xs={12}>
          <BluePaper sx={{ fontStyle: 'italic', paddingTop: 3.5, textAlign: 'center' }}>Aucun dommage</BluePaper>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {isRapportConseilActive && (
        <Grid container marginBottom={3}>
          <Grid item xs={6}>
            <TitleChip title={capitalizeFirst(libelleConclusion)} />
          </Grid>
          <Grid item xs={6}>
            <TitleChip title="Rapport de conseil" />
          </Grid>
        </Grid>
      )}
      <Grid container marginBottom={3}>
        {formatedDommages &&
          formatedDommages?.map((dommage: DommageField) => (
            <>
              <Grid item xs={isRapportConseilActive ? 6 : 12}>
                <BluePaper>
                  <ReadOnlyDommageConclusions
                    isRapportConseilActive={isRapportConseilActive}
                    dommage={dommage}
                    key={dommage.fieldName}
                    typeRapport={typeRapport}
                  />
                </BluePaper>
              </Grid>
              {isRapportConseilActive && (
                <Grid item xs={6}>
                  <BluePaper>
                    <ReadOnlyDommageRapportConseil
                      dommage={dommage}
                      key={dommage.fieldName}
                      typeRapport={typeRapport}
                    />
                  </BluePaper>
                </Grid>
              )}
            </>
          ))}
      </Grid>
    </>
  )
}
