import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import Utilisateur, { AuthDownloadRequest } from '../types/utilisateur'
import transmedApiUrl from './transmedApi'

export const utilisateurApi = createApi({
  reducerPath: 'utilisateurApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['Utilisateur'],
  endpoints: (builder) => ({
    getUtilisateurActif: builder.query<Utilisateur, void>({
      query: () => 'utilisateur',
      providesTags: ['Utilisateur'],
    }),
    postAuthDownload: builder.mutation<void, AuthDownloadRequest>({
      query: (body) => {
        return {
          url: 'utilisateur/auth/telechargement',
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { useGetUtilisateurActifQuery, usePostAuthDownloadMutation } = utilisateurApi
