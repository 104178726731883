import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid } from '@mui/material'
import {
  AnnulerButton,
  ValiderButton,
  DatePickerInput,
  EditTextField,
  ButtonsStack,
  AreaLoading,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import Referentiel from 'plateforme/store/types/referentiel'
import { determineConsentementRequis } from 'plateforme/services/dossier.services'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import { usePutSauvegarderConclusionMutation } from 'medecin/store/apis/dossierMedecinApi'
import { SauvegarderConclusionRequest } from 'medecin/store/types/rapportConclusionMedecin'

interface CreerRapportFormProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
  typeRapport: TypeRapport
  successMsg: string
  errorMsg: string
  onSuccess: VoidFunction
  onCancel: VoidFunction
  referentiel?: Referentiel
}

type CreerRapportFormValues = {
  consentement: boolean
  refConsentement: string
  dateExamen: Date | null
  dateConsolidationDef: Date | null
}

export default function CreerRapportForm({
  dossier,
  mission,
  typeRapport,
  successMsg,
  errorMsg,
  onSuccess,
  onCancel,
}: Omit<CreerRapportFormProps, 'referentiel'>) {
  const { data: referentiel, isLoading } = useGetReferentielQuery()

  if (isLoading) {
    return <AreaLoading height={100} />
  }
  return (
    <CreerRapportFormLoaded
      dossier={dossier}
      mission={mission}
      typeRapport={typeRapport}
      successMsg={successMsg}
      errorMsg={errorMsg}
      onSuccess={onSuccess}
      onCancel={onCancel}
      referentiel={referentiel}
    />
  )
}

function CreerRapportFormLoaded({
  dossier,
  mission,
  typeRapport,
  successMsg,
  errorMsg,
  onSuccess,
  onCancel,
  referentiel,
}: CreerRapportFormProps) {
  // props:
  const { code: codeDossier } = dossier
  const { code: codeMission, dateExamenPrevue } = mission

  // error:
  if (!codeDossier || !codeMission) {
    throw new Error(`Erreur de chargement des données`)
  }

  // hooks:
  const [putSauvegarderConclusion, { error: errorSauvegarderConclusion, isLoading: isLoadingSauvegarderConclusion }] =
    usePutSauvegarderConclusionMutation()
  const { enqueueSnackbar } = useSnackbar()
  const {
    control,
    getValues,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      consentement: false,
      refConsentement: '',
      dateExamen: dateExamenPrevue ?? null,
      dateConsolidationDef: null,
    } as CreerRapportFormValues,
  })
  useErrorFormMapper(errorSauvegarderConclusion as IQueryErrorResponse, setError, getValues)

  // format:
  const consentementRequis =
    determineConsentementRequis(dossier?.expertise?.cadreExpertise, referentiel) && typeRapport !== TypeRapport.CARENCE
  const checkConsentementValide = (consentement: boolean) => {
    if (consentementRequis && !consentement) {
      return 'Merci de renseigner la case à cocher concernant le RGPD'
    }
    return true
  }
  const formId = 'form-creer-rapport'

  // submit form:
  const onConfirm = async () => {
    const formValues = getValues() as CreerRapportFormValues
    const request: SauvegarderConclusionRequest = {
      codeDossier,
      codeMission,
      typeRapport,
      consentement: consentementRequis ? formValues.consentement : undefined,
      refConsentement: consentementRequis ? trimToUndefined(formValues.refConsentement) : undefined,
      dateExamen: formatDateISO(formValues.dateExamen),
      dateConsolidationDef: formatDateISO(formValues.dateConsolidationDef),
      validation: false,
    }

    await putSauvegarderConclusion(request)
      .unwrap()
      .then(() => {
        enqueueSnackbar(successMsg, { variant: 'success' })
        onSuccess()
      })
      .catch(() => {
        enqueueSnackbar(errorMsg, { variant: 'error' })
      })
  }

  // render:
  return (
    <form id={formId} name={formId} style={{ width: '100%' }}>
      <Grid container paddingTop={0}>
        {consentementRequis && (
          <>
            <Grid item xs={12}>
              <Controller
                name="consentement"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl error={error !== undefined}>
                    <FormGroup>
                      <FormControlLabel
                        labelPlacement="start"
                        label="RGPD : Avez-vous recueilli le consentement de la personne à examiner ?"
                        control={
                          <Checkbox
                            id="consentement"
                            name="consentement"
                            checked={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            sx={{ padding: 0, marginX: 1.5, marginTop: 1, display: 'block' }}
                          />
                        }
                      />
                    </FormGroup>
                    {error?.message && <FormHelperText sx={{ marginLeft: '35px' }}>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
                rules={{
                  validate: (value: boolean) => checkConsentementValide(value),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="refConsentement"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="ref-consentement"
                    label="Référence du recueil de consentement"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
          </>
        )}
        {typeRapport !== TypeRapport.CARENCE && (
          <Grid item xs={12}>
            <Controller
              name="dateExamen"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <DatePickerInput
                  InputProps={{
                    id: 'date-examen',
                    fullWidth: true,
                  }}
                  label="Date d'examen"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fieldError={error}
                />
              )}
            />
          </Grid>
        )}
        {typeRapport === TypeRapport.CONCLUSION_DEFINITIVE && (
          <Grid item xs={12}>
            <Controller
              name="dateConsolidationDef"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <DatePickerInput
                  InputProps={{
                    id: 'id-date-debut',
                    fullWidth: true,
                  }}
                  label="Date Consolidation"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fieldError={error}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ButtonsStack>
            <AnnulerButton onClick={onCancel} loading={isLoadingSauvegarderConclusion}>
              Annuler
            </AnnulerButton>
            <ValiderButton onClick={onConfirm} disabled={!isValid} loading={isLoadingSauvegarderConclusion}>
              Confirmer
            </ValiderButton>
          </ButtonsStack>
        </Grid>
      </Grid>
    </form>
  )
}
