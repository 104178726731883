import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { NoRowsOverlay } from 'plateforme/components'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import NotificationsMailsRow from './NotificationsMailsRow'

interface NotificationsMailPartProps {
  readOnly?: boolean
}

export default function NotificationsMailsPart({ readOnly }: NotificationsMailPartProps) {
  const { getValues } = useFormContext()
  const notificationsValues = getValues('parametrage.notifications')

  return (
    <Card>
      <CardHeader title="Paramétrage des notifications mails - cycle de vie dossier / mission" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Notification</TableCell>
                    <TableCell>BAL Générale</TableCell>
                    <TableCell>BAL Service</TableCell>
                    <TableCell>BAL Gestionnaire en charge du dossier</TableCell>
                  </TableRow>
                </TableHead>
                {notificationsValues ? (
                  <TableBody>
                    {Object.entries(notificationsValues).map(([key]) => {
                      return (
                        <NotificationsMailsRow
                          key={key}
                          controllerName={`parametrage.notifications.${key}`}
                          rowTitle={key}
                          readOnly={readOnly}
                        />
                      )
                    })}
                  </TableBody>
                ) : (
                  <NoRowsOverlay />
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
