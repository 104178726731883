import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { Stack } from '@mui/material'
import { AreaLoading, FallbackError } from 'plateforme/components'
import ActualitesPart from 'plateforme/parts/ActualitesPart'
import MessageAccueilPart from 'plateforme/parts/MessageAccueilPart'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import TableauDeBordMedecinPart from 'medecin/parts/home/TableauDeBordMedecinPart'
import DerniersDossiersMedecinPart from 'medecin/parts/home/DerniersDossiersMedecinPart'
import { useGetListeHabilitationsMedecinQuery } from 'medecin/store/apis/habilitationMedecinApi'
import MessageAucuneHabilitation from 'medecin/pages/MessageAucuneHabilitation/MessageAucuneHabilitation'
import { hasHabilitationActiveRequest, hasHabilitationInactiveRequest } from 'medecin/store/types/habilitationMedecin'
import DernieresNotificationsMedecinPart from 'medecin/parts/home/DernieresNotificationsMedecinPart'

export default function HomeMedecinPage() {
  const handleError = useErrorHandler()
  const {
    data: habilitationsActives,
    isError: isErrorHabilitationsActives,
    error: errorHabilitationsActives,
    isLoading: isLoadingHabilitationsActives,
    isFetching: isFetchingHabilitationsActives,
  } = useGetListeHabilitationsMedecinQuery(hasHabilitationActiveRequest())
  if (isErrorHabilitationsActives) {
    handleError({
      errorApi: errorHabilitationsActives,
      title: 'Erreur chargement des habilitations actives',
      api: 'GetListeHabilitationsMedecin',
    } as FallbackError)
  }

  const {
    data: habilitationsInactives,
    isError: isErrorHabilitationsInactives,
    error: errorHabilitationsInactives,
    isLoading: isLoadingHabilitationsInactives,
    isFetching: isFetchingHabilitationsInactives,
  } = useGetListeHabilitationsMedecinQuery(hasHabilitationInactiveRequest())
  if (isErrorHabilitationsInactives) {
    handleError({
      errorApi: errorHabilitationsInactives,
      title: 'Erreur chargement des habilitations inactives',
      api: 'GetListeHabilitationsMedecin',
    } as FallbackError)
  }

  const {
    data: utilisateur,
    isError: isErrorUtilisateur,
    error: errorUtilisateur,
    isLoading: isLoadingUtilisateur,
    isFetching: isFetchingUtilisateur,
  } = useGetUtilisateurActifQuery()
  if (isErrorUtilisateur) {
    handleError({
      errorApi: errorUtilisateur,
      title: `Erreur chargement de l'utilisateur`,
      api: 'GetUtilisateurActif',
    } as FallbackError)
  }

  const hasHabilitationActive = (habilitationsActives?.totalItems ?? 0) > 0
  const hasHabilitationInactive = (habilitationsInactives?.totalItems ?? 0) > 0

  // isMedecinConnu : pour détérminer si le medecin a été habilité dans la plateforme, sans tenir en compte si c'est active ou pas
  const isMedecinConnu = hasHabilitationActive || hasHabilitationInactive

  const loading =
    isLoadingUtilisateur ||
    isFetchingUtilisateur ||
    isLoadingHabilitationsActives ||
    isFetchingHabilitationsActives ||
    isLoadingHabilitationsInactives ||
    isFetchingHabilitationsInactives

  return (
    <Stack>
      {/* "Bonjour ..." */}
      <MessageAccueilPart utilisateur={utilisateur} loading={loading} />
      {/* actualités de la plateforme */}
      <ActualitesPart loading={loading} />
      {/* SES : si aucune habilitation il faut un message d'avertissement "Aucun assureur ne vous a ajouté ..." */}
      {loading && <AreaLoading height={200} />}
      {!loading && !isMedecinConnu && <MessageAucuneHabilitation />}
      {!loading && isMedecinConnu && (
        <>
          {/* tableau de bord (4 éléments d'accès rapides) */}
          <TableauDeBordMedecinPart />
          {/* tableau des notifications */}
          <DernieresNotificationsMedecinPart loading={loading} />
          {/* tableau des derniers dossiers consultés */}
          <DerniersDossiersMedecinPart />
        </>
      )}
    </Stack>
  )
}
