import * as React from 'react'
import { SvgIconProps, Tooltip, useTheme } from '@mui/material'
import AccessibleIcon from '@mui/icons-material/Accessible'
import MissionPlateforme from 'plateforme/store/types/mission'
import { determineTitleHandicapGrave } from 'plateforme/services/mission.services'

export default function HandicapGraveIcon(props: SvgIconProps & { mission?: MissionPlateforme; withMargin?: boolean }) {
  const { withMargin, mission, sx: otherSx, ...other } = props
  const theme = useTheme()

  const tooltipTitle = determineTitleHandicapGrave(mission)

  const baseSx = { fontSize: 32, ...otherSx }
  const iconSx = withMargin ? { margin: theme.spacing(1.5), ...baseSx } : { ...baseSx }
  return (
    <Tooltip title={tooltipTitle} placement="right">
      <AccessibleIcon sx={iconSx} {...other} />
    </Tooltip>
  )
}
