import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useErrorHandler } from 'react-error-boundary'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { FallbackError } from 'plateforme/components'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import RechercheHabilitationsEntreprisePart from 'assureur/parts/rechercheHabilitation/RechercheHabilitationsEntreprisePart'
import { useGetListeHabilitationsActivesEntrepriseQuery } from 'assureur/store/apis/habilitationEntrepriseApi'
import ListeHabilitationsEntreprisePart from 'assureur/parts/rechercheHabilitation/ListeHabilitationsEntreprisePart'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { getHabilitationCriteria } from 'assureur/store/slices/searchCriteriaSlice/searchCriteriaEntrepriseSlice'

export default function RechercheHabilitationsEntreprisePage() {
  const { data: profilEntreprise, isError: isErrorProfil, error: errorProfil } = useGetProfilEntrepriseQuery()
  const handleError = useErrorHandler()

  // handle error of useGetProfilEntrepriseQuery:
  if (isErrorProfil) {
    handleError({
      errorApi: errorProfil,
      title: 'Erreur chargement des données profil',
      api: 'getProfilEntreprise',
    } as FallbackError)
  }

  const defaultHabilitationCriteria = useSelector(getHabilitationCriteria)
  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSearch,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeHabilitationsActivesEntrepriseQuery, {
    defaultCriteria: defaultHabilitationCriteria,
  })

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('La recherche des habilitations a échoué', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <Stack>
      <NavigationPart label="Mon réseau de médecins" />
      <RechercheHabilitationsEntreprisePart
        initSearch={initSearch}
        search={search}
        searchError={error}
        profilEntreprise={profilEntreprise}
      />
      <ListeHabilitationsEntreprisePart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}
