import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { EditTextField } from 'plateforme/components'
import { clearRapportConseilErrors } from 'plateforme/services/utils'

interface DommageFieldCommentaireProps {
  name: string
  code: string
  readOnly?: boolean
  disabled?: boolean
}

export default function DommageFieldCommentaire({ name, code, readOnly, disabled }: DommageFieldCommentaireProps) {
  const { clearErrors, control, getFieldState, resetField, setError, watch } = useFormContext()

  const fieldName = `dommages.${code}.rapportConseil`
  const errorPath = `${fieldName}._error`
  const { error: groupError } = getFieldState(errorPath)

  // remplace l'erreur global (dommages.${code}.rapportConseil) par l'erreur sur le commentaire
  useEffect(() => {
    if (groupError?.message) {
      clearErrors(errorPath)
      setError(`${fieldName}.commentaire`, groupError)
    }
  }, [clearErrors, errorPath, fieldName, groupError, setError])

  return (
    <Controller
      name={`${name}.commentaire`}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <EditTextField
          disabled={disabled}
          fullWidth
          minRows={3}
          multiline
          id={code}
          value={value}
          onBlur={onBlur}
          onChange={(e) => {
            clearRapportConseilErrors(resetField, watch, clearErrors)
            onChange(e)
          }}
          label="Commentaire"
          fieldError={error}
          readOnly={readOnly}
        />
      )}
    />
  )
}
