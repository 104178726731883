import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import { trimToUndefined } from 'plateforme/services/utils'
import createUploader from 'plateforme/store/slices/uploadSlice/uploadSlice'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { toUploadRapportDocumentUrl } from 'medecin/MedecinApp'
import StartUploadDocumentMissionConfig from './startUploadDocumentMissionConfig'

export const documentRapportUploadSliceName = 'documentRapportUpload'
const { actions, reducer } = createUploader<DocumentUpload, DocumentTeleverse>(documentRapportUploadSliceName)

export const {
  add: addDocumentRapportUpload,
  change: changeDocumentRapportUpload,
  remove: removeDocumentRapportUpload,
  clear: clearDocumentRapportUpload,
  setValid: setDocumentRapportUploadValid,
} = actions

export const startUploadDocumentRapport = ({
  codeDossier,
  codeMission,
  uploadFileMap,
}: StartUploadDocumentMissionConfig) => {
  if (!codeDossier || !codeMission) {
    if (!codeDossier || !codeMission) {
      throw new Error(`impossible d'uploader le document, codeDossier ou codeMission est null`)
    }
  }

  return actions.startUpload({
    uploadUrl: toUploadRapportDocumentUrl(codeDossier, codeMission),
    toFormData: (upload, file) => {
      const formData = new FormData()
      formData.append(
        'metadata',
        JSON.stringify({
          typeDocument: upload.typeDocument,
          nomDocument: trimToUndefined(upload.nomDocument),
          dateDocument: upload.dateDocument,
        })
      )
      formData.append('file', file, upload.nomFichier)
      return formData
    },
    toUploadResponse: (status, response) => {
      if (status === 201) {
        return { type: 'SUCCESS', status, body: response as DocumentTeleverse }
      }
      return {
        type: 'ERROR',
        status,
        body: {
          code: 'réponse inattendue',
          message: JSON.stringify(response),
        },
      }
    },
    uploadFileMap,
  })
}

export default reducer
