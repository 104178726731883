import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { trimToUndefined } from 'plateforme/services/utils'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { MissionWithIndexes, determineLibelleStatutMissionAccordion } from 'plateforme/services/mission.services'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import WorkflowMissionEntreprisePart from './WorkflowMissionEntreprisePart'

export interface SuiviMissionAccordionProps {
  dossier: DossierEntreprise
  missionWithIndexes: MissionWithIndexes
  expanded?: boolean
  onChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
}

export default function SuiviMissionAccordion({
  dossier,
  missionWithIndexes,
  expanded,
  onChange,
}: SuiviMissionAccordionProps) {
  const { mission, indexMission, indexRapport } = missionWithIndexes

  const refMissionEntreprise =
    trimToUndefined(mission.refMissionEntreprise) === undefined ? '' : `- ${mission.refMissionEntreprise}`

  const title = `Mission${indexMission ? ` n°${indexMission}` : ''} ${determineLibelleStatutMissionAccordion(
    mission,
    indexRapport
  )} ${refMissionEntreprise} - ${mission.medecin?.libelle}`
  const prefix = `panel-${mission.code}`

  return (
    <Accordion expanded={expanded} onChange={onChange(mission.code)}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: '#fff' }} />}
        aria-controls={`${prefix}-content`}
        id={`${prefix}`}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#fff' }}>
        <WorkflowMissionEntreprisePart dossier={dossier} mission={mission as MissionEntreprise} />
      </AccordionDetails>
    </Accordion>
  )
}
