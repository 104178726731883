import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { NoRowsOverlay } from 'plateforme/components'
import { makeEllipsis } from 'plateforme/services/utils'
import { LOCALE } from 'plateforme/constantes'
import { ReferentielTransformed } from 'admin/pages/GererNomenclaturesPage/GererNomenclaturesPage'

export interface TableValuesNomenclaturesPartProps {
  motCle: string
  nomenclature: string
  result: ReferentielTransformed[]
}

export default function TableValuesNomenclaturesPart({
  motCle,
  nomenclature,
  result,
}: TableValuesNomenclaturesPartProps) {
  const filterValuesByMotsCles = () => {
    if (motCle && motCle.length >= 3) {
      return result.filter((option) =>
        nomenclature !== '' ? option.type === nomenclature : option.label.toLocaleLowerCase(LOCALE).includes(motCle)
      )
    }

    if (nomenclature !== '' && motCle === '') {
      return result.filter((r) => r.type === nomenclature)
    }

    return result
  }

  const sortByLabelOrCode = (): ((a: ReferentielTransformed, b: ReferentielTransformed) => number) | undefined => {
    return (a, b) => {
      if (a.label) {
        return a.label < b.label ? -1 : 1
      }
      return a.code < b.code ? -1 : 1
    }
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '500px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '20%' }}>Type</TableCell>
            <TableCell sx={{ width: '20%', maxWidth: '200px' }}>Clé</TableCell>
            <TableCell sx={{ width: '40%' }}>Libellé</TableCell>
            <TableCell sx={{ width: '20%' }}>Actif</TableCell>
          </TableRow>
        </TableHead>
        {filterValuesByMotsCles().length > 0 ? (
          <TableBody>
            {filterValuesByMotsCles()
              .sort(sortByLabelOrCode())
              .map((value) => (
                <TableRow key={value.code}>
                  <TableCell sx={{ width: '20%' }} component="th" scope="row">
                    {value.type}
                  </TableCell>
                  <TableCell sx={{ width: '20%', maxWidth: '200px' }} component="th" scope="row">
                    {makeEllipsis(value.code, 20)}
                  </TableCell>
                  <TableCell sx={{ width: '40%' }} component="th" scope="row">
                    {value.label}
                  </TableCell>
                  <TableCell sx={{ width: '20%' }} component="th" scope="row">
                    {value.statut === 'ACTIF' ? 'oui' : 'non'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        ) : (
          <NoRowsOverlay />
        )}
      </Table>
    </TableContainer>
  )
}
