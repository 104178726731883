import React from 'react'
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  styled,
  InputBaseComponentProps,
  useMediaQuery,
} from '@mui/material'
import { Create } from '@mui/icons-material'
import { FieldError } from 'react-hook-form'
import theme from 'plateforme/theme'

const ReadOnlyTextFieldStyled = styled(TextField)(() => ({
  '& .MuiFormLabel-root': { color: 'rgba(0, 0, 0, 0.75)' },
  '& label.Mui-focused': {
    color: 'rgba(0, 0, 0, 0.75)',
  },
}))

export type EditTextFieldProps = TextFieldProps & {
  readOnly?: boolean
  fieldError?: FieldError
}

export default function EditTextField({
  type,
  variant,
  readOnly,
  color,
  value,
  helperText,
  error,
  fieldError,
  inputProps,
  onKeyDown,
  onChange,
  required,
  ...other
}: EditTextFieldProps) {
  const TextFieldComponent = readOnly ? ReadOnlyTextFieldStyled : TextField
  const isNumeric = type === 'number'
  const isDecimal = type === 'decimal'

  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const InputProps = readOnly
    ? {
        readOnly,
        value: (!value || value === '') && value !== 0 ? ' ' : value,
      }
    : {
        endAdornment: isLg ? (
          <InputAdornment position="end">
            <Create sx={{ fontSize: '22px' }} />
          </InputAdornment>
        ) : (
          false
        ),
      }

  function handleNumericOrDecimalKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (
      e.key === 'Backspace' ||
      e.key === 'Enter' ||
      e.key === 'Tab' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowLeft' ||
      e.key === 'Control' ||
      e.key === 'Meta' ||
      e.ctrlKey ||
      e.metaKey
    ) {
      return
    }
    if (isNumeric && Number.isNaN(parseInt(e.key, 10))) e.preventDefault()
    if (isDecimal && e.key !== '.' && e.key !== ',' && Number.isNaN(parseInt(e.key, 10))) e.preventDefault()
  }

  function getInputProps(): InputBaseComponentProps {
    return isNumeric ? { inputMode: 'numeric', pattern: '[0-9]*' } : { inputMode: 'numeric', pattern: '[0-9,.]*' }
  }

  return (
    <TextFieldComponent
      type={isNumeric || isDecimal ? 'text' : type}
      color={readOnly ? 'readOnly' : color}
      variant={variant ?? 'filled'}
      InputProps={InputProps}
      value={value}
      helperText={fieldError?.message ?? helperText}
      error={fieldError !== undefined || error}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={isNumeric || isDecimal ? getInputProps() : inputProps}
      onKeyDown={isNumeric || isDecimal ? handleNumericOrDecimalKeyDown : onKeyDown}
      onChange={(e) => {
        if (onChange) {
          if (isDecimal && e.target.value.includes(',')) {
            e.target.value = e.target.value.replace(',', '.')
          }
          if (isNumeric || isDecimal) {
            e.target.value = e.target.value.replace(/[^\d.]/g, '')
          }
          onChange(e)
        }
      }}
      required={!readOnly && required}
      {...other}
    />
  )
}
