/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/query'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import SelectInput, { SelectComponentProps, SelectOption } from './SelectInput'

export type SelectInputWithQueryProps<TData> = Omit<SelectComponentProps, 'options'> & {
  withAffectationCentrale?: boolean
  isAdmin?: boolean
  isIdPourCodeOption?: boolean
  orderBy?: keyof SelectOption
  keepSortServer?: boolean
  orderMode?: 'asc' | 'dsc'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParam?: any
  isLoadingExternal?: boolean
  useQuery: UseQuery<
    QueryDefinition<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
      string,
      TData
    >
  >
  dataTransformer?: (data: TData) => SelectOption[]
}

export default function SelectInputWithQuery<TData>({
  // Le tri est par defaut sur le champ code
  orderBy = 'code',
  // Le tri est par defaut ascendant
  orderMode = 'asc',
  // Activer le tri backend par defaut
  keepSortServer = false,
  queryParam,
  // On ne charge rien depuis l'exterieur par defaut
  isLoadingExternal = false,
  useQuery,
  dataTransformer,
  ...restProps
}: SelectInputWithQueryProps<TData>) {
  const { data, isLoading, isFetching, isError } = useQuery(queryParam ?? {})

  if (isLoadingExternal || isLoading || isFetching || isError) {
    return (
      <SelectInput
        {...restProps}
        optionsLoader={{
          isLoading,
          isError,
        }}
        options={[]}
      />
    )
  }

  const transformed = (dataTransformer && data && dataTransformer(data)) ?? []
  const sorted = keepSortServer
    ? [...transformed]
    : [...transformed].sort((a, b) => a[orderBy].localeCompare(b[orderBy]) * (orderMode === 'asc' ? 1 : -1))

  return <SelectInput {...restProps} options={sorted} />
}
