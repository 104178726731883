import React, { useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import DossierPlateforme from 'plateforme/store/types/dossier'
import MissionPlateforme from 'plateforme/store/types/mission'
import { MessageMissionPlateforme } from 'plateforme/store/types/messageMission'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import MessageBulleListVide from './MessageBulleListVide'
import MessageBulle from './MessageBulle'

type MessageBulleListProps = {
  dossier?: DossierPlateforme
  mission?: MissionPlateforme
  messages?: MessageMissionPlateforme[]
  documents?: DocumentTeleverse[]
  typeUtilisateur?: TypePartenaire
}

export default function MessageBulleList({
  dossier,
  mission,
  messages,
  documents,
  typeUtilisateur,
}: MessageBulleListProps) {
  const gridRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollTop = gridRef.current.scrollHeight
    }
  }, [messages])

  if (messages === undefined || messages.length === 0) {
    return <MessageBulleListVide mission={mission} />
  }

  const sortedMessages = [...messages].reverse()

  return (
    <Grid
      container
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      alignItems="flex-start"
      alignContent="flex-start"
      overflow="auto"
      marginTop={0}
      marginBottom={0}
      paddingRight={2}
      rowSpacing={2.5}
      columnSpacing={0}
      height="100%"
      ref={gridRef}
      sx={{
        scrollBehavior: 'smooth',
      }}
    >
      {sortedMessages.map((message: MessageMissionPlateforme, index: number) => (
        <MessageBulle
          key={message.code}
          isFirst={index === 0}
          typeUtilisateur={typeUtilisateur}
          dossier={dossier}
          mission={mission}
          message={message}
          document={documents?.find((doc) => doc?.code === message?.codeDocument)}
        />
      ))}
    </Grid>
  )
}
