import { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { StatutMission } from 'plateforme/store/types/mission'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import DossierMedecin, { DossierMedecinFilter, DossierMedecinSortType } from 'medecin/store/types/dossierMedecin'

export function missionsAssociees(dossier?: DossierMedecin): MissionMedecin[] | undefined {
  return dossier?.missions?.filter((m) => m.associable)
}

export function derniereMissionAssociee(dossier?: DossierMedecin): MissionMedecin | undefined {
  return missionsAssociees(dossier)?.at(0)
}

// Nombre de dossier en cours de proposition
export const nbDossierEnCoursPropositionRequest = () =>
  ({
    filter: { statuts: [StatutMission.PROPOSEE] },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierMedecinFilter, DossierMedecinSortType>)

// Nombre de dossier de moins de 6 mois EN_DEMANDE_DE_MODIFICATION
export const nbMissionsDemanderModificationRequest = () =>
  ({
    filter: {
      statuts: [StatutMission.EN_DEMANDE_DE_MODIFICATION],
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierMedecinFilter, DossierMedecinSortType>)

// Nombre de missions EN_COURS
export const nbMissionsEnCoursRequest = () =>
  ({
    filter: {
      statuts: [StatutMission.EN_COURS],
    },
    onlyTotal: 1,
  } as PageRechercheRequest<DossierMedecinFilter, DossierMedecinSortType>)
