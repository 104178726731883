import React from 'react'
import { InputAdornment, Link, Tooltip } from '@mui/material'
import { Email } from '@mui/icons-material'
import { EditTextFieldProps } from './EditTextField'
import ReadOnlyTextField from './ReadOnlyTextField'

export type ReadOnlyEmailFieldProps = Omit<EditTextFieldProps, 'value' | 'readOnly'> & {
  value: string | null | undefined
}
export default function ReadOnlyEmailField(props: ReadOnlyEmailFieldProps) {
  const { value, InputProps, ...otherProps } = props
  const { ...otherInputProps } = InputProps ?? {}

  const endAdornment =
    value === undefined || value === null || !value.includes('@') ? (
      <InputAdornment position="start">
        <Tooltip title="Envoi mail non disponible" placement="left">
          <Email />
        </Tooltip>
      </InputAdornment>
    ) : (
      <InputAdornment position="start">
        <Tooltip title="Envoyer mail" placement="left">
          <Link underline="none" color="inherit" href={`mailto:${value}`} target="_blank">
            <Email />
          </Link>
        </Tooltip>
      </InputAdornment>
    )

  return (
    <ReadOnlyTextField
      InputProps={{
        endAdornment,
        readOnly: true,
        ...otherInputProps,
      }}
      value={value ?? ' '}
      {...otherProps}
    />
  )
}
