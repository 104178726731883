import * as React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { AreaLoading, ButtonsStack, ListeButton, NoRowsOverlay } from 'plateforme/components'
import { infoPeriodeActive } from 'plateforme/services/habilitation.services'
import HabilitationMedecin from 'medecin/store/types/habilitationMedecin'
import PageRecherche from 'plateforme/store/types/pageRecherche'

type HabilitationsActivesMedecinPartProps = {
  title?: string
  libelleBouton?: string
  habilitationsPath?: string
  habilitationResponse?: PageRecherche<HabilitationMedecin>
  isLoading?: boolean
  isFetching?: boolean
}

/* Page de profil tableau mes habilitations actives */
export default function HabilitationsActivesMedecinPart({
  title,
  libelleBouton,
  habilitationsPath,
  habilitationResponse,
  isLoading,
  isFetching,
}: HabilitationsActivesMedecinPartProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  if (isLoading || isFetching) {
    return <AreaLoading height={551} />
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const habilitations = habilitationResponse?.items
  const totalHabilitation = habilitations?.length ?? 0

  return (
    <Card>
      <CardHeader title={title ?? 'Mes habilitations actives'} />
      <CardContent>
        <ButtonsStack withGutter>
          {habilitationsPath ? (
            <ListeButton href={habilitationsPath}>{libelleBouton ?? 'Toutes mes habilitations'}</ListeButton>
          ) : (
            <ListeButton href="habilitations">{libelleBouton ?? 'Toutes mes habilitations'}</ListeButton>
          )}
        </ButtonsStack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Entreprise</TableCell>
                <TableCell>Réf. entreprise</TableCell>
                <TableCell>Réf. médecin</TableCell>
                <TableCell>Période d&apos;habilitation</TableCell>
              </TableRow>
            </TableHead>
            {totalHabilitation > 0 ? (
              <TableBody>
                {habilitations
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: HabilitationMedecin) => (
                    <TableRow key={row.code}>
                      <TableCell sx={{ width: '20%' }}>{row.libelle}</TableCell>
                      <TableCell sx={{ width: '20%' }}>{row.codeACPR ?? row.codeSIREN}</TableCell>
                      <TableCell sx={{ width: '20%' }}>{row.numeroHabilitation}</TableCell>
                      <TableCell sx={{ width: '40%' }}>{infoPeriodeActive(row)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              <NoRowsOverlay />
            )}
          </Table>
        </TableContainer>
        {totalHabilitation > 0 && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 50]}
            count={totalHabilitation}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </CardContent>
    </Card>
  )
}
