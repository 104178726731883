import React, { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Chip, Grid, Stack } from '@mui/material'
import {
  AnnulerButton,
  EditTextField,
  MessageAlert,
  SauvegarderButton,
  SelectInputEntreprise,
  StyledDropzone,
} from 'plateforme/components'
import useErrorFormMapper from 'plateforme/hooks/useErrorFormMapper'
import { toArray, trimToUndefined } from 'plateforme/services/utils'
import { ALLOWED_UPLOAD_FORMATS, MAX_UPLOAD_SIZE } from 'plateforme/constantes'
import {
  addDocumentMessageUpload,
  clearDocumentMessageUpload,
  documentMessageUploadSliceName,
  startUploadDocumentMessage,
} from 'medecin/store/slices/document/documentMessageUploadSlice'
import makeUpload from 'plateforme/services/document.services'
import useUpload from 'plateforme/store/hooks/useUpload'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import { UploadState } from 'plateforme/store/slices/uploadSlice'
import { MessageRequest } from 'medecin/store/types/messageMedecin'
import { messageMedecinApi } from 'medecin/store/apis/messageMedecinApi'
import UploadRowMessage from './UploadRowMessage'

type EcrireMessageFormProps = {
  onClose: () => void
}

type MessageFormData = {
  codesEntreprises?: string[]
  objet?: string
  message?: string
}

export default function EcrireMessageForm({ onClose }: EcrireMessageFormProps) {
  // initialisation du formulaire
  const initialValues: MessageFormData = {
    codesEntreprises: [],
    objet: '',
    message: '',
  }

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    reset,
    formState: { isValid },
  } = methods

  // used hooks:
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { filesMapRef, styledDropzoneProps, uploaderState } = useUpload({
    uploadSliceName: documentMessageUploadSliceName,
    makeUpload,
    typeDocument: 'PJ',
    addUploadAction: addDocumentMessageUpload,
    maxSize: MAX_UPLOAD_SIZE,
  })

  const { errorUploads, isErrorUploads, isSuccessUploads } = uploaderState

  useErrorFormMapper({ status: errorUploads?.status, data: { ...errorUploads } }, setError, getValues)

  useEffect(() => {
    dispatch(clearDocumentMessageUpload())
  }, [dispatch])

  useEffect(() => {
    if (uploaderState.isErrorUploads) {
      enqueueSnackbar(`${uploaderState.errorUploads?.message}`, {
        variant: 'error',
      })
    }

    if (uploaderState.isSuccessUploads) {
      enqueueSnackbar(`Votre message a été envoyé`, { variant: 'success' })
      reset(initialValues)
      dispatch(clearDocumentMessageUpload())
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorUploads, isSuccessUploads])

  // handleClick : envoyer message
  const onSubmit = async (data: MessageFormData) => {
    const message: MessageRequest = {
      codesEntreprises: data.codesEntreprises,
      objet: trimToUndefined(data.objet),
      message: trimToUndefined(data.message),
    }

    await dispatch(
      startUploadDocumentMessage({
        message,
        uploadFileMap: filesMapRef.current,
      })
    )
    // invalider le cash pour le lecture des messages
    dispatch(messageMedecinApi.util.invalidateTags([{ type: 'MessageMedecin' }]))
  }

  // handleClick : annuler
  const handleCancel = () => {
    dispatch(clearDocumentMessageUpload())
    reset(initialValues)
    onClose()
  }

  const isTotalPJValide = toArray(filesMapRef.current).length > 3

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="id-form-ecrire-message"
        name="form-ecrire-message"
        style={{ width: '100%' }}
      >
        <Grid container paddingTop={0}>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="codesEntreprises"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectInputEntreprise
                  multiple
                  withColors
                  id="id-entreprise"
                  label="Entreprise(s) destinataire(s)"
                  value={value}
                  onChangeMultiple={onChange}
                  onBlur={onBlur}
                  fullWidth
                  withNoSelectionItem
                  withAllSelectionItem
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="objet"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="id-objet-message"
                  label="Objet"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="message"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="id-texte-message"
                  label="Message"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  minRows={3}
                  multiline
                />
              )}
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Stack>
              <StyledDropzone
                {...styledDropzoneProps}
                accept={ALLOWED_UPLOAD_FORMATS}
                buttonText="Ajouter des pièces jointes"
                multiple
              />
              {uploaderState.uploads.map((upload) => (
                <Stack key={upload.key} direction="row" alignItems="start">
                  <UploadRowMessage documentUpload={upload} disabled={uploaderState.isRunning} />
                  {upload.state === UploadState.DONE && !upload.error && <Chip label="succès" color="success" />}
                </Stack>
              ))}

              {isTotalPJValide && (
                <MessageAlert severity="error">Vous avez dépassé le nombre maximal des pièces jointes (3)</MessageAlert>
              )}
              <Stack direction="row" justifyContent="right">
                {!uploaderState.isRunning && (
                  <>
                    <AnnulerButton onClick={handleCancel}>Annuler</AnnulerButton>
                    <SauvegarderButton type="submit" disabled={!isValid || isTotalPJValide}>
                      Envoyer le message
                    </SauvegarderButton>
                  </>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
