import React from 'react'
import { Alert, Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, MessageAlert, ModifierButton, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import DossierPlateforme from 'plateforme/store/types/dossier'
import { LoadingProps } from 'plateforme/services/utils'
import { TypeLieuExpertise } from 'assureur/store/types/dossierEntreprise'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import LieuExpertiseDialogForm from 'assureur/parts/editionDossier/ExpertiseEtLieuExpertiseForm/LieuExpertise/LieuExpertiseDialogForm'

type LieuExpertisePartProps = {
  dossier?: DossierPlateforme
  withModification?: boolean
}

export default function LieuExpertisePart({
  dossier,
  loading,
  withModification = false,
}: LieuExpertisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={219.5} />
  }
  return <LieuExpertisePartLoaded dossier={dossier} withModification={withModification} />
}

function LieuExpertisePartLoaded({ dossier, withModification }: LieuExpertisePartProps) {
  const { confirm, confirmDialog, closeConfirmDialog } = useConfirmDialog()

  // erreur:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { expertise } = dossier

  // render:
  if (!expertise) {
    return null
  }

  const handleModifierLieuExpertise = () => {
    confirm({
      title: `Modifier lieu d'expertise`,
      confirmMsg: '',
      withForm: true,
      maxWidth: 'md',
      form: <LieuExpertiseDialogForm dossier={dossier} onClose={closeConfirmDialog} />,
    })
  }

  return (
    <Card>
      {confirmDialog}
      <CardHeader title="Lieu d'expertise" />
      <CardContent>
        {expertise?.typeLieuExpertise === TypeLieuExpertise.CABINET && (
          <MessageAlert>Expertise au cabinet du médecin</MessageAlert>
        )}
        {expertise?.typeLieuExpertise === TypeLieuExpertise.NR_A_DEFINIR && (
          <Alert
            severity="info"
            action={
              withModification && (
                <ModifierButton id="sauvegarder" variant="outlined" onClick={handleModifierLieuExpertise}>
                  Modifier
                </ModifierButton>
              )
            }
          >
            Non renseigné / À définir
          </Alert>
        )}
        {expertise?.typeLieuExpertise === TypeLieuExpertise.AUTRE && (
          <Grid container>
            <Grid item xs={12}>
              <MessageAlert>
                L&apos;expertise se fera <strong>à une adresse spécifique</strong>
              </MessageAlert>
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyTextField
                fullWidth
                minRows={3}
                multiline
                id="expertise-adresse-complete"
                value={expertise.adresseComplete}
                label="Lieu de l'expertise"
              />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyEmailField id="expertise-expertise-mail" label="Adresse mail" value={expertise.mail} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyTextField
                id="expertise-telephone-portable"
                label="Tél. portable"
                value={expertise.telephoneMobile}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyTextField
                id="expertise-telephone-fixe"
                label="Tél. fixe"
                value={expertise.telephoneFixe}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
