import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Stack, styled, Typography } from '@mui/material'
import { ConnexionMenuButton, TransmedLogo } from 'plateforme/components'

const BoxStyled = styled(Box)(() => ({
  backgroundColor: '#ffffff',
}))

export default function LogoutPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const onLogin = async () => {
    return navigate('/', { replace: true, state: { from: location } })
  }
  return (
    <Box
      margin={0}
      padding={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100vh - 18px)"
    >
      <BoxStyled>
        <Stack direction="row" alignItems="center" justifyContent="center" padding={4}>
          <TransmedLogo />
        </Stack>
        <Typography variant="h1" sx={{ textAlign: 'center' }} padding={4}>
          Vous avez bien été déconnecté de la plateforme TransMED.
        </Typography>
        <Box display="flex" justifyContent="center" padding={4}>
          <ConnexionMenuButton onClick={onLogin}>Connexion</ConnexionMenuButton>
        </Box>
      </BoxStyled>
    </Box>
  )
}
