import { createAsyncThunk, PayloadActionCreator } from '@reduxjs/toolkit'
import type { RootState } from 'plateforme/store'
import sendRequest, { UploadResponse } from '../http'
import { Upload, UploaderState, UploadFileMap, UploadState } from '../types'

type UploaderThunkConfig<TUpload> = {
  sliceName: string
  change: PayloadActionCreator<TUpload>
  changeIsErrorUploads: PayloadActionCreator<boolean>
  changeIsSuccessUploads: PayloadActionCreator<boolean>
}

export type StartUploadPayload<TUpload, TBody> = {
  uploadUrl: string
  uploadFileMap: UploadFileMap
  toFormData: (upload: TUpload, file: File) => FormData
  toUploadResponse: (status: number, response: object) => UploadResponse<TBody>
}

export default function createUploaderThunk<TUpload extends Upload, TBody>({
  sliceName,
  change,
  changeIsErrorUploads,
  changeIsSuccessUploads,
}: UploaderThunkConfig<TUpload>) {
  return createAsyncThunk<void, StartUploadPayload<TUpload, TBody>>(
    `${sliceName}/startUpload`,
    async ({ uploadFileMap, toFormData, toUploadResponse, uploadUrl }, thunkApi) => {
      const { [sliceName as keyof RootState]: state } = thunkApi.getState() as RootState
      const { uploads } = state as UploaderState<TUpload>

      const firstIdx = uploads.findIndex((u) => u.state !== UploadState.DONE)
      for (let i = firstIdx; i >= 0 && i < uploads.length; i += 1) {
        const upload = uploads[i]
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { error, ...uploadWithoutErr } = upload

        if (!upload.isValid) {
          return
        }

        thunkApi.dispatch(change({ ...uploadWithoutErr, progress: 0, state: UploadState.IN_PROGRESS }))

        // eslint-disable-next-line no-await-in-loop
        const response = await sendRequest<TBody>({
          url: uploadUrl,
          state: thunkApi.getState() as RootState,
          onUploadProgress(progress) {
            thunkApi.dispatch(change({ ...uploadWithoutErr, progress, state: UploadState.IN_PROGRESS }))
          },
          onUploadLoad() {
            thunkApi.dispatch(change({ ...uploadWithoutErr, progress: 100, state: UploadState.IN_PROGRESS }))
          },
          formData: toFormData(upload, uploadFileMap[upload.key]),
          toResponse: toUploadResponse,
        })

        if (response.type === 'SUCCESS') {
          thunkApi.dispatch(changeIsSuccessUploads(true))
          thunkApi.dispatch(changeIsErrorUploads(false))
          thunkApi.dispatch(change({ ...uploadWithoutErr, state: UploadState.DONE }))
        } else {
          thunkApi.dispatch(changeIsSuccessUploads(false))
          thunkApi.dispatch(changeIsErrorUploads(true))
          thunkApi.dispatch(
            change({
              ...uploadWithoutErr,
              state: UploadState.IDLE,
              error: { ...response.body, status: response.status },
            })
          )
        }
      }
    }
  )
}
