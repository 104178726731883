import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Card, CardContent, CardHeader, Grid, Snackbar } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  RechargerButton,
  SelectInputEntreprise,
  SelectInputMedecinAdmin,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { trimToUndefined } from 'plateforme/services/utils'
import { formatDateLocalISO, parseDateLocal } from 'plateforme/services/dates.services'
import { useAppDispatch, useAppSelector } from 'plateforme/store/hooks/hooks'
import {
  getIndicateurActiviteCriteria,
  setIndicateurActiviteCriteria,
} from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'

type SearchIndicateurActiviteProps = {
  searchError: IQueryErrorResponse
}

export interface FilterData {
  dateCreationDebut: Date | null
  dateCreationFin: Date | null
  codeMedecin: string
  codeEntreprise: string
}

export default function RechercheIndicateurActiviteAdminPart({ searchError }: SearchIndicateurActiviteProps) {
  const dispatch = useAppDispatch()
  const criteria = useAppSelector(getIndicateurActiviteCriteria)

  // Les valeurs initiales du formulaire :
  const initialValues: FilterData = {
    dateCreationDebut: parseDateLocal(criteria?.dateCreationDebut) ?? null,
    dateCreationFin: parseDateLocal(criteria?.dateCreationFin) ?? null,
    codeMedecin: criteria?.codeMedecin ?? '',
    codeEntreprise: criteria?.codeEntreprise ?? '',
  }

  const resetValues: FilterData = {
    dateCreationDebut: null,
    dateCreationFin: null,
    codeMedecin: '',
    codeEntreprise: '',
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: FilterData) =>
    dispatch(
      setIndicateurActiviteCriteria({
        dateCreationDebut: formatDateLocalISO(data.dateCreationDebut),
        dateCreationFin: formatDateLocalISO(data.dateCreationFin),
        codeMedecin: trimToUndefined(data.codeMedecin),
        codeEntreprise: trimToUndefined(data.codeEntreprise),
      })
    )

  const handleClickInitSearch = () => {
    reset(resetValues)
    dispatch(setIndicateurActiviteCriteria({}))
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => setOpenSnackbar(false)

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-indicateur-activite">
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dateCreationDebut"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'dateCreationDebut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="dateCreationFin"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'dateCreationFin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="codeMedecin"
                control={control}
                defaultValue={initialValues.codeMedecin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputMedecinAdmin
                    id="medecin"
                    label="Médecin"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="codeEntreprise"
                control={control}
                defaultValue={initialValues.codeEntreprise}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputEntreprise
                    id="entreprise"
                    label="Entreprise"
                    isAdmin
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-indicateur-activite"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des indicateurs d&apos;activité
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
