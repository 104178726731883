import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface InformationsAssurePartProps {
  readOnly?: boolean
}

export default function InformationsAssurePart({ readOnly }: InformationsAssurePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Informations assuré" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="parametrage.assure"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="assure"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Saisie des informations assuré dans la création des dossiers"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
