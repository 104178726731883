import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import { AreaLoading, ReadOnlyEmailField, ReadOnlyTextField, LabelIconField, MedecinImage } from 'plateforme/components'
import { useGetProfilMedecinQuery } from 'medecin/store/apis/profilMedecinApi'

export default function InformationsMedecinPart() {
  const { data: medecin, isLoading, isFetching, isError, error } = useGetProfilMedecinQuery()

  // handle error of useGetReferentielQuery
  const handleError = useErrorHandler()
  if (isError) {
    handleError({
      errorApi: error,
      api: 'GetProfilMedecin',
      title: 'Erreur chargement des données de profil médecin',
    })
  }

  if (isLoading || isFetching) {
    return <AreaLoading height={351} />
  }

  return (
    <Card>
      <CardHeader title="Informations médecin" />
      <CardContent sx={{ maxHeight: '350px' }}>
        <Stack direction="row" spacing={0}>
          <Stack>
            <MedecinImage />
          </Stack>
          <Stack width="100%">
            <ReadOnlyTextField
              id="id-medecin-plateforme"
              label="Identifiant médecin plateforme"
              value={medecin?.codeRPPS ?? medecin?.codeSIREN}
              fullWidth
            />
            <ReadOnlyTextField id="id-medecin" label="Identité médecin" value={medecin?.libelle} fullWidth />
            <ReadOnlyEmailField id="medecin-mail" label="Adresse mail" value={medecin?.mailContact} fullWidth />
            {medecin?.irca && (
              <Stack direction="row" alignItems="center">
                <LabelIconField iconType="check" label="Médecin expert IRCA" />
              </Stack>
            )}
            {medecin?.expertiseSurPiece && (
              <Stack direction="row" alignItems="center">
                <LabelIconField iconType="check" label="Avis techniques sur pièce" />
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
