import * as React from 'react'
import { MouseEventHandler } from 'react'
import { AppBar, Box, Link, LinkProps, Menu, MenuItem, styled, Toolbar } from '@mui/material'
import { AccueilMenuButton, ButtonsStack, MobileMenuButton, RechercheMenuInput } from 'plateforme/components'
import { hasRoles, Role } from 'plateforme/services/roles.services'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { logout } from 'plateforme/security/keycloak'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'

export interface MenuAppBarItem {
  id: string
  href?: string
  onClick?: MouseEventHandler<HTMLLIElement>
  button: React.ReactElement
  mobile: React.ReactElement
  isDeconnexion?: boolean
  target?: string
  rel?: string
  download?: string
}

const MenuToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0),
  padding: theme.spacing(0, 2),
}))

const ButtonMobileMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

function LinkMobileMenuItem(props: LinkProps) {
  const { children, underline, sx: otherSx, ...other } = props
  return (
    <Link underline={underline ?? 'none'} sx={{ verticalAlign: 'middle', ...otherSx }} {...other}>
      <MenuItem>{children}</MenuItem>
    </Link>
  )
}

function MobileMenu(props: { menuItems?: MenuAppBarItem[]; logout: VoidFunction }) {
  const { menuItems, logout: onLogout } = props
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId = 'menu-mobile'

  return (
    <>
      <MobileMenuButton
        aria-label="Voir le menu"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
      />
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {menuItems?.map((menuItem) =>
          menuItem.href ? (
            <LinkMobileMenuItem
              download={menuItem.download}
              target={menuItem.target}
              rel={menuItem.rel}
              href={menuItem.href}
              key={menuItem.id}
            >
              {menuItem.mobile}
            </LinkMobileMenuItem>
          ) : (
            <ButtonMobileMenuItem
              onClick={menuItem.isDeconnexion ? () => onLogout() : menuItem.onClick}
              key={menuItem.id}
            >
              {menuItem.mobile}
            </ButtonMobileMenuItem>
          )
        )}
      </Menu>
    </>
  )
}

// avoir un props supplémentaire sur le type de partenaire ?
export interface MenuAppBarProps {
  homeHref: string
  onRechercheSubmit?: (motCle: string) => void
  onRechercheClick?: VoidFunction
  menuItems: MenuAppBarItem[]
  profilUtilisateur: ProfilUtilisateurActif | undefined
}

export default function MenuAppBar(props: MenuAppBarProps) {
  const { homeHref, onRechercheSubmit, onRechercheClick, menuItems, profilUtilisateur } = props
  const dispatch = useAppDispatch()

  if (!profilUtilisateur) {
    return null
  }

  const allowRechercheInput = !hasRoles(profilUtilisateur, [
    Role.ASSU_ADMIN_RESEAU_MEDECIN,
    Role.ASSU_REFERENT_TECH_FLUX,
  ])

  /* https://mui.com/material-ui/customization/z-index/ */
  return (
    <AppBar id="menu-app-bar" position="relative" elevation={2} sx={{ zIndex: 1100 }}>
      <MenuToolbar disableGutters>
        {/* Icone home */}
        <AccueilMenuButton href={homeHref} />

        <Box sx={{ flexGrow: 2 }} />

        {/* barre de recherche */}
        {/* passé le props sur le type de partenaire à la rechercheMenuInput */}
        {/* ne doit pas être affiché pour les profils : Administrateur réseau médecin  et Référent technique flux */}
        {allowRechercheInput && onRechercheClick && onRechercheSubmit && (
          <RechercheMenuInput
            onRechercheSubmit={onRechercheSubmit}
            onAfterSuccess={onRechercheClick}
            sx={{ flexGrow: 3 }}
          />
        )}

        <Box sx={{ flexGrow: 2 }} />

        <ButtonsStack sx={{ display: { xs: 'none', md: 'flex' } }}>
          {menuItems?.map((menuItem) =>
            React.cloneElement(menuItem.button, {
              href: menuItem.href,
              download: menuItem.download,
              target: menuItem.target,
              rel: menuItem.rel,
              onClick: menuItem.isDeconnexion ? () => logout(dispatch) : menuItem.onClick,
              key: menuItem.id,
            })
          )}
        </ButtonsStack>

        {/* mode tablette responsive  affichage d'un bouton pour accéder à un menu flottant */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <MobileMenu menuItems={menuItems} logout={() => logout(dispatch)} />
        </Box>
      </MenuToolbar>
    </AppBar>
  )
}
