import React from 'react'
import { Alert, AlertProps, Stack, Typography, useTheme } from '@mui/material'
import { HandicapGraveRoundedIcon } from '../index'

export default function HandicapGraveAlert(props: AlertProps & { title?: string }) {
  const { title, sx } = props
  const theme = useTheme()
  const alertSx = {
    paddingY: 0,
    paddingLeft: 1,
    paddingRight: 2,
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
    },
    ...sx,
  }
  return (
    <Alert icon={false} severity="info" elevation={1} sx={alertSx}>
      <Stack direction="row" alignItems="center" spacing={0}>
        <HandicapGraveRoundedIcon />
        <Typography variant="body2" fontWeight="bold" color={theme.palette.tile.contrastText} marginLeft={1.5}>
          {title}
        </Typography>
      </Stack>
    </Alert>
  )
}
