import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useGetListeHabilitationsMedecinQuery } from 'medecin/store/apis/habilitationMedecinApi'
import NavigationPart from 'plateforme/parts/NavigationPart'
import RechercheHabilitationsMedecinPart from 'medecin/parts/rechercheHabilitation/RechercheHabilitationsMedecinPart/RechercheHabilitationsMedecinPart'
import ListeHabilitationsMedecinPart from 'medecin/parts/rechercheHabilitation/ListeHabilitationsMedecinPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useSnackbar } from 'notistack'
import { useGetProfilMedecinQuery } from 'medecin/store/apis/profilMedecinApi'
import { HabilitationSortType } from 'medecin/store/types/habilitationMedecin'

export default function RechercheHabilitationsMedecinPage() {
  const { data: medecin, isLoading: profilMedecinLoading, isError: isErrorProfilMedecin } = useGetProfilMedecinQuery()

  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSearch,
    initSort,
    sortBy,
    isLoading: listeHabilitationLoading,
    isFetching,
    isError: isErrorListeHabilitations,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetListeHabilitationsMedecinQuery, {
    defaultSort: { sortType: HabilitationSortType.DATE_CREATION_SORT, sortOrder: 'DESC' },
  })

  const isLoading = profilMedecinLoading || listeHabilitationLoading

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isErrorListeHabilitations) {
      enqueueSnackbar('La Recherche des habilitations a échoué', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorListeHabilitations])

  useEffect(() => {
    if (isErrorProfilMedecin) {
      enqueueSnackbar('Le chargement du profil a échoué', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorProfilMedecin])

  return (
    <Stack>
      <NavigationPart label="Liste des habilitations" />
      <RechercheHabilitationsMedecinPart
        medecin={medecin}
        initSearch={initSearch}
        search={search}
        searchError={error}
      />
      <ListeHabilitationsMedecinPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        combinedData={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isErrorListeHabilitations}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}
