import React from 'react'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { Card, CardActionArea, CardContent, Checkbox, Grid, Stack, Typography, Box, darken } from '@mui/material'
import theme from 'plateforme/theme'
import { InformationChip } from 'plateforme/components'
import { adminModifierMedecinHref } from 'admin/AdminApp'
import ProfilMedecinAdmin from 'admin/store/types/profilMedecinAdmin'
import StatutMedecinChip from '../StatutMedecinChip'

interface MedecinAdminCardProps {
  medecin: ProfilMedecinAdmin
}

function ValueLabled({ label, value }: { label?: string; value?: string }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="body2" color="readOnly.main">
        {label}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {value}
      </Typography>
    </Box>
  )
}

export default function MedecinAdminCard({ medecin }: MedecinAdminCardProps) {
  const colorTheme = theme.palette.primary.main
  const {
    libelle,
    expertiseSurPiece,
    irca,
    codeSIREN,
    codeRPPS,
    lieuxExercice,
    code,
    statut,
    dateCreation,
    dateInscription,
    dateSuspension,
    dateSuppression,
  } = medecin

  const navigate = useNavigate()

  const navigateFicheMedecin = () => {
    navigate(adminModifierMedecinHref(code as string))
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '275px',
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: darken('#fff', 0.2),
        },
      }}
    >
      <CardActionArea sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginRight: '15px' }}>
          <Box sx={{ display: 'flex', width: '80px', alignItems: 'center' }}>
            <Checkbox color="default" />
          </Box>
          <Grid container spacing={1} width="100%" onClick={navigateFicheMedecin}>
            <Grid item md={7}>
              <Typography
                margin={0}
                align="left"
                variant="h3"
                sx={{ fontWeight: 'bold' }}
                color="text.primary"
                gutterBottom
              >
                {`${libelle ?? '-'} `}
              </Typography>
              <Stack direction="row" spacing={6}>
                {codeRPPS && <ValueLabled label="RPPS" value={codeRPPS} />}
                {codeSIREN && <ValueLabled label="SIREN" value={codeSIREN} />}
              </Stack>
            </Grid>
            <Grid item md={5}>
              <StatutMedecinChip
                statut={statut}
                dateCreation={dateCreation}
                dateInscription={dateInscription}
                dateSuspension={dateSuspension}
                dateSuppression={dateSuppression}
              />
            </Grid>
            <Grid item xs={6} md={8}>
              <Stack direction="row" padding={1} spacing={1}>
                {irca && (
                  <InformationChip
                    colorText="white"
                    backgroundColor="#a68cbe"
                    variant="subtitle2"
                    indicateur="Médecin IRCA"
                    border="solid 0.5px"
                    borderColor={colorTheme}
                  />
                )}
                {expertiseSurPiece && (
                  <InformationChip
                    colorText={colorTheme}
                    border="solid 0.5px"
                    borderColor={colorTheme}
                    variant="subtitle2"
                    indicateur="Avis technique sur pièce"
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={6} md={8}>
              <Typography variant="body2" color="readOnly.main">
                Établissements
              </Typography>
              <Stack direction="row" padding={1} spacing={1}>
                {lieuxExercice?.map((lieuExerice) => (
                  <InformationChip
                    key={uuid()}
                    colorText={colorTheme}
                    border="solid 0.5px"
                    borderColor={colorTheme}
                    variant="body2"
                    indicateur={`${lieuExerice?.codePostal ?? '-'} ${lieuExerice?.commune ?? '-'}`}
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
