import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { calculerMetaDataMissions } from 'plateforme/services/mission.services'
import { MetaDataMission, StatutMessagerie } from 'plateforme/store/types/mission'
import { SearchParamsNames } from 'plateforme/hooks/useTabNavigate'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import {
  messageMedecinApi,
  useGetListeMessagesQuery,
  usePostAcquitterMessageMutation,
} from 'medecin/store/apis/messageMedecinApi'
import { messagesMissionsParDossierFiltre } from 'medecin/services/messageMedecin.services'
import {
  SelectedMessagesMissionMedecin,
  getSelectedMessagesMission,
  setSelectedMessagesMissionMedecin,
} from 'medecin/store/slices/selectedMessagesMission/selectedMessagesMissionSlice'
import { setSelectedDocumentMedecin } from 'medecin/store/slices/document/selectedDocumentSlice'
import MessageTile from './MessageMissionTile'

type MessageTileListProps = {
  dossier?: DossierMedecin
}

type MessageTileListPartLoadedProps = {
  dossier?: DossierMedecin
  missionsEligibles: MetaDataMission[]
}

export default function MessageMissionTileListPart({ dossier }: MessageTileListProps) {
  const handleError = useErrorHandler()

  const filter = messagesMissionsParDossierFiltre(dossier?.code ?? '')

  const {
    data: messages,
    isLoading: isLoadingMessages,
    isError: isErrorMessages,
    error: errorMessages,
  } = useGetListeMessagesQuery(
    {
      perPage: 100,
      filter,
    },
    { skip: !dossier?.code }
  )

  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des messages mission',
      api: 'useGetListeMessagesQuery',
    } as FallbackError)
  }
  // chargement:
  if (isLoadingMessages) {
    return (
      <Stack display="flex" flexWrap="wrap" flexDirection="row" alignItems="flex-start" alignContent="flex-start">
        <AreaLoading height={168} sx={{ marginRight: 2 }} />
      </Stack>
    )
  }

  const missionsMetaData = calculerMetaDataMissions(dossier?.missions, messages?.items)
  const missionsEligibles = missionsMetaData.filter(
    (m: MetaDataMission) => m.mission.messagerieActive !== StatutMessagerie.DESACTIVE
  )

  if (!dossier || !missionsEligibles || missionsEligibles.length === 0) {
    return null
  }

  return <MessagesMissionTileListPartLoaded dossier={dossier} missionsEligibles={missionsEligibles} />
}

export function MessagesMissionTileListPartLoaded({ missionsEligibles, dossier }: MessageTileListPartLoadedProps) {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const codeMissionParam = searchParams.get(SearchParamsNames.MISSION) ?? ''
  const [acquitterMessage] = usePostAcquitterMessageMutation()
  const selectedMessagesMission = useSelector(getSelectedMessagesMission)

  useEffect(() => {
    const missionEligible = (missionsEligibles.find((me) => me.mission?.code === codeMissionParam) ??
      missionsEligibles[0]) as SelectedMessagesMissionMedecin
    selectMessagesMission(missionEligible)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMessagesMission])

  const handleClickMessageTile = (missionEligible: MetaDataMission) => {
    selectMessagesMission({ ...missionEligible } as SelectedMessagesMissionMedecin)
  }

  const selectMessagesMission = (missionEligible?: SelectedMessagesMissionMedecin) => {
    dispatch(setSelectedDocumentMedecin(undefined))
    dispatch(setSelectedMessagesMissionMedecin(missionEligible))
    acquitterMessage({
      codeDossier: dossier?.code ?? '',
      codeMission: missionEligible?.mission?.code ?? '',
      types: ['MEL_MIS'],
    })
    dispatch(messageMedecinApi.util.invalidateTags([{ type: 'MessageMedecin' }, { type: 'MessageMissionMedecin' }]))
    setSearchParams(
      {
        [SearchParamsNames.ONGLET]: 'messages',
        [SearchParamsNames.MISSION]: missionEligible?.mission?.code ?? '',
      },
      { replace: true }
    )
  }

  return (
    <Stack
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      alignItems="flex-start"
      alignContent="flex-start"
      overflow="auto"
      spacing={2.5}
      paddingRight={2}
      marginTop={0}
      marginBottom={0}
      maxHeight={790}
      sx={{
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '8px',
          backgroundColor: '#e7e7e7',
          border: '1px solid #cacaca',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '8px',
          backgroundColor: '#b7a4cb',
        },
      }}
    >
      {missionsEligibles &&
        missionsEligibles.map((missionEligible) => (
          <MessageTile
            key={missionEligible.mission.code}
            onClick={() => {
              handleClickMessageTile(missionEligible)
            }}
            missionMessagerie={missionEligible}
            loading={false}
            selected={selectedMessagesMission?.mission?.code === missionEligible?.mission?.code}
          />
        ))}
    </Stack>
  )
}
