import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyTextField } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import DossierAdmin from 'admin/store/types/dossierAdmin'

type ReferenceDossierEntreprisePartProps = {
  dossier?: DossierAdmin
}

export default function ReferenceDossierAdminPart({
  dossier,
  loading,
}: ReferenceDossierEntreprisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={230} />
  }
  return <ReferenceDossierEntreprisePartLoaded dossier={dossier} />
}

function ReferenceDossierEntreprisePartLoaded({ dossier }: ReferenceDossierEntreprisePartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // render:
  return (
    <Card>
      <CardHeader title="Identification du dossier" />
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <ReadOnlyTextField
              fullWidth
              name="refDossierAssureur"
              id="id-ref-entreprise"
              value={dossier.refDossierEntreprise}
              label="Votre référence dossier"
            />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyTextField
              fullWidth
              name="refVictimeAssureur"
              id="numero-de-victime"
              value={dossier.refVictimeEntreprise}
              label="Numéro de victime"
            />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyTextField fullWidth id="idDossier" value={dossier.code} label="Identifiant dossier plateforme" />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
