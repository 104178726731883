import React, { useEffect, useState } from 'react'
import { SelectInputReferentiel, SelectReferentielProps } from 'plateforme/components'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'

export default function SelectInputCadreExpertise({
  profilEntreprise,
  ...restProps
}: SelectReferentielProps<'cadresExpertise'> & { profilEntreprise?: ProfilEntreprise }) {
  const { data: profilEntrepriseInternal, isLoading } = useGetProfilEntrepriseQuery(undefined, {
    skip: profilEntreprise === undefined,
  })
  const profilEntrepriseSelected = profilEntreprise ?? profilEntrepriseInternal

  const [filtre, setFiltre] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    if (!isLoading) {
      const filreSet = new Set<string>()
      const typesFichesConclusions = profilEntrepriseSelected?.parametrage?.typesFichesConclusions

      if (typesFichesConclusions) {
        type ObjectKey = keyof typeof typesFichesConclusions
        Object.keys(typesFichesConclusions).map((key) =>
          Object.keys(typesFichesConclusions[key as ObjectKey] ?? []).forEach(filreSet.add, filreSet)
        )
        setFiltre(filreSet.size > 0 ? Array.from(filreSet) : undefined)
      }
    }
  }, [isLoading, profilEntrepriseSelected])

  return (
    <SelectInputReferentiel
      {...restProps}
      id="cadre-expertise"
      label="Cadre de l'expertise"
      referentielName="cadresExpertise"
      codeFilter={filtre}
      isLoadingExternal={isLoading}
    />
  )
}
