import { Rapport, TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { TypeFicheConclusion } from 'plateforme/store/types/referentiel'
import { MissionRequest } from './missionMedecin'
import { DocumentRequest, SupprimerDocumentRequest } from './dossierMedecin'

interface RapportConclusionMedecin extends Rapport {
  dateExamen?: Date
  delaiNouvelExamen?: string
  dateConsolidationPro?: Date
  dateConsolidationDef?: Date
  commentaireNouvelExamen?: string
  typeFicheConclusion?: TypeFicheConclusion
  dommages?: Dommages
  lesions?: string[]
  lesionSequelles?: LesionSequelle[]
  motifCarence?: string
  remissionnementPreconise?: boolean
  handicapGrave?: boolean
  commentaireRapportConseil?: string
}

export default RapportConclusionMedecin

export interface LesionSequelle {
  labelLesion: string
  labelSequelle: string
  lesion: string
  sequelle: string
}

export interface LesionRequest {
  code: string
}

export interface LesionSequelleRequest {
  lesion: string
  sequelle: string
}

export interface Dommages {
  [key: string]: Dommage
}

export interface Dommage {
  codePrejudice?: string
  code?: string
  label?: string
  commentaire?: string
  plageDeDates?: PlageDeDate
  periodes?: TiercePersonne[]
  valeur?: number
  min?: number
  max?: number
  typeDommage?: TypeDommage.DEFINITIF | TypeDommage.TEMPORAIRE
  nonEvaluable?: boolean
  rapportConseil?: RapportConseil
}

export enum TypeDommage {
  DEFINITIF = 'DEFINITIF',
  TEMPORAIRE = 'TEMPORAIRE',
}

export interface RapportConseil {
  commentaire?: string
  min?: number
  max?: number
  nonCompletion?: boolean
}

export interface PlageDeDate {
  mode?: ModeDommage
  periodes?: Periode[] | PeriodeRequest[]
  min?: Estimation
  max?: Estimation
  estimation?: Estimation
}

export interface TiercePersonne {
  type?: keyof typeof TypePersonne
  nombreHeures?: number
  par?: keyof typeof UniteEstimation
  periode?: Periode
}

export enum TypePersonne {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}

export interface Periode {
  du?: Date
  au?: Date
}

export interface Estimation {
  valeur: number
  unite: keyof typeof UniteEstimation
}

export enum UniteEstimation {
  JOUR = 'JOUR',
  SEMAINE = 'SEMAINE',
  MOIS = 'MOIS',
  ANNEE = 'ANNEE',
}

export enum ModeDommage {
  PERIODE = 'PERIODE',
  ESTIMATION = 'ESTIMATION',
  FOURCHETTE = 'FOURCHETTE',
}

export enum StatutRapport {
  EN_COURS = 'EN_COURS',
  VALIDE = 'VALIDE',
  VALIDE_DEFINITIVEMENT = 'VALIDE_DEFINITIVEMENT',
  VALIDE_DEFINITIVEMENT_EN_COURS = 'VALIDE_DEFINITIVEMENT_EN_COURS',
}

export type TypeTagsDocument =
  | TypeRapport.CONCLUSION_PROVISOIRE
  | TypeRapport.CONCLUSION_DEFINITIVE
  | TypeRapport.CARENCE
  | 'ALL_DOCUMENTS'

export interface SauvegarderConclusionRequest extends MissionRequest {
  typeRapport: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
  validation: boolean
  nomPersonneAExaminer?: string
  prenomPersonneAExaminer?: string
  dateNaissancePersonneAExaminer?: string
  lieuSignature?: string
  dateSignature?: string
  consentement?: boolean
  refConsentement?: string
  signataire?: string
  commentaireNouvelExamen?: string
  commentaire?: string
  dateConsolidationPro?: string
  dateConsolidationDef?: string
  dateExamen?: string
  delaiNouvelExamen?: string
  lesions?: (string | undefined)[]
  lesionSequelles?: LesionSequelleRequest[]
  dommages?: Dommages
  motifCarence?: string
  remissionnementPreconise?: boolean
  handicapGrave?: boolean
  commentaireRapportConseil?: string
}

export interface DocumentConclusionRequest extends MissionRequest, DocumentRequest {}

export interface SupprimerDocumentConclusionRequest extends DocumentConclusionRequest, SupprimerDocumentRequest {}

export interface PeriodeRequest {
  du?: string
  au?: string
}
