import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import MedecinEntreprise, {
  MedecinPlateformeEntreprise,
  MedecinEntrepriseFilter,
  MedecinEntrepriseRequest,
  MedecinEntrepriseSortType,
} from '../types/medecinEntreprise'
import HabilitationEntreprise from '../types/habilitationEntreprise'

export interface MedecinPartenaireFilter {
  actif?: 0 | 1
}

export const medecinEntrepriseApi = createApi({
  reducerPath: 'medecinEntrepriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['MedecinPartenaire', 'MedecinEntreprise'],
  endpoints: (builder) => ({
    getMedecinByCode: builder.query<MedecinEntreprise, MedecinEntrepriseRequest>({
      query: ({ codeMedecin }) => `entreprise/medecin/${codeMedecin}`,
      providesTags: (result, error, { codeMedecin }) => [{ type: 'MedecinEntreprise', id: codeMedecin }],
    }),
    getListeMedecinsEntreprise: builder.query<
      PageRecherche<MedecinPlateformeEntreprise>,
      PageRechercheRequest<MedecinEntrepriseFilter, MedecinEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'entreprise/medecin',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    getMedecinsPartenaire: builder.query<HabilitationEntreprise[], MedecinPartenaireFilter>({
      query: ({ actif }) => ({
        url: 'entreprise/medecin-partenaire',
        params: {
          actif,
        },
        providesTags: ['MedecinPartenaire'],
      }),
    }),
    getMedecinsPlateforme: builder.query<MedecinPlateformeEntreprise[], unknown>({
      query: () => 'entreprise/medecin-plateforme',
      providesTags: ['MedecinEntreprise'],
    }),
  }),
})

export const {
  useGetListeMedecinsEntrepriseQuery,
  useGetMedecinByCodeQuery,
  useGetMedecinsPartenaireQuery,
  useGetMedecinsPlateformeQuery,
} = medecinEntrepriseApi

export const useGetListeMedecinsEntrepriseHabilitableQuery = ({
  filter,
  perPage,
  page,
  sort,
  onlyTotal,
}: PageRechercheRequest<MedecinEntrepriseFilter, MedecinEntrepriseSortType>) =>
  useGetListeMedecinsEntrepriseQuery({
    perPage,
    page,
    sort,
    onlyTotal,
    filter: {
      ...filter,
      habilitable: 1,
    },
  })
