import React from 'react'
import { useSnackbar } from 'notistack'
import { DocumentPanelType } from 'plateforme/store/types/documentUpload'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import DocumentsPart from 'plateforme/parts/DocumentsPart'
import { determineDocumentsDossier } from 'plateforme/services/dossier.services'
import { ReferentielTypeDocument } from 'plateforme/store/types/referentiel'
import { useDeleteDocumentDossierMutation } from 'medecin/store/apis/dossierMedecinApi'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import { derniereMissionAssociee } from 'medecin/services/dossierMedecin.services'
import { isValideDefinitivement } from 'plateforme/services/rapport.services'

type TabDocumentsPartProps = {
  dossier?: DossierMedecin
  loading?: boolean
}

export default function TabDocumentsMedecinPart({ dossier, loading }: TabDocumentsPartProps) {
  // props:
  const { documentDeposable, code: codeDossier } = dossier || {}

  // hooks:
  const [deleteDocument, { isLoading: isLoadingDelete }] = useDeleteDocumentDossierMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { confirm, confirmDialog } = useConfirmDialog()

  // format:
  const documents = determineDocumentsDossier(dossier)
  // NB: le médecin n'a pas le droit de rajouter un document au dossier s'il n'a pas déjà accepté une mission au niveau de dossier
  const derniereMission = derniereMissionAssociee(dossier)
  const enCours =
    derniereMission?.rapportModifiable ||
    derniereMission?.carenceCreable ||
    derniereMission?.conclusionProvisoireCreable ||
    derniereMission?.conclusionDefinitiveCreable
  const finie = !enCours && derniereMission?.facturable

  const readOnly = !documentDeposable || (!enCours && !finie)
  const tagsFilter = (ref: ReferentielTypeDocument) =>
    (ref.tags.includes('DOSSIER_ANNEXE') || ref.tags.includes('MISSION_ANNEXE')) &&
    ref.tags.includes('MEDECIN') &&
    ((!finie && !isValideDefinitivement(derniereMission?.rapport)) || ref.tags.includes('MEDECIN_HORS_MISSION'))

  // envoi formulaire:
  const onDeleteDocument = async (doc: DocumentTeleverse) => {
    return confirm({
      maxWidth: 'lg',
      confirmMsg: `Êtes-vous sûr de vouloir supprimer le document «${doc.nomDocument}» ?`,
      variant: 'delete',
      onYes: async () => {
        const codeDocument = doc.code
        if (!codeDossier || !codeDocument) {
          throw new Error('erreur inattendu')
        }
        deleteDocument?.({
          codeDossier,
          codeDocument,
        })
          .unwrap()
          .then(() => enqueueSnackbar('Le document a été supprimé avec succès', { variant: 'success' }))
          .catch(() => {
            enqueueSnackbar('La suppression du document a échoué', { variant: 'error' })
          })
      },
    })
  }

  // rendu:
  return (
    <>
      {confirmDialog}
      <DocumentsPart
        title="Nouveaux documents"
        buttonText="Ajouter des documents au dossier"
        dossier={dossier}
        documents={documents}
        loading={loading}
        typeDocumentPanel={DocumentPanelType.DOSSIER_MEDECIN}
        typeDocumentFilter={tagsFilter}
        readOnly={readOnly}
        onDeleteDocument={onDeleteDocument}
        isLoadingDelete={isLoadingDelete}
      />
    </>
  )
}
