import { StatutsHabilitation } from 'plateforme/store/types/habilitation'

export default interface HabilitationMedecinAdmin {
  code?: string
  codeSIREN?: string
  codeACPR?: string
  libelle?: string
  numeroHabilitation?: string
  intervenantOccasionnel?: boolean
  expertiseSurPiece?: boolean
  dateDebut?: Date
  dateFin?: Date
  statut?: StatutsHabilitation
  labelStatut?: string
  dateCreation: Date
  dateMiseAJour: Date
}

export interface HabilitationMedecinAdminFilter {
  codeMedecin?: string
  numeroHabilitation?: string
  codeEntreprise?: string
  intervenantOccasionnel?: 0 | 1
  expertiseSurPiece?: 0 | 1
  active?: 0 | 1
  activeFutur?: 0 | 1
  dateActive?: string
  dateCreationDebut?: string
  dateCreationFin?: string
}

export enum HabilitationMedecinAdminSortType {
  ENTREPRISE_SORT = 'entreprise',
  DATE_CREATION_SORT = 'date_creation',
  NUMERO_HABILITATION_SORT = 'numero_habilitation',
  DATE_DEBUT_SORT = 'date_debut',
  DATE_FIN_SORT = 'date_fin',
  STATUT_SORT = 'statut',
}
