import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyTextField } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type GestionDossierEntreprisePartProps = {
  dossier?: DossierEntreprise
  profilEntreprise?: ProfilEntreprise
}

export default function InformationsAssureEntreprisePart({
  dossier,
  profilEntreprise,
  loading,
}: GestionDossierEntreprisePartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={219.5} />
  }
  return <InformationsAssureEntreprisePartLoaded dossier={dossier} profilEntreprise={profilEntreprise} />
}

function InformationsAssureEntreprisePartLoaded({ dossier, profilEntreprise }: GestionDossierEntreprisePartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  const { assure } = dossier

  // render:
  if (!profilEntreprise?.parametrage?.assure) {
    return null
  }
  return (
    <Card>
      <CardHeader title="Informations assuré" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField id="numéro-contrat" label="Référence assuré" value={assure?.numeroContrat} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField id="code-Assure" label="Code assuré" value={assure?.codeAssure} fullWidth />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField id="nom" label="Nom de l'assuré" value={assure?.nom} fullWidth />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
