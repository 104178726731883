import React from 'react'
import { Grid, Stack } from '@mui/material'
import EvenementPart from 'plateforme/parts/DonneesDetailleesParts/EvenementPart'
import ExpertisePart from 'plateforme/parts/DonneesDetailleesParts/ExpertisePart'
import PersonneAExaminerPart from 'plateforme/parts/DonneesDetailleesParts/PersonneAExaminerPart'
import AutreMedecinPart from 'plateforme/parts/DonneesDetailleesParts/AutreMedecinPart'
import LieuExpertisePart from 'plateforme/parts/DonneesDetailleesParts/LieuExpertisePart'
import { LoadingProps } from 'plateforme/services/utils'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import MissionAdmin from 'admin/store/types/missionAdmin'
import ReferenceDossierAdminPart from './ReferenceDossierAdminPart'
import InformationsAssureAdminPart from './InformationsAssureAdminPart'
import GestionDossierAdminPart from './GestionDossierAdminPart'

type TabDonneesDetailleesEntreprisePartProps = {
  dossier?: DossierAdmin
  derniereMission?: MissionAdmin
}

export default function TabDonneesDetailleesAdminPart({
  dossier,
  derniereMission,
  loading,
}: TabDonneesDetailleesEntreprisePartProps & LoadingProps) {
  return (
    <Grid container>
      <Grid item md={12} lg={6}>
        <Stack>
          <ReferenceDossierAdminPart dossier={dossier} loading={loading} />
          <GestionDossierAdminPart dossier={dossier} loading={loading} />
          <EvenementPart dossier={dossier} loading={loading} />
          <ExpertisePart dossier={dossier} mission={derniereMission} loading={loading} />
          <LieuExpertisePart dossier={dossier} loading={loading} />
        </Stack>
      </Grid>
      <Grid item md={12} lg={6}>
        <Stack>
          <PersonneAExaminerPart dossier={dossier} loading={loading} />
          <AutreMedecinPart dossier={dossier} loading={loading} />
          <InformationsAssureAdminPart dossier={dossier} loading={loading} />
        </Stack>
      </Grid>
    </Grid>
  )
}
