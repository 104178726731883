import * as React from 'react'
import { Fab, FabProps, styled, Tooltip, Typography } from '@mui/material'

const MenuFab = styled(Fab)(({ theme }) => ({
  height: '36px',
  minWidth: '64px',
  padding: '0 16px',
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(53, 86, 167, 0.08)',
  },
}))

export interface MenuBaseButtonProps extends FabProps {
  icon?: React.ReactNode
  tooltip?: NonNullable<React.ReactNode>
  noWrap?: boolean
  fontSize?: number
  lineHeight?: number
}

export default function MenuBaseButton(props: MenuBaseButtonProps) {
  const { icon, children, tooltip, fontSize, lineHeight, noWrap, ...other } = props
  return (
    <Tooltip title={tooltip ?? false} placement="bottom" open={tooltip ? undefined : false}>
      <MenuFab variant="extended" size="small" {...other}>
        {icon}
        <Typography variant="body2" fontSize={fontSize} lineHeight={lineHeight} noWrap={noWrap}>
          {children}
        </Typography>
      </MenuFab>
    </Tooltip>
  )
}
