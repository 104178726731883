import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import HabilitationEntrepriseAdmin, {
  HabilitationEntrepriseAdminFilter,
  HabilitationEntrepriseAdminSortType,
} from 'admin/store/types/habilitationEntrepriseAdmin'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'

export const habilitationEntrepriseAdminApi = createApi({
  reducerPath: 'habilitationEntrepriseAdminApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getListeHabilitationEntrepriseAdmin: builder.query<
      PageRecherche<HabilitationEntrepriseAdmin>,
      PageRechercheRequest<HabilitationEntrepriseAdminFilter, HabilitationEntrepriseAdminSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'admin/entreprise/habilitation',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
  }),
})

export const { useGetListeHabilitationEntrepriseAdminQuery } = habilitationEntrepriseAdminApi

export const useGetListeHabilitationsEntrepriseAdminQuery = (
  code: string,
  {
    filter,
    perPage,
    page,
    sort,
    onlyTotal,
  }: PageRechercheRequest<HabilitationEntrepriseAdminFilter, HabilitationEntrepriseAdminSortType>
) =>
  useGetListeHabilitationEntrepriseAdminQuery({
    perPage,
    page,
    sort,
    onlyTotal,
    filter: {
      codeEntreprise: code,
      ...filter,
    },
  })

export const totalHabilitationsPourUneEntrepriseRequest = (codeEntreprise: string) =>
  ({
    filter: { codeEntreprise },
    onlyTotal: 1,
  } as PageRechercheRequest<HabilitationEntrepriseAdminFilter, HabilitationEntrepriseAdminSortType>)

export const habilitationsPourUneEntreprise = (codeEntreprise: string) =>
  ({
    filter: { codeEntreprise },
  } as PageRechercheRequest<HabilitationEntrepriseAdminFilter, HabilitationEntrepriseAdminSortType>)
