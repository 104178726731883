import HabilitationEntreprise from 'assureur/store/types/habilitationEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'

export default function isMissionPrecedenteValide(
  missionPrecedente?: MissionEntreprise,
  medecinsPartenaires?: HabilitationEntreprise[]
) {
  // check si le medecin de la mission precedente n'est pas suspendu ou cloturé depuis les medecins partenaires
  return missionPrecedente && medecinsPartenaires?.some((m) => m.code === missionPrecedente.medecin?.code)
}
