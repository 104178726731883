import React from 'react'
import { useSelector } from 'react-redux'
import { useErrorHandler } from 'react-error-boundary'
import { Card, CardContent, CardHeader, Grid, Stack, useTheme } from '@mui/material'
import { FallbackError } from 'plateforme/components/dialog/ErrorFallback'
import { AreaLoading } from 'plateforme/components'
import { determineLibelleStatutMissionAccordion } from 'plateforme/services/mission.services'
import { determineDocumentsDossier } from 'plateforme/services/dossier.services'
import MessageBulleList from 'plateforme/parts/MessageriePart/MessageBulleList'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { useGetListMessageMissionQuery } from 'assureur/store/apis/messageEntrepriseApi'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { getSelectedMessagesMission } from 'assureur/store/slices/selectedMessagesMission/selectedMessagesMissionSlice'
import { missionMessageEnvoyable } from 'assureur/services/rolesEntreprise.services'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import MessageEntrepriseForm from './MessageEntrepriseForm'

type MessagerieEntreprisePartProps = {
  dossier?: DossierEntreprise
}

export default function MessagerieEntreprisePart({ dossier }: MessagerieEntreprisePartProps) {
  const selectedMessagesMission = useSelector(getSelectedMessagesMission)
  const theme = useTheme()
  const handleError = useErrorHandler()

  const {
    data: utilisateur,
    error: utilisateurError,
    isError: isUtilisateurError,
    isLoading: utilisateurLoading,
    isFetching: utilisateurFetching,
  } = useGetUtilisateurActifQuery()

  const {
    data: messages,
    isLoading: isLoadingMessages,
    isFetching: isFetchingMessages,
    isError: isErrorMessages,
    error: errorMessages,
  } = useGetListMessageMissionQuery({
    codeDossier: dossier?.code,
    codeMission: selectedMessagesMission?.mission?.code,
    perPage: 100,
  })

  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des messages',
      api: 'useGetListeMessagesQuery',
    } as FallbackError)
  }

  if (isUtilisateurError) {
    handleError({
      errorApi: utilisateurError,
      title: 'Erreur chargement utilisateur',
      api: 'useGetUtilisateurActifQuery',
    } as FallbackError)
  }

  // chargement:
  if (
    isLoadingMessages ||
    isFetchingMessages ||
    utilisateurLoading ||
    utilisateurFetching ||
    !dossier ||
    !selectedMessagesMission
  ) {
    return <AreaLoading height="calc(100vh - 242px)" />
  }

  const title = selectedMessagesMission
    ? `Mission${
        selectedMessagesMission?.indexMission ? ` n°${selectedMessagesMission?.indexMission}` : ''
      } ${determineLibelleStatutMissionAccordion(
        selectedMessagesMission?.mission,
        selectedMessagesMission?.indexRapport
      )}`
    : '-'

  const documents = determineDocumentsDossier(dossier)

  const mission = dossier.missions?.find((m) => m.code === selectedMessagesMission?.mission?.code)

  return (
    <Grid item>
      <Card>
        <CardHeader title={title} />
        {/* On prend au max la hauteur de la fenetre mais on met une taille minimum tout de meme */}
        <CardContent sx={{ height: 'calc(100vh - 500px)', minHeight: '215px' }}>
          <MessageBulleList
            typeUtilisateur={TypePartenaire.Entreprise}
            dossier={dossier}
            mission={mission}
            messages={messages?.items}
            documents={documents}
          />
        </CardContent>
        {missionMessageEnvoyable(mission, utilisateur?.profilActif) && (
          <CardContent
            sx={{
              overflow: 'hidden',
              borderRadius: 0.5,
              margin: '12px 16px 16px 16px',
              padding: '0px !important',
              backgroundColor: '#ddd3e666',
              boxShadow: theme.shadows[1],
            }}
          >
            <Stack justifyContent="end">
              <MessageEntrepriseForm dossier={dossier} mission={mission} documents={documents} />
            </Stack>
          </CardContent>
        )}
      </Card>
    </Grid>
  )
}
