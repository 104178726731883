import { Upload } from 'plateforme/store/slices/uploadSlice'

export interface DocumentUpload extends Upload {
  dateDocument: string | null
  nomDocument: string
  typeDocument?: string
}

export enum DocumentPanelType {
  RAPPORT = 'RAPPORT',
  DOSSIER_MEDECIN = 'DOSSIER_MEDECIN',
  DOSSIER_ENTREPRISE = 'DOSSIER_ENTREPRISE',
}
