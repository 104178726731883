import React, { useState } from 'react'
import { Alert, Card, CardContent, CardHeader, Grid, Snackbar } from '@mui/material'
import {
  getEntrepriseReferenceCriteria,
  setEntrepriseReferenceCriteria,
} from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { ReferentielEntrepriseFilter, statutsEnterprise } from 'admin/store/types/profilEntrepriseAdmin'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  EditTextField,
  RechargerButton,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO } from 'plateforme/services/dates.services'
import { emptyToUndefined, trimToUndefined } from 'plateforme/services/utils'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

type RechercheEntrepriseReferencesPartProps = {
  search: (criteria: ReferentielEntrepriseFilter) => void
  searchError: IQueryErrorResponse
}

type EntrepriseReferencesFilterData = {
  motCle?: string
  statuts?: statutsEnterprise[] | null
  dateCreationDebut?: Date | null
}

export default function RechercheEntrepriseReferencesPart({
  search,
  searchError,
}: RechercheEntrepriseReferencesPartProps) {
  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
    setError,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  // get default values from localStorage
  const defaultDossierCriteria = useSelector(getEntrepriseReferenceCriteria)
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: EntrepriseReferencesFilterData = {
    motCle: defaultDossierCriteria?.motCle?.length === 0 ? undefined : defaultDossierCriteria?.motCle,
    statuts: defaultDossierCriteria?.statuts,
    dateCreationDebut: defaultDossierCriteria?.dateCreationDebut
      ? new Date(defaultDossierCriteria.dateCreationDebut)
      : null,
  }

  const resetValues: EntrepriseReferencesFilterData = {
    motCle: '',
    statuts: null,
    dateCreationDebut: null,
  }

  const onSubmit = async (data: EntrepriseReferencesFilterData) => {
    const filtre = {
      motCle: trimToUndefined(data.motCle),
      statuts: emptyToUndefined(data.statuts),
      dateCreationDebut: formatDateISO(data.dateCreationDebut),
    }
    search(filtre)
    dispatch(setEntrepriseReferenceCriteria(filtre))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      motCle: undefined,
      statuts: undefined,
      dateCreationDebut: undefined,
    }

    reset(resetValues)
    search(filtre)
    dispatch(setEntrepriseReferenceCriteria(filtre))
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-habilitation">
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="motCle"
                control={control}
                defaultValue={initialValues.motCle}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="keywords"
                    label="Mots clés"
                    value={value}
                    onChange={(e) => {
                      if (e.target.value.length === 0) {
                        setValue('keywords', undefined)
                      }
                      onChange(e)
                    }}
                    onBlur={onBlur}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="statuts"
                control={control}
                defaultValue={initialValues.statuts}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <SelectInputReferentiel
                    id="statutsEnteprise"
                    label="Statut"
                    referentielName="statutsEnterprise"
                    multiple
                    value={value}
                    onChangeMultiple={onChange}
                    onBlur={onBlur}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="dateCreationDebut"
                control={control}
                defaultValue={initialValues.dateCreationDebut}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DatePickerInput
                    InputProps={{
                      id: 'date-debut',
                      fullWidth: true,
                    }}
                    label="Date d'inscription"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-habilitation"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des entreprises référencées
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
