import React from 'react'

import { AreaLoading } from 'plateforme/components'
import { Grid } from '@mui/material'
import { LoadingProps } from 'plateforme/services/utils'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MessagerieMedecinPart from './MessagerieMedecinPart/MessagerieMedecinPart'
import MessageMissionTileListPart from './MessageMissionTileListPart/MessageMissionTileListPart'

type TabMessagesDossierProps = {
  dossier?: DossierMedecin
}

const areaLoading = <AreaLoading height={224} />

export default function TabMessageMissionMedecinPart({ dossier, loading }: TabMessagesDossierProps & LoadingProps) {
  if (loading) {
    return areaLoading
  }
  return <TabMessagesDossierMedecinLoaded dossier={dossier} />
}

function TabMessagesDossierMedecinLoaded({ dossier }: TabMessagesDossierProps) {
  // erreur:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  return (
    <Grid container columnSpacing={1.5}>
      <Grid item xs={3.5} sm={3.5} md={2}>
        <MessageMissionTileListPart dossier={dossier} />
      </Grid>
      <Grid item xs={8.5} sm={8.5} md={10}>
        <MessagerieMedecinPart dossier={dossier} />
      </Grid>
    </Grid>
  )
}
