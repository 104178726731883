import React from 'react'
import { TabsComponent, TabDefinition } from 'plateforme/components'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { LoadingProps } from 'plateforme/services/utils'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import MissionAdmin, { TypeRapport } from 'admin/store/types/missionAdmin'
import {
  isRapportCarence,
  isRapportConclusionDefinitive,
  isRapportConclusionProvisoire,
  isRapportDemandeModification,
} from 'plateforme/services/rapport.services'
import EditionDocumentsDossierAdminPart from 'admin/part/editionDocuments/EditionDocumentsDossierEntreprisePart'
import TabSyntheseAdminPart from '../TabSyntheseAdminPart'
import TabDonneesDetailleesAdminPart from '../TabDonneesDetailleesAdminPart'
import TabFactureAdminPart from '../TabFactureAdminPart'
import TabHistoriqueDossierAdminPart from '../TabHistoriqueDossierAdminPart'
import TabRapportAdminPart from '../TabRapportAdminPart'

export interface TabsConsultationDossierEntrepriseProps {
  dossier?: DossierAdmin
  derniereMission?: MissionAdmin
  profilUtilisateur?: ProfilUtilisateurActif
}

export enum TabId {
  SYNTHESE_TAB = 'synthese',
  DETAILS_TAB = 'details',
  DOCUMENTS_TAB = 'documents',
  FACTURES_TAB = 'factures',
  HISTORIQUE_TAB = 'historique',
  MESSAGES_TAB = 'messages',
}

type TabRapportParam = {
  mission: MissionAdmin
  index?: number
}

export default function TabsConsultationDossierAdmin({
  dossier,
  derniereMission,
  profilUtilisateur,
  loading,
}: TabsConsultationDossierEntrepriseProps & LoadingProps) {
  // props:
  const { missions } = dossier || {}

  // format:
  let carenceIndex = 0
  let conclusionsProvisoiresIndex = 0
  let conclusionsDefinitivesIndex = 0

  const tabRapportParams = !missions
    ? []
    : missions
        .filter((mission) => mission.code)
        .filter((mission) => mission?.rapport)
        .map((mission) => {
          switch (mission.rapport?.typeRapport) {
            case TypeRapport.CONCLUSION_PROVISOIRE: {
              conclusionsProvisoiresIndex += 1
              return { mission, index: conclusionsProvisoiresIndex } as TabRapportParam
            }
            case TypeRapport.CONCLUSION_DEFINITIVE: {
              conclusionsDefinitivesIndex += 1
              return { mission, index: conclusionsDefinitivesIndex } as TabRapportParam
            }
            case TypeRapport.CARENCE: {
              carenceIndex += 1
              return { mission, index: carenceIndex } as TabRapportParam
            }
            default: {
              return { mission } as TabRapportParam
            }
          }
        })
        .sort((p1, p2) => (p2.mission.code ?? '').localeCompare(p1.mission.code ?? ''))

  const calcRapportIndex = (mission: MissionAdmin, index?: number) => {
    if (!index) {
      return undefined
    }

    switch (mission.rapport?.typeRapport) {
      case TypeRapport.CONCLUSION_PROVISOIRE: {
        if (conclusionsProvisoiresIndex <= 1) {
          return undefined
        }
        return conclusionsProvisoiresIndex - index + 1
      }
      case TypeRapport.CONCLUSION_DEFINITIVE: {
        if (conclusionsDefinitivesIndex <= 1) {
          return undefined
        }
        return conclusionsDefinitivesIndex - index + 1
      }
      case TypeRapport.CARENCE: {
        if (carenceIndex <= 1) {
          return undefined
        }
        return carenceIndex - index + 1
      }
      default: {
        return undefined
      }
    }
  }

  const rapportTitle = (prefix: string, rapportIndex?: number) => `${prefix}${rapportIndex ? ` ${rapportIndex}` : ''}`

  const tabsMissionsAvecRapport: TabDefinition[] =
    !dossier || !profilUtilisateur || !tabRapportParams
      ? []
      : (tabRapportParams
          .map((param) => {
            const { mission, index } = param
            const { rapport } = mission
            const rapportIndex = calcRapportIndex(mission, index)
            const isError = isRapportDemandeModification(mission)
            if (isRapportCarence(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Carence', rapportIndex),
                isError,
                component: (
                  <TabRapportAdminPart dossier={dossier} mission={mission} typeConclusion={TypeRapport.CARENCE} />
                ),
              }
            }
            if (isRapportConclusionProvisoire(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Conclusions provisoires', rapportIndex),
                isError,
                component: (
                  <TabRapportAdminPart
                    dossier={dossier}
                    mission={mission}
                    typeConclusion={TypeRapport.CONCLUSION_PROVISOIRE}
                  />
                ),
              }
            }
            if (rapport && isRapportConclusionDefinitive(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Conclusions définitives', rapportIndex),
                isError,
                component: (
                  <TabRapportAdminPart
                    dossier={dossier}
                    mission={mission}
                    typeConclusion={TypeRapport.CONCLUSION_DEFINITIVE}
                  />
                ),
              }
            }
            return undefined
          })
          .filter((t) => t !== undefined) as TabDefinition[])

  const tabList = ([] as TabDefinition[])
    .concat([
      {
        id: TabId.SYNTHESE_TAB,
        title: 'Synthèse',
        component: <TabSyntheseAdminPart dossier={dossier} loading={loading} />,
      },
      {
        id: TabId.DETAILS_TAB,
        title: 'Données détaillées',
        component: (
          <TabDonneesDetailleesAdminPart dossier={dossier} derniereMission={derniereMission} loading={loading} />
        ),
      },
    ] as TabDefinition[])
    .concat(tabsMissionsAvecRapport)
    .concat([
      {
        id: TabId.DOCUMENTS_TAB,
        title: 'Tous les documents',
        component: <EditionDocumentsDossierAdminPart dossier={dossier} loading={loading} />,
      },
      ...(dossier?.missions?.some((m) => m.factures && m.factures.length > 0)
        ? [
            {
              id: TabId.FACTURES_TAB,
              title: 'Factures',
              component: <TabFactureAdminPart dossier={dossier} loading={loading} />,
            },
          ]
        : []),
      {
        id: TabId.HISTORIQUE_TAB,
        title: 'Historique',
        component: <TabHistoriqueDossierAdminPart dossier={dossier} loading={loading} />,
      },
    ] as TabDefinition[])
    .filter((t) => t !== undefined)

  return <TabsComponent withNavigation tabs={tabList} />
}
