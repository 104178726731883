import { useEffect } from 'react'
import { FieldPath, FieldValues, UseFormGetValues, UseFormSetError } from 'react-hook-form'

export interface IQueryErrorDetailsResponse {
  message?: string
  fields?: string[]
}

export interface IValidationErrorRepsonse {
  status?: number
  code?: string
  message?: string
  details?: IQueryErrorDetailsResponse[] | IQueryErrorDetailsResponse
}

export type IQueryErrorResponse = {
  status?: number
  data?: IValidationErrorRepsonse
}

export interface ErrorMessage {
  message: string
  fields: string[]
}

export default function useErrorFormMapper<TFieldValues extends FieldValues = FieldValues>(
  searchError: IQueryErrorResponse,
  setError: UseFormSetError<TFieldValues>,
  getValues: UseFormGetValues<TFieldValues>
) {
  useEffect(() => {
    function bindError(err: IQueryErrorDetailsResponse) {
      const fields = err?.fields instanceof Array ? (err?.fields as Array<string>) : []
      if (fields.length === 0) {
        // Pour remonter les erreurs sans fields coté API
        setError('_error' as FieldPath<TFieldValues>, { type: 'global', message: err?.message })
      }
      fields.forEach((field) => {
        const fieldPath = field as FieldPath<TFieldValues>
        const fieldValue = getValues(fieldPath)
        if (
          fieldValue !== undefined &&
          (fieldValue === null || typeof fieldValue !== 'object' || Object.keys(fieldValue).length === 0)
        ) {
          setError(fieldPath, { type: 'server', message: err?.message })
        } else {
          const globalField = field ? `${field}._error` : '_error'
          const globalFieldPath = globalField as FieldPath<TFieldValues>
          setError(globalFieldPath, { type: 'global', message: err?.message })
        }
      })
    }

    if (searchError?.status === 400) {
      if (searchError?.data?.details instanceof Array) {
        const errors = searchError?.data?.details as Array<IQueryErrorDetailsResponse>
        errors.forEach((err) => {
          bindError(err)
        })
      } else if (searchError?.data?.details !== null) {
        const err = searchError?.data?.details as IQueryErrorDetailsResponse
        bindError(err)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchError?.status, searchError?.data?.details, getValues, setError])
}
