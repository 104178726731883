import React, { ReactElement } from 'react'

import { Card, CardContent, Grid, Typography, Box } from '@mui/material'
import { FichierIcon, ReferentielText, SupprimerIconButton, TelechargerIconButton } from 'plateforme/components'

import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { formatDateFR } from 'plateforme/services/dates.services'
import { toHumanReadableFileSize } from 'plateforme/services/document.services'
import { AuthDownloadAction, AuthDownloadDocumentDossierRequest } from 'plateforme/store/types/utilisateur'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import { useSnackbar } from 'notistack'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import DossierPlateforme from 'plateforme/store/types/dossier'

export interface DocumentSelectedCardProp {
  dossier?: DossierPlateforme
  document?: DocumentTeleverse
  readOnly?: boolean
  onDeleteDocument?: VoidFunction
}

function ValueLabled({ label, value }: { label?: string; value?: string | ReactElement }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="body2" color="text.primary">
        {label}
      </Typography>
      <Typography textOverflow="ellipsis" overflow="hidden" variant="body1" color="text.secondary">
        {value}
      </Typography>
    </Box>
  )
}

export default function DocumentSelectedCard({
  dossier,
  document,
  readOnly,
  onDeleteDocument,
}: DocumentSelectedCardProp) {
  const { enqueueSnackbar } = useSnackbar()
  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()

  if (!document) {
    return null
  }

  // props:
  const { nomDocument, typeDocument, dateDocument, formatFichier, tailleFichier } = document
  const codeDossier = dossier?.code

  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeDossier) {
      throw new Error('erreur inattendu')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement du document a échoué`, { variant: 'error' })
      })
  }

  // rendu:
  return (
    <Card sx={{ width: '100%' }}>
      <CardContent sx={{ padding: '4px !important', backgroundColor: '#d0c3dd91' }}>
        <Grid padding={0} container width="100%">
          <Grid display="flex" alignItems="center" item md={0.5} xs={0.5}>
            <FichierIcon />
          </Grid>
          <Grid item md={3} xs={3}>
            <ValueLabled label="Nom document" value={nomDocument} />
          </Grid>
          <Grid item md={4} xs={4}>
            <ValueLabled
              label="Type document"
              value={<ReferentielText referentielName="typesDocument" referentielCode={typeDocument} />}
            />
          </Grid>
          <Grid item md={2} xs={2}>
            <ValueLabled label="Date document" value={formatDateFR(dateDocument)} />
          </Grid>
          <Grid item md={2} xs={2}>
            <ValueLabled
              label="Format / Taille"
              value={`${formatFichier ?? '--'} / ${toHumanReadableFileSize(tailleFichier)}`}
            />
          </Grid>
          <Grid display="flex" alignItems="center" item md={0.5} xs={0.5}>
            {readOnly ? (
              <TelechargerIconButton
                onClick={() => onDownload(document)}
                loading={isLoadingAuthDownload}
                tooltip
                tooltipPlacement="bottom"
              />
            ) : (
              <SupprimerIconButton
                // onClick={() => dispatch(setSelectedDocument(undefined))}
                onClick={onDeleteDocument}
                tooltip="Supprimer le document sélectionné"
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
