import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { Grid, Stack } from '@mui/material'
import { FallbackError } from 'plateforme/components'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import ForbiddenPage from 'plateforme/pages/ForbiddenPage'
import InformationsMedecinPart from 'assureur/parts/ficheMedecin/InformationsMedecinPart'
import MessagesMedecinPart from 'assureur/parts/ficheMedecin/MessageMedecinPart'
import LieuxExerciceMedecinPart from 'assureur/parts/ficheMedecin/LieuxExerciceMedecinPart'
import HabilitationsMedecinPart from 'assureur/parts/ficheMedecin/HablitationsMedecinPart'
import { useGetMedecinByCodeQuery } from 'assureur/store/apis/medecinEntrepriseApi'
import { usePostAcquitterMessageMutation } from 'assureur/store/apis/messageEntrepriseApi'
import { useGetListeHabilitationsActivesEntrepriseQuery } from 'assureur/store/apis/habilitationEntrepriseApi'
import { hasAccesFicheMedecinNonHabilite } from 'assureur/services/rolesEntreprise.services'

export default function FicheMedecinEntreprisePage() {
  const { codeMedecin } = useParams()
  const handleError = useErrorHandler()

  if (codeMedecin === undefined) {
    throw new Error('Aucun code médecin défini')
  }

  const {
    data: utilisateur,
    isError: isErrorUtilisateur,
    error: errorUtilisateur,
    isLoading: isLoadingUtilisateur,
    isFetching: isFetchingUtilisateur,
  } = useGetUtilisateurActifQuery()
  if (isErrorUtilisateur) {
    handleError({
      errorApi: errorUtilisateur,
      title: `Erreur chargement de l'utilisateur`,
      api: 'GetUtilisateurActif',
    } as FallbackError)
  }

  const { data: medecin, isLoading, isFetching, isError } = useGetMedecinByCodeQuery({ codeMedecin })

  const [acquitterMessage] = usePostAcquitterMessageMutation()
  useEffect(() => {
    acquitterMessage({ types: ['CREA_HAB'], codeMedecin: medecin?.code })
  }, [acquitterMessage, medecin?.code])

  if (medecin === undefined && !isFetching && !isLoading && isError) {
    handleError({
      errorApi: isError,
      title: 'Erreur chargement du profil médecin',
      api: 'GetMedecinByCodeQuery',
    } as FallbackError)
  }

  // get habilitations actives de medecin
  const {
    data: listeHabilitationActiveMedecin,
    isError: isErrorHabilitationsActives,
    error: errorHabilitationsActives,
    isLoading: isLoadingHabilitationsActives,
    isFetching: isFetchingHabilitationsActives,
  } = useGetListeHabilitationsActivesEntrepriseQuery({
    filter: { active: 1, codeMedecin },
  })
  if (isErrorHabilitationsActives) {
    handleError({
      errorApi: errorHabilitationsActives,
      title: 'Erreur chargement des habilitations actives',
      api: 'GetListeHabilitationsActivesEntrepriseQuery',
    } as FallbackError)
  }

  const loading =
    isLoading ||
    isFetching ||
    isLoadingHabilitationsActives ||
    isFetchingHabilitationsActives ||
    isLoadingUtilisateur ||
    isFetchingUtilisateur

  const isMedecinHabilite = (listeHabilitationActiveMedecin?.totalItems ?? 0) > 0 ?? true
  if (!loading && !isMedecinHabilite && !hasAccesFicheMedecinNonHabilite(utilisateur?.profilActif)) {
    return <ForbiddenPage />
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start">
      <Grid item xs={12}>
        <NavigationPart label="Fiche médecin" />
      </Grid>
      <Grid item container xs={12} sm={12} md={12} lg={5}>
        <Grid item xs={12}>
          <InformationsMedecinPart medecin={medecin} loading={loading} />
        </Grid>
        <Grid item xs={12}>
          <MessagesMedecinPart codeMedecin={codeMedecin} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={7}>
        <Stack>
          <LieuxExerciceMedecinPart medecin={medecin} loading={loading} />
          <HabilitationsMedecinPart medecin={medecin} loading={loading} />
        </Stack>
      </Grid>
    </Grid>
  )
}
