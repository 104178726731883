import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { ReadOnlyDatePicker, ReadOnlyTextField } from 'plateforme/components'
import { AFFECTATION_CENTRALE_LABEL } from 'plateforme/constantes'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'

type IdentificationDossierPartProps = {
  dossier?: DossierEntreprise
  profilEntreprise?: ProfilEntreprise
}

export default function IdentificationDossierPart({ dossier, profilEntreprise }: IdentificationDossierPartProps) {
  const cloisonnementServiceActif = profilEntreprise?.parametrage?.cloisonnementService
  const refVictimeEntrepriseActif = profilEntreprise?.parametrage?.refVictimeEntreprise || dossier?.refVictimeEntreprise

  let refDossierEntrepriseMd
  let refVictimeEntrepriseMd
  let codeDossierMd
  let nomPersonneAExaminerMd
  let dateNaissancePersonneAExaminerMd
  let servicerMd

  let refDossierEntrepriseLg
  let refVictimeEntrepriseLg
  let codeDossierLg
  let nomPersonneAExaminerLg
  let dateNaissancePersonneAExaminerLg
  let servicerLg

  if (cloisonnementServiceActif && !refVictimeEntrepriseActif) {
    // cloisonnementServiceActif seul
    refDossierEntrepriseMd = 6
    refVictimeEntrepriseMd = 0
    codeDossierMd = 6
    nomPersonneAExaminerMd = 6
    dateNaissancePersonneAExaminerMd = 6
    servicerMd = 12

    refDossierEntrepriseLg = 8
    refVictimeEntrepriseLg = 0
    codeDossierLg = 4
    nomPersonneAExaminerLg = 4
    dateNaissancePersonneAExaminerLg = 4
    servicerLg = 4
  } else if (refVictimeEntrepriseActif && !cloisonnementServiceActif) {
    // refVictimeEntrepriseActif seul

    refDossierEntrepriseMd = 6
    refVictimeEntrepriseMd = 6
    codeDossierMd = 12
    nomPersonneAExaminerMd = 6
    dateNaissancePersonneAExaminerMd = 6
    servicerMd = 0

    refDossierEntrepriseLg = 4
    refVictimeEntrepriseLg = 4
    codeDossierLg = 4
    nomPersonneAExaminerLg = 8
    dateNaissancePersonneAExaminerLg = 4
    servicerLg = 0
  } else if (refVictimeEntrepriseActif && cloisonnementServiceActif) {
    // les deux

    refDossierEntrepriseMd = 6
    refVictimeEntrepriseMd = 6
    codeDossierMd = 6
    nomPersonneAExaminerMd = 6
    dateNaissancePersonneAExaminerMd = 6
    servicerMd = 6

    refDossierEntrepriseLg = 4
    refVictimeEntrepriseLg = 4
    codeDossierLg = 4
    nomPersonneAExaminerLg = 4
    dateNaissancePersonneAExaminerLg = 4
    servicerLg = 4
  } else {
    // aucun

    refDossierEntrepriseMd = 6
    refVictimeEntrepriseMd = 0
    codeDossierMd = 6
    nomPersonneAExaminerMd = 6
    dateNaissancePersonneAExaminerMd = 6
    servicerMd = 0

    refDossierEntrepriseLg = 6
    refVictimeEntrepriseLg = 0
    codeDossierLg = 6
    nomPersonneAExaminerLg = 6
    dateNaissancePersonneAExaminerLg = 6
    servicerLg = 0
  }

  return (
    <Card>
      <CardHeader title="Identification du dossier" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={refDossierEntrepriseMd} lg={refDossierEntrepriseLg}>
            <ReadOnlyTextField
              id="ref-dossier-entreprise"
              label="Votre référence dossier"
              value={dossier?.refDossierEntreprise}
              fullWidth
            />
          </Grid>
          {refVictimeEntrepriseActif && (
            <Grid item xs={12} md={refVictimeEntrepriseMd} lg={refVictimeEntrepriseLg}>
              <ReadOnlyTextField
                id="ref-victime-entreprise"
                label="Numéro de victime"
                value={dossier?.refVictimeEntreprise}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} md={codeDossierMd} lg={codeDossierLg}>
            <ReadOnlyTextField id="code" label="Identifiant dossier plateforme" value={dossier?.code} fullWidth />
          </Grid>
          <Grid item xs={12} md={nomPersonneAExaminerMd} lg={nomPersonneAExaminerLg}>
            <ReadOnlyTextField
              id="personne-a-examiner-prenom-nom"
              label="Personne à examiner"
              value={`${dossier?.personneAExaminer?.prenom} ${dossier?.personneAExaminer?.nom}`}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={dateNaissancePersonneAExaminerMd} lg={dateNaissancePersonneAExaminerLg}>
            <ReadOnlyDatePicker
              InputProps={{
                id: 'personne-a-examiner-date-naissance',
                fullWidth: true,
              }}
              label="Date de naissance de la personne à examiner"
              value={
                dossier?.personneAExaminer?.dateNaissance === undefined
                  ? null
                  : dossier?.personneAExaminer?.dateNaissance
              }
            />
          </Grid>
          {cloisonnementServiceActif && (
            <Grid item xs={12} md={servicerMd} lg={servicerLg}>
              <ReadOnlyTextField
                id="service-entreprise"
                label="Service"
                value={dossier?.libelleServiceEntreprise ?? AFFECTATION_CENTRALE_LABEL}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
