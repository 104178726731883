import React from 'react'
import uuid from 'react-uuid'
import { Card, CardContent, Typography, Box, Avatar, Grid, darken } from '@mui/material'
import { formatDateTimeStringFr } from 'plateforme/services/dates.services'
import { MedecinIcon, ProfilIcon } from 'plateforme/components'
import CheckedIcon from 'plateforme/components/icons/CheckdIcon'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { ExpediteurType, MessageMissionPlateforme } from 'plateforme/store/types/messageMission'
import DossierPlateforme from 'plateforme/store/types/dossier'
import MissionPlateforme from 'plateforme/store/types/mission'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import DocumentSelectedCard from './DocumentSelectedCard'
import DocumentDeletedCard from './DocumentDeletedCard'

type MessageContentProps = {
  messageMission: MessageMissionPlateforme
  labelExpediteur?: string
  dossier?: DossierPlateforme
  document?: DocumentTeleverse
  isUserExpediteur?: boolean
}

function MessageContent({ messageMission, labelExpediteur, dossier, document, isUserExpediteur }: MessageContentProps) {
  const { expediteur, message, date, lu } = messageMission
  const isExpediteurEntreprise = expediteur === ExpediteurType.ENTREPRISE
  return (
    <Card sx={{ marginBottom: 0.5 }}>
      <CardContent
        sx={{
          padding: '8px 16px 0 16px !important',
          backgroundColor: isUserExpediteur ? '#c9bdd9' : '#ddd3e666',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar sx={{ width: 35, height: 35, backgroundColor: '#a68cbe' }}>
              {isExpediteurEntreprise ? <ProfilIcon /> : <MedecinIcon />}
            </Avatar>
            <Typography noWrap variant="subtitle2" color="text.primary" paddingLeft={1}>
              {labelExpediteur}
            </Typography>
            <Typography variant="body2" color="gray" paddingLeft={1}>
              {formatDateTimeStringFr(date)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'end' }}>
            {lu && !isUserExpediteur && <CheckedIcon sx={{ color: darken('#37c6bf', 0.2) }} />}
          </Box>
        </Box>
        <Box sx={{ paddingTop: 1, paddingBottom: 1.5 }}>
          {message?.split('\n').map((line) => (
            <Typography
              key={uuid()}
              style={{ margin: 0 }}
              sx={{ width: '100%' }}
              variant="body2"
              color="text.primary"
              paragraph
              display="block"
            >
              {line}
            </Typography>
          ))}
        </Box>
        {document && messageMission.codeDocument && (
          <Box sx={{ display: 'flex', width: '100%', paddingBottom: 1.5 }}>
            <DocumentSelectedCard dossier={dossier} readOnly document={document} />
          </Box>
        )}
        {!document && messageMission.codeDocument && (
          <Box sx={{ display: 'flex', width: '100%', paddingBottom: 1.5 }}>
            <DocumentDeletedCard codeDocument={messageMission.codeDocument} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

type MessageBulleProps = {
  isFirst?: boolean
  dossier?: DossierPlateforme
  mission?: MissionPlateforme
  message?: MessageMissionPlateforme
  document?: DocumentTeleverse
  typeUtilisateur?: TypePartenaire
}

export default function MessageBulle({
  isFirst,
  dossier,
  mission,
  message,
  document,
  typeUtilisateur,
}: MessageBulleProps) {
  const isExpedeteurEntreprise = message?.expediteur === ExpediteurType.ENTREPRISE
  const labelExpediteur = isExpedeteurEntreprise ? dossier?.contactEntreprise?.libelle : mission?.medecin?.libelle
  const isUserExpediteur = typeUtilisateur === message?.expediteur

  return (
    <>
      {isUserExpediteur && <Grid item xs={2} />}
      <Grid item xs={10} marginTop={isFirst ? -2.5 : 0}>
        {message && (
          <MessageContent
            dossier={dossier}
            messageMission={message}
            labelExpediteur={labelExpediteur}
            document={document}
            isUserExpediteur={isUserExpediteur}
          />
        )}
      </Grid>
      {!isUserExpediteur && <Grid item xs={2} />}
    </>
  )
}
