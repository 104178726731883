import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import HabilitationEntreprise, {
  CreerHabilitationRequest,
  HabilitationEntrepriseFilter,
  HabilitationEntrepriseSortType,
  ModifierHabilitationRequest,
} from '../types/habilitationEntreprise'

export const habilitationEntrepriseApi = createApi({
  reducerPath: 'habilitationEntrepriseApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getListeHabilitationsEntreprise: builder.query<
      PageRecherche<HabilitationEntreprise>,
      PageRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'entreprise/habilitation',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    postCreerHabilitation: builder.mutation<HabilitationEntreprise, CreerHabilitationRequest>({
      query: (body) => ({
        url: 'entreprise/habilitation',
        method: 'POST',
        body,
      }),
    }),
    putModifierHabilitation: builder.mutation<HabilitationEntreprise, ModifierHabilitationRequest>({
      query: ({ codeMedecin, clotureImmediate, ...body }) => ({
        url: `entreprise/habilitation/${codeMedecin}?clotureImmediate=${clotureImmediate}`,
        method: 'PUT',
        param: {
          clotureImmediate,
        },
        body,
      }),
    }),
  }),
})

export const {
  useGetListeHabilitationsEntrepriseQuery,
  usePostCreerHabilitationMutation,
  usePutModifierHabilitationMutation,
} = habilitationEntrepriseApi

export const useGetListeHabilitationsActivesEntrepriseQuery = ({
  filter,
  perPage,
  page,
  sort,
  onlyTotal,
}: PageRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>) =>
  useGetListeHabilitationsEntrepriseQuery({
    perPage,
    page,
    sort,
    filter: {
      ...filter,
    },
    onlyTotal,
  })
