import { profilMedecinApi } from './apis/profilMedecinApi'
import { dossierMedecinApi } from './apis/dossierMedecinApi'
import { habilitationMedecinApi } from './apis/habilitationMedecinApi'
import { referentielEntrepriseMedecinApi } from './apis/referentielEntrepriseMedecinApi'
import documentUploadReducer, { documentUploadSliceName } from './slices/document/documentDossierUploadSlice'
import documentRapportUploadReducer, {
  documentRapportUploadSliceName,
} from './slices/document/documentRapportUploadSlice'

import documentFactureUploadReducer, {
  documentFactureUploadSliceName,
} from './slices/document/documentFactureUploadSlice'
// import dommagesReducer, { dommagesSliceName } from './slices/dommage/dommagesSlice'
import searchCriteriaReducer, { searchCrieteriaSliceName } from './slices/searchCriteriaSlice/searchCriteriaSlice'
import documentMessageUploadReducer, {
  documentMessageUploadSliceName,
} from './slices/document/documentMessageUploadSlice'
import { messageMedecinApi } from './apis/messageMedecinApi'
import selectedMessagesMissionMedecinReducer, {
  selectedMessagesMissionMedecinSliceName,
} from './slices/selectedMessagesMission/selectedMessagesMissionSlice'
import selectedDocumentMedecinReducer, {
  selectedDocumentMedecinSliceName,
} from './slices/document/selectedDocumentSlice'

/* Définition des reducers de la plateforme Médecin */
export const medecinReducers = {
  /* --- sync --- */

  /* --- async -> rtk.query --- */
  [messageMedecinApi.reducerPath]: messageMedecinApi.reducer,
  [profilMedecinApi.reducerPath]: profilMedecinApi.reducer,
  [dossierMedecinApi.reducerPath]: dossierMedecinApi.reducer,
  [habilitationMedecinApi.reducerPath]: habilitationMedecinApi.reducer,
  [referentielEntrepriseMedecinApi.reducerPath]: referentielEntrepriseMedecinApi.reducer,

  /* --- autres --- */
  [documentUploadSliceName]: documentUploadReducer,
  [documentRapportUploadSliceName]: documentRapportUploadReducer,
  [documentFactureUploadSliceName]: documentFactureUploadReducer,
  [documentMessageUploadSliceName]: documentMessageUploadReducer,
  // [dommagesSliceName]: dommagesReducer,
  [searchCrieteriaSliceName]: searchCriteriaReducer,
  [selectedMessagesMissionMedecinSliceName]: selectedMessagesMissionMedecinReducer,
  [selectedDocumentMedecinSliceName]: selectedDocumentMedecinReducer,
}

/* Définition des middlewares de la plateforme Médecin */
export const medecinMiddlewares = [
  messageMedecinApi.middleware,
  profilMedecinApi.middleware,
  dossierMedecinApi.middleware,
  habilitationMedecinApi.middleware,
  referentielEntrepriseMedecinApi.middleware,
]

/* Définition des utils de la plateforme Médecin */
export const medecinUtils = [
  messageMedecinApi.util,
  profilMedecinApi.util,
  dossierMedecinApi.util,
  habilitationMedecinApi.util,
  referentielEntrepriseMedecinApi.util,
]
