import React from 'react'
import { Fab, FabProps } from '@mui/material'
import { Close } from '@mui/icons-material'

export default function RefuserRoundButton(props: FabProps) {
  const { ...other } = props

  return (
    <Fab color="tile" aria-label="Refuser" size="small" {...other}>
      <Close color="error" />
    </Fab>
  )
}
