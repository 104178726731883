import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { EditTextField, PhoneNumberInput } from 'plateforme/components'

interface ContactsGenerauxPartProps {
  readOnly?: boolean
}

export default function ContactsGenerauxPart({ readOnly }: ContactsGenerauxPartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Contacts généraux / organisation" />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name="mail"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label="Adresse mail entreprise"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="telephone"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <PhoneNumberInput
                  id="contact-entreprise"
                  label="Téléphone entreprise"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
