import * as React from 'react'
import { SvgIconProps, Tooltip, useTheme } from '@mui/material'
import { QueryBuilder } from '@mui/icons-material'

export interface FileInProgressIconProps extends SvgIconProps {
  tooltip?: NonNullable<React.ReactNode>
  tooltipPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
  withMargin?: boolean
}

export default function FileInProgressIcon(props: FileInProgressIconProps) {
  const { tooltip, tooltipPlacement, withMargin, sx: otherSx, ...other } = props
  const placement = tooltipPlacement ?? 'right'
  const tooltipMargin = placement === 'right' ? { marginLeft: '-8px !important' } : { marginRight: '-8px !important' }
  const theme = useTheme()
  const iconSx = withMargin ? { margin: theme.spacing(1.5) } : { ...otherSx }
  return (
    <Tooltip
      title={tooltip === true ? 'Traitement en cours' : tooltip ?? false}
      placement={placement}
      open={tooltip ? undefined : false}
      PopperProps={{ sx: { '& .MuiTooltip-tooltip': tooltipMargin } }}
    >
      {/* La div n'est ici que pour tricher, car sinon la tooltip ne s'affiche pas quand le bouton est disable */}
      <div>
        <QueryBuilder sx={iconSx} {...other} />
      </div>
    </Tooltip>
  )
}
