import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import HabilitationMedecinAdmin, {
  HabilitationMedecinAdminFilter,
  HabilitationMedecinAdminSortType,
} from 'admin/store/types/habilitationMedecinAdmin'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest, SortParam } from 'plateforme/store/types/pageRecherche'

export const habilitationMedecinAdminApi = createApi({
  reducerPath: 'habilitationMedecinAdminApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getListeHabilitationMedecinAdmin: builder.query<
      PageRecherche<HabilitationMedecinAdmin>,
      PageRechercheRequest<HabilitationMedecinAdminFilter, HabilitationMedecinAdminSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'admin/medecin/habilitation',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
  }),
})

export const { useGetListeHabilitationMedecinAdminQuery } = habilitationMedecinAdminApi

export const habilitationActiveWithCodeMedecin = (codeMedecin: string) =>
  ({
    filter: { activeFutur: 1, codeMedecin },
    sort: {
      sortType: HabilitationMedecinAdminSortType.DATE_DEBUT_SORT,
      sortOrder: 'DESC',
    } as SortParam<HabilitationMedecinAdminSortType>,
    perPage: 100,
  } as PageRechercheRequest<HabilitationMedecinAdminFilter, HabilitationMedecinAdminSortType>)
