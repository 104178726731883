import React from 'react'
import { Stack } from '@mui/material'
import NavigationPart from 'plateforme/parts/NavigationPart'
import RechercheIndicateurActiviteAdminPart from 'admin/part/consultationIndicateurActivite/RechercheIndicateurActiviteAdminPart'
import { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { useAppSelector } from 'plateforme/store/hooks/hooks'
import { getIndicateurActiviteCriteria } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { useGetIndicateurActiviteAdminQuery } from 'admin/store/apis/indicateurActiviteAdminApi'
import TableIndicateurActiviteAdminPart from 'admin/part/consultationIndicateurActivite/TableIndicateurActiviteAdminPart'

export default function ConsultationIndicateurActiviteAdminPage() {
  const criteria = useAppSelector(getIndicateurActiviteCriteria) ?? {}
  const { data, isLoading, isSuccess, isError, error } = useGetIndicateurActiviteAdminQuery(criteria)

  return (
    <Stack>
      <NavigationPart label="Indicateurs d'activité de la plateforme TransMED" />
      <RechercheIndicateurActiviteAdminPart searchError={error as IQueryErrorResponse} />
      <TableIndicateurActiviteAdminPart data={data} isLoading={isLoading} isError={isError} isSuccess={isSuccess} />
    </Stack>
  )
}
