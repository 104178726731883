import * as React from 'react'
import { Button, IconButton, IconButtonProps, ButtonProps, Tooltip } from '@mui/material'
import { Add } from '@mui/icons-material'
import theme from 'plateforme/theme'

export interface AjouterIconButtonProps {
  shape?: 'default' | 'square'
  href?: string
  tooltip?: NonNullable<React.ReactNode>
}

export interface DefaultProps extends AjouterIconButtonProps, IconButtonProps {
  variant?: 'contained'
}

export interface SquareProps extends AjouterIconButtonProps, ButtonProps {}

export default function AjouterIconButton(props: DefaultProps | SquareProps) {
  const { tooltip = 'Ajouter', disabled, shape, variant, ...other } = props
  return (
    <Tooltip title={tooltip ?? false} placement="right" open={tooltip ? undefined : false}>
      {/* La div n'est ici que pour tricher, car sinon la tooltip ne s'affiche pas quand le bouton est disable */}
      <div>
        {shape === 'square' ? (
          <Button
            {...other}
            sx={{ '& .MuiButton-startIcon': { margin: 0 }, paddingX: 0, minWidth: 52 }}
            startIcon={<Add />}
            color="primary"
            variant={variant ?? 'contained'}
            disabled={disabled}
          />
        ) : (
          <IconButton
            color="secondary"
            sx={{
              '&:hover ': { backgroundColor: '#2b9f99' },
              backgroundColor: theme.palette.secondary.main,
              '&:disabled': { backgroundColor: 'rgba(0, 0, 0, 0.12)' },
            }}
            disabled={disabled}
            {...other}
          >
            <Add
              sx={{
                color: disabled ? 'rgba(0, 0, 0, 0.26)' : theme.palette.secondary.contrastText,
              }}
            />
          </IconButton>
        )}
      </div>
    </Tooltip>
  )
}
