import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { NoRowsOverlay, VoirIconButton } from 'plateforme/components'
import { TypeReferentielLabel } from 'plateforme/store/types/referentiel'
import { LOCALE } from 'plateforme/constantes'
import { ReferentielTransformed } from 'admin/pages/GererNomenclaturesPage/GererNomenclaturesPage'

export interface TableNomenclaturesPartProps {
  motCle: string
  result: ReferentielTransformed[]
  setNomenclature: React.Dispatch<React.SetStateAction<string>>
}

export default function TableNomenclaturesPart({ motCle, result, setNomenclature }: TableNomenclaturesPartProps) {
  const filterNomenclaturesByMotsCles = () => {
    if (motCle && motCle.length >= 3) {
      return result.filter((option) => option.label.toLocaleLowerCase(LOCALE).includes(motCle))
    }

    return result
  }

  const sortByReferentielLabel = (): ((a: string, b: string) => number) | undefined => {
    return (a, b) =>
      TypeReferentielLabel[a as keyof typeof TypeReferentielLabel] <
      TypeReferentielLabel[b as keyof typeof TypeReferentielLabel]
        ? -1
        : 1
  }

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '500px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nomenclatures</TableCell>
            <TableCell sx={{ width: 100 }} />
          </TableRow>
        </TableHead>
        {filterNomenclaturesByMotsCles().length > 0 ? (
          <TableBody>
            {Array.from(new Set(filterNomenclaturesByMotsCles().map((element) => element.type)))
              .sort(sortByReferentielLabel())
              .map((type) => (
                <TableRow key={type}>
                  <TableCell component="th" scope="row">
                    {TypeReferentielLabel[type as keyof typeof TypeReferentielLabel]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <VoirIconButton onClick={() => setNomenclature(type)} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        ) : (
          <NoRowsOverlay />
        )}
      </Table>
    </TableContainer>
  )
}
