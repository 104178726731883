import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, CardActionArea, CardContent, Checkbox, Grid, Stack, Typography } from '@mui/material'
import { StatutIcon } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import EntrepriseAdmin from 'admin/store/types/profilEntrepriseAdmin'
import { parametrageEntrepriseHref } from 'admin/AdminApp'

export interface CardEntreprisesReferencesProps {
  entreprise: EntrepriseAdmin
}

function ValueLabled({ label, value }: { label?: string; value?: ReactNode }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {value}
      </Typography>
    </Box>
  )
}

export default React.memo(EntreprisesReferencesCard)

function EntreprisesReferencesCard({ entreprise }: CardEntreprisesReferencesProps) {
  const { code: codeEntreprise, dateCreation, mail, codeACPR, codeSIREN, libelle, telephone } = entreprise

  const navigate = useNavigate()

  return (
    <Grid item xs={12} sm={12} md={12} key={entreprise?.code}>
      <Card sx={{ width: '100%' }}>
        <CardActionArea>
          <CardContent sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Box sx={{ display: 'flex', width: '80px', alignItems: 'center' }}>
              <Checkbox color="default" />
            </Box>
            <Grid
              container
              spacing={2}
              width="100%"
              onClick={() => {
                navigate(parametrageEntrepriseHref(codeEntreprise))
              }}
            >
              <Grid item xs={12} sm={6} md={6} lg={3} sx={{ paddingLeft: 0, marginLeft: 0 }}>
                <ValueLabled value={libelle} />
              </Grid>
              {codeACPR && (
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <ValueLabled label="Code ACPR" value={codeACPR} />
                </Grid>
              )}
              {!codeACPR && codeSIREN && (
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <ValueLabled label="Code SIREN" value={codeSIREN} />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ValueLabled
                  label="Contact"
                  value={
                    <>
                      {mail}
                      <br />
                      {telephone}
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{
                    padding: '10px 20px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '70px',
                    alignItems: 'center',
                  }}
                >
                  <StatutIcon active size="sm" />
                  <Typography variant="subtitle2" color="text.secondary" sx={{ marginRight: 1 }}>
                    {`Inscrit depuis le ${formatDateFR(dateCreation)}`}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
