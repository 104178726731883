import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import Actualite from 'plateforme/store/types/actualite'
import { CreerActualiteRequest, ModifierActualiteRequest } from '../types/actualiteAdmin'

export const actualiteAdminApi = createApi({
  reducerPath: 'actualiteAdminApi',
  tagTypes: ['Actualite'],
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getListeActualitesAdmin: builder.query<Actualite[], void>({
      query: () => 'utilisateur/actualite',
      providesTags: (result, error) => (error ? [] : ['Actualite']),
    }),
    postCreerActualite: builder.mutation<Actualite, CreerActualiteRequest>({
      query: (body) => {
        return {
          url: `admin/actualite`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['Actualite']),
    }),
    putModifierActualite: builder.mutation<Actualite, ModifierActualiteRequest>({
      query: ({ id, ...body }) => {
        return {
          url: `admin/actualite/${id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['Actualite']),
    }),
  }),
})

export const { useGetListeActualitesAdminQuery, usePostCreerActualiteMutation, usePutModifierActualiteMutation } =
  actualiteAdminApi
