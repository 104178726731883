import * as React from 'react'
import { Fab, FabProps } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

export interface RetourButtonProps extends FabProps {
  // href : est le path de la page de retour
  href?: string
}

export default function RetourRoundButton(props: RetourButtonProps) {
  const { color, 'aria-label': label, ...other } = props

  return (
    <Fab
      aria-label={label ?? 'Retour'}
      size="small"
      color={color ?? 'secondary'}
      {...other}
      sx={{ marginBottom: '4px', minHeight: '40px', minWidth: '40px' }}
    >
      <ArrowBack sx={{ color: 'white' }} />
    </Fab>
  )
}
