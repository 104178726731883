import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import {
  useGetListeHabilitationEntrepriseAdminQuery,
  totalHabilitationsPourUneEntrepriseRequest,
} from 'admin/store/apis/habilitationEntrepriseAdminApi'
import { AreaLoading } from 'plateforme/components'
import { useGetAdminEntrepriseQuery } from 'admin/store/apis/gestionEntrepriseApi'
import TabsParametresEntreprisePart from 'assureur/parts/parametresEntreprise/TabsParametresEntreprisePart'
import determineAccesModificationParametrage from 'admin/services/rolesAdmin.service'
import TabHabilitationsEntrepriseAdminPart from 'admin/part/HabilitationsEntrepriseAdminPart/TabHabilitationsEntrepriseAdminPart'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'

export default function ParametresEntrepriseAdminPage() {
  const { codeEntreprise } = useParams()
  const {
    data: referentiel,
    isLoading: isLoadingReferentiel,
    isFetching: isFetchingReferentiel,
  } = useGetReferentielQuery()
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilUtilisateur = utilisateur?.profilActif

  if (codeEntreprise === undefined) {
    throw new Error('Aucun code entreprise défini')
  }

  const { data: entreprise, isLoading, isFetching } = useGetAdminEntrepriseQuery({ code: codeEntreprise })

  const { data: listHabilitation } = useGetListeHabilitationEntrepriseAdminQuery(
    totalHabilitationsPourUneEntrepriseRequest(codeEntreprise)
  )

  if (isLoading || isFetching || isLoadingReferentiel || isFetchingReferentiel) {
    return <AreaLoading height={350} />
  }

  const habilitationTab =
    listHabilitation && listHabilitation.totalItems > 0
      ? {
          id: 'habilitations',
          title: 'Liste des habilitations',
          component: <TabHabilitationsEntrepriseAdminPart codeEntreprise={entreprise?.code} />,
        }
      : undefined

  const readOnly = !determineAccesModificationParametrage(profilUtilisateur)

  return (
    <Box marginTop={2}>
      <TabsParametresEntreprisePart
        entreprise={entreprise}
        habilitationTab={habilitationTab}
        readOnly={readOnly}
        referentiel={referentiel}
      />
    </Box>
  )
}
