import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { ReadOnlyEmailField, ReadOnlyTextField, SupprimerIconButton } from 'plateforme/components'
import RechercheMedecinsComponent from 'assureur/parts/rechercheMedecin/RechercheMedecinsComponent'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { getSelectedMedecin, setSelectedMedecin } from 'assureur/store/slices/selectedMedecin/selectedMedecinSlice'
import AutreMedecinPersonnaliseForm from './AutreMedecinPersonnaliseForm'

type MedecinFormPropos = {
  dossier: DossierEntreprise
}

export default function AutreMedecinForm({ dossier }: MedecinFormPropos) {
  const dispatch = useDispatch()
  const { control, watch, resetField, trigger } = useFormContext()
  const isMedecinTransMEDWatched = watch('expertise.isMedecinTransMED')
  const autreMedecin = dossier?.expertise?.autreMedecin
  const selectedMedecin = useSelector(getSelectedMedecin)
  const isMedecinSelected = !!selectedMedecin?.code
  const isMedecinTransmed = !!autreMedecin?.codeMedecin

  const makeMedecinData = () => {
    if (isMedecinSelected) {
      return {
        code: selectedMedecin.code,
        libelle: selectedMedecin.libelle,
        adresseComplete: selectedMedecin.adresseComplete,
        mail: selectedMedecin.mailContact,
        telephoneMobile: selectedMedecin.telephoneMobile,
        telephoneFixe: selectedMedecin.telephoneFixe,
      }
    }
    if (isMedecinTransmed) {
      return {
        code: autreMedecin.codeMedecin,
        libelle: autreMedecin.libelle,
        adresseComplete: autreMedecin.adresseComplete,
        mail: autreMedecin.mail,
        telephoneMobile: autreMedecin.telephoneMobile,
        telephoneFixe: autreMedecin.telephoneFixe,
      }
    }
    return undefined
  }

  const handleRemoveMedecin = () => {
    if (isMedecinTransMEDWatched) {
      dispatch(
        setSelectedMedecin({
          code: undefined,
          codeSIREN: undefined,
          codeRPPS: undefined,
          libelle: undefined,
          mailContact: undefined,
          adresseComplete: undefined,
          telephoneFixe: undefined,
          telephoneMobile: undefined,
        })
      )
    } else {
      resetField('expertise.autreMedecin', {
        defaultValue: {
          code: undefined,
          codeMedecin: '',
          libelle: '',
          adresse1: '',
          adresse2: '',
          adresse3: '',
          codePostal: '',
          commune: '',
          pays: 'FR',
          mail: '',
          telephoneMobile: '',
          telephoneFixe: '',
        },
      })
    }
  }

  const medecinData = makeMedecinData()

  return (
    <Card>
      <CardHeader title="Autre médecin" />
      <CardContent>
        <Grid container>
          <Grid item xs={11} display="flex" alignItems="center">
            <Controller
              name="expertise.isMedecinTransMED"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <FormControl error={!!error} variant="standard">
                  <FormControlLabel
                    id="expertise-is-medecin-transmed"
                    label="Médecin Transmed"
                    value={value}
                    control={
                      <Switch
                        onBlur={onBlur}
                        onChange={(e) => {
                          onChange(e)
                          trigger('expertise.autreMedecin')
                        }}
                        checked={value}
                      />
                    }
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={1} textAlign="end">
            {(isMedecinSelected || !isMedecinTransMEDWatched) && (
              <Box>
                <SupprimerIconButton onClick={handleRemoveMedecin} tooltip="Retirer" />
              </Box>
            )}
          </Grid>
          {isMedecinTransMEDWatched ? (
            <>
              {isMedecinSelected && (
                <>
                  <Grid item xs={12}>
                    <ReadOnlyTextField
                      id="expertise-autre-medecin-libelle"
                      label="Identité autre médecin"
                      value={medecinData?.libelle}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReadOnlyTextField
                      id="expertise-autre-medecin-adresse-complete"
                      label="Adresse"
                      value={medecinData?.adresseComplete}
                      fullWidth
                      minRows={3}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <ReadOnlyEmailField
                      id="expertise-autre-medecin-mail"
                      label="Adresse mail"
                      value={medecinData?.mail}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ReadOnlyTextField
                      id="expertise-autre-medecin-telephone-mobile"
                      label="Tél. portable"
                      value={medecinData?.telephoneMobile}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ReadOnlyTextField
                      id="expertise-autre-medecin-telephone-fixe"
                      label="Tél. fixe"
                      value={medecinData?.telephoneFixe}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
              {!isMedecinSelected && <RechercheMedecinsComponent cardsNavigable={false} />}
            </>
          ) : (
            <AutreMedecinPersonnaliseForm />
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
