import * as React from 'react'
import { Tooltip } from '@mui/material'
import { Info } from '@mui/icons-material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export interface InformationIconButtonProps extends LoadingButtonProps {
  tooltip?: NonNullable<React.ReactNode>
  tooltipPlacement?: 'right' | 'left'
}

export default function InformationIconButton(props: InformationIconButtonProps) {
  const { tooltip, tooltipPlacement, 'aria-label': label, sx: otherSx, ...other } = props
  const placement = tooltipPlacement ?? 'right'
  const tooltipMargin = placement === 'right' ? { marginLeft: '-8px !important' } : { marginRight: '-8px !important' }
  return (
    <Tooltip
      title={tooltip === true ? 'Information' : tooltip ?? false}
      placement={placement}
      open={tooltip ? undefined : false}
      PopperProps={{ sx: { '& .MuiTooltip-tooltip': tooltipMargin } }}
    >
      <div>
        <LoadingButton
          color="secondary"
          aria-label={label ?? 'information'}
          sx={{ minWidth: '32px !important', ...otherSx }}
          {...other}
        >
          <Info />
        </LoadingButton>
      </div>
    </Tooltip>
  )
}
