import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import { HISTORIQUE_DOSSIERS_MAX_TIME_CACHE } from 'plateforme/constantes'
import transmedApiUrl from './transmedApi'
import HistoriqueConsultation from '../types/historiqueConsultation'

export const historiqueConsultationApi = createApi({
  reducerPath: 'historiqueConsultationsMedecinApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getTop10HistoriqueConsultations: builder.query<HistoriqueConsultation[], void>({
      query: () => 'utilisateur/historique',
      // TODO a remplacer par un reset du tag
      keepUnusedDataFor: HISTORIQUE_DOSSIERS_MAX_TIME_CACHE,
    }),
  }),
})

export const { useGetTop10HistoriqueConsultationsQuery } = historiqueConsultationApi
