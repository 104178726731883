import * as React from 'react'
import { Button, ButtonProps, SvgIcon, Stack, styled, Typography, CircularProgress, Tooltip } from '@mui/material'
import { LoadingProps } from 'plateforme/services/utils'

const ActionButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 168,
  textAlign: 'center',
  alignItems: 'flex-end',
  padding: theme.spacing(3, 1, 3, 1),
  '&:disabled': {
    boxShadow: theme.shadows[2],
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'transparent',
    },
  },
}))

const ActionStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
}))

type TableauDeBordTileProps = {
  label?: string
  labelSize?: 'small' | 'large'
  icon?: React.ReactNode
  tooltip?: string
}

function ActionTile(props: TableauDeBordTileProps & LoadingProps & ButtonProps) {
  const { label, labelSize, icon, tooltip, loading, ...other } = props
  return (
    <Tooltip title={tooltip}>
      <div>
        <ActionButton variant="contained" color="tile" disabled={loading} {...other}>
          <ActionStack>
            <Typography
              variant={labelSize === 'small' ? 'subtitle2' : 'subtitle1'}
              fontWeight="500"
              sx={{ verticalAlign: 'top' }}
            >
              {label}
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <SvgIcon fontSize="large" color="secondary">
                {icon}
              </SvgIcon>
            )}
          </ActionStack>
        </ActionButton>
      </div>
    </Tooltip>
  )
}

export default ActionTile
