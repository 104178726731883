import React from 'react'
import DelaisCommonPart from './DelaisCommonPart/DelaisCommonPart'

interface DelaisNonReponsePartProps {
  readOnly?: boolean
}

export default function DelaisNonReponsePart({ readOnly }: DelaisNonReponsePartProps) {
  return (
    <DelaisCommonPart
      readOnly={readOnly}
      controllerName="parametrage.delaisRelance.DELAI_REPONSE_MISSION"
      title="Délai relance médecin non réponse proposition de mission"
    />
  )
}
