import * as React from 'react'
import { SvgIconProps, Tooltip, useTheme } from '@mui/material'
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined'
import FacturePlateforme from 'plateforme/store/types/facture'
import { StatutFacture } from 'medecin/store/types/factureMedecin'

export default function FactureIcon(props: SvgIconProps & { withMargin?: boolean; facture?: FacturePlateforme }) {
  const { withMargin, facture, sx: otherSx, ...other } = props
  const theme = useTheme()

  let colorSx = { color: 'text.primary' }
  let title = 'Facture déposée'
  if (facture?.statut === StatutFacture.ACCEPTEE) {
    colorSx = { color: '#44b700 !important' }
    title = 'Facture acceptée'
  }
  if (facture?.statut === StatutFacture.DEMANDE_MODIFICATION) {
    colorSx = { color: '#f91010 !important' }
    title = 'Modification nécéssaire'
  }

  const baseSx = { fontSize: 32, ...otherSx, ...colorSx }
  const iconSx = withMargin ? { margin: theme.spacing(1.5), ...baseSx } : { ...baseSx }
  return (
    <Tooltip title={title} placement="right">
      <EuroSymbolOutlinedIcon sx={iconSx} {...other} />
    </Tooltip>
  )
}
