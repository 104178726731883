import React from 'react'
import { Grid } from '@mui/material'
import { Mail } from '@mui/icons-material'
import { ActionTile } from 'plateforme/components'
import { MAIL_ASSISTANCE } from 'plateforme/constantes'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import EcrireMessageForm from '../EcrireMessageForm/EcrireMessageForm'

export default function ActionTilesProfilMedecinPart() {
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <ActionTile
          label="Écrire au support plateforme pour modifier mes informations"
          labelSize="small"
          icon={<Mail />}
          onClick={() => window.open(`mailto:${MAIL_ASSISTANCE}`, '_self')}
          loading={false}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <ActionTile
          label="Écrire au support plateforme pour une autre question"
          labelSize="small"
          icon={<Mail />}
          onClick={() => window.open(`mailto:${MAIL_ASSISTANCE}`, '_self')}
          loading={false}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <ActionTile
          label="Écrire un message aux entreprises m'habilitant"
          labelSize="small"
          icon={<Mail />}
          loading={false}
          onClick={() =>
            confirm({
              title: `Écrire un message aux entreprises m'habilitant`,
              confirmMsg: '',
              maxWidth: 'lg',
              fullWidth: true,
              withForm: true,
              form: <EcrireMessageForm onClose={closeConfirmDialog} />,
            })
          }
        />
      </Grid>
      {confirmDialog}
    </Grid>
  )
}
