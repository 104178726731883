import React from 'react'
import { Stack } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import Dossier from 'assureur/store/types/dossierEntreprise'
import { factureConsultable } from 'assureur/services/rolesEntreprise.services'
import FactureMissionEntrepriseCard from './FactureMissionEntrepriseCard'

type TabFacturePartProps = {
  dossier?: Dossier
  profilUtilisateur?: ProfilUtilisateurActif
  loading?: boolean
}

export default function TabFactureEntreprisePart({ dossier, profilUtilisateur, loading }: TabFacturePartProps) {
  const readOnly = factureConsultable(profilUtilisateur)

  return (
    <Stack>
      {loading ? (
        <AreaLoading height={540} />
      ) : (
        dossier &&
        dossier?.missions
          ?.filter((m) => (m.factures?.length ?? 0) > 0)
          .map((m) => (
            <Stack key={m.code}>
              <FactureMissionEntrepriseCard dossier={dossier} mission={m} readOnly={readOnly} />
            </Stack>
          ))
      )}
    </Stack>
  )
}
