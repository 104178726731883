import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@mui/material'
import {
  AreaLoading,
  EditTextField,
  FallbackError,
  PhoneNumberInput,
  SelectInputReferentiel,
} from 'plateforme/components'
import { TypeLieuExpertise } from 'assureur/store/types/dossierEntreprise'
import InfosContactRequisesAlert from 'assureur/parts/editionDossier/InfosContactRequisesAlert'
import { useGetProfilEntrepriseQuery } from 'assureur/store/apis/profilEntrepriseApi'
import { useErrorHandler } from 'react-error-boundary'
import DossierPlateforme from 'plateforme/store/types/dossier'

interface LieuExpertiseCommonProps {
  dossier: DossierPlateforme
}

export default function LieuExpertiseCommon({ dossier }: LieuExpertiseCommonProps) {
  const { control, formState, watch } = useFormContext()
  const handleError = useErrorHandler()

  const {
    data: profilEntreprise,
    isError: isErrorGetProfilEntreprise,
    error: errorGetProfilEntreprise,
    isLoading: loadingProfilEntreprise,
    isFetching: fetchingProfilEntreprise,
  } = useGetProfilEntrepriseQuery()

  if (isErrorGetProfilEntreprise) {
    handleError({
      errorApi: errorGetProfilEntreprise,
      title: 'Erreur chargement des données profil entreprise',
      api: 'GetProfilEntreprise',
    } as FallbackError)
  }

  const paramLieuExpertiseAvecADefinir = profilEntreprise?.parametrage?.lieuExpertiseAvecADefinir

  const severity = formState.errors.expertise && '_error' in formState.errors.expertise ? 'error' : 'info'
  const watchTypeLieuExercice = watch('expertise.typeLieuExpertise')

  if (loadingProfilEntreprise || fetchingProfilEntreprise) {
    return <AreaLoading height={420} />
  }

  return (
    <Grid container>
      <Grid item xs={12} display="flex" alignItems="center">
        <Controller
          name="expertise.typeLieuExpertise"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              variant="standard"
              sx={{ minHeight: 55, padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
            >
              <RadioGroup
                id="radios-lieu-expertise"
                row
                aria-labelledby="radios-lieu-expertise"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
              >
                <FormControlLabel
                  value={TypeLieuExpertise.CABINET}
                  control={
                    <Radio
                      sx={{
                        color: `${error ? 'red' : ''}`,
                      }}
                    />
                  }
                  label="Expertise au cabinet du médecin"
                  sx={{ minHeight: 55 }}
                />
                <FormControlLabel
                  value={TypeLieuExpertise.AUTRE}
                  control={
                    <Radio
                      sx={{
                        color: `${error ? 'red' : ''}`,
                      }}
                    />
                  }
                  label="Autre lieu d’expertise"
                  sx={{ minHeight: 55 }}
                />
                {(dossier.expertise?.typeLieuExpertise === TypeLieuExpertise.NR_A_DEFINIR ||
                  paramLieuExpertiseAvecADefinir === true) && (
                  <FormControlLabel
                    value={TypeLieuExpertise.NR_A_DEFINIR}
                    control={
                      <Radio
                        sx={{
                          color: `${error ? 'red' : ''}`,
                        }}
                      />
                    }
                    label="Non renseigné / À définir"
                    sx={{ minHeight: 55 }}
                  />
                )}
              </RadioGroup>
              <FormHelperText sx={{ margin: '3px 14px' }}>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      {watchTypeLieuExercice === TypeLieuExpertise.AUTRE && (
        <>
          <Grid item xs={12}>
            <InfosContactRequisesAlert severity={severity} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="expertise.adresse1"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-adresse1"
                  label="Adresse 1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="expertise.adresse2"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-adresse2"
                  label="Adresse 2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="expertise.adresse3"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-adresse3"
                  label="Adresse 3"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="expertise.codePostal"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-code-postal"
                  label="Code postal"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="expertise.commune"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-commune"
                  label="Ville"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="expertise.pays"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <SelectInputReferentiel
                  id="expertise-pays"
                  label="Pays"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  referentielName="pays"
                  fullWidth
                  withNoSelectionItem
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="expertise.mail"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="expertise-mail"
                  label="Adresse mail"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="expertise.telephoneMobile"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <PhoneNumberInput
                  id="expertise-telephone-mobile"
                  label="Tél. portable"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              name="expertise.telephoneFixe"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <PhoneNumberInput
                  id="expertise-telephone-fixe"
                  label="Tél. fixe"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  fullWidth
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
