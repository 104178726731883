import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { RevisionDossierRequest } from 'plateforme/store/types/dossier'
import DossierMedecin, {
  DossierRequest,
  DossierMedecinFilter,
  DossierMedecinSortType,
  DocumentRequest,
  HistoriqueDossierMedecin,
} from '../types/dossierMedecin'
import Facture, { FactureFilter, FactureSortType, SuiviFactureMedecinAction } from '../types/factureMedecin'
import MissionEntreprise, {
  MissionRequest,
  ModifierMissionMedecinRequest,
  SuiviMissionRequest,
} from '../types/missionMedecin'
import { SauvegarderConclusionRequest, SupprimerDocumentConclusionRequest } from '../types/rapportConclusionMedecin'

/* Pour l'ajout d'un document au dossier voir --> documentDossierUploadSlice.ts */
export const dossierMedecinApi = createApi({
  reducerPath: 'dossierMedecinApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['Dossier', 'Mission'],
  endpoints: (builder) => ({
    getDossierByCode: builder.query<DossierMedecin, DossierRequest>({
      query: ({ codeDossier }) => `medecin/dossier/${codeDossier}`,
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
    getRechercheDossier: builder.query<
      PageRecherche<DossierMedecin>,
      PageRechercheRequest<DossierMedecinFilter, DossierMedecinSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'medecin/dossier',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
      providesTags: ['Dossier', 'Mission'],
    }),
    getHistoriqueDossier: builder.query<HistoriqueDossierMedecin[], DossierRequest>({
      query: ({ codeDossier }) => `medecin/dossier/${codeDossier}/historique`,
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
    deleteDocumentDossier: builder.mutation<void, DocumentRequest>({
      query: ({ codeDossier, codeDocument }) => {
        return {
          url: `medecin/dossier/${codeDossier}/document/${codeDocument}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, { codeDossier }) => (error ? [] : [{ type: 'Dossier', id: codeDossier }]),
    }),
    postSuiviMission: builder.mutation<MissionEntreprise, SuiviMissionRequest>({
      query: ({ codeDossier, codeMission, ...body }) => ({
        url: `medecin/dossier/${codeDossier}/mission/${codeMission}/suivi`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error ? [] : [{ type: 'Dossier', id: codeDossier }, { type: 'Mission', id: codeMission }, 'Dossier', 'Mission'],
    }),
    putRefMissionMedecin: builder.mutation<MissionEntreprise, ModifierMissionMedecinRequest>({
      query(data) {
        const { codeDossier, codeMission, ...body } = data
        return {
          url: `medecin/dossier/${codeDossier}/mission/${codeMission}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    getMissionByCode: builder.query<MissionEntreprise, MissionRequest>({
      query: ({ codeDossier, codeMission }) => `medecin/dossier/${codeDossier}/mission/${codeMission}`,
      providesTags: (result, error, { codeDossier, codeMission }) => [
        { type: 'Dossier', id: codeDossier },
        { type: 'Mission', id: codeMission },
      ],
    }),
    putSauvegarderConclusion: builder.mutation<MissionEntreprise, SauvegarderConclusionRequest>({
      query: ({ codeDossier, codeMission, validation, ...body }) => ({
        url: `medecin/dossier/${codeDossier}/mission/${codeMission}/rapport`,
        params: {
          validation: validation ? 1 : 0,
        },
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    deleteConclusionDocument: builder.mutation<void, SupprimerDocumentConclusionRequest>({
      query: ({ codeDossier, codeMission, codeDocument }) => ({
        url: `medecin/dossier/${codeDossier}/mission/${codeMission}/rapport/document/${codeDocument}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    postValiderConclusion: builder.mutation<MissionEntreprise, MissionRequest>({
      query: ({ codeDossier, codeMission }) => ({
        url: `medecin/dossier/${codeDossier}/mission/${codeMission}/rapport/validation`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    deleteConclusion: builder.mutation<void, MissionRequest>({
      query: ({ codeDossier, codeMission }) => {
        return {
          url: `medecin/dossier/${codeDossier}/mission/${codeMission}/rapport`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, { codeDossier, codeMission }) =>
        error
          ? []
          : [
              { type: 'Dossier', id: codeDossier },
              { type: 'Mission', id: codeMission },
            ],
    }),
    deleteFacture: builder.mutation<void, { codeDossier: string; codeMission: string; codeFacture: string }>({
      query: ({ codeDossier, codeMission, codeFacture }) => {
        return {
          url: `medecin/dossier/${codeDossier}/mission/${codeMission}/facture/${codeFacture}/suivi`,
          body: {
            action: SuiviFactureMedecinAction.ANNULER,
          },
          method: 'POST',
        }
      },
      invalidatesTags: (result, error) => (error ? [] : ['Dossier']),
    }),
    getRechercheFacture: builder.query<PageRecherche<Facture>, PageRechercheRequest<FactureFilter, FactureSortType>>({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'medecin/facture',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
    getRevisionDossier: builder.query<DossierMedecin, RevisionDossierRequest>({
      query: ({ codeDossier, codeMission }) => ({
        url: `medecin/dossier/${codeDossier}/revision`,
        params: {
          codeMission,
        },
      }),
      providesTags: (result, error, { codeDossier }) => [{ type: 'Dossier', id: codeDossier }],
    }),
  }),
})

export const {
  useGetDossierByCodeQuery,
  useGetRechercheDossierQuery,
  useGetHistoriqueDossierQuery,
  useDeleteDocumentDossierMutation,
  usePostSuiviMissionMutation,
  usePutRefMissionMedecinMutation,
  useGetMissionByCodeQuery,
  usePutSauvegarderConclusionMutation,
  useDeleteConclusionDocumentMutation,
  usePostValiderConclusionMutation,
  useDeleteConclusionMutation,
  useDeleteFactureMutation,
  useGetRechercheFactureQuery,
  useGetRevisionDossierQuery,
} = dossierMedecinApi
