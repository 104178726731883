import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { ReferenceDossier } from './dossierMedecin'
import { ReferenceMission } from './missionMedecin'

export default interface FactureMedecin {
  code: string
  numeroFacture?: string
  dossier?: ReferenceDossier
  mission?: ReferenceMission
  dateFacture?: Date
  montantHonoraires?: Montant
  montantFrais?: Montant
  statut: StatutFacture
  labelStatut?: string
  commentaireDemandeModification?: string
  document: DocumentTeleverse
  dateCreation?: Date
  dateAcceptation?: Date
  dateAnnulation?: Date
  dateDemandeModification?: Date
  dateMiseAJour?: Date
}

export type Montant = {
  montantHT?: number
  montantTTC?: number
  montantTVA?: number
  tauxTVA?: number
}

export enum SuiviFactureMedecinAction {
  ANNULER = 'ANNULER',
}

export interface FactureFilter {
  statuts?: StatutFacture[]
  dateDebut?: string
  dateFin?: string
  codeEntreprise?: string
}

export interface FactureRequest {
  codeDossier: string
  codeMission: string
  numeroFacture?: string
  dateFacture?: Date
  montantHonoraires?: Montant
  montantFrais?: Montant
}

export enum FactureSortType {
  ENTREPRISE_SORT = 'entreprise',
  DATE_FACTURE_SORT = 'date_facture',
  REFERENCE_MISSION_SORT = 'reference_mission',
  NUMERO_FACTURE_SORT = 'numero_facture',
  STATUT_SORT = 'statut',
}

export enum StatutFacture {
  DEPOSEE = 'DEPOSEE',
  ANNULEE = 'ANNULEE',
  ACCEPTEE = 'ACCEPTEE',
  DEMANDE_MODIFICATION = 'DEMANDE_MODIFICATION',
}

// Nombre de facture en attente de validation
export const nbFactureEnAttenteValidationRequest = () =>
  ({
    filter: {
      statuts: [StatutFacture.DEPOSEE],
    },
    onlyTotal: 1,
  } as PageRechercheRequest<FactureFilter, FactureSortType>)
