import React from 'react'
import { Stack, Typography } from '@mui/material'
import { StatutIcon } from 'plateforme/components'
import { StatutMission } from 'plateforme/store/types/mission'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import DossierMedecin from 'medecin/store/types/dossierMedecin'

export interface StatutMissionProps {
  dossier: DossierMedecin
  mission?: MissionMedecin
}

export default React.memo(StatutMissionMedecinChip)

function StatutMissionMedecinChip({ dossier, mission }: StatutMissionProps) {
  const getColorIconStatut = () => {
    if (!mission) {
      return '#858484'
    }
    switch (mission.statut) {
      case StatutMission.PROPOSEE:
      case StatutMission.EN_DEMANDE_DE_MODIFICATION:
        return '#e9de43'
      case StatutMission.EN_COURS:
        return '#4db251'
      default:
        return '#858484'
    }
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '70px',
        alignItems: 'center',
      }}
    >
      <StatutIcon statutColor={getColorIconStatut()} size="sm" />
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {dossier.descriptionStatut}
      </Typography>
    </Stack>
  )
}
