import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyTextField } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import DossierPlateforme from 'plateforme/store/types/dossier'
import { LoadingProps } from 'plateforme/services/utils'

type EvenementPartProps = {
  dossier?: DossierPlateforme
}

export default function EvenementPart({ dossier, loading }: EvenementPartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={264} />
  }
  return <EvenementPartLoaded dossier={dossier} />
}

function EvenementPartLoaded({ dossier }: EvenementPartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props
  const { evenement } = dossier

  // render:
  if (!evenement) {
    return null
  }
  return (
    <Card>
      <CardHeader title="Informations de l'événement" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField
              fullWidth
              id="ref-dossier"
              value={evenement.reference}
              label="Numéro d’événement si différent identifiant dossier"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField
              fullWidth
              id="id-nature-evenement"
              value={evenement.labelNature}
              label="Nature de l'évenement"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReadOnlyTextField
              fullWidth
              id="id-date-evenement"
              value={formatDateFR(evenement.date)}
              label="Date de l'événement"
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              fullWidth
              multiline
              minRows={3}
              id="id-commentaires-evenement"
              value={evenement.commentaire}
              label="Commentaire de l'événement"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
