import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import {
  NotificationAdmin,
  NotificationAdminFilter,
  NotificationAdminSortType,
} from 'admin/store/types/notificationsAdmin'

export const notificationsAdminApi = createApi({
  reducerPath: 'notificationsAdminApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getListeMessages: builder.query<
      PageRecherche<NotificationAdmin>,
      PageRechercheRequest<NotificationAdminFilter, NotificationAdminSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => {
        return {
          url: 'admin/message',
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
            onlyTotal,
          },
        }
      },
    }),
  }),
})

export const { useGetListeMessagesQuery } = notificationsAdminApi
export const useGetListeDernieresNotificationsQuery = () => useGetListeMessagesQuery({ perPage: 10 })
