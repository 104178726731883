import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { AdresseButton, ReadOnlyTextField } from 'plateforme/components'
import { LieuExercice } from 'assureur/store/types/habilitationEntreprise'
import { setSelectedMissionRDV } from 'assureur/store/slices/selectedMissionRDV/selectedMissionRDVSlice'

type LieuExerciceMedecinTabProps = {
  index: number
  lieuExercice: LieuExercice
}

function LieuMissionRDVTab({ index, lieuExercice }: LieuExerciceMedecinTabProps) {
  const dispatch = useDispatch()

  const onSelectLieuRDV = () => {
    dispatch(setSelectedMissionRDV({ ...lieuExercice }))
  }

  return (
    <Grid container marginBottom={-2}>
      <Grid item xs={12}>
        <ReadOnlyTextField
          fullWidth
          rows={3}
          multiline
          id={`lieu-exercice-adresse-${index}`}
          value={lieuExercice.adresseComplete}
          label="Adresse du cabinet"
        />
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="row-reverse">
        <AdresseButton color="secondary" onClick={onSelectLieuRDV}>
          Sélectionner le lieu du rendez-vous
        </AdresseButton>
      </Grid>
    </Grid>
  )
}

// #deebff

export default LieuMissionRDVTab
