import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'

export enum Role {
  ASSU_GESTIONNAIRE = 'PROF_TRANSMED_ASSU_1',
  ASSU_SUPERVISEUR = 'PROF_TRANSMED_ASSU_2',
  ASSU_LECTEUR = 'PROF_TRANSMED_ASSU_3',
  ASSU_ADMIN_RESEAU_MEDECIN = 'PROF_TRANSMED_ASSU_4',
  ASSU_REFERENT_TECH_FLUX = 'PROF_TRANSMED_ASSU_5',
  MED_MEDECIN = 'PROF_TRANSMED_MED_1',
  MED_SECRETAIRE = 'PROF_TRANSMED_MED_2',
  MED_ACCES_API = 'PROF_TRANSMED_MED_3',
  GPSA_ADMIN_METIER = 'PROF_TRANSMED_GPSA_1',
  GPSA_ADMIN_TECH = 'PROF_TRANSMED_GPSA_2',
  GPSA_SONDE = 'PROF_TRANSMED_GPSA_3',
}

export const hasRoles = (profilUtilisateur?: ProfilUtilisateurActif, roles?: Role[]) => {
  return profilUtilisateur && roles?.some((role) => profilUtilisateur?.role?.value === role)
}

export const entrepriseRoles = [
  Role.ASSU_GESTIONNAIRE,
  Role.ASSU_SUPERVISEUR,
  Role.ASSU_LECTEUR,
  Role.ASSU_ADMIN_RESEAU_MEDECIN,
  Role.ASSU_REFERENT_TECH_FLUX,
]
export const entrepriseRolesMetiers = [Role.ASSU_GESTIONNAIRE, Role.ASSU_SUPERVISEUR, Role.ASSU_LECTEUR]

export const entrepriseRolesParametrage = [Role.ASSU_SUPERVISEUR, Role.ASSU_REFERENT_TECH_FLUX]

export const entrepriseRolesNotificationsDossiers = [Role.ASSU_GESTIONNAIRE, Role.ASSU_SUPERVISEUR]

export const medecinRoles = [Role.MED_MEDECIN, Role.MED_SECRETAIRE, Role.MED_ACCES_API]
export const adminRoles = [Role.GPSA_ADMIN_METIER, Role.GPSA_ADMIN_TECH, Role.GPSA_SONDE]
