import React, { useEffect } from 'react'
import { Grid, Stack } from '@mui/material'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { LoadingProps } from 'plateforme/services/utils'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import { messageEntrepriseApi } from 'assureur/store/apis/messageEntrepriseApi'
import InformationSyntheseEntreprisePart from './InformationSyntheseEntreprisePart/InformationSyntheseEntreprisePart'
import DocumentsSyntheseEntreprisePart from './DocumentsSyntheseEntreprisePart/DocumentsSyntheseEntreprisePart'
import NotificationSyntheseEntreprisePart from './NotificationSyntheseEntreprisePart/NotificationSyntheseEntreprisePart'
import ActionTilesSyntheseEntreprisePart from './ActionTilesSyntheseEntreprisePart/ActionTilesSyntheseEntreprisePart'
import SuiviSyntheseEntreprisePart from './SuiviSyntheseEntreprisePart/SuiviSyntheseEntreprisePart'

type TabSyntheseEntreprisePartProps = {
  dossier?: DossierEntreprise
  derniereMission?: MissionEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
  profilEntreprise?: ProfilEntreprise
}

export default function TabSyntheseEntreprisePart({
  dossier,
  derniereMission,
  profilUtilisateur,
  profilEntreprise,
  loading,
}: TabSyntheseEntreprisePartProps & LoadingProps) {
  const dispatch = useAppDispatch()

  // NOTE: REMOVE CASHE TO UPDATE NOTIFICATIONS
  useEffect(() => {
    dispatch(messageEntrepriseApi.util.invalidateTags([{ type: 'MessageEntreprise' }]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Grid container>
      <Grid item md={12} lg={6}>
        <Stack>
          <InformationSyntheseEntreprisePart dossier={dossier} loading={loading} />
          <DocumentsSyntheseEntreprisePart dossier={dossier} profilUtilisateur={profilUtilisateur} loading={loading} />
        </Stack>
      </Grid>
      <Grid item md={12} lg={6}>
        <Stack>
          <NotificationSyntheseEntreprisePart dossier={dossier} loading={loading} />
          <SuiviSyntheseEntreprisePart dossier={dossier} profilUtilisateur={profilUtilisateur} loading={loading} />
          <ActionTilesSyntheseEntreprisePart
            dossier={dossier}
            derniereMission={derniereMission}
            profilUtilisateur={profilUtilisateur}
            profilEntreprise={profilEntreprise}
            loading={loading}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}
