import * as React from 'react'
import { ExpandMore } from '@mui/icons-material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export default function VoirPlusButton(props: LoadingButtonProps) {
  const { children, variant, color, ...other } = props
  return (
    <LoadingButton
      variant={variant ?? 'contained'}
      color={color ?? 'secondary'}
      startIcon={<ExpandMore />}
      loadingPosition="start"
      {...other}
    >
      {children}
    </LoadingButton>
  )
}
