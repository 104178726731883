import * as React from 'react'
import { SvgIconProps, useTheme } from '@mui/material'
import { CompareArrows, Person } from '@mui/icons-material'

export default function ChangerProfilIcon(props: SvgIconProps & { withMargin?: boolean; withCompareArrows?: boolean }) {
  const { withMargin, sx: otherSx, withCompareArrows, ...other } = props
  const theme = useTheme()
  const iconSx1 = withMargin
    ? {
        marginY: theme.spacing(1.5),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(-0.5),
        ...otherSx,
      }
    : {
        marginRight: theme.spacing(-0.5),
        ...otherSx,
      }
  const iconSx2 = withMargin
    ? {
        marginY: theme.spacing(1.5),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0.5),
        ...otherSx,
      }
    : { ...otherSx }
  return (
    <>
      {withCompareArrows && <CompareArrows sx={iconSx1} {...other} />}
      <Person sx={iconSx2} {...other} />
    </>
  )
}
