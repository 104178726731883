import React, { useState } from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Grid,
  Stack,
  Typography,
  Box,
  FormControlLabel,
  darken,
} from '@mui/material'
import { InformationChip } from 'plateforme/components'
import { MedecinPlateformeEntreprise } from 'assureur/store/types/medecinEntreprise'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedMedecin,
  setSelectedMedecin,
  setShowSelectedMedecin,
} from 'assureur/store/slices/selectedMedecin/selectedMedecinSlice'
import theme from 'plateforme/theme'
import { consultationFicheMedecinHref } from 'assureur/EntrepriseApp'
import { useNavigate } from 'react-router-dom'
import HabilitationEntreprise from 'assureur/store/types/habilitationEntreprise'
import { setSelectedMissionRDV } from 'assureur/store/slices/selectedMissionRDV/selectedMissionRDVSlice'
import StatutMedecinChip from './StatutMedecinChip'

interface MedecinEntrepriseCardProps {
  medecin: HabilitationEntreprise | MedecinPlateformeEntreprise
  navigable?: boolean
  chipCardHidden?: boolean
}

function ValueLabled({ label, value }: { label?: string; value?: string }) {
  return (
    <Box
      sx={{
        flexDirection: 'column',
      }}
    >
      <Typography variant="body2" color="readOnly.main">
        {label}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {value}
      </Typography>
    </Box>
  )
}

export default function MedecinEntrepriseCard({
  medecin,
  navigable = true,
  chipCardHidden = false,
}: MedecinEntrepriseCardProps) {
  const colorTheme = theme.palette.primary.main
  const {
    libelle,
    expertiseSurPiece,
    irca,
    numeroHabilitation,
    codeSIREN,
    codeRPPS,
    lieuxExercice,
    code,
    mailContact,
  } = medecin

  const intervenantOccasionnel = 'intervenantOccasionnel' in medecin ? medecin.intervenantOccasionnel : undefined

  const selectedMedecinData = {
    code,
    codeSIREN,
    codeRPPS,
    libelle,
    mailContact,
    adresseComplete: lieuxExercice?.find((lieu) => lieu?.principal)?.adresseComplete,
    telephoneFixe: lieuxExercice?.find((lieu) => lieu?.principal)?.telephone,
    telephoneMobile: undefined,
  }

  const selectedMedecin = useSelector(getSelectedMedecin)
  const [checked, setChecked] = useState(code === selectedMedecin.code)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const checkMedecin = () => {
    setChecked(true)
    dispatch(setShowSelectedMedecin(false))
    dispatch(
      setSelectedMedecin({
        ...selectedMedecinData,
      })
    )
  }

  const uncheckMedecin = () => {
    setChecked(false)
    dispatch(setShowSelectedMedecin(true))
    dispatch(
      setSelectedMedecin({
        code: undefined,
        codeSIREN: undefined,
        codeRPPS: undefined,
        libelle: undefined,
        mailContact: undefined,
        adresseComplete: undefined,
        telephoneFixe: undefined,
        telephoneMobile: undefined,
      })
    )
  }

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      checkMedecin()
    } else {
      uncheckMedecin()
    }

    dispatch(setSelectedMissionRDV(undefined))
  }

  const handleClickCard = () => {
    if (!navigable)
      if (checked) {
        uncheckMedecin()
      } else {
        checkMedecin()
      }
  }

  return (
    <Card
      onClick={handleClickCard}
      sx={{
        width: '100%',
        height: '215px',
        backgroundColor: '#deebff',
        '&:hover': {
          backgroundColor: darken('#deebff', 0.2),
        },
      }}
    >
      <CardActionArea sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
        <CardContent sx={{ display: 'flex', width: '80px', marginRight: '12px', alignItems: 'center' }}>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChangeCheckbox} color="default" />}
              label=""
            />
          </Box>
        </CardContent>
        <CardContent sx={{ display: 'flex' }}>
          <Grid
            container
            direction="column"
            spacing={2.5}
            onClick={() => {
              if (navigable) {
                navigate(consultationFicheMedecinHref(code))
              }
            }}
          >
            <Stack
              direction="row"
              sx={{
                paddingTop: '20px',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <Stack spacing={0}>
                <Typography
                  margin={0}
                  align="left"
                  variant="h3"
                  sx={{ fontWeight: 'bold' }}
                  color="text.primary"
                  gutterBottom
                >
                  {`${libelle ?? ''}  ${numeroHabilitation ? `(${numeroHabilitation})` : ''}`}
                </Typography>

                <Stack direction="row" spacing={6}>
                  {codeRPPS && <ValueLabled label="RPPS" value={codeRPPS} />}
                  {codeSIREN && <ValueLabled label="SIREN" value={codeSIREN} />}
                </Stack>
              </Stack>
              {numeroHabilitation && !chipCardHidden && <StatutMedecinChip labelStatut="Connu" />}
            </Stack>
            <Stack direction="row" padding={0.5} spacing={1}>
              {irca && (
                <InformationChip
                  colorText="white"
                  backgroundColor="#a68cbe"
                  variant="subtitle2"
                  indicateur="Médecin IRCA"
                  border="solid 0.5px"
                  borderColor={colorTheme}
                />
              )}
              {expertiseSurPiece && (
                <InformationChip
                  colorText={colorTheme}
                  border="solid 0.5px"
                  borderColor={colorTheme}
                  variant="subtitle2"
                  indicateur="Avis technique sur pièce"
                />
              )}
              {intervenantOccasionnel && (
                <InformationChip
                  colorText={colorTheme}
                  border="solid 0.5px"
                  borderColor={colorTheme}
                  variant="subtitle2"
                  indicateur="Intervenant occasionnel"
                />
              )}
            </Stack>
            <Stack spacing={0}>
              <Typography variant="body2" color="readOnly.main">
                Établissements
              </Typography>
              <Stack direction="row" padding={1} spacing={1}>
                {lieuxExercice?.map((lieuExerice) => (
                  <InformationChip
                    key={uuid()}
                    colorText={colorTheme}
                    border="solid 0.5px"
                    borderColor={colorTheme}
                    variant="body2"
                    indicateur={`${lieuExerice.adresseComplete.split('\n').pop()}`}
                  />
                ))}
              </Stack>
            </Stack>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
