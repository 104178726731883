import React, { useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { Box, Card, CardContent, CardHeader, Stack, useMediaQuery, useTheme } from '@mui/material'
import { AjouterIconButton, EditTextField, SupprimerIconButton } from 'plateforme/components'

interface RestrictionAccesIpPartProps {
  type: 'extranet' | 'ws'
  readOnly?: boolean
}

export default function RestrictionAccesIpPart({ type, readOnly }: RestrictionAccesIpPartProps) {
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up('xl'))
  const { clearErrors, control, getFieldState, setError, resetField } = useFormContext()
  const name = type === 'extranet' ? 'plagesIpAutoriseesExtranet' : 'plagesIpAutoriseesWs'
  const title = type === 'extranet' ? `Restriction d'accès IP Extranet` : `Restriction d'accès IP Ws`

  const { fields, append, remove } = useFieldArray({
    control,
    name: `parametrage.${name}`,
  })

  const tooltip = `Ajouter une adresse ou une plage d'adresse`
  /* useEffect(() => {
    if (fields.length === 0) {
      append(' ')
    }
  }, [append, fields]) */

  const errors = fields.map((f, index) => getFieldState(`parametrage.${name}.${index}`))
  useEffect(() => {
    errors.forEach((e, index) => {
      if (e.error) {
        setError(`parametrage.${name}.${index}`, e.error)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setError])

  function addOnClick() {
    return () => {
      append(' ')
    }
  }

  function removeOnClick(index: number) {
    return () => {
      remove(index)
      if (fields.length === 1) {
        append(' ')
      }
    }
  }

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
          {fields.map((item, index) => {
            return (
              <li key={item.id}>
                <Stack direction="row" spacing={3.5} marginBottom={index === fields.length - 1 ? 0 : 2.5}>
                  <Controller
                    name={`parametrage.${name}.${index}`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        readOnly={readOnly}
                        id="plages-ip-autorisees"
                        label="Adresse ou plage d'adresses"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          clearErrors(`parametrage.${name}.${index}`)
                          resetField(`parametrage.${name}.${index}`)
                          onChange(e)
                        }}
                        fullWidth
                        fieldError={error}
                      />
                    )}
                  />
                  {!readOnly && (
                    <Box height="60px" paddingTop="12px" marginLeft="8px !important">
                      <SupprimerIconButton onClick={removeOnClick(index)} tooltip="Retirer" />
                    </Box>
                  )}
                  {!readOnly && (
                    <Box height="60px" minWidth="40px" paddingTop="10px" marginLeft="8px !important">
                      {index === fields.length - 1 ? (
                        <AjouterIconButton title="Ajouter" onClick={addOnClick()} tooltip={tooltip} />
                      ) : (
                        ' '
                      )}
                    </Box>
                  )}
                  {breakpoint && <Box width="35%"> </Box>}
                </Stack>
              </li>
            )
          })}
        </ul>
      </CardContent>
    </Card>
  )
}
