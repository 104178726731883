import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import DocumentsTablePart from 'plateforme/parts/DocumentsTablePart'
import { determineDocumentsDossierReadOnly } from 'plateforme/services/dossier.services'
import { LoadingProps } from 'plateforme/services/utils'
import { StatutMission } from 'plateforme/store/types/mission'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'

type DocumentsSyntheseMedecinPartProps = {
  dossier?: DossierMedecin
  mission?: MissionEntreprise
}

export default function DocumentsSyntheseMedecinPart({
  dossier,
  mission,
  loading,
}: DocumentsSyntheseMedecinPartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={391} />
  }
  return <DocumentsSyntheseMedecinPartLoaded dossier={dossier} mission={mission} />
}

function DocumentsSyntheseMedecinPartLoaded({ dossier, mission }: DocumentsSyntheseMedecinPartProps) {
  // error:
  if (!dossier || !mission || !mission?.statut) {
    throw new Error(`Erreur de chargement des données`)
  }

  // format:
  const documents = determineDocumentsDossierReadOnly(dossier)

  // rendu:
  // si statut est PROPOSEE on ne voit pas les documents
  // sinon la visibilité des documents est assurée par le back
  if (StatutMission.PROPOSEE === mission?.statut) {
    return null
  }
  return (
    <Card>
      <CardHeader title="Derniers documents" />
      <CardContent>
        <DocumentsTablePart documents={documents} dossier={dossier} nbLigneParPage={5} readOnly compact />
      </CardContent>
    </Card>
  )
}
