import * as React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { FormatListBulleted } from '@mui/icons-material'

export default function ListeButton(props: ButtonProps) {
  const { children, variant, color, ...other } = props
  return (
    <Button color={color ?? 'primary'} variant={variant ?? 'contained'} startIcon={<FormatListBulleted />} {...other}>
      {children}
    </Button>
  )
}
