import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CloisonnementActive from './CloisonnementActive'

interface CloisonnementPartProps {
  readOnly?: boolean
}

export default function CloisonnementPart({ readOnly }: CloisonnementPartProps) {
  const fieldName = `parametrage.cloisonnementService`
  const { control, watch } = useFormContext()

  return (
    <Card>
      <CardHeader title="Cloisonnement par service" />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Controller
              name={`${fieldName}`}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id={`${fieldName}`}
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Cloisonnement par service activé"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {watch(fieldName) && <CloisonnementActive readOnly={readOnly} fieldName="parametrage.services" />}
        </Grid>
      </CardContent>
    </Card>
  )
}
