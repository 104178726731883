import * as React from 'react'
import { Badge, SvgIconProps, useTheme } from '@mui/material'
import { Notifications } from '@mui/icons-material'

export default function NotificationsIcon(props: SvgIconProps & { nbNotifications: number; withMargin?: boolean }) {
  const { nbNotifications, withMargin, sx: otherSx, ...other } = props
  const theme = useTheme()
  const iconSx = withMargin ? { margin: theme.spacing(1.5), ...otherSx } : { ...otherSx }

  return (
    <Badge badgeContent={nbNotifications} color="error" sx={iconSx}>
      <Notifications {...other} />
    </Badge>
  )
}
