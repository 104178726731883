import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import {
  AnnulerButton,
  AreaLoading,
  ButtonsStack,
  EditTextField,
  ReadOnlyEmailField,
  ReadOnlyTextField,
  SauvegarderButton,
} from 'plateforme/components'
import {
  syntheseEvenement,
  syntheseExpertise,
  syntheseLibellePersonnneAExaminer,
  syntheseTelephonePersonnneAExaminer,
} from 'plateforme/services/dossier.services'
import { LoadingProps, trimToUndefined } from 'plateforme/services/utils'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionMedecin, { ModifierMissionMedecinRequest } from 'medecin/store/types/missionMedecin'
import { usePutRefMissionMedecinMutation } from 'medecin/store/apis/dossierMedecinApi'

type InformationSyntheseMedecinPartProps = {
  dossier?: DossierMedecin
  mission?: MissionMedecin
}

type InformationSyntheseMedecinFormValues = {
  refMissionMedecin: string
}

export default function InformationsSyntheseMedecinPart({
  dossier,
  mission,
  loading,
}: InformationSyntheseMedecinPartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={548} />
  }
  return <InformationsSyntheseMedecinPartLoaded dossier={dossier} mission={mission} />
}

function InformationsSyntheseMedecinPartLoaded({ dossier, mission }: InformationSyntheseMedecinPartProps) {
  // error:
  if (!dossier || !mission || !dossier?.code || !mission?.code) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { code: codeDossier, personneAExaminer, contactEntreprise } = dossier
  const { code: codeMission, libelle: libelleMission, refMissionMedecin, editable } = mission

  // hooks:
  const {
    control,
    getValues,
    reset,
    setError,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      refMissionMedecin: refMissionMedecin ?? '',
    } as InformationSyntheseMedecinFormValues,
  })
  const [putRefMissionMedecin, { error: errorModifierMission, isLoading: loadingModifierMission }] =
    usePutRefMissionMedecinMutation()
  const { enqueueSnackbar } = useSnackbar()
  useErrorFormMapper(errorModifierMission as IQueryErrorResponse, setError, getValues)

  // format:
  const libelleEvenement = syntheseEvenement(dossier)
  const libelleExpertise = syntheseExpertise(dossier)
  const libellePersonnneAExaminer = syntheseLibellePersonnneAExaminer(dossier)
  const telephonePersonnneAExaminer = syntheseTelephonePersonnneAExaminer(dossier)
  const refMissionMedecinAffichable = editable || refMissionMedecin
  const formId = 'form-modifier-mission'

  // cancel form:
  const onCancel = () => reset()

  // submit form:
  const onSubmitForm = async () => {
    const formValues = getValues() as InformationSyntheseMedecinFormValues
    await putRefMissionMedecin({
      codeDossier,
      codeMission,
      refMissionMedecin: trimToUndefined(formValues.refMissionMedecin),
    } as ModifierMissionMedecinRequest)
      .unwrap()
      .then(() => enqueueSnackbar('Votre référence mission a été modifiée avec succès', { variant: 'success' }))
      .catch(() => {
        enqueueSnackbar('La modification de la référence mission a échoué', { variant: 'error' })
      })
  }

  // render:
  return (
    <Card>
      <CardHeader title="Informations" />
      <CardContent>
        <form id={formId} name={formId}>
          <Grid container>
            <Grid item xs={refMissionMedecinAffichable ? 6 : 12}>
              <ReadOnlyTextField id="mission-libelle" label="Réf. entreprise" value={libelleMission} fullWidth />
            </Grid>
            {refMissionMedecinAffichable && (
              <Grid item xs={6}>
                <Controller
                  name="refMissionMedecin"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <EditTextField
                      id="ref-mission-medecin"
                      label="Ma référence"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      readOnly={!editable}
                      fullWidth
                      fieldError={error}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <ReadOnlyTextField id="evenement-libelle" label="&#201;vénement" value={libelleEvenement} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyTextField id="expertise-libelle" label="Expertise" value={libelleExpertise} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyTextField
                id="personne-a-examiner-libelle"
                label="Personne à examiner"
                value={libellePersonnneAExaminer}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyEmailField
                id="personne-a-examiner-mail"
                label="Adresse mail personne à examiner"
                value={personneAExaminer?.mail}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyTextField
                id="personne-a-examiner-telephones"
                label="Téléphone(s) personne à examiner"
                value={telephonePersonnneAExaminer}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyTextField
                id="contact-entreprise-libelle"
                label="Contact entreprise"
                value={contactEntreprise?.libelle}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyEmailField
                id="contact-entreprise-mail"
                label="Adresse mail contact entreprise"
                value={contactEntreprise?.mail}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyTextField
                id="contact-entreprise-telephone"
                label="Téléphone contact entreprise"
                value={contactEntreprise?.telephone}
                fullWidth
              />
            </Grid>
            {isDirty && (
              <Grid item xs={12}>
                <ButtonsStack>
                  <AnnulerButton id="annuler" onClick={onCancel}>
                    Annuler
                  </AnnulerButton>
                  <SauvegarderButton
                    id="sauvegarder"
                    onClick={onSubmitForm}
                    loading={loadingModifierMission}
                    disabled={!isValid}
                  >
                    Sauvegarder
                  </SauvegarderButton>
                </ButtonsStack>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}
