import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DatePickerInput,
  RechargerButton,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateISO, isDateAfterNow } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'
import { formatBooleanFilter } from 'plateforme/services/apis.services'
import { HabilitationEntrepriseAdminFilter } from 'admin/store/types/habilitationEntrepriseAdmin'
import { ReferentielDetail } from 'plateforme/store/types/referentiel'

type SearchHabilitationsProps = {
  codeEntreprise: string
  search: (criteria: HabilitationEntrepriseAdminFilter) => void
  searchError: IQueryErrorResponse
}

export interface HabilitationEntrepriseAdminFilterData {
  codeEntreprise: string
  numeroHabilitation: string
  codeMedecin: string
  nomMedecin: string
  intervenantOccasionnel: boolean
  expertiseSurPiece: boolean
  irca: boolean
  dateActivation: Date | null
  codePostal: string
  statutHabilitation: StatutsHabilitation | string
}

export default function RechercheHabilitationsEntrepriseAdminPart({
  codeEntreprise,
  search,
  searchError,
}: SearchHabilitationsProps) {
  if (codeEntreprise === undefined || codeEntreprise.length === 0) {
    throw new Error('Aucun code entreprise défini')
  }
  // Les valeurs initiales du formulaire :
  const initialValues: HabilitationEntrepriseAdminFilterData = {
    codeEntreprise: codeEntreprise ?? '',
    numeroHabilitation: '',
    codeMedecin: '',
    nomMedecin: '',
    intervenantOccasionnel: false,
    expertiseSurPiece: false,
    irca: false,
    statutHabilitation: '',
    dateActivation: null,
    codePostal: '',
  }

  const determineNomMedecin = (value: string | undefined) => {
    if (value) {
      const isDigit = /^\d+$/.test(value)
      if (!isDigit) {
        return value
      }
    }
    return undefined
  }

  const determineCodeMedecin = (value: string | undefined) => {
    if (value) {
      const isDigit = /^\d+$/.test(value)
      if (isDigit) {
        return value
      }
    }
    return undefined
  }

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { isValid },
    getFieldState,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  const onSubmit = async (data: HabilitationEntrepriseAdminFilterData) => {
    const statut = trimToUndefined(data?.statutHabilitation)
    const active = statut === StatutsHabilitation.ACTIVE
    const inactive = statut === StatutsHabilitation.INACTIVE
    const activeFutur = active && data.dateActivation && isDateAfterNow(data.dateActivation)

    const determinerActive = () => {
      if (inactive) {
        return 0
      }
      return activeFutur ? undefined : formatBooleanFilter(active)
    }

    search({
      codeEntreprise: trimToUndefined(codeEntreprise), // critère par défaut
      numeroHabilitation: trimToUndefined(data.numeroHabilitation),
      nomMedecin: determineNomMedecin(data.nomMedecin),
      codeMedecin: determineCodeMedecin(data.nomMedecin),
      intervenantOccasionnel: formatBooleanFilter(data.intervenantOccasionnel),
      expertiseSurPiece: formatBooleanFilter(data.expertiseSurPiece),
      irca: formatBooleanFilter(data.irca),
      active: determinerActive(),
      activeFutur: formatBooleanFilter(activeFutur),
      dateActive: active ? formatDateISO(data.dateActivation) : undefined,
      codePostal: trimToUndefined(data.codePostal),
    })
  }

  const handleClickInitSearch = () => {
    const filtre = {
      codeEntreprise: trimToUndefined(codeEntreprise), // critère par défaut
    }
    reset(initialValues)
    search(filtre)
  }

  const clearErrorNomMedecin = () => {
    clearErrors('nomMedecin')
    clearErrors('codeMedecin')
    setValue('nomMedecin', '', {
      shouldValidate: true,
      shouldDirty: true,
    })
  }
  const statutHabilitationFilter = (ref: ReferentielDetail) => ref.code !== 'ACTIVE_AU_FUTUR'

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-habilitations-entreprise-admin">
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="nomMedecin"
                control={control}
                defaultValue={initialValues.nomMedecin}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                  const errorCodeMedecin = getFieldState('codeMedecin').error
                  const groupError = error || errorCodeMedecin
                  return (
                    <TextField
                      id="nomMedecin"
                      label="Médecin"
                      variant="filled"
                      value={value}
                      onChange={(e) => {
                        clearErrorNomMedecin()
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      sx={{ width: '100%' }}
                      helperText={groupError?.message}
                      error={groupError !== undefined}
                    />
                  )
                }}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid container item xs={12} sm={12} md={8}>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="numeroHabilitation"
                    control={control}
                    defaultValue={initialValues.numeroHabilitation}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        id="numero-habilitation"
                        label="Identifiant entreprise (numéro d’habilitation)"
                        variant="filled"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        sx={{ width: '100%' }}
                        helperText={error?.message}
                        error={error !== undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="codePostal"
                    control={control}
                    defaultValue={initialValues.codePostal}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        id="lieu-exercice-medecin"
                        label="Code postal du lieu d’exercice"
                        variant="filled"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        sx={{ width: '100%' }}
                        helperText={error?.message}
                        error={error !== undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="statutHabilitation"
                    control={control}
                    defaultValue={initialValues.statutHabilitation}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <SelectInputReferentiel
                        id="statut-habilitation"
                        label="Statut"
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          setValue('dateActivation', null)
                          onChange(e)
                        }}
                        referentielName="statutsHabilitation"
                        dataFilter={statutHabilitationFilter}
                        fullWidth
                        withNoSelectionItem
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-start">
                    <Typography width={100}>Actif au</Typography>
                    <Controller
                      name="dateActivation"
                      control={control}
                      defaultValue={initialValues.dateActivation}
                      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                        <DatePickerInput
                          InputProps={{
                            id: 'date-activation',
                            fullWidth: true,
                          }}
                          value={value === undefined ? null : value}
                          onBlur={onBlur}
                          onChange={(e) => {
                            setValue('statutHabilitation', StatutsHabilitation.ACTIVE)
                            onChange(e)
                          }}
                          label="Date"
                          disabled={watch('statutHabilitation') !== StatutsHabilitation.ACTIVE}
                          fieldError={error}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Stack direction="column" alignItems="flex-start" justifyContent="center" spacing={0} height="100%">
                  <Controller
                    name="intervenantOccasionnel"
                    control={control}
                    defaultValue={initialValues.intervenantOccasionnel}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <FormControl error={error !== undefined}>
                        <FormGroup>
                          <FormControlLabel
                            label="Intervenant occasionnel"
                            sx={{ height: 30 }}
                            control={
                              <Checkbox
                                name="intervenantOccasionnel"
                                checked={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                sx={{ padding: 0, marginX: 1.5 }}
                              />
                            }
                          />
                        </FormGroup>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="expertiseSurPiece"
                    control={control}
                    defaultValue={initialValues.expertiseSurPiece}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <FormControl error={error !== undefined}>
                        <FormGroup>
                          <FormControlLabel
                            label="Avis technique sur pièce"
                            sx={{ height: 30 }}
                            control={
                              <Checkbox
                                name="expertiseSurPiece"
                                checked={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                              />
                            }
                          />
                        </FormGroup>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="irca"
                    control={control}
                    defaultValue={initialValues.irca}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <FormControl error={error !== undefined}>
                        <FormGroup>
                          <FormControlLabel
                            label="Médecin IRCA"
                            sx={{ height: 30 }}
                            control={
                              <Checkbox
                                name="typologieMedecin"
                                checked={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                sx={{ padding: 0, marginX: 1.5, display: 'block' }}
                              />
                            }
                          />
                        </FormGroup>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-habilitations-entreprise-admin"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}
