import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { AnnulerButton, ButtonsStack, EditTextField, ValiderButton } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { usePostSuiviMissionMutation } from 'medecin/store/apis/dossierMedecinApi'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise, { SuiviMissionRequest, SuiviMissionMedecinAction } from 'medecin/store/types/missionMedecin'

interface RefuserMissionFormProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
  onClose: VoidFunction
}

export default function RefuserMissionForm({ dossier, mission, onClose }: RefuserMissionFormProps) {
  const { code: codeDossier } = dossier
  const { code: codeMission } = mission

  const [postSuiviMission, { error: errorPost, isLoading }] = usePostSuiviMissionMutation()
  const { enqueueSnackbar } = useSnackbar()
  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { isValid },
  } = useForm({ mode: 'onTouched', reValidateMode: 'onChange', criteriaMode: 'all' })

  if (codeDossier === undefined || codeMission === undefined) {
    throw new Error(`Aucun mission associée n'a été trouvée`)
  }

  useErrorFormMapper(errorPost as IQueryErrorResponse, setError, getValues)

  const onSubmit = async (data: { commentaire?: string }) => {
    const suiviMissionRequest: SuiviMissionRequest = {
      ...data,
      codeDossier,
      codeMission,
      action: SuiviMissionMedecinAction.REFUSER,
    }

    await postSuiviMission(suiviMissionRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar('La mission a été refusée avec succès', { variant: 'success' })
      })
      .catch((response: IQueryErrorResponse) => {
        if (response.status === 500) {
          enqueueSnackbar('Le refus de la mission a échoué', { variant: 'error' })
        } else {
          enqueueSnackbar(response.data?.message, { variant: 'error' })
        }
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form-acceptation-mission">
      <Stack paddingTop={2}>
        <Controller
          name="commentaire"
          control={control}
          render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
            <EditTextField
              id="id-commentaire-refus"
              label="Commentaire sur le refus"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              fullWidth
              multiline
              minRows={3}
              fieldError={error}
            />
          )}
        />
        <ButtonsStack>
          <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
          <ValiderButton disabled={!isValid} color="error" type="submit" loading={isLoading}>
            Confirmer mon choix
          </ValiderButton>
        </ButtonsStack>
      </Stack>
    </form>
  )
}
