import React from 'react'
import { Badge, BadgeProps } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import styled from '@emotion/styled'
import { TabsComponent, TabDefinition } from 'plateforme/components'
import {
  isRapportCarence,
  isRapportConclusionDefinitive,
  isRapportConclusionProvisoire,
  isRapportDemandeModification,
} from 'plateforme/services/rapport.services'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { LoadingProps } from 'plateforme/services/utils'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import EditionDocumentsDossierEntreprisePart from 'assureur/parts/editionDocuments/EditionDocumentsDossierEntreprisePart'
import { StatutMessagerie } from 'plateforme/store/types/mission'
import TabSyntheseEntreprisePart from '../TabSyntheseEntreprisePart'
import TabDonneesDetailleesEntreprisePart from '../TabDonneesDetailleesEntreprisePart'
import TabRapportEntreprisePart from '../TabRapportEntreprisePart'
import TabFactureEntreprisePart from '../TabFactureEntreprisePart'
import TabHistoriqueDossierEntreprisePart from '../TabHistoriqueDossierEntreprisePart'
import TabMessageMissionEntreprisePart from '../TabMessageMissionEntreprise/TabMessageMissionEntreprisePart'

export interface TabsConsultationDossierEntrepriseProps {
  dossier?: DossierEntreprise
  derniereMission?: MissionEntreprise
  profilEntreprise?: ProfilEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
  totalMessagesNonLus?: number
}

export enum TabId {
  SYNTHESE_TAB = 'synthese',
  DETAILS_TAB = 'details',
  DOCUMENTS_TAB = 'documents',
  FACTURES_TAB = 'factures',
  HISTORIQUE_TAB = 'historique',
  MESSAGES_TAB = 'messages',
}

type TabRapportParam = {
  mission: MissionEntreprise
  index?: number
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 3,
  },
}))

export default function TabsConsultationDossierEntreprise({
  dossier,
  derniereMission,
  profilEntreprise,
  profilUtilisateur,
  loading,
  totalMessagesNonLus = 0,
}: TabsConsultationDossierEntrepriseProps & LoadingProps) {
  // props:
  const { missions } = dossier || {}

  // format:
  let carenceIndex = 0
  let conclusionsProvisoiresIndex = 0
  let conclusionsDefinitivesIndex = 0

  const tabRapportParams = !missions
    ? []
    : missions
        .filter((mission) => mission.code)
        .filter((mission) => mission?.rapport)
        .map((mission) => {
          switch (mission.rapport?.typeRapport) {
            case TypeRapport.CONCLUSION_PROVISOIRE: {
              conclusionsProvisoiresIndex += 1
              return { mission, index: conclusionsProvisoiresIndex } as TabRapportParam
            }
            case TypeRapport.CONCLUSION_DEFINITIVE: {
              conclusionsDefinitivesIndex += 1
              return { mission, index: conclusionsDefinitivesIndex } as TabRapportParam
            }
            case TypeRapport.CARENCE: {
              carenceIndex += 1
              return { mission, index: carenceIndex } as TabRapportParam
            }
            default: {
              return { mission } as TabRapportParam
            }
          }
        })
        .sort((p1, p2) => (p2.mission.code ?? '').localeCompare(p1.mission.code ?? ''))

  const calcRapportIndex = (mission: MissionEntreprise, index?: number) => {
    if (!index) {
      return undefined
    }

    switch (mission.rapport?.typeRapport) {
      case TypeRapport.CONCLUSION_PROVISOIRE: {
        if (conclusionsProvisoiresIndex <= 1) {
          return undefined
        }
        return conclusionsProvisoiresIndex - index + 1
      }
      case TypeRapport.CONCLUSION_DEFINITIVE: {
        if (conclusionsDefinitivesIndex <= 1) {
          return undefined
        }
        return conclusionsDefinitivesIndex - index + 1
      }
      case TypeRapport.CARENCE: {
        if (carenceIndex <= 1) {
          return undefined
        }
        return carenceIndex - index + 1
      }
      default: {
        return undefined
      }
    }
  }

  const rapportTitle = (prefix: string, rapportIndex?: number) => `${prefix}${rapportIndex ? ` ${rapportIndex}` : ''}`

  const tabsMissionsAvecRapport: TabDefinition[] =
    !dossier || !profilUtilisateur || !tabRapportParams
      ? []
      : (tabRapportParams
          .map((param) => {
            const { mission, index } = param
            const { rapport } = mission
            const rapportIndex = calcRapportIndex(mission, index)
            const isError = isRapportDemandeModification(mission)
            if (isRapportCarence(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Carence', rapportIndex),
                isError,
                component: (
                  <TabRapportEntreprisePart dossier={dossier} mission={mission} typeConclusion={TypeRapport.CARENCE} />
                ),
              }
            }
            if (isRapportConclusionProvisoire(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Conclusions provisoires', rapportIndex),
                isError,
                component: (
                  <TabRapportEntreprisePart
                    dossier={dossier}
                    mission={mission}
                    typeConclusion={TypeRapport.CONCLUSION_PROVISOIRE}
                  />
                ),
              }
            }
            if (rapport && isRapportConclusionDefinitive(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Conclusions définitives', rapportIndex),
                isError,
                component: (
                  <TabRapportEntreprisePart
                    dossier={dossier}
                    mission={mission}
                    typeConclusion={TypeRapport.CONCLUSION_DEFINITIVE}
                  />
                ),
              }
            }
            return undefined
          })
          .filter((t) => t !== undefined) as TabDefinition[])

  const tabList = ([] as TabDefinition[])
    .concat([
      {
        id: TabId.SYNTHESE_TAB,
        title: 'Synthèse',
        component: (
          <TabSyntheseEntreprisePart
            dossier={dossier}
            derniereMission={derniereMission}
            profilUtilisateur={profilUtilisateur}
            profilEntreprise={profilEntreprise}
            loading={loading}
          />
        ),
      },
      {
        id: TabId.DETAILS_TAB,
        title: 'Données détaillées',
        component: (
          <TabDonneesDetailleesEntreprisePart
            dossier={dossier}
            derniereMission={derniereMission}
            profilEntreprise={profilEntreprise}
            loading={loading}
          />
        ),
      },
    ] as TabDefinition[])
    .concat(tabsMissionsAvecRapport)
    .concat([
      {
        id: TabId.DOCUMENTS_TAB,
        title: 'Tous les documents',
        component: (
          <EditionDocumentsDossierEntreprisePart dossier={dossier} profilActif={profilUtilisateur} loading={loading} />
        ),
      },
      ...(dossier?.missions?.some((m) => m.factures && m.factures.length > 0)
        ? [
            {
              id: TabId.FACTURES_TAB,
              title: 'Factures',
              component: (
                <TabFactureEntreprisePart dossier={dossier} profilUtilisateur={profilUtilisateur} loading={loading} />
              ),
            },
          ]
        : []),
      {
        id: TabId.HISTORIQUE_TAB,
        title: 'Historique',
        component: <TabHistoriqueDossierEntreprisePart dossier={dossier} loading={loading} />,
      },
      ...(dossier?.missions?.some((m) => m.messagerieActive !== StatutMessagerie.DESACTIVE)
        ? [
            {
              id: TabId.MESSAGES_TAB,
              title: 'Messagerie',
              iconTab:
                totalMessagesNonLus > 0 ? (
                  <StyledBadge color="error" variant="dot">
                    <MailIcon fontSize="small" />
                  </StyledBadge>
                ) : undefined,
              component: <TabMessageMissionEntreprisePart dossier={dossier} loading={loading} />,
            },
          ]
        : []),
    ] as TabDefinition[])
    .filter((t) => t !== undefined)

  return <TabsComponent withNavigation tabs={tabList} />
}
