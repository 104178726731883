import React from 'react'
import MailIcon from '@mui/icons-material/Mail'
import Badge from '@mui/material/Badge'
import styled from '@emotion/styled'
import { BadgeProps } from '@mui/material'
import { TabsComponent, TabDefinition } from 'plateforme/components'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { LoadingProps } from 'plateforme/services/utils'
import { StatutMessagerie, StatutMission } from 'plateforme/store/types/mission'
import {
  isRapportCarence,
  isRapportConclusionDefinitive,
  isRapportConclusionProvisoire,
  isValideDefinitivement,
  isRapportDemandeModification,
} from 'plateforme/services/rapport.services'
import { LibelleRapport, TypeRapport } from 'plateforme/store/types/rapportConclusion'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import RapportConclusionMedecin from 'medecin/store/types/rapportConclusionMedecin'
import TabSyntheseMedecinPart from '../TabSyntheseMedecinPart'
import TabDonneesDetailleesMedecinPart from '../TabDonneesDetailleesMedecinPart'
import TabDocumentsMedecinPart from '../TabDocumentsMedecinPart'
import TabRapportMedecinPart from '../TabRapportMedecinPart'
import TabFactureMedecinPart from '../TabFactureMedecinPart'
import TabHistoriqueDossierMedecinPart from '../TabHistoriqueDossierMedecinPart'
import TabMessageMissionMedecinPart from '../TabMessageMissionMedecin'

export interface TabsConsultationDossierMedecinPartProps {
  dossier?: DossierMedecin
  derniereMissionAssociee?: MissionMedecin
  profilUtilisateur?: ProfilUtilisateurActif
  totalMessagesNonLus?: number
}

export enum TabId {
  SYNTHESE_TAB = 'synthese',
  DETAILS_TAB = 'details',
  DOCUMENTS_TAB = 'documents',
  FACTURES_TAB = 'factures',
  HISTORIQUE_TAB = 'historique',
  MESSAGES_TAB = 'messages',
}

type TabRapportParam = {
  mission: MissionMedecin
  index?: number
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 3,
  },
}))

export default function TabsConsultationDossierMedecinPart({
  dossier,
  derniereMissionAssociee,
  profilUtilisateur,
  loading,
  totalMessagesNonLus = 0,
}: TabsConsultationDossierMedecinPartProps & LoadingProps) {
  // props:
  const { missions } = dossier || {}

  // format:
  let carenceIndex = 0
  let conclusionsProvisoiresIndex = 0
  let conclusionsDefinitivesIndex = 0

  const tabRapportParams = !missions
    ? []
    : missions
        .filter((mission) => mission.code)
        .filter((mission) => mission?.rapport)
        .map((mission) => {
          switch (mission.rapport?.typeRapport) {
            case TypeRapport.CONCLUSION_PROVISOIRE: {
              conclusionsProvisoiresIndex += 1
              return { mission, index: conclusionsProvisoiresIndex } as TabRapportParam
            }
            case TypeRapport.CONCLUSION_DEFINITIVE: {
              conclusionsDefinitivesIndex += 1
              return { mission, index: conclusionsDefinitivesIndex } as TabRapportParam
            }
            case TypeRapport.CARENCE: {
              carenceIndex += 1
              return { mission, index: carenceIndex } as TabRapportParam
            }
            default: {
              return { mission } as TabRapportParam
            }
          }
        })
        .sort((p1, p2) => (p2.mission.code ?? '').localeCompare(p1.mission.code ?? ''))

  const calcRapportIndex = (mission: MissionMedecin, index?: number) => {
    if (!index) {
      return undefined
    }

    switch (mission.rapport?.typeRapport) {
      case TypeRapport.CONCLUSION_PROVISOIRE: {
        if (conclusionsProvisoiresIndex <= 1) {
          return undefined
        }
        return conclusionsProvisoiresIndex - index + 1
      }
      case TypeRapport.CONCLUSION_DEFINITIVE: {
        if (conclusionsDefinitivesIndex <= 1) {
          return undefined
        }
        return conclusionsDefinitivesIndex - index + 1
      }
      case TypeRapport.CARENCE: {
        if (carenceIndex <= 1) {
          return undefined
        }
        return carenceIndex - index + 1
      }
      default: {
        return undefined
      }
    }
  }

  const rapportTitle = (prefix: string, rapportIndex?: number) => `${prefix}${rapportIndex ? ` ${rapportIndex}` : ''}`
  const isRapportEdition = (mission?: MissionMedecin, rapport?: RapportConclusionMedecin) =>
    mission && mission.rapportModifiable && rapport && !isValideDefinitivement(rapport)

  const tabsMissionsAvecRapport =
    !dossier || !profilUtilisateur || !tabRapportParams
      ? []
      : (tabRapportParams
          .map((param) => {
            const { mission, index } = param
            const { rapport } = mission
            const rapportIndex = calcRapportIndex(mission, index)
            const isEdition = isRapportEdition(mission, rapport)
            const isError = isRapportDemandeModification(mission)
            if (rapport && isRapportCarence(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Carence', rapportIndex),
                isEdition,
                isError,
                component: (
                  <TabRapportMedecinPart
                    dossier={dossier}
                    mission={mission}
                    profilUtilisateur={profilUtilisateur}
                    libelleConclusion={LibelleRapport.CARENCE}
                    typeConclusion={TypeRapport.CARENCE}
                  />
                ),
              } as TabDefinition
            }
            if (rapport && isRapportConclusionProvisoire(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Conclusions provisoires', rapportIndex),
                isEdition,
                isError,
                component: (
                  <TabRapportMedecinPart
                    dossier={dossier}
                    mission={mission}
                    profilUtilisateur={profilUtilisateur}
                    libelleConclusion={LibelleRapport.CONCLUSION_PROVISOIRE}
                    typeConclusion={TypeRapport.CONCLUSION_PROVISOIRE}
                  />
                ),
              } as TabDefinition
            }
            if (rapport && isRapportConclusionDefinitive(rapport)) {
              return {
                id: mission.code,
                title: rapportTitle('Conclusions définitives', rapportIndex),
                isEdition,
                isError,
                component: (
                  <TabRapportMedecinPart
                    dossier={dossier}
                    mission={mission}
                    profilUtilisateur={profilUtilisateur}
                    libelleConclusion={LibelleRapport.CONCLUSION_DEFINITIVE}
                    typeConclusion={TypeRapport.CONCLUSION_DEFINITIVE}
                  />
                ),
              } as TabDefinition
            }
            return undefined
          })
          .filter((t) => t !== undefined) as TabDefinition[])

  const propsTab = ([] as TabDefinition[])
    .concat([
      {
        id: TabId.SYNTHESE_TAB,
        title: 'Synthèse',
        component: (
          <TabSyntheseMedecinPart
            dossier={dossier}
            mission={derniereMissionAssociee}
            profilUtilisateur={profilUtilisateur}
            loading={loading}
          />
        ),
      },
      {
        id: TabId.DETAILS_TAB,
        title: 'Données détaillées',
        component: (
          <TabDonneesDetailleesMedecinPart dossier={dossier} mission={derniereMissionAssociee} loading={loading} />
        ),
      },
    ] as TabDefinition[])
    .concat(tabsMissionsAvecRapport)
    .concat([
      {
        id: TabId.DOCUMENTS_TAB,
        title: 'Tous les documents',
        component: <TabDocumentsMedecinPart dossier={dossier} loading={loading} />,
      },
      ...(dossier?.missions
        ?.filter((mission) => mission.associable)
        .some((m) => m.facturable || (m.factures?.length ?? 0) > 0 || isValideDefinitivement(m.rapport))
        ? [
            {
              id: TabId.FACTURES_TAB,
              title: 'Factures',
              component: (
                <TabFactureMedecinPart dossier={dossier} mission={derniereMissionAssociee} loading={loading} />
              ),
            },
          ]
        : []),
      ...(derniereMissionAssociee?.statut !== StatutMission.PROPOSEE
        ? [
            {
              id: TabId.HISTORIQUE_TAB,
              title: 'Historique',
              component: <TabHistoriqueDossierMedecinPart dossier={dossier} loading={loading} />,
            },
          ]
        : []),
      ...(dossier?.missions?.some((m) => m.messagerieActive !== StatutMessagerie.DESACTIVE)
        ? [
            {
              id: TabId.MESSAGES_TAB,
              title: 'Messagerie',
              iconTab:
                totalMessagesNonLus > 0 ? (
                  <StyledBadge color="error" variant="dot">
                    <MailIcon fontSize="small" />
                  </StyledBadge>
                ) : undefined,
              component: <TabMessageMissionMedecinPart dossier={dossier} loading={loading} />,
            },
          ]
        : []),
    ] as TabDefinition[])
    .filter((t) => t !== undefined)

  return <TabsComponent withNavigation tabs={propsTab} />
}
