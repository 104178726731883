import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardContent, CardHeader, Grid, useTheme } from '@mui/material'
import { ReadOnlyTextField, SupprimerIconButton, TabsComponent } from 'plateforme/components'
import {
  getSelectedMissionRDV,
  setSelectedMissionRDV,
} from 'assureur/store/slices/selectedMissionRDV/selectedMissionRDVSlice'
import { useGetMedecinByCodeQuery } from 'assureur/store/apis/medecinEntrepriseApi'
import LieuMissionRDVTab from 'assureur/parts/ficheMedecin/LieuxExerciceMedecinPart/LieuMissionRDVTab'

type ChoixMissionRDVProps = {
  codeMedecin?: string
}

export default function ChoixMissionRDVPart({ codeMedecin }: ChoixMissionRDVProps) {
  const { data: medecin } = useGetMedecinByCodeQuery({ codeMedecin: codeMedecin ?? '' })
  const dispatch = useDispatch()
  const selectedMissionRDV = useSelector(getSelectedMissionRDV)
  const theme = useTheme()

  const isMissionRDVSelected = !!selectedMissionRDV

  const handleRemoveRDV = () => {
    dispatch(setSelectedMissionRDV(undefined))
  }

  const lieuxExercice =
    medecin?.lieuxExercice?.map((lieuExercice, index) => ({
      id: index.toString(),
      title: index === 0 ? 'Étab. principal' : `Étab. secondaire ${index}`,
      component: <LieuMissionRDVTab index={index} lieuExercice={lieuExercice} />,
    })) ?? []

  return (
    <Card>
      <CardHeader title="Choix du rendez-vous de la mission" />
      <CardContent>
        {isMissionRDVSelected && (
          <Grid container>
            <Grid item xs={11.5}>
              <ReadOnlyTextField
                id="rdv-mission"
                fullWidth
                rows={3}
                multiline
                value={selectedMissionRDV?.adresseComplete}
                label="Adresse du rendez-vous"
              />
            </Grid>
            <Grid item xs={0.5} display="flex" justifyContent="center" alignItems="center">
              <SupprimerIconButton onClick={handleRemoveRDV} tooltip="Retirer" />
            </Grid>
          </Grid>
        )}
        {!isMissionRDVSelected && <TabsComponent tabs={lieuxExercice} marginX={theme.spacing(2, 0)} />}
      </CardContent>
    </Card>
  )
}
