import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'

export interface SelectedMissionRDV {
  adresseComplete?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
}

export const selectedMissionRDVSliceName = 'selectedMissionRDV'

const selectedMissionRDVSlice = createSlice({
  name: selectedMissionRDVSliceName,
  initialState: {
    selectedMissionRDV: undefined,
    showSelectedMissionRDV: false,
  } as { selectedMissionRDV?: SelectedMissionRDV; showSelectedMissionRDV?: boolean },
  reducers: {
    setSelectedMissionRDV: (state, action: PayloadAction<SelectedMissionRDV | undefined>) => {
      state.selectedMissionRDV = action.payload
    },
  },
})

export const { setSelectedMissionRDV } = selectedMissionRDVSlice.actions

export const getSelectedMissionRDV = (state: RootState) => state?.selectedMissionRDV?.selectedMissionRDV

export default selectedMissionRDVSlice.reducer
