import React from 'react'

import { DialogArgs } from 'plateforme/hooks/useConfirmDialog'
import { Grid } from '@mui/material'
import { AnnulerButton } from 'plateforme/components'

export default function showPropertiesChanged(
  title: string,
  propertiesChanged: string[],
  confirm: (args: DialogArgs) => void,
  closeConfirmDialog: () => void
) {
  confirm({
    title,
    maxWidth: 'md',
    confirmMsg: 'Données modifiées : ',
    withForm: true,
    form: (
      <Grid container paddingTop={0}>
        <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
          {propertiesChanged?.map((p) => (
            <Grid key={p} item xs={12}>
              - {p}
            </Grid>
          ))}
          <Grid item>
            <AnnulerButton onClick={() => closeConfirmDialog()}>Fermer</AnnulerButton>
          </Grid>
        </Grid>
      </Grid>
    ),
  })
}
