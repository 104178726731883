import { DemandeModification } from 'plateforme/store/types/demandeModification'
import { TypeRapport, TypeRapportConseil, LibelleRapport } from 'plateforme/store/types/rapportConclusion'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import MessagePlateforme from 'plateforme/store/types/message'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { formatDateFR } from './dates.services'
import { capitalizeFirst } from './utils'
import { determineLibelleRapport } from './rapport.services'
import DossierPlateforme, { StatutDossier } from '../store/types/dossier'
import MissionPlateforme, { MetaDataMission, StatutMission, StatutSuiviMission } from '../store/types/mission'

// dernière date de validation médecin soit celle par défaut soit la dernière date de validation dans les demandes de
// modification
export function determineDerniereDateValidationMedecin(
  dateValidationMedecin: Date | undefined,
  demandesModifications: [DemandeModification]
) {
  // date de validation médecin par défaut si elle existe
  const affichageDateValidationMedecin = dateValidationMedecin ? formatDateFR(dateValidationMedecin) : ''

  // récupère la dernière demande de modification si elle existe
  const derniereDemandeDeModification = !demandesModifications
    ? undefined
    : demandesModifications.at(demandesModifications.length - 1)

  // retourne le libellé pour la chips
  // prend la date de validation par défaut
  // ou récupère la dernière date de validation médecin dans la demande de modification
  return !derniereDemandeDeModification || !derniereDemandeDeModification.dateValidation
    ? affichageDateValidationMedecin
    : `${formatDateFR(derniereDemandeDeModification.dateValidation)} - compléments transmis`
}

export function determineLibelleStatut(dossier: DossierPlateforme, mission?: MissionPlateforme) {
  switch (dossier.statut) {
    case StatutDossier.EN_CREATION: {
      if ('dateCreation' in dossier) {
        return `Dossier en création depuis le ${formatDateFR(dossier.dateCreation)}`
      }
      break
    }
    case StatutDossier.EN_COURS: {
      if (!mission && 'dateCreation' in dossier) {
        return `Dossier créé le ${formatDateFR(dossier.dateCreation)}`
      }
      break
    }
    case StatutDossier.ANNULE: {
      if (!mission && 'dateAnnulation' in dossier) {
        return `Dossier annulé le ${formatDateFR(dossier.dateAnnulation)}`
      }
      break
    }
    case StatutDossier.PURGE: {
      return `Dossier purgé le ${formatDateFR(dossier.dateMiseAJour)}`
    }
    default:
      break
  }
  if (mission) {
    const affichageMissionCloture = mission?.statut === StatutMission.CLOTUREE ? ' - Mission clôturée' : ''
    const affichageDossierCloture = dossier.statut === StatutDossier.CLOTURE ? ' - Dossier clôturé' : ''
    const affichageDossierOuMissionCloture =
      affichageDossierCloture.length > 0 ? affichageDossierCloture : affichageMissionCloture
    return `${determineLibelleStatutMission(mission)}${affichageDossierOuMissionCloture}`
  }
  return ''
}

function determineLibelleStatutMission(mission: MissionPlateforme) {
  const labelConclusions = capitalizeFirst(determineLibelleRapport(mission?.rapport?.typeRapport))

  // récupère la dernière date de validation médecin
  const affichageDerniereDateValidationMedecin = determineDerniereDateValidationMedecin(
    mission?.dateValidationMedecin,
    mission?.demandesModifications
  )
  switch (mission?.statut) {
    case StatutMission.PROPOSEE: {
      return `Mission proposée médecin le ${formatDateFR(mission?.dateProposition)}`
    }
    case StatutMission.ANNULEE: {
      return `Mission annulée le ${formatDateFR(mission?.dateCloture)}`
    }
    case StatutMission.ARRETEE: {
      return `Mission arrêtée le ${formatDateFR(mission?.dateCloture)}`
    }
    case StatutMission.REFUSEE: {
      return `Mission refusée médecin le ${formatDateFR(mission?.dateReponseMedecin)}`
    }
    case StatutMission.PROPOSEE_SANS_SUITE: {
      return `Mission proposée - classée sans suite entreprise le ${formatDateFR(mission?.dateCloture)}`
    }
    case StatutMission.EN_COURS: {
      if (mission?.statutSuivi === StatutSuiviMission.BLOQUE) {
        return `Mission en cours - bloquée`
      }
      if (mission?.dateExamenPrevue && mission?.statutSuivi === StatutSuiviMission.EXAMEN_PREVU) {
        return `Mission en cours - date d'examen le ${formatDateFR(mission?.dateExamenPrevue)}`
      }
      return `Mission en cours depuis le ${formatDateFR(mission?.dateReponseMedecin)}`
    }
    case StatutMission.VALIDEE_MEDECIN: {
      return `${labelConclusions} le ${affichageDerniereDateValidationMedecin}`
    }
    case StatutMission.EN_DEMANDE_DE_MODIFICATION: {
      return `${labelConclusions} le ${formatDateFR(mission?.dateValidationMedecin)} - en attente de compléments`
    }
    case StatutMission.CLOTUREE: {
      return `${labelConclusions} le ${formatDateFR(mission?.dateValidationMedecin)}`
    }
    default: {
      return ''
    }
  }
}

export function isRapportConseilActive(
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE,
  mission?: MissionPlateforme
) {
  if (typeConclusion === TypeRapport.CONCLUSION_PROVISOIRE) {
    return mission?.typeRapportConseilPro && mission?.typeRapportConseilPro !== TypeRapportConseil.DESACTIVE
  }
  if (typeConclusion === TypeRapport.CONCLUSION_DEFINITIVE) {
    return mission?.typeRapportConseilDef && mission?.typeRapportConseilDef !== TypeRapportConseil.DESACTIVE
  }
  return false
}

export function determineShowPreconisationHandicapGrave(
  profilEntreprise?: ProfilEntreprise,
  dossier?: DossierEntreprise
) {
  // si le param entreprise est inactif pour handicap grave
  // ou s'il y a un domaine contractuel sur le dossier
  // ou si la fiche de conclusion est simplifiée
  // → alors il faudra envoyer null dans la request de création de mission
  // → sinon il faudra obligatoirement true ou false
  return (
    profilEntreprise?.parametrage?.handicapGrave &&
    dossier?.expertise?.domaineContractuel === undefined &&
    dossier?.typeFicheConclusionPrevisible !== 'SIMP'
  )
}

export function determineShowHandicapGrave(mission?: MissionPlateforme) {
  return mission?.rapport?.handicapGrave ?? mission?.handicapGrave ?? false
}

export function determineTitleHandicapGrave(mission?: MissionPlateforme) {
  if (mission?.rapport?.handicapGrave) {
    return 'Conclusions de type handicap grave'
  }
  if (mission?.handicapGrave && mission?.rapport?.handicapGrave === undefined) {
    return 'Mission de type handicap grave'
  }
  return undefined
}

export function determineShowPrecisionsMission(mission?: MissionPlateforme) {
  return mission?.precisions ?? false
}

export function determineShowRdvMission(mission?: MissionPlateforme) {
  return mission?.rdv?.adresseComplete ?? false
}

export function determineLibelleStatutMissionAccordion(
  mission?: MissionPlateforme,
  rapportIndex?: number
): string | undefined {
  if (mission?.statut === StatutMission.VALIDEE_MEDECIN && mission?.rapport?.typeRapport) {
    switch (mission.rapport?.typeRapport) {
      case TypeRapport.CONCLUSION_PROVISOIRE: {
        return `${LibelleRapport.CONCLUSION_PROVISOIRE}${rapportIndex ? ` ${rapportIndex}` : ''}`
      }
      case TypeRapport.CONCLUSION_DEFINITIVE: {
        return `${LibelleRapport.CONCLUSION_DEFINITIVE}${rapportIndex ? ` ${rapportIndex}` : ''}`
      }
      case TypeRapport.CARENCE: {
        return `${LibelleRapport.CARENCE}${rapportIndex ? ` ${rapportIndex}` : ''}`
      }
      default: {
        break
      }
    }
  }
  return mission?.labelStatut
}

export type MissionWithIndexes = {
  mission: MissionPlateforme
  indexMission: number
  indexRapport?: number
}

type MissionIndex = {
  mission: MissionPlateforme
  index: number
}

export function calculerIndexesMission(missions?: MissionPlateforme[]): MissionWithIndexes[] {
  let missionIndex = 0
  let carenceIndex = 0
  let conclusionsProvisoiresIndex = 0
  let conclusionsDefinitivesIndex = 0

  const missionsWithIndex =
    missions
      ?.filter((mission) => mission.code)
      .map((mission) => {
        missionIndex += 1
        switch (mission.rapport?.typeRapport) {
          case TypeRapport.CONCLUSION_PROVISOIRE: {
            conclusionsProvisoiresIndex += 1
            return { mission, index: conclusionsProvisoiresIndex } as MissionIndex
          }
          case TypeRapport.CONCLUSION_DEFINITIVE: {
            conclusionsDefinitivesIndex += 1
            return { mission, index: conclusionsDefinitivesIndex } as MissionIndex
          }
          case TypeRapport.CARENCE: {
            carenceIndex += 1
            return { mission, index: carenceIndex } as MissionIndex
          }
          default: {
            return { mission } as MissionIndex
          }
        }
      }) || []

  const calcMissionIndex = (index?: number) => {
    if (index === undefined) {
      return undefined
    }
    if (missionIndex <= 1) {
      return undefined
    }
    return missionIndex - index
  }

  const calcRapportIndex = (mission: MissionPlateforme, index?: number) => {
    if (!index) {
      return undefined
    }

    switch (mission.rapport?.typeRapport) {
      case TypeRapport.CONCLUSION_PROVISOIRE: {
        if (conclusionsProvisoiresIndex <= 1) {
          return undefined
        }
        return conclusionsProvisoiresIndex - index + 1
      }
      case TypeRapport.CONCLUSION_DEFINITIVE: {
        if (conclusionsDefinitivesIndex <= 1) {
          return undefined
        }
        return conclusionsDefinitivesIndex - index + 1
      }
      case TypeRapport.CARENCE: {
        if (carenceIndex <= 1) {
          return undefined
        }
        return carenceIndex - index + 1
      }
      default: {
        return undefined
      }
    }
  }

  return missionsWithIndex.map((missionWithIndex, index) => {
    return {
      mission: missionWithIndex.mission,
      indexMission: calcMissionIndex(index),
      indexRapport: calcRapportIndex(missionWithIndex.mission, missionWithIndex.index),
    } as MissionWithIndexes
  })
}

export function calculerMetaDataMissions(missions?: MissionPlateforme[], messages?: MessagePlateforme[]) {
  const missionsWithIndexes = calculerIndexesMission(missions)
  const groupedMessages =
    messages?.reduce((acc: MetaDataMission[], message: MessagePlateforme) => {
      const mission = message?.mission
      const existingMission = acc.find((item) => item?.mission?.code === mission?.code)

      if (existingMission) {
        if (message.lu) {
          existingMission.totalLu += 1
        } else {
          // eslint-disable-next-line no-unused-expressions
          message.expediteur === TypePartenaire.Medecin
            ? (existingMission.totalNonLuEntreprise += 1)
            : (existingMission.totalNonLuMedecin += 1)
        }
      } else {
        const newMission = {
          mission: missions?.find((item) => item?.code === mission?.code),
          totalLu: message.lu ? 1 : 0,
          totalNonLuEntreprise: !message.lu && message.expediteur === TypePartenaire.Medecin ? 1 : 0,
          totalNonLuMedecin: !message.lu && message.expediteur === TypePartenaire.Entreprise ? 1 : 0,
        }
        acc.push(newMission as MetaDataMission)
      }

      return acc
    }, []) ?? []

  return missionsWithIndexes.map((m) => {
    const gmission = groupedMessages.find((gm) => gm?.mission?.code === m?.mission?.code)
    return {
      ...m,
      totalNonLuMedecin: gmission?.totalNonLuMedecin ?? 0,
      totalNonLuEntreprise: gmission?.totalNonLuEntreprise ?? 0,
      totalLu: gmission?.totalLu ?? 0,
    }
  }) as MetaDataMission[]
}
