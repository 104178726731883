import * as React from 'react'
import { Button, ButtonProps, darken, Stack, styled, Typography } from '@mui/material'
import { Loading } from '../index'

export const TableauDeBordButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<TableauDeBordTileProps>(({ theme, backgroundColor }) => ({
  backgroundColor,
  color: '#fff',
  textShadow: `0 0 .25em ${darken(backgroundColor, 0.25)}`,
  boxSizing: 'border-box',
  width: '100%',
  minHeight: 180,
  textAlign: 'center',
  alignItems: 'flex-start',
  verticalAlign: 'top',
  '&:hover': {
    backgroundColor: darken(backgroundColor, 0.2),
  },
  padding: theme.spacing(2, 3, 3, 3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 1.5, 3, 1.5),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2, 1.5, 3, 1.5),
  },
}))

interface TableauDeBordTileProps extends ButtonProps {
  backgroundColor: string
  value?: number
  isIconButton?: boolean
  icon?: React.ReactElement
  description?: React.ReactNode
}

function TableauDeBordTile(props: TableauDeBordTileProps) {
  const { backgroundColor, value, description, isIconButton, icon, ...other } = props
  return (
    <TableauDeBordButton variant="contained" backgroundColor={backgroundColor} {...other}>
      <Stack alignItems="center" spacing={1}>
        <>
          {!isIconButton && (
            <Typography fontSize={34} color="inherit">
              {value !== undefined ? value : <Loading color="inherit" />}
            </Typography>
          )}
          {icon}
          <Typography fontSize={20} color="inherit">
            {description}
          </Typography>
        </>
      </Stack>
    </TableauDeBordButton>
  )
}

export default TableauDeBordTile
