import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from './transmedApi'

export const versionApi = createApi({
  reducerPath: 'versionApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getVersionApi: builder.query<{ version?: string }, void>({
      query: () => 'version',
    }),
  }),
})

export const version = createApi({
  reducerPath: 'version',
  baseQuery: fetchBaseQuery({ baseUrl: '/extranet' }),
  endpoints: (builder) => ({
    getVersion: builder.query<{ version?: string }, void>({
      query: () => 'version.json',
    }),
  }),
})

export const { useGetVersionApiQuery } = versionApi
export const { useGetVersionQuery } = version
