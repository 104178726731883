import { TableCell, TableRow } from '@mui/material'
import { ReferentielText, SelectInputReferentiel } from 'plateforme/components'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface NotificationsMailsRowProps {
  rowTitle: string
  controllerName: string
  readOnly?: boolean
}

export default function NotificationsMailsRow({ rowTitle, controllerName, readOnly }: NotificationsMailsRowProps) {
  const { control } = useFormContext()

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <ReferentielText referentielName="typeMessageNotification" referentielCode={rowTitle} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`${controllerName}.CG`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInputReferentiel
              fullWidth
              id="general"
              label="Type notification"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              referentielName="frequencesNotification"
              fieldError={error}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`${controllerName}.SV`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInputReferentiel
              fullWidth
              id="service"
              label="Type notification"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              referentielName="frequencesNotification"
              fieldError={error}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`${controllerName}.GE`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInputReferentiel
              fullWidth
              id="gestionnaire"
              label="Type notification"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              referentielName="frequencesNotification"
              fieldError={error}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>
    </TableRow>
  )
}
