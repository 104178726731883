import React from 'react'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import { ConfirmDialog } from 'plateforme/components'

export default function Prompt({
  title,
  msgConfirmation,
  when,
  displayNo = true,
}: {
  title: string
  msgConfirmation: string
  when: boolean
  displayNo?: boolean
}) {
  const blocker = useBlocker(when)

  /*   React.useEffect(() => {
    console.log(blocker)
  }, [blocker]) */

  const handleClose = () => (blocker.reset ? blocker.reset() : undefined)
  const handleConfirm = () => (blocker?.proceed ? blocker.proceed() : undefined)

  return (
    <ConfirmDialog
      open={blocker.state === 'blocked'}
      title={title}
      confirmMsg={msgConfirmation}
      onYes={handleConfirm}
      onNo={handleClose}
      displayNo={displayNo}
    />
  )
}
