import { NotificationAdmin } from 'admin/store/types/notificationsAdmin'
import { MessageEntreprise } from 'assureur/store/types/messageEntreprise'
import { NotificationEntreprise } from 'assureur/store/types/notificationsEntreprise'
import { MessageMedecin } from 'medecin/store/types/messageMedecin'
import { NotificationMedecin } from 'medecin/store/types/notificationsMedecin'
import { TypeMessageNotification } from 'plateforme/store/types/referentiel'

const determineLuNonLuInitial = (lu?: 0 | 1) => {
  if (lu === undefined) {
    return ['lue', 'nonLue']
  }
  return lu === 0 ? ['nonLue'] : ['lue']
}

export const isVoirNotificationEnable = (
  notification: MessageEntreprise | NotificationEntreprise | MessageMedecin | NotificationMedecin | NotificationAdmin
) => {
  return notification.type === 'MEL_MIS' || notification.type === 'CREA_HAB' || notification.dossier
}

export const tooltipVoirNotification = (type?: TypeMessageNotification) => {
  if (type === 'CREA_HAB') {
    return `Voir l'habilitation`
  }
  if (type === 'MEL_MIS') {
    return `Voir le message`
  }
  return 'Voir le dossier'
}

export default determineLuNonLuInitial
