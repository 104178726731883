import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Card, CardContent, useTheme } from '@mui/material'
import { TableauDeBordGrid, TableauDeBordGridItem, TableauDeBordTile } from 'plateforme/components'
import { TABLEAU_DE_BORD_MAX_TIME_CACHE } from 'plateforme/constantes'
import { rechercheFacturesHref, rechercheDossiersHref } from 'medecin/MedecinApp'
import { useGetRechercheDossierQuery, useGetRechercheFactureQuery } from 'medecin/store/apis/dossierMedecinApi'
import { setDossierCriteria, setFactureCriteria } from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { nbFactureEnAttenteValidationRequest, FactureFilter } from 'medecin/store/types/factureMedecin'
import { DossierMedecinFilter } from 'medecin/store/types/dossierMedecin'
import {
  nbDossierEnCoursPropositionRequest,
  nbMissionsDemanderModificationRequest,
  nbMissionsEnCoursRequest,
} from 'medecin/services/dossierMedecin.services'

function TableauDeBordMedecin() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Nombre de dossier en propositions de missions
  const nbDossierEnCoursProposition = nbDossierEnCoursPropositionRequest()
  const listDossierEnCoursProposition = useGetRechercheDossierQuery(nbDossierEnCoursProposition, {
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurDossierEnCoursProposition = listDossierEnCoursProposition?.data?.totalItems as number

  // Nombre de missions DEMANDER_MODIFICATION
  const nbMissionsDemanderModification = nbMissionsDemanderModificationRequest()
  const listMissionsDemanderModification = useGetRechercheDossierQuery(nbMissionsDemanderModification, {
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurMissionsDemanderModification = listMissionsDemanderModification?.data?.totalItems as number

  // Nombre de dossier EN_COURS
  const nbMissionsEnCours = nbMissionsEnCoursRequest()
  const listMissionsEnCours = useGetRechercheDossierQuery(nbMissionsEnCours, {
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurMissionsEnCours = listMissionsEnCours?.data?.totalItems as number

  // Nombre de facture en attente de validation
  const nbFactureEnAttenteValidation = nbFactureEnAttenteValidationRequest()
  const listFacturesDeposees = useGetRechercheFactureQuery(nbFactureEnAttenteValidation, {
    refetchOnMountOrArgChange: TABLEAU_DE_BORD_MAX_TIME_CACHE,
  })
  const compteurFactureEnAttenteValidation = listFacturesDeposees?.data?.totalItems as number

  const handlClickCardDossier = (filter?: DossierMedecinFilter) => {
    if (filter) {
      dispatch(setDossierCriteria(filter))
    }
    navigate(rechercheDossiersHref)
  }

  const handlClickCardFacture = (filter?: FactureFilter) => {
    if (filter) {
      dispatch(setFactureCriteria(filter))
    }
    navigate(rechercheFacturesHref)
  }

  return (
    <TableauDeBordGrid container>
      {/* Action propositions de mission, fond bleu */}
      <TableauDeBordGridItem>
        <TableauDeBordTile
          backgroundColor="#507fd1"
          value={compteurDossierEnCoursProposition}
          description="Propositions de missions à répondre"
          onClick={() => handlClickCardDossier(nbDossierEnCoursProposition.filter)}
        />
      </TableauDeBordGridItem>
      {/* Action facture en attente, fond violet */}
      <TableauDeBordGridItem>
        <TableauDeBordTile
          backgroundColor="#8b5dbc"
          value={compteurFactureEnAttenteValidation}
          description="Factures en attente de validation"
          onClick={() => handlClickCardFacture(nbFactureEnAttenteValidation.filter)}
        />
      </TableauDeBordGridItem>
      {/* Action missions en cours, fond gris */}
      <TableauDeBordGridItem>
        <TableauDeBordTile
          backgroundColor="#b1b0b0"
          value={compteurMissionsEnCours}
          description="Nombre de missions en cours"
          onClick={() => handlClickCardDossier(nbMissionsEnCours.filter)}
        />
      </TableauDeBordGridItem>
      {/* Action missions Demander Modification , fond orange */}
      <TableauDeBordGridItem>
        <TableauDeBordTile
          backgroundColor="#FC6A03"
          value={compteurMissionsDemanderModification}
          description="Nombre de missions en demande de compléments"
          onClick={() => handlClickCardDossier(nbMissionsDemanderModification.filter)}
        />
      </TableauDeBordGridItem>
    </TableauDeBordGrid>
  )
}

export default function TableauDeBordMedecinPart() {
  const theme = useTheme()
  return (
    <Card>
      <CardContent sx={{ paddingX: theme.spacing(4) }}>
        <TableauDeBordMedecin />
      </CardContent>
    </Card>
  )
}
