import React from 'react'
import { Alert, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import {
  AreaLoading,
  FichierIcon,
  InfiniteScrollComponent,
  NoRowsOverlay,
  ResultatRechercheBarCard,
  VoirIconButton,
} from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { SortOption, SortParam } from 'plateforme/store/types/pageRecherche'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import {
  MessageVersAssureur,
  NotificationMedecin,
  NotificationMedecinMessageSortType,
} from 'medecin/store/types/notificationsMedecin'
import MessageMedecinDetails from 'medecin/parts/home/DernieresNotificationsMessageMedecinPart/MessageMedecinDetails'
import { makeEllipsis } from 'plateforme/services/utils'

type ListeNotificationsMessageAdminPartProps = {
  combinedData: Array<NotificationMedecin>
  total?: number
  readMore: VoidFunction
  sortBy: (param: SortParam<NotificationMedecinMessageSortType>) => void
  initSort: VoidFunction
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
}

const getDocuments = (documents: DocumentTeleverse[] | undefined) => {
  return documents && documents.length > 0 ? <FichierIcon fontSize="medium" /> : ''
}

export default function ListeNotificationsMessageMedecinPart({
  combinedData,
  total,
  readMore,
  sortBy,
  initSort,
  isError,
  isLoading,
  isFetching,
  isSuccess,
}: ListeNotificationsMessageAdminPartProps) {
  const sortOptions: SortOption<NotificationMedecinMessageSortType>[] = [
    { sortType: NotificationMedecinMessageSortType.OBJET, label: 'Objet' },
    { sortType: NotificationMedecinMessageSortType.DESTINATAIRE, label: 'Entreprise' },
    { sortType: NotificationMedecinMessageSortType.DATE_CREATION_SORT, label: 'Date' },
  ]

  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}
    >
      <Grid item container xs={12} paddingBottom={2.5}>
        {confirmDialog}
        <ResultatRechercheBarCard
          title="Liste des messages"
          total={total}
          sortBy={sortBy}
          sortOptions={sortOptions}
          initSort={initSort}
          withSorting
          withExportCSV={false}
        />
      </Grid>
      {isError && !isSuccess && !isLoading && !isFetching ? (
        <Alert severity="info" sx={{ width: '100%' }}>
          Aucune donnée
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '40%' }}>Objet</TableCell>
                <TableCell sx={{ width: '20%' }}>Entreprise</TableCell>
                <TableCell sx={{ width: '10%' }} align="center">
                  Pièce jointe
                </TableCell>
                <TableCell sx={{ width: '20%' }}>Date/Heure</TableCell>
                <TableCell sx={{ width: '10%' }} align="center" />
              </TableRow>
            </TableHead>
          </Table>
          {total && total > 0 ? (
            <InfiniteScrollComponent
              dataLength={combinedData?.length}
              next={readMore}
              hasMore={combinedData.length < (total ?? 0)}
              isLoading={isLoading || isFetching}
              areaLoading={<AreaLoading height={90} />}
              xs={12}
            >
              {combinedData?.map((na: NotificationMedecin) => (
                <Table key={na.code}>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ width: '40%' }}>{makeEllipsis(na.objet, 40)}</TableCell>
                      <TableCell sx={{ width: '20%' }}>{na.entreprise?.libelle}</TableCell>
                      <TableCell sx={{ width: '10%' }} align="center">
                        {getDocuments(na.documents)}
                      </TableCell>
                      <TableCell sx={{ width: '20%' }}>{formatDateTimeFR(na.date)}</TableCell>
                      <TableCell sx={{ width: '10%', paddingRight: 4 }} align="right">
                        <VoirIconButton
                          onClick={() =>
                            confirm({
                              maxWidth: 'lg',
                              fullWidth: true,
                              withForm: true,
                              form: (
                                <MessageMedecinDetails
                                  messageMedecin={na as MessageVersAssureur}
                                  onClose={closeConfirmDialog}
                                />
                              ),
                              confirmMsg: '',
                              title: 'Détail du message',
                            })
                          }
                          tooltip="Voir le message"
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </InfiniteScrollComponent>
          ) : (
            <Table>
              <NoRowsOverlay />
            </Table>
          )}
        </TableContainer>
      )}
    </Grid>
  )
}
