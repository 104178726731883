import React from 'react'
import { useSnackbar } from 'notistack'
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import {
  determineDocumentsRapport,
  determineLibelleRapport,
  isRapportCarence,
  isValidationPrimaire,
  isValideDefinitivement,
} from 'plateforme/services/rapport.services'
import DocumentsTablePart from 'plateforme/parts/DocumentsTablePart'
import { formatDateFR } from 'plateforme/services/dates.services'
import { ButtonsStack, MessageAlert, TelechargerPDFButton } from 'plateforme/components'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { AuthDownloadAction, AuthDownloadDocumentDossierRequest } from 'plateforme/store/types/utilisateur'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import { determineShowPrecisionsMission } from 'plateforme/services/mission.services'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import FicheRapportEntreprisePart from './FicheRapportEntreprisePart'

interface TabRapportEntreprisePartProps {
  dossier: DossierEntreprise
  mission: MissionEntreprise
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
}

export default function TabRapportEntreprisePart({ dossier, mission, typeConclusion }: TabRapportEntreprisePartProps) {
  const { code: codeDossier } = dossier
  const { demandesModifications, rapport } = mission

  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()
  const { enqueueSnackbar } = useSnackbar()

  const documents = determineDocumentsRapport(mission)
  const derniereDemandeDeModification =
    !demandesModifications || demandesModifications?.length < 1
      ? undefined
      : demandesModifications.at(demandesModifications.length - 1)

  const derniereDemandeDeModificationEnCours =
    derniereDemandeDeModification && !derniereDemandeDeModification?.dateValidation
      ? derniereDemandeDeModification
      : undefined

  const ficheDocument = rapport?.documents
    ?.filter((d) => d.statut === 'ACTIF')
    .find((d) => d.typeDocument === 'FP' || d.typeDocument === 'FD' || d.typeDocument === 'FC')

  const missionCloturee = mission?.statut === 'CLOTUREE'
  const missionTerminee = missionCloturee || isValideDefinitivement(rapport)

  const showAlerteMissionPrecisions = determineShowPrecisionsMission(mission)
  function alertePrecisionsMission() {
    return (
      <MessageAlert title="Précisions sur la mission" backgroundColor="white" paddingLeft={2}>
        {mission?.precisions?.split('\n').map((line) => (
          <p style={{ margin: 0 }}>{line}</p>
        ))}
      </MessageAlert>
    )
  }

  const showAlerteDemandeModification = derniereDemandeDeModificationEnCours
  function alerteDemandeModification() {
    return (
      <MessageAlert severity="warning" backgroundColor="white" withGutter paddingLeft={2}>
        <>
          <strong>Demande de modification : </strong>
          {derniereDemandeDeModificationEnCours?.commentaire}
        </>
      </MessageAlert>
    )
  }

  const showAlerteEnCourDeSaisie = !missionTerminee && !isValidationPrimaire(rapport)
  function alerteEnCourDeSaisie() {
    return (
      <MessageAlert iconCenter backgroundColor="white" paddingLeft={2}>
        <Grid container paddingY={0.5} alignItems="center">
          <Grid item xs={12} md={6}>
            <strong>
              {isRapportCarence(typeConclusion)
                ? 'Rapport de carence de la mission : '
                : 'Conclusions de la mission : '}
            </strong>
            {derniereDemandeDeModificationEnCours
              ? `en demande de modification depuis le ${formatDateFR(derniereDemandeDeModificationEnCours?.date)}`
              : `en cours de saisie depuis le ${formatDateFR(rapport?.dateCreation)}`}
          </Grid>
        </Grid>
      </MessageAlert>
    )
  }

  const showAlerteRapportValide = missionTerminee
  function alerteRapportValide() {
    return (
      <MessageAlert iconCenter backgroundColor="white" paddingLeft={2}>
        <Grid container paddingY={0.5} alignItems="center">
          <Grid item xs={12} lg={6} marginTop="-2px">
            <strong>Le rapport a été validé : </strong>
            le {formatDateFR(rapport?.dateValidationMedecin)}
          </Grid>
          <Grid item xs={12} lg={6}>
            <ButtonsStack>
              <TelechargerPDFButton
                onClick={() => ficheDocument && onDownload(ficheDocument)}
                loading={isLoadingAuthDownload}
                disabled={!ficheDocument}
              >
                Télécharger la fiche au format pdf
              </TelechargerPDFButton>
            </ButtonsStack>
          </Grid>
        </Grid>
      </MessageAlert>
    )
  }

  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeDossier) {
      throw new Error('erreur inattendu')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement du document a échoué`, { variant: 'error' })
      })
  }

  return (
    <Stack>
      {showAlerteMissionPrecisions && alertePrecisionsMission()}
      {showAlerteDemandeModification && alerteDemandeModification()}
      {showAlerteEnCourDeSaisie && alerteEnCourDeSaisie()}
      {showAlerteRapportValide && alerteRapportValide()}
      <Card>
        <CardHeader title={`Fiche de ${determineLibelleRapport(typeConclusion)}`} />
        <CardContent>
          <FicheRapportEntreprisePart dossier={dossier} mission={mission} typeConclusion={typeConclusion} />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Documents rapport et annexe" />
        <CardContent>
          <DocumentsTablePart documents={documents} dossier={dossier} nbLigneParPage={15} readOnly />
        </CardContent>
      </Card>
    </Stack>
  )
}
