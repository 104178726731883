import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'

export interface SelectedDocumentMedecin {
  code?: string
}

export const selectedDocumentMedecinSliceName = 'selectedDocumentMedecin'

const selectedDocumentMedecinSlice = createSlice({
  name: selectedDocumentMedecinSliceName,
  initialState: {
    selectedDocumentMedecin: undefined,
  } as { selectedDocumentMedecin: SelectedDocumentMedecin | undefined },
  reducers: {
    setSelectedDocumentMedecin: (state, action: PayloadAction<SelectedDocumentMedecin | undefined>) => {
      state.selectedDocumentMedecin = action.payload
    },
  },
})

export const { setSelectedDocumentMedecin } = selectedDocumentMedecinSlice.actions

export const getSelectedDocumentMedecin = (state: RootState) => state?.selectedDocumentMedecin?.selectedDocumentMedecin

export default selectedDocumentMedecinSlice.reducer
