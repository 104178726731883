import React, { useCallback } from 'react'
import { fileSave } from 'browser-fs-access'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AreaLoading, FontAwesomeSvgIcon, NoRowsOverlay } from 'plateforme/components'
import IndicateurActiviteAdmin from 'admin/store/types/indicateurActiviteAdmin'

type TableIndicateurActivitePartProps = {
  data?: IndicateurActiviteAdmin
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
}

const indicateurMapping: Record<keyof IndicateurActiviteAdmin, string> = {
  dossiersOuverts: 'Dossiers ouverts',
  dossiersEnCours: 'Dossiers en cours',
  dossiersClotures: 'Dossiers clôturés',
  dossiersAnnules: 'Dossiers annulés',
  missionsAcceptees: 'Missions acceptées',
  missionsRefusees: 'Missions refusées',
  missionsEnCoursDeSaisie: 'Missions en cours/conclusions en cours de saisie',
  missionsValidees: 'Missions validées',
  missionsEnCoursDemandeDeModification: 'Missions en cours/en demande de modification',
  missionsAnnulees: 'Missions annulées',
  missionsProposeesSansSuite: 'Missions proposées sans suite',
  missionsArretees: 'Missions arrêtées',
  missionsCloturees: 'Missions clôturées',
  facturesEmises: 'Factures émises',
  facturesAcceptees: 'Factures acceptées',
  facturesRejetees: 'Factures rejetées',
}

export default function TableIndicateurActiviteAdminPart({
  data,
  isError,
  isLoading,
  isSuccess,
}: TableIndicateurActivitePartProps) {
  const onExport = useCallback(() => doExport(data), [data])
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const isNoData = isError || !isSuccess || !data

  if (isLoading) {
    return <AreaLoading height={180} />
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" width="100%">
      <Grid item container xs={12} paddingBottom={2.5}>
        <TableHeader onExport={onExport} disabledExport={isNoData} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={isMd ? { width: '30%' } : { width: '40%' }}>Indicateur</TableCell>
                <TableCell sx={isMd ? { width: '70%' } : { width: '60%' }}>Nombre</TableCell>
              </TableRow>
            </TableHead>
            {isNoData ? (
              <NoRowsOverlay />
            ) : (
              <TableBody>
                {Object.entries(data).map(([k, v]) => (
                  <TableRow key={k}>
                    <TableCell>{indicateurMapping[k as keyof IndicateurActiviteAdmin]}</TableCell>
                    <TableCell>{v}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

function TableHeader({ onExport, disabledExport }: { onExport: () => void; disabledExport: boolean }) {
  return (
    <Card
      sx={{
        width: '100%',
        height: '50px',
        backgroundColor: (theme) => theme.palette.info.main,
        color: (theme) => theme.palette.info.contrastText,
      }}
    >
      <Stack paddingLeft="20px" direction="row" alignItems="center" justifyContent="flex-start">
        <Typography variant="h5" sx={{ WebkitFontSmoothing: 'auto', MozOsxFontSmoothing: 'auto' }}>
          Liste des indicateurs d&apos;activité
        </Typography>
      </Stack>
      <Stack direction="row" spacing={0} flexGrow={3} justifyContent="flex-end">
        <>
          <Divider color="#f9f9f9" variant="inset" orientation="vertical" light flexItem sx={{ marginLeft: 0 }} />
          <Tooltip title="Télécharger CSV" placement="bottom">
            <Button
              disabled={disabledExport}
              onClick={onExport}
              id="downlod-csv"
              color="info"
              variant="contained"
              disableElevation
            >
              <FontAwesomeSvgIcon icon={faFileCsv} />
            </Button>
          </Tooltip>
        </>
      </Stack>
    </Card>
  )
}

async function doExport(data?: IndicateurActiviteAdmin) {
  if (!data) {
    return
  }

  const headers = Object.keys(data)
    .map((k) => `${indicateurMapping[k as keyof IndicateurActiviteAdmin]}`)
    .join(',')
  const values = Object.values(data)
    .map((k) => `${k}`)
    .join(',')
  // The BOM character (represented by "\ufeff" in JavaScript) is a special Unicode character that indicates the byte
  // order and the encoding scheme of the text.
  const bom = '\ufeff'
  const headersBom = `${bom}${headers}`

  const blob = new Blob([headersBom, '\r\n', values, '\r\n'], { type: 'text/csv' })

  await fileSave(blob, {
    fileName: 'indicateur-activite.csv',
    extensions: ['.csv'],
  })
}
