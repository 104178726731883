import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { useGetListeMessagesQuery } from 'assureur/store/apis/messageEntrepriseApi'
import { AreaLoading, FichierIcon, NoRowsOverlay, VoirIconButton } from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { derniersMessagesMedecinFiltre } from 'assureur/services/messageEntreprise.services'
import { isMessageDuMedecinVersAssureur, MessageDuMedecinVersAssureur } from 'assureur/store/types/messageEntreprise'
import { useSearchParams } from 'react-router-dom'
import MessageMedecinDetails from './MessageMedecinDetails'

export interface MessagesMedecinPartProps {
  codeMedecin: string
}

type SelectedMessage = {
  code: string
  message?: MessageDuMedecinVersAssureur
}

export default function MessagesMedecinPart({ codeMedecin }: MessagesMedecinPartProps) {
  const nbLigneParPage = 5
  const filter = derniersMessagesMedecinFiltre(codeMedecin)
  const { data, isLoading } = useGetListeMessagesQuery(
    {
      perPage: 10,
      filter,
    },
    {
      selectFromResult: ({ data: result, ...rest }) => ({
        ...rest,
        data: { ...result, items: result?.items.filter(isMessageDuMedecinVersAssureur) ?? [] },
      }),
    }
  )
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(nbLigneParPage)

  const [searchParams, setSearchParams] = useSearchParams()
  const codeMessage = searchParams.get('codeMessage')

  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const [selectedMessage, setSelectedMessage] = React.useState<SelectedMessage | undefined>(
    codeMessage ? { code: codeMessage } : undefined
  )
  React.useEffect(() => {
    if (selectedMessage) {
      setSearchParams({ codeMessage: selectedMessage.code }, { replace: true })
      confirm({
        maxWidth: 'lg',
        fullWidth: true,
        withForm: true,
        form: (
          <MessageMedecinDetails
            codeMessage={selectedMessage.code}
            messageMedecin={selectedMessage.message}
            onClose={() => setSelectedMessage(undefined)}
          />
        ),
        confirmMsg: '',
        title: 'Détail du message du médecin',
      })
    } else {
      closeConfirmDialog()
      searchParams.delete('codeMessage')
      setSearchParams(searchParams, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMessage])

  if (isLoading) {
    return <AreaLoading height={351} />
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const messages = data?.items
  const total = messages?.length ?? 0

  return (
    <>
      {confirmDialog}
      <Card>
        <CardHeader title="Messages du médecin" />
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '30%' }}>Date/heure</TableCell>
                  <TableCell sx={{ width: '60%' }}>Objet</TableCell>
                  <TableCell sx={{ width: '5%' }} />
                  <TableCell sx={{ width: '5%' }} />
                </TableRow>
              </TableHead>
              {total > 0 ? (
                <TableBody>
                  {messages.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((messageMedecin) => (
                    <TableRow key={messageMedecin.code}>
                      <TableCell sx={{ width: '30%' }}>{formatDateTimeFR(messageMedecin.date)}</TableCell>
                      <TableCell sx={{ width: '60%' }}>{messageMedecin.objet}</TableCell>
                      <TableCell sx={{ width: '5%' }}>
                        {messageMedecin.documents && messageMedecin.documents.length > 0 && (
                          <FichierIcon fontSize="medium" />
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '5%' }} align="center">
                        <VoirIconButton
                          color="tile"
                          onClick={() => setSelectedMessage({ code: messageMedecin.code, message: messageMedecin })}
                          tooltip="Voir le message"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <NoRowsOverlay />
              )}
            </Table>
          </TableContainer>
          {total > 0 && (
            <TablePagination
              component="div"
              count={total}
              rowsPerPageOptions={[nbLigneParPage]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </CardContent>
      </Card>
    </>
  )
}
