import React from 'react'
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid } from '@mui/material'
import {
  EditTextField,
  ErrorField,
  PhoneNumberInput,
  SelectInputReferentiel,
  SupprimerIconButton,
} from 'plateforme/components'

type LieuExerciceMedecinTabProps = {
  index: number
  readOnly: boolean
  remove: UseFieldArrayRemove
}

export default function LieuExerciceAdminMedecinTab({ index, readOnly, remove }: LieuExerciceMedecinTabProps) {
  const { control, getValues, setValue, formState, clearErrors, trigger } = useFormContext()
  const lieuxExerciceError = 'lieuxExercice._error'

  function clearLieuExerciceError() {
    clearErrors(lieuxExerciceError)
    setValue(lieuxExerciceError, undefined, {
      shouldValidate: true,
      shouldDirty: true,
    })
    trigger('lieuxExercice')
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Controller
          name={`lieuxExercice.${index}.adresse1`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              label="Adresse 1"
              fullWidth
              readOnly={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`lieuxExercice.${index}.adresse2`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              label="Adresse 2"
              fullWidth
              readOnly={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`lieuxExercice.${index}.adresse3`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              label="Adresse 3"
              fullWidth
              readOnly={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name={`lieuxExercice.${index}.codePostal`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              label="Code postal"
              fullWidth
              readOnly={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name={`lieuxExercice.${index}.commune`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              label="Ville"
              fullWidth
              readOnly={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name={`lieuxExercice.${index}.pays`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInputReferentiel
              id="pays"
              label="Pays"
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              referentielName="pays"
              fullWidth
              fieldError={error}
              readOnly={readOnly}
              dataFormatter={(code, label) => {
                return `${label}`
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name={`lieuxExercice.${index}.telephone`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <PhoneNumberInput
              label="Téléphone"
              value={value}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              fullWidth
              readOnly={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name={`lieuxExercice.${index}.codeSIRET`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <EditTextField
              value={value}
              onChange={(e) => {
                clearLieuExerciceError()
                onChange(e)
              }}
              onBlur={onBlur}
              fieldError={error}
              label="Numéro de SIRET"
              fullWidth
              readOnly={readOnly}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name={`lieuxExercice.${index}.principal`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <FormControl error={error !== undefined}>
              <FormGroup>
                <FormControlLabel
                  label="Établissement principal"
                  sx={{ height: 30 }}
                  control={
                    <Checkbox
                      disabled={readOnly}
                      name="principal"
                      checked={value}
                      onChange={(e) => {
                        clearLieuExerciceError()
                        onChange(e)
                      }}
                      onBlur={onBlur}
                      sx={{ padding: 0, marginX: 1.5, display: 'bloc' }}
                    />
                  }
                />
              </FormGroup>
              {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={6} textAlign="end">
        <Box display="flex" justifyContent="flex-end">
          {/* Pas de suppression possible si 1 élément ou moins */}
          {getValues('lieuxExercice').length > 1 && (
            <SupprimerIconButton tooltip="Supprimer l'etablissement" onClick={() => remove(index)} />
          )}
        </Box>
      </Grid>
      {formState.errors && Object.keys(formState.errors).length !== 0 && (
        <Grid item xs={12} color="error.main">
          <ErrorMessage
            name={lieuxExerciceError}
            errors={formState.errors}
            render={({ message }) => <ErrorField message={message} />}
          />
        </Grid>
      )}
    </Grid>
  )
}
