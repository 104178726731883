import React from 'react'
import { Alert, AlertProps, AlertTitle, Typography, useTheme } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'

export default function MessageAlert(
  props: AlertProps & {
    title?: string
    iconCenter?: boolean
    textCenter?: boolean
    paddingLeft?: number
    backgroundColor?: string
    withGutter?: boolean
    elevation?: number
    textVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
  }
) {
  const {
    paddingLeft,
    withGutter,
    title,
    iconCenter,
    textCenter,
    children,
    backgroundColor,
    textVariant = 'body2',
    elevation = 1,
    severity = 'info',
    sx,
  } = props
  const theme = useTheme()
  const baseSx = {
    paddingTop: 0,
    paddingBottom: withGutter ? 1.5 : 0,
    paddingLeft: paddingLeft ?? 1,
    paddingRight: 2,
    backgroundColor,
    width: '100%',
    '& .MuiAlert-message': {
      width: '100%',
      alignItems: iconCenter ? 'center' : undefined,
      display: textCenter ? 'flex' : undefined,
    },
    '& .MuiAlert-icon': {
      alignItems: iconCenter ? 'center' : undefined,
    },
    ...sx,
  }
  return (
    <Alert severity={severity} elevation={elevation} sx={baseSx}>
      {title && (
        <AlertTitle>
          <Typography
            variant={textVariant}
            fontWeight="bold"
            color={theme.palette.tile.contrastText}
            marginTop={iconCenter ? undefined : '2px'}
          >
            {title}
          </Typography>
        </AlertTitle>
      )}
      {children && (
        <Typography
          variant={textVariant}
          color={theme.palette.tile.contrastText}
          sx={{ wordWrap: 'break-word' }}
          component="div"
        >
          {children}
        </Typography>
      )}
    </Alert>
  )
}
