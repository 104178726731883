import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'

interface LieuExpertisePartProps {
  readOnly?: boolean
}

export default function LieuExpertisePart({ readOnly }: LieuExpertisePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title={`Paramétrage lieux d'expertise`} />
      <CardContent>
        <Grid item xs={12}>
          <Controller
            name="parametrage.lieuExpertiseAvecADefinir"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                <FormControlLabel
                  id="lieuExpertiseAvecADefinir"
                  value={value}
                  control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                  label={`Utilisation de la valeur "À définir" pour les lieux d'expertise`}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}
