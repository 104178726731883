import React from 'react'
import { ReferentielEntreprise } from 'plateforme/store/types/profilEntreprise'
import { useGetReferentielAdminEntrepriseByCodeMedecinQuery } from 'admin/store/apis/gestionEntrepriseApi'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

type SelectReferentielProps = Omit<SelectInputWithQueryProps<void>, 'useQuery' | 'dataTransformer' | 'options'> & {
  dataFormatter?: (code: string, label: string) => string
  codeMedecin: string
}

export default function SelectInputEntrepriseDuMedecinAdmin({ codeMedecin, ...restProps }: SelectReferentielProps) {
  const dataTransformer = (data: ReferentielEntreprise[]) => {
    return data.map((ref) => {
      return { code: ref.code, label: ref.libelle } as SelectOption
    })
  }

  return (
    <SelectInputWithQuery
      {...restProps}
      orderBy="label"
      queryParam={{ codeMedecin }}
      useQuery={useGetReferentielAdminEntrepriseByCodeMedecinQuery}
      dataTransformer={dataTransformer}
    />
  )
}
