import * as React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  styled,
} from '@mui/material'

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}))

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
  fontSize: '17px',
  color: theme.palette.text.primary,
}))

export interface DialogComponentProps extends DialogProps {
  title: string
  confirmMsg: string | React.ReactNode
  dialogContentNode: React.ReactNode
}

export default function DialogComponent(props: DialogComponentProps) {
  const { title, confirmMsg, dialogContentNode, ...other } = props
  return (
    <Dialog {...other} aria-labelledby="draggable-dialog-title">
      <StyledDialogTitle id="draggable-dialog-title">{title}</StyledDialogTitle>
      {confirmMsg !== '' && (
        <DialogContent sx={{ flexGrow: 0, flexShrink: 0 }}>
          <StyledDialogContentText>{confirmMsg}</StyledDialogContentText>
        </DialogContent>
      )}
      <DialogActions>{dialogContentNode}</DialogActions>
    </Dialog>
  )
}
