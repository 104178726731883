import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { hasRoles, Role } from 'plateforme/services/roles.services'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { StatutMessagerie } from 'plateforme/store/types/mission'
import MissionEntreprise from '../store/types/missionEntreprise'

export function isGestionnaire(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function messageConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_ADMIN_RESEAU_MEDECIN])
}

export function dossierCreable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function habilitationCreable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_ADMIN_RESEAU_MEDECIN])
}

export function fluxTechniqueConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_REFERENT_TECH_FLUX])
}

export function dossierConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR, Role.ASSU_GESTIONNAIRE, Role.ASSU_LECTEUR])
}

export function dossierAttenteAcceptationConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR, Role.ASSU_LECTEUR])
}

export function toutesLesFacturesATraiter(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR, Role.ASSU_LECTEUR])
}

export function habilitationModifiable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_ADMIN_RESEAU_MEDECIN])
}

export function parametrageConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR, Role.ASSU_REFERENT_TECH_FLUX])
}

export function notificationConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR, Role.ASSU_GESTIONNAIRE])
}

export function dossierValidable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.validable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierMissionnable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.missionable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierAnnulable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.annulable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierCloturable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.cloturable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierReouvrable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.reouvrable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierConsentementRetirable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.consentementRetirable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierPurgeable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.purgeable && hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR])
}

export function missionCloturable(mission?: MissionEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return mission?.cloturable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function missionAnnulable(mission?: MissionEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return mission?.annulable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function missionDeclarableSansSuite(mission?: MissionEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return mission?.declarableSansSuite && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function missionArretable(mission?: MissionEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return mission?.arretable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function missionModificationDemandable(mission?: MissionEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return mission?.modificationDemandable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function missionMessageEnvoyable(mission?: MissionEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return mission?.messagerieActive === StatutMessagerie.ACTIVE && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function gestionnaireAffectable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR])
}

export function tousLesDossiersHorsDelaiConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_SUPERVISEUR])
}

export function mesDossiersHorsDelaiConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierEditable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.editable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function dossierDocumentDeposable(dossier?: DossierEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return dossier?.documentDeposable && hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE])
}

export function factureTraitable(mission?: MissionEntreprise, profilUtilisateur?: ProfilUtilisateurActif) {
  return mission?.factureTraitable && !hasRoles(profilUtilisateur, [Role.ASSU_LECTEUR])
}

export function factureConsultable(profilUtilisateur?: ProfilUtilisateurActif) {
  return !hasRoles(profilUtilisateur, [Role.ASSU_GESTIONNAIRE, Role.ASSU_SUPERVISEUR])
}

export function hasAccesFicheMedecinNonHabilite(profilUtilisateur?: ProfilUtilisateurActif) {
  return hasRoles(profilUtilisateur, [Role.ASSU_ADMIN_RESEAU_MEDECIN])
}
