import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Grid, Stack } from '@mui/material'
import { ActionTile, MedecinIcon } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { useNavigate } from 'react-router-dom'
import NavigationPart from 'plateforme/parts/NavigationPart'
import { trimToUndefined } from 'plateforme/services/utils'
import { usePostAdminStatutMedecinMutation, usePutAdminMedecinMutation } from 'admin/store/apis/gestionMedecinApi'
import {
  useGetListeHabilitationMedecinAdminQuery,
  habilitationActiveWithCodeMedecin,
} from 'admin/store/apis/habilitationMedecinAdminApi'
import ProfilMedecinAdmin, {
  LieuExerciceAdmin,
  ParametrageMedecinAdmin,
  StatutMedecin,
  SuiviMedecinRequest,
  SuiviMedecinType,
} from 'admin/store/types/profilMedecinAdmin'
import { adminMedecinHabilitationsHref, adminModifierMedecinHref } from 'admin/AdminApp'
import HabilitationsActivesMedecinPart from 'medecin/parts/profil/HablitationsActivesMedecinPart'
import AlertAdminMedecinPart from './AlertAdminMedecinPart'
import InformationsAdminMedecinPart from './InformationsAdminMedecinPart'
import LieuxExerciceAdminMedecinPart from './LieuxExerciceAdminMedecinPart'
import PreferencesPlateformeAdminMedecinPart from './PreferencesPlateformeAdminMedecinPart'

interface ModifierMedecinFormProps {
  medecin: ProfilMedecinAdmin | undefined
}

export default function ModifierMedecinForm({ medecin }: ModifierMedecinFormProps) {
  const initialValues = {
    code: medecin?.code ?? '',
    codeRPPS: medecin?.codeRPPS ?? '',
    codeSIREN: medecin?.codeSIREN ?? '',
    libelle: medecin?.libelle ?? '',
    mailContact: medecin?.mailContact ?? '',
    statut: medecin?.statut ?? '',
    labelStatut: medecin?.labelStatut ?? '',
    irca: medecin?.irca ?? false,
    expertiseSurPiece: medecin?.expertiseSurPiece ?? false,
    lieuxExercice: medecin?.lieuxExercice ?? [],
    dateMiseAJour: medecin?.dateMiseAJour ?? null,
    commentaireActivite: medecin?.commentaireActivite ?? '',
    parametrage: {
      mailNotification: medecin?.parametrage.mailNotification ?? '',
      frequenceNotification: medecin?.parametrage.frequenceNotification,
      signature: medecin?.parametrage.signature ?? '',
    },
  }

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })

  const [putAdminMedecin, { error: errorModifierMedecin }] = usePutAdminMedecinMutation()
  const [postAdminStatutMedecin, { isLoading: isModifierStatutLoading }] = usePostAdminStatutMedecinMutation()
  const {
    data: habilitationResponse,
    isLoading,
    isFetching,
  } = useGetListeHabilitationMedecinAdminQuery(habilitationActiveWithCodeMedecin(medecin?.code as string))

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const { setError, getValues, handleSubmit } = methods

  const setLieuxExercice = (listLieux: LieuExerciceAdmin[]) => {
    return listLieux.map((l) => {
      return {
        principal: l.principal,
        codePostal: trimToUndefined(l.codePostal),
        commune: trimToUndefined(l.commune),
        codeSIRET: trimToUndefined(l.codeSIRET),
        telephone: trimToUndefined(l.telephone),
        adresse1: trimToUndefined(l.adresse1),
        adresse2: trimToUndefined(l.adresse2),
        adresse3: trimToUndefined(l.adresse3),
        pays: trimToUndefined(l.pays),
      } as LieuExerciceAdmin
    })
  }

  const setParametrage = (parametrage: ParametrageMedecinAdmin) => {
    return {
      mailNotification: trimToUndefined(parametrage.mailNotification),
      frequenceNotification: trimToUndefined(parametrage.frequenceNotification),
      signature: trimToUndefined(parametrage.signature),
    }
  }

  // Mapping Errors in form:
  useErrorFormMapper(errorModifierMedecin as IQueryErrorResponse, setError, getValues)

  const readOnly = medecin?.statut === StatutMedecin.SUPPRIME
  const enCreation = medecin?.statut === StatutMedecin.INSCRIPTION

  const onSubmit = async () => {
    const formValues = getValues() as ProfilMedecinAdmin

    const formData = {
      code: trimToUndefined(formValues.code),
      codeRPPS: trimToUndefined(formValues.codeRPPS),
      codeSIREN: trimToUndefined(formValues.codeSIREN),
      libelle: trimToUndefined(formValues.libelle),
      mailContact: trimToUndefined(formValues.mailContact),
      statut: formValues.statut,
      irca: formValues.irca,
      expertiseSurPiece: formValues.expertiseSurPiece,
      lieuxExercice: setLieuxExercice(formValues.lieuxExercice),
      commentaireActivite: trimToUndefined(formValues.commentaireActivite),
      parametrage: setParametrage(formValues.parametrage),
    } as ProfilMedecinAdmin

    await putAdminMedecin(formData)
      .unwrap()
      .then((medecinMod) => {
        if (medecin?.code !== medecinMod?.code) {
          navigate(adminModifierMedecinHref(medecinMod.code as string))
        }
        enqueueSnackbar(`Les informations médecin ont été modifiées avec succès`, { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar(`La modification des informations médecin a échoué`, { variant: 'error' })
      })
  }

  const handleChangeStatutMedecin = async (statut: SuiviMedecinType) => {
    await postAdminStatutMedecin({
      code: getValues('code'),
      action: statut,
    } as SuiviMedecinRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar(`Le statut du médecin a été modifié avec succès`, { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar(`La modification du statut du médecin a échoué`, { variant: 'error' })
      })
  }

  const formId = 'form-parametres-medecin'

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id={formId} name={formId}>
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <NavigationPart label="Organisation médecin et habilitations" />
          </Grid>
          <Grid item xs={12}>
            <AlertAdminMedecinPart readOnly={readOnly} />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Stack>
              <InformationsAdminMedecinPart
                readOnly={readOnly}
                codeRPPSReadOnly={!enCreation && medecin?.codeRPPS !== undefined && medecin?.codeRPPS !== null}
                codeSIRENReadOnly={!enCreation && medecin?.codeSIREN !== undefined && medecin?.codeSIREN !== null}
              />
              <PreferencesPlateformeAdminMedecinPart readOnly={readOnly} />
              <Grid container marginTop="0 !important" marginLeft="-28px !important">
                {medecin?.activable && (
                  <Grid item xs={6} md={3} lg={4}>
                    <ActionTile
                      label="Activer médecin"
                      icon={<MedecinIcon />}
                      onClick={() => {
                        handleChangeStatutMedecin(SuiviMedecinType.ACTIVER)
                      }}
                      loading={isModifierStatutLoading}
                    />
                  </Grid>
                )}
                {medecin?.suspensible && (
                  <Grid item xs={6} md={3} lg={4}>
                    <ActionTile
                      label="Suspendre médecin"
                      icon={<MedecinIcon />}
                      onClick={() => {
                        handleChangeStatutMedecin(SuiviMedecinType.SUSPENDRE)
                      }}
                      loading={isModifierStatutLoading}
                    />
                  </Grid>
                )}
                {medecin?.statut !== StatutMedecin.SUPPRIME && (
                  <Grid item xs={6} md={3} lg={4}>
                    <ActionTile
                      disabled={!medecin?.supprimable}
                      tooltip={!medecin?.supprimable ? 'Une mission active est en cours pour ce médecin' : undefined}
                      label="Supprimer médecin"
                      icon={<MedecinIcon />}
                      onClick={() => {
                        handleChangeStatutMedecin(SuiviMedecinType.SUPPRIMER)
                      }}
                      loading={isModifierStatutLoading}
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Stack>
              <LieuxExerciceAdminMedecinPart readOnly={readOnly} />
              <HabilitationsActivesMedecinPart
                title="Habilitations actives"
                libelleBouton="Toutes les habilitations"
                habilitationsPath={adminMedecinHabilitationsHref(medecin?.code as string)}
                habilitationResponse={habilitationResponse}
                isLoading={isLoading}
                isFetching={isFetching}
              />
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
