import React, { useEffect } from 'react'
import { Grid, Stack } from '@mui/material'
import { ProfilUtilisateurActif } from 'plateforme/store/types/utilisateur'
import { LoadingProps } from 'plateforme/services/utils'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import { messageMedecinApi } from 'medecin/store/apis/messageMedecinApi'
import { useAppDispatch } from 'plateforme/store/hooks/hooks'
import InformationsSyntheseMedecinPart from './InformationsSyntheseMedecinPart'
import DocumentsSyntheseMedecinPart from './DocumentsSyntheseMedecinPart'
import NotificationSyntheseMedecinPart from './NotificationSyntheseMedecinPart'
import SuiviSyntheseMedecinPart from './SuiviSyntheseMedecinPart'
import ActionTilesSyntheseMedecinPart from './ActionTilesSyntheseMedecinPart'
import LieuRDVMissionPart from './LieuRDVMissionPart'

type TabSyntheseMedecinPartProps = {
  dossier?: DossierMedecin
  mission?: MissionEntreprise
  profilUtilisateur?: ProfilUtilisateurActif
}

export default function TabSyntheseMedecinPart({
  dossier,
  mission,
  profilUtilisateur,
  loading,
}: TabSyntheseMedecinPartProps & LoadingProps) {
  const dispatch = useAppDispatch()

  // NOTE: REMOVE CACHE TO UPDATE NOTIFICATIONS
  useEffect(() => {
    dispatch(messageMedecinApi.util.invalidateTags([{ type: 'MessageMedecin' }]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container>
      <Grid item md={12} lg={6}>
        <Stack>
          <InformationsSyntheseMedecinPart dossier={dossier} mission={mission} loading={loading} />
          <LieuRDVMissionPart mission={mission} loading={loading} />
          <DocumentsSyntheseMedecinPart dossier={dossier} mission={mission} loading={loading} />
        </Stack>
      </Grid>
      <Grid item md={12} lg={6}>
        <Stack>
          <NotificationSyntheseMedecinPart dossier={dossier} loading={loading} />
          <SuiviSyntheseMedecinPart dossier={dossier} mission={mission} loading={loading} />
          <ActionTilesSyntheseMedecinPart
            dossier={dossier}
            mission={mission}
            profilUtilisateur={profilUtilisateur}
            loading={loading}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}
