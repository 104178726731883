import React from 'react'

import { DommageField } from 'assureur/store/types/dommagesEntreprise'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import determineSuffixeLibelleDommage from 'plateforme/services/dommage.services'
import ReadOnlyDommageTexte from './ReadOnlyDommageTexte'
import ReadOnlyDommagePourcentageFourchette from './ReadOnlyDommagePourcentageFourchette'
import ReadOnlyDommageSliderRange from './ReadOnlyDommageSliderRange'

interface DommagesFieldProps {
  dommage: DommageField
  typeRapport: TypeRapport
}

export default function ReadOnlyDommageRapportConseil({ dommage, typeRapport }: DommagesFieldProps) {
  const suffixeTitre = determineSuffixeLibelleDommage(typeRapport)

  switch (dommage.fieldName) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'INSTITUTIONNALISATION':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_APPAREILLAGE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return (
        <ReadOnlyDommageTexte
          widthTitle="20%"
          value={dommage?.rapportConseil?.commentaire}
          title={dommage.label}
          label="Commentaire"
        />
      )

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
      return (
        <ReadOnlyDommagePourcentageFourchette
          min={dommage?.rapportConseil?.min}
          max={dommage?.rapportConseil?.max}
          commentaire={dommage?.rapportConseil?.commentaire}
          title={dommage.label + suffixeTitre}
        />
      )

    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return (
        <ReadOnlyDommageSliderRange
          min={dommage?.rapportConseil?.min}
          max={dommage?.rapportConseil?.max}
          commentaire={dommage?.rapportConseil?.commentaire}
          title={dommage.label + suffixeTitre}
        />
      )

    default:
      return null
  }
}
