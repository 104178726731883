import React from 'react'
import { useSnackbar } from 'notistack'
import { Grid } from '@mui/material'
import { AddToPhotos, CreateNewFolder, Description, Download, Euro, MarkEmailRead, NoteAlt } from '@mui/icons-material'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { ActionTilesSyntheseLoading, ActionTile } from 'plateforme/components'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import {
  AuthDownloadAction,
  AuthDownloadFicheMissionRequest,
  AuthDownloadArchiveDossierRequest,
  ProfilUtilisateurActif,
} from 'plateforme/store/types/utilisateur'
import { toTelechargementArchiveDossierHref, toTelechargementFicheMissionHref } from 'plateforme/App'
import { LoadingProps } from 'plateforme/services/utils'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { StatutMessagerie, StatutMission } from 'plateforme/store/types/mission'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import {
  documentDossierAjoutable,
  ficheDossierTelechargeable,
  missionFacturable,
  propositionMissionTraitable,
  rapportCreable,
  rapportModifiable,
} from 'medecin/services/rolesMedecin.services'
import { downloadLastLettreMission, lettreMissionTelechargeable } from 'plateforme/services/document.services'
import CreerRapportForm from './CreerRapportForm'

type ActionTilesSyntheseMedecinPartProps = {
  dossier?: DossierMedecin
  mission?: MissionMedecin
  profilUtilisateur?: ProfilUtilisateurActif
}

export default function ActionTilesSyntheseMedecinPart({
  dossier,
  mission,
  profilUtilisateur,
  loading,
}: ActionTilesSyntheseMedecinPartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <ActionTilesSyntheseLoading />
  }
  return (
    <ActionTilesSyntheseMedecinPartLoaded dossier={dossier} mission={mission} profilUtilisateur={profilUtilisateur} />
  )
}

function ActionTilesSyntheseMedecinPartLoaded({
  dossier,
  mission,
  profilUtilisateur,
}: ActionTilesSyntheseMedecinPartProps) {
  // error:
  if (!dossier || !mission || !dossier?.code || !mission?.code || !profilUtilisateur) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { code: codeDossier } = dossier
  const { code: codeMission } = mission

  // hooks:
  const [authDownload, { isLoading: isAuthDownloadLoading }] = usePostAuthDownloadMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const { navigateTab } = useTabNavigate()

  // format:
  const messagerieActive = dossier?.missions?.some((m) => m.messagerieActive !== StatutMessagerie.DESACTIVE)
  const navigateTabMission = () => navigateTab(mission?.code)
  const navigateTabDocuments = () => navigateTab('documents')
  const navigateTabFactures = () => navigateTab('factures')
  const navigateTabMessagerie = () => navigateTab('messages')

  // open dialog form creation rapport:
  const openDialogCreationRapport = (typeRapport: TypeRapport) => {
    let confirmMsg: string
    let successMsg: string
    let errorMsg: string

    switch (typeRapport) {
      case TypeRapport.CONCLUSION_PROVISOIRE: {
        confirmMsg = 'Vous allez renseigner des conclusions provisoires pour cette mission, souhaitez-vous continuer ?'
        successMsg = 'Les conclusions provisoires ont été sauvegardées avec succès'
        errorMsg = 'La sauvegarde des conclusions provisoires a échoué'
        break
      }
      case TypeRapport.CONCLUSION_DEFINITIVE: {
        confirmMsg = 'Vous allez renseigner des conclusions définitives pour cette mission, souhaitez-vous continuer ?'
        successMsg = 'Les conclusions définitives ont été sauvegardées avec succès'
        errorMsg = 'La sauvegarde des conclusions définitives a échoué'
        break
      }
      case TypeRapport.CARENCE: {
        confirmMsg = 'Vous allez constater une carence sur ce dossier, souhaitez-vous continuer ?'
        successMsg = 'Le rapport de carence a été sauvegardé avec succès'
        errorMsg = 'La sauvegarde du rapport de carence a échoué'
        break
      }
      default: {
        confirmMsg = ''
        successMsg = ''
        errorMsg = ''
        break
      }
    }

    confirm({
      confirmMsg,
      maxWidth: 'md',
      fullWidth: true,
      withForm: true,
      form: (
        <CreerRapportForm
          dossier={dossier}
          mission={mission}
          typeRapport={typeRapport}
          successMsg={successMsg}
          errorMsg={errorMsg}
          onSuccess={navigateTabMission}
          onCancel={closeConfirmDialog}
        />
      ),
    })
  }

  // download archive documents du dossier:
  const downloadArchiveDossier = async () => {
    const msgEchec = 'Le téléchargement du fichier a échoué'
    const action = AuthDownloadAction.TELECHARGER_ARCHIVE_DOSSIER

    const request: AuthDownloadArchiveDossierRequest = { action, codeDossier, codeMission }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementArchiveDossierHref(codeDossier, codeMission)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(msgEchec, { variant: 'error' })
      })
  }

  // download fiche mission:
  const downloadFicheMission = async () => {
    const msgEchec = 'Le téléchargement de la fiche a échoué'
    const action = AuthDownloadAction.TELECHARGER_FICHE_MISSION

    const request: AuthDownloadFicheMissionRequest = { action, codeDossier, codeMission }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementFicheMissionHref(codeDossier, codeMission)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(msgEchec, { variant: 'error' })
      })
  }

  // render:
  return (
    <>
      {confirmDialog}
      <Grid container marginTop="0 !important" marginLeft="-28px !important">
        {rapportModifiable(mission, TypeRapport.CONCLUSION_PROVISOIRE, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Compléter le rapport de conclusions provisoires"
              icon={<NoteAlt />}
              onClick={navigateTabMission}
              loading={false}
            />
          </Grid>
        )}
        {rapportCreable(mission, TypeRapport.CONCLUSION_PROVISOIRE, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Renseigner les conclusions provisoires"
              icon={<NoteAlt />}
              onClick={() => openDialogCreationRapport(TypeRapport.CONCLUSION_PROVISOIRE)}
              loading={false}
            />
          </Grid>
        )}
        {rapportModifiable(mission, TypeRapport.CONCLUSION_DEFINITIVE, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Compléter le rapport de conclusions définitives"
              icon={<NoteAlt />}
              onClick={navigateTabMission}
              loading={false}
            />
          </Grid>
        )}
        {rapportCreable(mission, TypeRapport.CONCLUSION_DEFINITIVE, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Renseigner les conclusions définitives"
              icon={<NoteAlt />}
              onClick={() => openDialogCreationRapport(TypeRapport.CONCLUSION_DEFINITIVE)}
              loading={false}
            />
          </Grid>
        )}
        {rapportModifiable(mission, TypeRapport.CARENCE, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Compléter le rapport de carence"
              icon={<AddToPhotos />}
              onClick={navigateTabMission}
              loading={false}
            />
          </Grid>
        )}
        {rapportCreable(mission, TypeRapport.CARENCE, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Constater une carence"
              icon={<AddToPhotos />}
              onClick={() => openDialogCreationRapport(TypeRapport.CARENCE)}
              loading={false}
            />
          </Grid>
        )}
        {propositionMissionTraitable(mission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Télécharger tous les documents du dossier"
              icon={<Download />}
              onClick={downloadArchiveDossier}
              loading={isAuthDownloadLoading}
            />
          </Grid>
        )}
        {ficheDossierTelechargeable(mission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Générer la fiche dossier"
              icon={<Description />}
              onClick={downloadFicheMission}
              loading={isAuthDownloadLoading}
            />
          </Grid>
        )}
        {lettreMissionTelechargeable(dossier.missions, mission.code) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile
              label="Télécharger la lettre de mission"
              icon={<Description />}
              onClick={() =>
                downloadLastLettreMission(dossier.missions, codeDossier, mission?.code, enqueueSnackbar, authDownload)
              }
              loading={isAuthDownloadLoading}
            />
          </Grid>
        )}
        {documentDossierAjoutable(dossier, profilUtilisateur) &&
          mission.statut &&
          [StatutMission.EN_COURS, StatutMission.EN_DEMANDE_DE_MODIFICATION, StatutMission.VALIDEE_MEDECIN].includes(
            mission.statut
          ) && (
            <Grid item xs={6} md={3} lg={4}>
              <ActionTile
                label="Ajouter un document"
                icon={<CreateNewFolder />}
                onClick={navigateTabDocuments}
                loading={false}
              />
            </Grid>
          )}
        {missionFacturable(mission, profilUtilisateur) && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile label="Ajouter une facture" icon={<Euro />} onClick={navigateTabFactures} loading={false} />
          </Grid>
        )}
        {messagerieActive && (
          <Grid item xs={6} md={3} lg={4}>
            <ActionTile label="Messagerie" icon={<MarkEmailRead />} onClick={navigateTabMessagerie} loading={false} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
