import { SelectOption } from 'plateforme/components'
import { PageRechercheRequest, SortParam } from 'plateforme/store/types/pageRecherche'
import { StatutsHabilitation } from 'plateforme/store/types/habilitation'

export default interface HabilitationEntreprise extends SelectOption {
  code: string
  codeSIREN?: string
  codeACPR?: string
  codeRPPS?: string
  commentaireActivite?: string
  libelle: string
  numeroHabilitation?: string
  intervenantOccasionnel?: boolean
  irca?: boolean
  expertiseSurPiece?: boolean
  dateDebut?: Date
  dateFin?: Date
  statut?: StatutsHabilitation
  labelStatut?: string
  dateCreation: Date
  dateMiseAJour: Date
  mailContact: string
  lieuxExercice: LieuExercice[]
}

export interface LieuExercice {
  principal: boolean
  adresseComplete: string
  codeSIRET?: string
  telephone: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
}

export interface HabilitationEntrepriseFilter {
  numeroHabilitation?: string
  nomMedecin?: string
  codeMedecin?: string
  intervenantOccasionnel?: 0 | 1
  expertiseSurPiece?: 0 | 1
  irca?: 0 | 1
  onlyTotal?: 0 | 1
  dateActive?: string
  active?: 0 | 1 | string
  activeFutur?: 0 | 1 | string
  dateDebut?: string
  codePostal?: string
}

export enum HabilitationEntrepriseSortType {
  ENTREPRISE_SORT = 'entreprise',
  CODE_MEDECIN_SORT = 'code_medecin',
  NOM_MEDECIN_SORT = 'nom_medecin',
  NUMERO_HABILITATION_SORT = 'numero_habilitation',
  DATE_DEBUT_SORT = 'date_debut',
  DATE_FIN_SORT = 'date_fin',
  STATUT_SORT = 'statut',
}

export interface CreerHabilitationRequest {
  codeMedecin: string
  numeroHabilitation?: string
  intervenantOccasionnel?: boolean
  expertiseSurPiece?: boolean
  dateDebut?: Date
  dateFin?: Date
}

export interface ModifierHabilitationRequest {
  codeMedecin: string
  dateFin?: Date
  clotureImmediate?: 0 | 1
}

export const habilitationActiveWithCodeMedecin = (codeMedecin: string) =>
  ({
    filter: { activeFutur: 1, codeMedecin },
  } as PageRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>)

export const top100HabilitationRequest = (codeMedecin: string) =>
  ({
    filter: { codeMedecin },
    sort: {
      sortType: HabilitationEntrepriseSortType.DATE_DEBUT_SORT,
      sortOrder: 'DESC',
    } as SortParam<HabilitationEntrepriseSortType>,
    perPage: 100,
  } as PageRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>)

export const totalHabilitationEntrepriseRequest = () =>
  ({
    filter: { activeFutur: 1 },
    onlyTotal: 1,
  } as PageRechercheRequest<HabilitationEntrepriseFilter, HabilitationEntrepriseSortType>)
