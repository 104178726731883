import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { EditTextField } from 'plateforme/components'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import { DOMMAGES_COMMENTAIRES_MAX_ELIGIBLES } from 'plateforme/constantes'

interface DommagesPartProps {
  readOnly?: boolean
}

export default function DommagesCommentairesMaxPart({ readOnly }: DommagesPartProps) {
  const { control } = useFormContext()
  const { data: referentiel } = useGetReferentielQuery()

  return (
    <Card>
      <CardHeader title="Paramétrage dommages" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Commentaire</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {DOMMAGES_COMMENTAIRES_MAX_ELIGIBLES.map((d) => (
                    <TableRow key={d}>
                      <TableCell component="th" scope="row">
                        {referentiel?.dommagePredefini.find((dommage) => dommage.code === d)?.label}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Controller
                          name={`parametrage.dommagesCommentairesMax[${d}]`}
                          control={control}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <EditTextField
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              fieldError={error}
                              readOnly={readOnly}
                              label='Taille max du champ "commentaire" (0 pour désactiver la fonctionnalité)'
                              fullWidth
                              type="number"
                            />
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
