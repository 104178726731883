import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { AnnulerButton } from 'plateforme/components'
import AttacherPJButton from 'plateforme/components/buttons/AttacherPJButton'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { SelectedDocument } from 'plateforme/store/types/selectedDocument'
import { RootState } from 'plateforme/store'
import DocumentsTableSelection from './DocumentsTableSelection'

type AjouterReferenceDocumentProps = {
  documents?: DocumentTeleverse[]
  onAjouter: VoidFunction
  onAnnuler: VoidFunction
  onSelectRow: (doc: SelectedDocument) => void
  getSelectedDocument: (state: RootState) => SelectedDocument | undefined
}

export default function AjouterReferenceDocument({
  documents,
  onAjouter,
  onAnnuler,
  onSelectRow,
  getSelectedDocument,
}: AjouterReferenceDocumentProps) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <DocumentsTableSelection
              onSelectRow={onSelectRow}
              documents={documents ?? []}
              nbLigneParPage={10}
              getSelectedDocument={getSelectedDocument}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
        <Grid item>
          <AnnulerButton onClick={onAnnuler}>Annuler</AnnulerButton>
        </Grid>
        <Grid item>
          <AttacherPJButton color="secondary" onClick={onAjouter}>
            Ajouter référence document
          </AttacherPJButton>
        </Grid>
      </Grid>
    </Grid>
  )
}
