import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import { AnnulerButton, ValiderButton } from 'plateforme/components'
import DossierPlateforme from 'plateforme/store/types/dossier'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { trimToUndefined } from 'plateforme/services/utils'
import { EtapeCreationParam, ModificationDossierRequest } from 'assureur/store/types/dossierEntreprise'
import { usePutModifierDossierMutation } from 'assureur/store/apis/dossierAssureurApi'
import { setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import LieuExpertiseCommon from './LieuExpertiseCommon'

interface LieuExpertiseDialogFormProps {
  dossier: DossierPlateforme
  onClose: VoidFunction
}

export default function LieuExpertiseDialogForm({ dossier, onClose }: LieuExpertiseDialogFormProps) {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      expertise: {
        adresse1: '',
        adresse2: '',
        adresse3: '',
        codePostal: '',
        commune: '',
        pays: 'FR',
        telephoneFixe: '',
        telephoneMobile: '',
        mail: '',
        typeLieuExpertise: dossier.expertise?.typeLieuExpertise,
      },
    },
  })

  const { setError, getValues } = methods

  const { enqueueSnackbar } = useSnackbar()
  const [putModifierDossier, { error: errorModification }] = usePutModifierDossierMutation()
  useErrorFormMapper(errorModification as IQueryErrorResponse, setError, getValues)
  const dispatch = useDispatch()

  const modifierLieuExpertise = async () => {
    const formData = getValues()
    const { code, expertise } = dossier

    const body = {
      expertise: {
        ...expertise,
        adresse1: trimToUndefined(formData.expertise.adresse1),
        adresse2: trimToUndefined(formData.expertise.adresse2),
        adresse3: trimToUndefined(formData.expertise.adresse3),
        codePostal: trimToUndefined(formData.expertise.codePostal),
        commune: trimToUndefined(formData.expertise.commune),
        pays: trimToUndefined(formData.expertise.pays),
        telephoneFixe: trimToUndefined(formData.expertise.telephoneFixe),
        telephoneMobile: trimToUndefined(formData.expertise.telephoneMobile),
        mail: trimToUndefined(formData.expertise.mail),
        typeLieuExpertise: trimToUndefined(formData.expertise.typeLieuExpertise),
        domaineContractuel: expertise?.domaineContractuel?.code,
      },
    } as ModificationDossierRequest

    return putModifierDossier({ codeDossier: code, etape: EtapeCreationParam.EXPERTISE, ...body })
      .unwrap()
      .then(() => {
        enqueueSnackbar(`La modification du lieu d'exercice a été enregistré avec succès`, { variant: 'success' })
      })
      .catch((error) => {
        dispatch(setErrorDossier(error))
        enqueueSnackbar(`La modification de lieu d'exercice a échoué`, { variant: 'error' })
      })
  }

  return (
    <FormProvider {...methods}>
      <Grid container>
        <Grid item xs={12} display="flex" alignItems="center">
          <LieuExpertiseCommon dossier={dossier} />
        </Grid>
        <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
          <Grid item>
            <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
          </Grid>
          <Grid item>
            <ValiderButton onClick={() => modifierLieuExpertise()}>Modifier</ValiderButton>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
