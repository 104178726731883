import React from 'react'
import { Stack, Typography } from '@mui/material'
import { StatutIcon } from 'plateforme/components'
import { StatutDossier } from 'plateforme/store/types/dossier'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'

export interface StatutDossierChipProps {
  dossier: DossierEntreprise
  mission?: MissionEntreprise
}

export default React.memo(StatutDossierChip)

function StatutDossierChip({ dossier, mission }: StatutDossierChipProps) {
  const getColorIconStatut = () => {
    switch (dossier.statut) {
      case StatutDossier.EN_CREATION:
        return '#507fd1'
      case StatutDossier.EN_COURS: {
        if (!mission) {
          return '#507fd1'
        }
        return dossier.horsDelais ? '#e74242' : '#4db251'
      }
      case StatutDossier.CLOTURE:
      case StatutDossier.ANNULE:
      case StatutDossier.PURGE:
      default:
        return '#858484'
    }
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '70px',
        alignItems: 'center',
      }}
    >
      <StatutIcon statutColor={getColorIconStatut()} size="sm" />
      <Typography variant="subtitle2" color="text.primary" gutterBottom>
        {dossier.descriptionStatut}
      </Typography>
    </Stack>
  )
}
