import React, { useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { addDays } from 'date-fns'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { AjouterIconButton, DatePickerInput, EditTextField, SupprimerIconButton } from 'plateforme/components'
import { ReferenceParametrageEntreprise } from 'plateforme/store/types/profilEntreprise'
import { TypePersonne, UniteEstimation } from 'medecin/store/types/rapportConclusionMedecin'
import { PeriodeData, TiercePersonneData } from '../FicheRapportMedecinForm'

interface DommageFieldTiercePersonneProps {
  name: string
  code: string
  readOnly?: boolean
  disabled?: boolean
  max?: number
  parametrage?: ReferenceParametrageEntreprise
}

export default function DommageFieldTiercePersonne({
  name,
  code,
  readOnly,
  disabled,
  max,
  parametrage,
}: DommageFieldTiercePersonneProps) {
  const fieldName = `${name}.periodes`
  const { clearErrors, control, getFieldState, getValues, resetField, setError, watch } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })
  const errorPath = `${fieldName}._error`
  const { error: groupError } = getFieldState(errorPath)

  // si parametrage activé && dommage de type TIERCE_PERSONNE_DEFINITIVE
  const hideDateAu =
    parametrage?.tiercePersonneDefinitivePeriodeSansFin === true && code === 'TIERCE_PERSONNE_DEFINITIVE'

  useEffect(() => {
    if (groupError?.message) {
      setError(`${fieldName}[0].type`, groupError)
      setError(`${fieldName}[0].nombreHeures`, groupError)
      setError(`${fieldName}[0].par`, groupError)
      setError(`${fieldName}[0].periode.du`, groupError)
      if (!hideDateAu) {
        setError(`${fieldName}[0].periode.au`, groupError)
      }
    }
  }, [fieldName, groupError, hideDateAu, setError])

  function addTooltip(index: number) {
    if (max && fields.length >= max) {
      return `Le nombre maximum de personne tierce est de ${max}`
    }
    return index === fields.length - 1 ? 'Ajouter une personne' : ''
  }

  function addDisabled() {
    return max ? fields.length >= max : false
  }

  function addOnClick() {
    return () => {
      let du: Date | null = null
      if (fields.length > 0) {
        const lastAu = getValues(`${fieldName}.${fields.length - 1}.periode.au`)
        du = lastAu ? addDays(new Date(lastAu), 1) : null
      }

      append({
        type: null,
        nombreHeures: '',
        par: null,
        periode: { du, au: null } as PeriodeData,
      } as TiercePersonneData)
    }
  }

  function removeTooltip() {
    return fields.length <= 1 ? 'Au minimum, une personne tierce est requise' : 'Retirer'
  }

  function removeDisabled() {
    return fields.length <= 1
  }

  function removeOnClick(index: number) {
    return () => remove(index)
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
      {fields.map((item, index) => {
        return (
          <li key={`${code}_${item.id}`}>
            <Stack
              direction="row"
              alignItems="flex-end"
              spacing={2}
              paddingBottom={index === fields.length - 1 ? 0 : 3.5}
            >
              <Grid container direction="row" alignItems="flex-start">
                <Grid item xs={12} display="flex" alignItems="center">
                  <Controller
                    name={`${fieldName}.${index}.type`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <>
                        <Typography variant="subtitle2" color={error ? 'error' : 'primary'} minWidth="55px">
                          Type :
                        </Typography>
                        <FormControl
                          id={`${fieldName}-${index}-type`}
                          disabled={readOnly || disabled}
                          error={!!error}
                          variant="standard"
                        >
                          <RadioGroup
                            row
                            aria-labelledby="demo-error-radios"
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            sx={{ minHeight: 35, display: 'flex', alignItems: 'center' }}
                          >
                            <FormControlLabel
                              value={TypePersonne.ACTIVE}
                              control={
                                <Radio
                                  sx={{
                                    color: `${error ? 'red' : ''}`,
                                  }}
                                />
                              }
                              label="Active"
                            />
                            <FormControlLabel
                              value={TypePersonne.PASSIVE}
                              control={
                                <Radio
                                  sx={{
                                    color: `${error ? 'red' : ''}`,
                                  }}
                                />
                              }
                              label="Passive"
                            />
                          </RadioGroup>
                          {error?.message && (
                            <FormHelperText sx={{ marginLeft: '14px' }}>
                              {error?.message?.split(' (')?.pop()}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={5} display="flex" alignItems="flex-start">
                  <Controller
                    name={`${fieldName}.${index}.nombreHeures`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <EditTextField
                        fullWidth
                        readOnly={readOnly}
                        disabled={disabled}
                        type="number"
                        id={`${fieldName}.${index}.nombreHeures`}
                        label="Nombre d'heures"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={7} display="flex" alignItems="flex-start">
                  <Controller
                    name={`${fieldName}.${index}.par`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <>
                        <Typography
                          variant="subtitle2"
                          color={error ? 'error' : 'primary'}
                          minWidth="48px"
                          paddingTop={2.5}
                        >
                          Par :
                        </Typography>
                        <FormControl
                          id={`${fieldName}.${index}.par`}
                          disabled={readOnly || disabled}
                          error={!!error}
                          variant="standard"
                        >
                          <RadioGroup
                            row
                            aria-labelledby="demo-error-radios"
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            sx={{ minHeight: 60, display: 'flex', alignItems: 'center' }}
                          >
                            <FormControlLabel
                              value={UniteEstimation.JOUR}
                              control={
                                <Radio
                                  sx={{
                                    color: `${error ? 'red' : ''}`,
                                  }}
                                />
                              }
                              label="Jours"
                            />
                            <FormControlLabel
                              value={UniteEstimation.SEMAINE}
                              control={
                                <Radio
                                  sx={{
                                    color: `${error ? 'red' : ''}`,
                                  }}
                                />
                              }
                              label="Semaines"
                            />
                            <FormControlLabel
                              value={UniteEstimation.MOIS}
                              control={
                                <Radio
                                  sx={{
                                    color: `${error ? 'red' : ''}`,
                                  }}
                                />
                              }
                              label="Mois"
                            />
                          </RadioGroup>
                          {error?.message && (
                            <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={hideDateAu ? 12 : 6} display="flex" alignItems="flex-start">
                  <Controller
                    name={`${fieldName}.${index}.periode.du`}
                    control={control}
                    render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                      <DatePickerInput
                        InputProps={{
                          id: `${fieldName}.${index}.periode.du`,
                          fullWidth: true,
                        }}
                        label={hideDateAu ? 'À partir du' : 'Du'}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          const relatedFieldName = `${fieldName}.${index}.periode.au`
                          clearErrors(relatedFieldName)
                          resetField(relatedFieldName, {
                            defaultValue: watch(relatedFieldName),
                          })
                          onChange(e)
                        }}
                        readOnly={readOnly}
                        disabled={disabled}
                        fieldError={error}
                      />
                    )}
                  />
                </Grid>
                {!hideDateAu && (
                  <Grid item xs={6} display="flex" alignItems="flex-start">
                    <Controller
                      name={`${fieldName}.${index}.periode.au`}
                      control={control}
                      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                        <DatePickerInput
                          InputProps={{
                            id: `${fieldName}.${index}.periode.au`,
                            fullWidth: true,
                          }}
                          label="Au"
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => {
                            const relatedFieldName = `${fieldName}.${index}.periode.du`
                            clearErrors(relatedFieldName)
                            resetField(relatedFieldName, {
                              defaultValue: watch(relatedFieldName),
                            })
                            onChange(e)
                          }}
                          readOnly={readOnly}
                          disabled={disabled}
                          fieldError={error}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              {!(readOnly || disabled) && (
                <Box height="60px" paddingTop="12px" marginLeft="8px !important">
                  <SupprimerIconButton
                    onClick={removeOnClick(index)}
                    disabled={removeDisabled()}
                    tooltip={removeTooltip()}
                  />
                </Box>
              )}
              {!(readOnly || disabled) && (
                <Box height="60px" minWidth="40px" paddingTop="10px" marginLeft="8px !important">
                  {index === fields.length - 1 ? (
                    <AjouterIconButton
                      title="Ajouter"
                      onClick={addOnClick()}
                      disabled={addDisabled()}
                      tooltip={addTooltip(index)}
                    />
                  ) : (
                    ' '
                  )}
                </Box>
              )}
            </Stack>
            <Divider hidden={index === fields.length - 1} />
          </li>
        )
      })}
    </ul>
  )
}
