import FacturePlateforme, { StatutFacture } from 'plateforme/store/types/facture'
import { formatDateFR } from './dates.services'

export default function dateOfStatutFacture(facture: FacturePlateforme) {
  switch (facture?.statut) {
    case StatutFacture.ACCEPTEE:
      return facture?.dateAcceptation
    case StatutFacture.ANNULEE:
      return facture?.dateAnnulation
    case StatutFacture.DEMANDE_MODIFICATION:
      return facture?.dateDemandeModification
    case StatutFacture.DEPOSEE:
      return facture?.dateCreation
    default:
      return undefined
  }
}

export function formatStatutFacture(facture: FacturePlateforme) {
  switch (facture?.statut) {
    case StatutFacture.DEPOSEE:
      return `Déposée${facture.dateCreation && ` le ${formatDateFR(facture.dateCreation)}`}`
    case StatutFacture.ANNULEE:
      return `Annulée${facture.dateAnnulation && ` le ${formatDateFR(facture.dateAnnulation)}`}`
    case StatutFacture.ACCEPTEE:
      return `Acceptée${facture.dateAcceptation && ` le ${formatDateFR(facture.dateAcceptation)}`}`
    case StatutFacture.DEMANDE_MODIFICATION:
      return `Demande de modification${
        facture.dateDemandeModification && ` le ${formatDateFR(facture.dateDemandeModification)}`
      }`
    default:
      return undefined
  }
}
