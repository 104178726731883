import React from 'react'
import { Box, Typography } from '@mui/material'
import { AjouterIconButton } from 'plateforme/components'

interface DividerFormButtonProps {
  title: string
  tooltip?: string
  addAction?: VoidFunction
  readOnly?: boolean
  disabled?: boolean
}

export default function DividerFormButton({ title, tooltip, addAction, readOnly, disabled }: DividerFormButtonProps) {
  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <hr
        style={{
          flex: '1',
          border: 'none',
          borderTop: '0.2em solid #3556A7',
          alignItems: 'center',
          marginRight: '10px',
        }}
      />
      <Typography paddingRight={readOnly ? 0 : 2} variant="h1" color="primary">
        {title}
      </Typography>
      {!readOnly && <AjouterIconButton disabled={disabled} tooltip={tooltip} onClick={addAction} shape="square" />}
      <hr
        style={{
          flex: '1',
          border: 'none',
          borderTop: '0.2em solid #3556A7',
          alignItems: 'center',
          marginLeft: '10px',
        }}
      />
    </Box>
  )
}
