import * as React from 'react'
import { Button, ButtonProps, useMediaQuery, useTheme } from '@mui/material'
import TransmedCouleurLogo from '../images/TransmedCouleurLogo'
import TransmedCouleurShortLogo from '../images/TransmedCouleurShortLogo'

export default function AccueilMenuButton(props: ButtonProps) {
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.up('sm'))
  const { ...other } = props
  return (
    <Button {...other}>
      {breakpoint ? <TransmedCouleurLogo width={160} /> : <TransmedCouleurShortLogo width={45} />}
    </Button>
  )
}
