import React from 'react'
import { EditTextField } from 'plateforme/components'
import { Controller, useFormContext } from 'react-hook-form'

interface DommageFieldNumericProps {
  name: string
  code: string
  readOnly?: boolean
  disabled?: boolean
}

export default function DommageFieldPourcentage({ name, code, readOnly, disabled }: DommageFieldNumericProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={`${name}.valeur`}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <EditTextField
          value={value}
          id={code}
          type="number"
          label="Valeur numérique entière (%)"
          onBlur={onBlur}
          onChange={onChange}
          fieldError={error}
          readOnly={readOnly}
          disabled={disabled}
          fullWidth
        />
      )}
    />
  )
}
