import React from 'react'
import { Box, Typography } from '@mui/material'
import { StatutIcon } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import { StatutMedecin } from 'admin/store/types/profilMedecinAdmin'

export interface StatutMedecinChipProps {
  statut?: StatutMedecin
  dateCreation?: Date
  dateInscription?: Date
  dateSuspension?: Date
  dateSuppression?: Date
}

export default React.memo(StatutMedecinChip)

function StatutMedecinChip({
  statut,
  dateCreation,
  dateInscription,
  dateSuspension,
  dateSuppression,
}: StatutMedecinChipProps) {
  const getLibelleStatut = () => {
    switch (statut) {
      case StatutMedecin.ACTIF:
        return `Actif depuis le ${formatDateFR(dateInscription)}`
      case StatutMedecin.INSCRIPTION:
        return `Inscription en cours depuis le ${formatDateFR(dateCreation)}`
      case StatutMedecin.SUSPENDU:
        return `Suspendu depuis le ${formatDateFR(dateSuspension)}`
      case StatutMedecin.SUPPRIME:
        return `Supprimé depuis le ${formatDateFR(dateSuppression)}`
      default:
        return `Statut incorrect.`
    }
  }

  const getColorIconStatut = () => {
    switch (statut) {
      case StatutMedecin.ACTIF:
        return '#4db251'
      case StatutMedecin.INSCRIPTION:
        return '#e9de43'
      case StatutMedecin.SUSPENDU:
        return '#f17913'
      case StatutMedecin.SUPPRIME:
        return '#e74242'
      default:
        return '#e74242'
    }
  }

  return (
    <Box
      sx={{
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '70px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <StatutIcon statutColor={getColorIconStatut()} size="sm" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography margin={0} marginLeft={2} variant="subtitle2" color="text.primary" gutterBottom>
          {getLibelleStatut()}
        </Typography>
      </Box>
    </Box>
  )
}
