import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import DocumentsTablePart from 'plateforme/parts/DocumentsTablePart'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import { determineDocumentsDossierReadOnly } from 'plateforme/services/dossier.services'
import { LoadingProps } from 'plateforme/services/utils'

type DerniersDocumentsSyntheseProps = {
  dossier?: DossierAdmin
}

export default function DocumentsSyntheseAdminPart({
  dossier,
  loading,
}: DerniersDocumentsSyntheseProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={391} />
  }
  return <DocumentsSyntheseAdminPartLoaded dossier={dossier} />
}

function DocumentsSyntheseAdminPartLoaded({ dossier }: DerniersDocumentsSyntheseProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  const documents = determineDocumentsDossierReadOnly(dossier)

  // rendu:
  return (
    <Card>
      <CardHeader title="Derniers documents" />
      <CardContent>
        <DocumentsTablePart documents={documents} dossier={dossier} nbLigneParPage={5} readOnly compact />
      </CardContent>
    </Card>
  )
}
