import { Grid } from '@mui/material'
import React from 'react'
import AreaLoading from './AreaLoading'

export default function ActionTilesSyntheseLoading() {
  return (
    <Grid container marginTop="0 !important" marginLeft="-28px !important">
      <Grid item xs={6} md={3} lg={4}>
        <AreaLoading height={168} />
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <AreaLoading height={168} />
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <AreaLoading height={168} />
      </Grid>
    </Grid>
  )
}
