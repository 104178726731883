import * as React from 'react'
import { useTheme } from '@mui/material'
import { ConnexionIcon, MenuBaseButton, MenuBaseButtonProps } from 'plateforme/components'

export default function ConnexionMenuButton(props: MenuBaseButtonProps) {
  const { icon, tooltip, color, ...other } = props
  const theme = useTheme()
  return (
    <MenuBaseButton
      icon={icon ?? <ConnexionIcon htmlColor={color ?? 'primary'} sx={{ marginRight: theme.spacing(1) }} />}
      tooltip={tooltip ?? 'Connexion'}
      {...other}
    />
  )
}
