import ModifierMedecinForm from 'admin/part/ModifierMedecinPart/ModifierMedecinForm'
import { useGetAdminMedecinByCodeQuery } from 'admin/store/apis/gestionMedecinApi'
import { AreaLoading } from 'plateforme/components'
import React from 'react'
import { useParams } from 'react-router-dom'

export default function ModifierMedecinPage() {
  const { codeMedecin } = useParams()

  if (codeMedecin === undefined) {
    throw new Error('Aucun code medecin défini')
  }

  const { data: medecin, isLoading, isFetching } = useGetAdminMedecinByCodeQuery({ codeMedecin })

  if (isLoading || isFetching) {
    return <AreaLoading height={351} />
  }

  return <ModifierMedecinForm medecin={medecin} />
}
