import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import { RootState } from 'plateforme/store'

export interface SelectedMission {
  code?: string
}

export const selectedMissionSliceName = 'selectedMission'

const selectedMissionSlice = createSlice({
  name: selectedMissionSliceName,
  initialState: {
    selectedMission: undefined,
    dossier: undefined,
  } as { selectedMission: SelectedMission | undefined; dossier: DossierEntreprise | undefined },
  reducers: {
    setSelectedMission: (state, action: PayloadAction<SelectedMission | undefined>) => {
      state.selectedMission = action.payload
    },
    setDossierRevision: (state, action: PayloadAction<DossierEntreprise | undefined>) => {
      state.dossier = action.payload
    },
  },
})

export const { setSelectedMission, setDossierRevision } = selectedMissionSlice.actions

export const getSelectedMission = (state: RootState) => state?.selectedMission.selectedMission

export const getDossierRevision = (state: RootState) => state?.selectedMission.dossier

export default selectedMissionSlice.reducer
