import * as React from 'react'
import { SvgIconProps, useTheme } from '@mui/material'
import CircleRoundedIcon from '@mui/icons-material/CircleRounded'

export default function StatutIcon(
  props: SvgIconProps & { withMargin?: boolean; statutColor?: string; active?: boolean; size?: 'sm' | 'md' }
) {
  const { withMargin, statutColor, active, size, sx: otherSx, ...other } = props
  const theme = useTheme()
  const sizeSx = { width: size === 'sm' ? '15px' : '20px' }
  const colorSx = {
    color: statutColor ? `${statutColor} !important` : `${active ? '#4db251' : '#e74242'} !important`,
  }
  const iconSx = withMargin
    ? { margin: theme.spacing(1.5), ...otherSx, ...colorSx, ...sizeSx }
    : { ...otherSx, ...colorSx, ...sizeSx }
  return <CircleRoundedIcon sx={iconSx} {...other} />
}
