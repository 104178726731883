import React from 'react'
import { Box, useTheme } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import SuiviDossierPart from 'plateforme/parts/SuiviDossierPart'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import { calculerIndexesMission } from 'plateforme/services/mission.services'
import SuiviMissionAccordion from './SuiviMissionAccordion'

type SuiviSyntheseAdminPartProps = {
  dossier?: DossierAdmin
}

export default function SuiviSyntheseAdminPart({ dossier, loading }: SuiviSyntheseAdminPartProps & LoadingProps) {
  // chargement:
  if (loading) {
    return <AreaLoading height={224} />
  }
  return <SuiviSyntheseAdminPartLoaded dossier={dossier} />
}

function SuiviSyntheseAdminPartLoaded({ dossier }: SuiviSyntheseAdminPartProps) {
  // erreur:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { missions } = dossier
  const derniereMission = missions && missions.length > 0 ? missions[0] : undefined

  // hooks:
  const theme = useTheme()

  // state:
  const [expanded, setExpanded] = React.useState<string | undefined | false>(derniereMission?.code)

  // change accordion:
  const onChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const missionsWithIndexes = calculerIndexesMission(missions)

  // rendu:
  return (
    <>
      <SuiviDossierPart dossier={dossier} />
      <Box marginBottom={`${theme.spacing(-2.5)} !important`}>
        {missionsWithIndexes.map((missionWithIndexes) => (
          <SuiviMissionAccordion
            key={missionWithIndexes.mission.code}
            dossier={dossier}
            missionWithIndexes={missionWithIndexes}
            expanded={expanded === missionWithIndexes.mission.code}
            onChange={() => onChange(missionWithIndexes.mission.code)}
          />
        ))}
      </Box>
    </>
  )
}
