import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { EditTextField } from 'plateforme/components'

type InformationsAssureProps = {
  personneAExaminerEstLAssure?: boolean
}

export default function InformationsAssure({ personneAExaminerEstLAssure }: InformationsAssureProps) {
  const { control } = useFormContext()
  return (
    <Card>
      <CardHeader title="Informations assuré" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Controller
              name="assure.numeroContrat"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="numero-Contrat"
                  label="Référence assuré"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Controller
              name="assure.codeAssure"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="code-Assure"
                  label="Code assuré"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Controller
              name="assure.nom"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="nom"
                  label="Nom de l'assuré"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  readOnly={personneAExaminerEstLAssure}
                  fieldError={error}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
