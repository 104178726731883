import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, MessageAlert, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import { formatDateFR } from 'plateforme/services/dates.services'
import DossierPlateforme from 'plateforme/store/types/dossier'
import { LoadingProps } from 'plateforme/services/utils'
import PersonneAContacterPart from './PersonneAContacterPart'

type PersonneAExaminerPartProps = {
  dossier?: DossierPlateforme
}

export default function PersonneAExaminerPart({ dossier, loading }: PersonneAExaminerPartProps & LoadingProps) {
  // loading:
  if (loading) {
    return (
      <>
        <Grid item xs={12}>
          <AreaLoading height={677.5} />
        </Grid>
        <Grid item xs={12}>
          <AreaLoading height={140} />
        </Grid>
      </>
    )
  }
  return <PersonneAExaminerPartLoaded dossier={dossier} />
}

function PersonneAExaminerPartLoaded({ dossier }: PersonneAExaminerPartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { personneAExaminer } = dossier

  // L’indicateur « La personne à examiner est l’assuré »
  const isAssure = personneAExaminer?.assure

  // L’indicateur «La personne à contacter est la personne à examiner »
  const isContactUnique = personneAExaminer?.contactUnique

  // render:
  if (!personneAExaminer) {
    return null
  }
  return (
    <>
      <Card>
        <CardHeader title="Personne à examiner" />
        <CardContent>
          <Grid container>
            <Grid item xs={4}>
              <ReadOnlyTextField
                id="personne-a-examiner-sexe"
                label="Sexe"
                value={personneAExaminer.labelSexe}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <ReadOnlyTextField id="personne-a-examiner-nom" label="Nom" value={personneAExaminer.nom} fullWidth />
            </Grid>

            <Grid item xs={8}>
              <ReadOnlyTextField
                id="personne-a-examiner-prenom"
                label="Prénom"
                value={personneAExaminer.prenom}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyTextField
                id="personne-a-examiner-date-naissance"
                label="Date de naissance"
                value={formatDateFR(personneAExaminer.dateNaissance)}
                fullWidth
              />
            </Grid>
            {personneAExaminer.labelQualite && (
              <Grid item xs={6}>
                <ReadOnlyTextField
                  id="personne-a-examiner-qualite"
                  label="Qualité"
                  value={personneAExaminer.labelQualite}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={personneAExaminer.labelQualite ? 6 : 12}>
              <ReadOnlyTextField
                id="personne-a-examiner-profession"
                label="Profession"
                value={personneAExaminer.profession}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyTextField
                id="personne-a-examiner-adresse"
                label="Adresse"
                value={personneAExaminer.adresseComplete}
                fullWidth
                minRows={3}
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <ReadOnlyEmailField
                id="personne-a-examiner-mail"
                label="Adresse mail"
                value={personneAExaminer.mail}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <ReadOnlyTextField
                id="personne-a-examiner-telephone-mobile"
                label="Tél. portable"
                value={personneAExaminer.telephoneMobile}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <ReadOnlyTextField
                id="personne-a-examiner-telephone-fixe"
                label="Tél. fixe"
                value={personneAExaminer.telephoneFixe}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <MessageAlert id="personne-a-examiner-assure">
                {isAssure ? `La personne à examiner est l'assuré` : `La personne à examiner n'est pas l'assuré`}
              </MessageAlert>
            </Grid>
            <Grid item xs={12}>
              <MessageAlert id="personne-a-examiner-contact-unique">
                {isContactUnique
                  ? `La personne à contacter est la personne à examiner`
                  : `La personne à contacter n'est pas la personne à examiner - Informations précisées ci-dessous`}
              </MessageAlert>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {!isContactUnique && dossier.personneAContacter && <PersonneAContacterPart dossier={dossier} />}
    </>
  )
}
