import { UploadFileMap } from 'plateforme/store/slices/uploadSlice'
import { DocumentUpload } from 'plateforme/store/types/documentUpload'
import createMultiUploader from 'plateforme/store/slices/uploadSlice/multiUploadSlice'
import { MessageRequest } from 'medecin/store/types/messageMedecin'
import { toUploadMessageUrl } from 'medecin/MedecinApp'

export interface PiecesJointesUploadSuccess {
  id: string
}

export const documentMessageUploadSliceName = 'documentMessageUpload'
const { slice, actions, reducer } = createMultiUploader<DocumentUpload, PiecesJointesUploadSuccess>(
  documentMessageUploadSliceName
)

export const documentMessageUploadSlice = slice

export const {
  add: addDocumentMessageUpload,
  change: changeDocumentMessageUpload,
  remove: removeDocumentMessageUpload,
  clear: clearDocumentMessageUpload,
  setValid: setDocumentMessageUploadValid,
} = actions

type StartUploadDocumentConfig = {
  message: MessageRequest
  uploadFileMap: UploadFileMap
}
export function startUploadDocumentMessage({ message, uploadFileMap }: StartUploadDocumentConfig) {
  return actions.startMultiUpload({
    uploadUrl: toUploadMessageUrl(),
    toFormData: (uploads, file1, file2, file3) => {
      const formData = new FormData()
      formData.append('form', JSON.stringify(message))

      // TODO: a voir s'il faut envoyer les metadata:
      /*    formData.append(
        'metadata',
        JSON.stringify({
          typeDocument: upload.typeDocument,
          nomDocument: upload.nomDocument,
        })
      ) */

      if (file1 !== undefined && uploads.length > 0) {
        formData.append('file1', file1, uploads[0].nomFichier)
      }

      if (file2 !== undefined && uploads.length > 1) {
        formData.append('file2', file2, uploads[1].nomFichier)
      }

      if (file3 !== undefined && uploads.length > 2) {
        formData.append('file3', file3, uploads[2].nomFichier)
      }

      return formData
    },
    toUploadResponse: (status, response) => {
      if (status === 201) {
        return { type: 'SUCCESS', status, body: response as PiecesJointesUploadSuccess }
      }
      return {
        type: 'ERROR',
        status,
        body: {
          code: 'réponse inattendue',
          message: JSON.stringify(response),
        },
      }
    },
    uploadFileMap,
  })
}

export default reducer
