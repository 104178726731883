import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { StatutDossier } from 'plateforme/store/types/dossier'
import { StatutMission } from 'plateforme/store/types/mission'
import MissionAdmin from './missionAdmin'
import Contact, { ContactComplet } from './contactAdmin'

export default interface DossierAdmin {
  code: string
  refDossierEntreprise?: string
  refVictimeEntreprise?: string
  libelle?: string
  entreprise?: ReferenceEntreprise
  serviceEntreprise?: string
  libelleServiceEntreprise?: string
  gestionnaire?: ReferenceGestionnaire
  typeContactEntreprise?: string
  labelTypeContactEntreprise?: string
  contactEntreprise?: ContactEntreprise
  assure?: Assure
  evenement?: Evenement
  expertise?: Expertise
  personneAExaminer?: PersonneAExaminer
  personneAContacter?: PersonneAContacter
  consentement?: boolean
  refConsentement?: string
  statut?: StatutDossier
  labelStatut?: string
  descriptionStatut?: string
  motifCloture?: string
  labelMotifCloture?: string
  commentaireCloture?: string
  commentaireReouverture?: string
  commentaireAnnulation?: string
  documents?: DocumentTeleverse[]
  missions?: [MissionAdmin]
  horsDelais?: boolean
  modifiable?: boolean
  // documentDeposable n'existe pas coté back ? c'est 'modifiable'
  documentDeposable?: boolean
  editableMajeure?: boolean
  validable?: boolean
  missionable?: boolean
  annulable?: boolean
  cloturable?: boolean
  reouvrable?: boolean
  consentementRetirable?: boolean
  purgeable?: boolean
  dateCreation?: Date
  dateEdition?: Date
  dateEditionMajeure?: Date
  dateEnCours?: Date
  dateAnnulation?: Date
  dateCloture?: Date
  dateReouverture?: Date
  dateMiseAJour?: Date
}

export interface Assure {
  nom?: string
  numeroContrat?: string
  codeAssure?: string
}

export interface ContactEntreprise {
  libelle?: string
  mail?: string
  telephone?: string
}

export interface ReferenceDossier {
  code?: string
  libelle?: string
  entreprise?: ReferenceEntreprise
  refDossierEntreprise?: string
  refVictimeEntreprise?: string
  nomPersonneAExaminer?: string
  prenomPersonneAExaminer?: string
}

export interface ReferenceEntreprise {
  code?: string
  codeACPR?: string
  codeSIREN?: string
  libelle?: string
}

export interface ReferenceGestionnaire {
  code?: string
  libelle?: string
  mail?: string
  telephone?: string
}

export interface AutreMedecin extends Contact {
  codeMedecin?: string
  libelle?: string
  mail?: string
  telephoneFixe?: string
  telephoneMobile?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
}

export interface DomaineContractuel {
  code?: string
  libelle?: string
  dommagesPredefinis?: string[]
}
export interface Expertise extends Contact, ContactComplet {
  cadreExpertise?: string
  labelCadreExpertise?: string
  garantieApplicable?: string
  domaineContractuel?: DomaineContractuel
  commentaire?: string
  isMedecinTransMED: boolean
  autreMedecin?: AutreMedecin
  prejudiceEnAggravation?: boolean
  typeLieuExpertise?: 'AUTRE' | 'CABINET'
  labelTypeLieuExpertise?: string
  libelleAdresse?: string
}

export interface Evenement {
  reference?: string
  date?: Date
  nature?: string
  labelNature?: string
  commentaire?: string
}

export interface PersonneAExaminer extends Contact, ContactComplet {
  nom?: string
  prenom?: string
  sexe?: string
  labelSexe?: string
  dateNaissance?: Date
  profession?: string
  qualite?: string
  labelQualite?: string
  assure?: boolean
  contactUnique?: boolean
}

export interface PersonneAContacter extends Contact, ContactComplet {
  nom?: string
  prenom?: string
  qualite?: string
  labelQualite?: string
  coordonneesIdentiquesPersonneAExaminer?: boolean
}

export interface InformationRefDossier {
  refDossierEntreprise?: string
  refVictimeEntreprise: string
  evenement: {
    nature: string | null
    date: Date | null
  }
  identifiantDossierPlateforme: string
  gestionnaire: string
  service: string
  nomGestionnaire: string
  preNomGestionnaire: string
  mailGestionnaire: string
  telephoneGestionnaire: string
  gestinnaireSwitch: boolean
  refEvenement: string
  civiliteGestionnaire: string
  commentaire: string
  codeDossier: string
}

export interface DossierAdminList {
  values: DossierAdmin[]
  pageIndex: number
  total: number
  next: string
}

export interface DossierRequest {
  codeDossier: string
}

export interface HistoriqueDossierAdmin {
  dateAction?: Date
  codeMission?: string
  codeDocument?: string
  codeFacture?: string
  utilisateur?: HistoriqueUtilisateurAdmin
  partenaire?: HistoriquePartenaireAdmin
  action?: string
  actionLabel?: string
  propertiesChanged?: string[]
}

export interface HistoriqueUtilisateurAdmin {
  id?: string
  title?: string
  nom?: string
  prenom: string
  libelle?: string
}

export interface HistoriquePartenaireAdmin {
  codePartenaire?: string
  typePartenaire?: TypePartenaire
  libelle?: string
}

export interface DossierAdminFilter {
  motCle?: string
  codeMedecin?: string
  codeEntreprise?: string
  statutsDossier?: StatutDossier[]
  statutsMission?: StatutMission[]
  aucuneMission?: 0 | 1
  natureEvenement?: string
  cadreExpertise?: string
  service?: string
  dateCreationDebut?: string
  dateCreationFin?: string
}

export enum DossierAdminSortType {
  ENTREPRISE_SORT = 'entreprise',
  MEDECIN_SORT = 'medecin',
  DATE_REPONSE_MEDECIN_SORT = 'date_reponse_medecin',
  DATE_CREATION_DOSSIER_SORT = 'date_creation_dossier',
  STATUT_SORT = 'statut',
  CADRE_EXPERTISE_SORT = 'cadre_expertise',
  NATURE_EVENEMENT_SORT = 'nature_evenement',
  REFERENCE_DOSSIER_SORT = 'ref_dossier',
  PERSONNE_A_EXAMINER_SORT = 'personne_a_examiner',
}
