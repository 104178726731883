import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import ListeDossierMedecinPart from 'medecin/parts/rechercheDossier/ListeDossierMedecinPart'
import RechercheDossierMedecinPart from 'medecin/parts/rechercheDossier/RechercheDossierMedecinPart/RechercheDossierMedecinPart'
import NavigationPart from 'plateforme/parts/NavigationPart'
import useInfiniteScroll from 'plateforme/hooks/infiniteScroll/useInfiniteScroll'
import { useSelector } from 'react-redux'
import { getDossierCriteria } from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { useGetRechercheDossierQuery } from 'medecin/store/apis/dossierMedecinApi'
import { useSnackbar } from 'notistack'

export default function RechercheDossierMedecinPage() {
  // get default values from localStorage
  const defaultDossierCriteria = useSelector(getDossierCriteria)
  const {
    combinedData,
    exportParams,
    readMore,
    remoteTotal,
    search,
    initSort,
    sortBy,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useInfiniteScroll(useGetRechercheDossierQuery, { defaultCriteria: defaultDossierCriteria })

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('La Recherche des dossiers a échoué', { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  return (
    <Stack>
      <NavigationPart label="Liste des dossiers " />
      <RechercheDossierMedecinPart search={search} searchError={error} />
      <ListeDossierMedecinPart
        sortBy={sortBy}
        initSort={initSort}
        readMore={readMore}
        dossiers={combinedData}
        total={remoteTotal}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        isSuccess={isSuccess}
        exportParams={exportParams}
      />
    </Stack>
  )
}
