import * as React from 'react'
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { PAGE_PADDING_Y, PAGE_PADDING_X, PAGE_LARGE_PADDING_X } from 'plateforme/constantes'
import { AreaLoading, RetourRoundButton, TransparentCard, TransparentCardContent } from 'plateforme/components'

export interface NavigationProps {
  // label si children n'est pas défini
  children?: React.ReactNode
  label?: string
  fullWidth?: boolean
  isLoading?: boolean
  backgroundColor?: string
  height?: number
}

export default function NavigationPart(props: NavigationProps) {
  const { isLoading, fullWidth, children, label, backgroundColor, height } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const marginY = PAGE_PADDING_Y
  const marginX = useMediaQuery(theme.breakpoints.up('xl')) ? PAGE_LARGE_PADDING_X : PAGE_PADDING_X
  const paddingX = useMediaQuery(theme.breakpoints.up('xl')) && !fullWidth ? PAGE_LARGE_PADDING_X : PAGE_PADDING_X

  if (isLoading) {
    return (
      <AreaLoading
        height={height || 61}
        sx={{
          marginY: theme.spacing(-marginY),
          marginX: theme.spacing(-marginX),
          paddingTop: theme.spacing(marginY - 0.8),
          paddingBottom: theme.spacing(0.8),
          paddingX: theme.spacing(paddingX),
          boxShadow: 'none',
        }}
      />
    )
  }

  return (
    <TransparentCard
      sx={{
        height: height || 61,
        marginY: theme.spacing(-marginY),
        marginX: theme.spacing(-marginX),
        paddingTop: theme.spacing(marginY - 0.8),
        paddingBottom: theme.spacing(0.8),
        paddingX: theme.spacing(paddingX),
        backgroundColor: backgroundColor || (fullWidth ? '#fdfdfd' : 'inherit'),
        borderBottom: fullWidth ? '1px solid rgba(0, 0, 0, .12)' : 'inherit',
      }}
    >
      <TransparentCardContent>
        <Stack direction="row" alignItems="center">
          <RetourRoundButton aria-label="Retour" onClick={() => navigate(-1)}>
            <ArrowBack />
          </RetourRoundButton>
          {children || (
            <Typography variant="h2" color="primary">
              {label}
            </Typography>
          )}
        </Stack>
      </TransparentCardContent>
    </TransparentCard>
  )
}
