import React from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { SerializedError } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import uuid from 'react-uuid'
import { Card, CardContent, CardHeader, Grid, Stack, Tooltip } from '@mui/material'
import {
  AreaLoading,
  AnnulerButton,
  ButtonsStack,
  MessageAlert,
  SupprimerButton,
  TelechargerPDFButton,
  ValiderDefinitivementButton,
} from 'plateforme/components'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import useTabNavigate from 'plateforme/hooks/useTabNavigate'
import { formatDateFR } from 'plateforme/services/dates.services'
import {
  isRapportCarence,
  isRapportConclusionDefinitive,
  isRapportConclusionProvisoire,
  isValidationPrimaire,
  isValideDefinitivement,
} from 'plateforme/services/rapport.services'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { useGetReferentielQuery } from 'plateforme/store/apis/referentielApi'
import {
  useDeleteConclusionMutation,
  usePostValiderConclusionMutation,
  usePutSauvegarderConclusionMutation,
} from 'medecin/store/apis/dossierMedecinApi'
import {
  AuthDownloadAction,
  AuthDownloadDocumentDossierRequest,
  ProfilUtilisateurActif,
} from 'plateforme/store/types/utilisateur'
import { ReferentielDommage } from 'plateforme/store/types/referentiel'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import { LibelleRapport, TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { determineShowPrecisionsMission } from 'plateforme/services/mission.services'
import { useGetProfilMedecinQuery } from 'medecin/store/apis/profilMedecinApi'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import { rapportValidable } from 'medecin/services/rolesMedecin.services'
import DocumentsConclusion from './DocumentsConclusion'
import FicheRapportMedecinForm from './FicheRapportMedecinForm'
import { prepareSauvegarderConclusionRequestFromRapportConclusionMedecin as prepareSauvegarderConclusionRequest } from './utils'

interface TabConclusionPartProps {
  dossier: DossierMedecin
  mission: MissionMedecin
  profilUtilisateur: ProfilUtilisateurActif
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
  libelleConclusion:
    | LibelleRapport.CONCLUSION_PROVISOIRE
    | LibelleRapport.CONCLUSION_DEFINITIVE
    | LibelleRapport.CARENCE
}

export default function TabRapportMedecinPart({
  dossier,
  mission,
  profilUtilisateur,
  typeConclusion,
  libelleConclusion,
}: TabConclusionPartProps) {
  // props:
  const { code: codeDossier } = dossier
  const { code: codeMission, demandesModifications, rapport } = mission

  // error:
  if (!codeDossier || !codeMission || !rapport) {
    throw new Error(`Erreur de chargement des données`)
  }

  // hooks:
  const { navigateTab } = useTabNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { confirm, confirmDialog } = useConfirmDialog()
  const handleError = useErrorHandler()
  const {
    data: profil,
    isError: isErrorProfil,
    error: errorProfil,
    isLoading: isLoadingProfil,
    isFetching: isFetchingProfil,
  } = useGetProfilMedecinQuery()
  const {
    data: referentiel,
    isError: isErrorReferentiel,
    error: errorReferentiel,
    isLoading: isLoadingReferentiel,
    isFetching: isFetchingReferentiel,
  } = useGetReferentielQuery()

  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()
  const [putSauvegarderConclusion, { isLoading: isLoadingSauvegarder }] = usePutSauvegarderConclusionMutation()
  const [postValiderConclusion, { isError: isErrorValider, error: errorValider, isLoading: isLoadingValider }] =
    usePostValiderConclusionMutation()
  const [deleteConclusion, { isLoading: isLoadingDelete }] = useDeleteConclusionMutation()

  // format:
  const rapportModifiable = mission?.rapportModifiable
  const missionCloturee = mission?.statut === 'CLOTUREE'
  const missionTerminee = missionCloturee || isValideDefinitivement(rapport)

  const nbDocuments = rapport?.documents?.length ?? 0
  const hasRapport = rapport?.documents?.some(
    (d) => d.typeDocument === 'RC' || d.typeDocument === 'RP' || d.typeDocument === 'RD'
  )
  const mustHaveRapport = isRapportConclusionProvisoire(rapport) || isRapportConclusionDefinitive(rapport)
  const isRapportFulfilled = !mustHaveRapport || hasRapport
  const isRapportValidable = rapportValidable(profilUtilisateur)
  const nbDocumentsAnnexe = hasRapport ? nbDocuments - 1 : nbDocuments

  const ficheDocument = rapport?.documents
    ?.filter((d) => d.statut === 'ACTIF')
    .find((d) => d.typeDocument === 'FP' || d.typeDocument === 'FD' || d.typeDocument === 'FC')

  const errorNarrowed = (isError: boolean, error: FetchBaseQueryError | SerializedError | undefined) =>
    isError &&
    error &&
    'status' in error &&
    error.status === 400 &&
    (error.data as {
      code: string
      message: string
      details: { message: string; fields?: string[] }[]
    })

  const errorValiderNarrowed = errorNarrowed(isErrorValider, errorValider)

  if (isFetchingProfil || isLoadingProfil || isFetchingReferentiel || isLoadingReferentiel) {
    return <AreaLoading height={550} />
  }

  // handle error of useGetProfilMedecinQuery
  if (isErrorProfil || !profil) {
    handleError({
      errorApi: errorProfil,
      api: 'GetProfilMedecin',
      title: 'Erreur chargement des données de profil médecin',
    })
    return null
  }

  // handle error of useGetReferentielQuery
  if (isErrorReferentiel || !referentiel) {
    handleError({
      errorApi: errorReferentiel,
      api: 'GetReferentiel',
      title: 'Erreur chargement des données de référentiel',
    })
    return null
  }

  const sauvegarder = async (validation: boolean) => {
    // const formattedLesionSequelles = formatInitLesionSequelles(rapport.lesionSequelles)
    const request = prepareSauvegarderConclusionRequest(codeDossier, codeMission, typeConclusion, validation, rapport)
    await putSauvegarderConclusion(request)
      .unwrap()
      .then(() =>
        enqueueSnackbar(`La validation de votre saisie ${libelleConclusion} est annulée`, { variant: 'success' })
      )
      .catch(() => {
        enqueueSnackbar(`L'annulation de votre saisie ${libelleConclusion} a échoué `, { variant: 'error' })
      })
  }
  const onSauvegarderAvecAnnulation = async () => sauvegarder(false)
  const onValider = () =>
    confirm({
      maxWidth: 'lg',
      confirmMsg: `Êtes-vous sûr d'avoir bien rempli la fiche de ${libelleConclusion} avec l'ensemble des documents liés et de vouloir la valider définitivement ?`,
      onYes: async () =>
        postValiderConclusion({ codeDossier, codeMission })
          .unwrap()
          .then(() =>
            enqueueSnackbar(`Votre saisie ${libelleConclusion} a été validée définitivement avec succès`, {
              variant: 'success',
            })
          )
          .catch(() => {
            enqueueSnackbar(`La validation définitive de la fiche ${libelleConclusion} a échoué`, { variant: 'error' })
          }),
    })
  const onSupprimerConclusionProvisoire = async () =>
    confirm({
      confirmMsg: `Voulez-vous supprimer la fiche de ${libelleConclusion} ?`,
      variant: 'delete',
      onYes: async () => {
        await deleteConclusion({ codeDossier, codeMission })
          .unwrap()
          .then(() => {
            enqueueSnackbar(`La fiche ${libelleConclusion} a été supprimée`, { variant: 'success' })
            navigateTab()
          })
          .catch(() => {
            enqueueSnackbar(`La suppression de la fiche ${libelleConclusion} a échoué`, { variant: 'error' })
          })
      },
    })
  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeDossier) {
      throw new Error('erreur inattendu')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement du document a échoué`, { variant: 'error' })
      })
  }

  const makeDommages = () => {
    const domaineContractuelDommages = dossier?.expertise?.domaineContractuel?.dommages ?? []

    // Cas 1 : si domaine contarctuel (Mission spécifique) contient des dommages :
    if (domaineContractuelDommages.length > 0) {
      const set = new Set(domaineContractuelDommages)
      return referentiel.dommagePredefini.filter((dommage: ReferentielDommage) => set.has(dommage?.code))
    }

    // Cas 2 : filtrer les dommages predefinis par  typeFicheConclusion du rapport :
    return referentiel.dommagePredefini.filter(
      (dommage: ReferentielDommage) =>
        mission?.rapport?.typeFicheConclusion &&
        dommage.typesFicheConclusion.includes(mission?.rapport?.typeFicheConclusion)
    )
  }

  const derniereDemandeDeModification =
    !demandesModifications || demandesModifications?.length < 1
      ? undefined
      : demandesModifications.at(demandesModifications.length - 1)

  const derniereDemandeDeModificationEnCours =
    derniereDemandeDeModification && !derniereDemandeDeModification?.dateValidation
      ? derniereDemandeDeModification
      : undefined

  const validable = isRapportFulfilled && isRapportValidable
  const messageRapportNonValidable = 'La validation du rapport est réservée au profil Médecin'
  const messageDocumentRapportManquant = 'Doit contenir un document "rapport"'
  const tooltipvalidable = !isRapportValidable ? messageRapportNonValidable : messageDocumentRapportManquant

  const showAlerteMissionPrecisions = determineShowPrecisionsMission(mission)
  function alertePrecisionsMission() {
    return (
      <MessageAlert title="Précisions sur la mission" backgroundColor="white" paddingLeft={2}>
        {mission?.precisions?.split('\n').map((line) => (
          <p key={uuid()} style={{ margin: 0 }}>
            {line}
          </p>
        ))}
      </MessageAlert>
    )
  }

  const showAlerteDemandeModification = rapportModifiable && derniereDemandeDeModificationEnCours
  function alerteDemandeModification() {
    return (
      <MessageAlert severity="warning" backgroundColor="white" withGutter paddingLeft={2}>
        <>
          <strong>Demande de modification : </strong>
          {derniereDemandeDeModificationEnCours?.commentaire}
        </>
      </MessageAlert>
    )
  }

  const showAlerteEnCourDeSaisie = rapportModifiable && !missionTerminee && !isValidationPrimaire(rapport)
  function alerteEnCourDeSaisie() {
    return (
      <MessageAlert iconCenter backgroundColor="white" paddingLeft={2}>
        <Grid container paddingY={0.5} alignItems="center">
          <Grid item xs={12} md={6}>
            <strong>
              {isRapportCarence(typeConclusion)
                ? 'Rapport de carence de la mission : '
                : 'Conclusions de la mission : '}
            </strong>
            {derniereDemandeDeModificationEnCours
              ? `en demande de modification depuis le ${formatDateFR(derniereDemandeDeModificationEnCours?.date)}`
              : `en cours de saisie depuis le ${formatDateFR(rapport?.dateCreation)}`}
          </Grid>
          {!derniereDemandeDeModificationEnCours && (
            <Grid item xs={12} lg={6}>
              <ButtonsStack>
                <SupprimerButton loading={isLoadingDelete} onClick={onSupprimerConclusionProvisoire}>
                  {isRapportCarence(typeConclusion)
                    ? 'Supprimer le rapport de carence'
                    : 'Supprimer le rapport de conclusions'}
                </SupprimerButton>
              </ButtonsStack>
            </Grid>
          )}
        </Grid>
      </MessageAlert>
    )
  }

  const showAlerteFicheValidee = rapportModifiable && !missionTerminee && isValidationPrimaire(rapport)
  function alerteFicheValidee() {
    return (
      <MessageAlert severity={validable ? 'info' : 'error'} backgroundColor="white" withGutter paddingLeft={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <strong>La fiche a été validée : </strong>
            en cours de saisie depuis le {formatDateFR(rapport?.dateCreation)}
          </Grid>
          <Grid item xs={12} lg={6}>
            <ul style={{ marginTop: -2 }}>
              {!isRapportValidable && <li style={{ color: '#e74242' }}>{messageRapportNonValidable}</li>}
              {!isRapportFulfilled ? (
                <li style={{ color: '#e74242' }}>{messageDocumentRapportManquant}</li>
              ) : (
                <li>{hasRapport ? '1' : 'Aucun'} document &quot;rapport&quot;</li>
              )}
              <li>
                {nbDocumentsAnnexe > 0 ? nbDocumentsAnnexe : 'Aucun'} document{nbDocumentsAnnexe > 1 ? 's ' : ' '}
                &quot;annexe&quot;
              </li>
              {errorValiderNarrowed &&
                errorValiderNarrowed?.details?.map(({ message }) => <li color="error.main">{message}</li>)}
            </ul>
            Confirmez-vous la validation du rapport de {libelleConclusion} pour la mission ?
          </Grid>
          <Grid item xs={12} lg={6} display="flex" alignItems="flex-end" justifyContent="flex-end">
            <ButtonsStack>
              <AnnulerButton onClick={onSauvegarderAvecAnnulation} loading={isLoadingSauvegarder}>
                Annuler et modifier
              </AnnulerButton>
              <Tooltip
                title={tooltipvalidable}
                placement="bottom"
                disableInteractive={validable}
                disableHoverListener={validable}
                disableFocusListener={validable}
                disableTouchListener={validable}
              >
                {/* La div n'est ici que pour tricher, car sinon la tooltip ne s'affiche pas quand le bouton est disable */}
                <div>
                  <ValiderDefinitivementButton onClick={onValider} loading={isLoadingValider} disabled={!validable}>
                    Valider le rapport
                  </ValiderDefinitivementButton>
                </div>
              </Tooltip>
            </ButtonsStack>
          </Grid>
        </Grid>
      </MessageAlert>
    )
  }

  const showAlerteRapportValide = missionTerminee
  function alerteRapportValide() {
    return (
      <MessageAlert iconCenter backgroundColor="white" paddingLeft={2}>
        <Grid container paddingY={0.5} alignItems="center">
          <Grid item xs={12} md={6}>
            <strong>Le rapport a été validé : </strong>
            le {formatDateFR(rapport?.dateValidationMedecin)}
          </Grid>
          <Grid item xs={12} lg={6}>
            <ButtonsStack>
              <TelechargerPDFButton
                onClick={() => ficheDocument && onDownload(ficheDocument)}
                loading={isLoadingAuthDownload}
                disabled={!ficheDocument}
              >
                Télécharger la fiche au format pdf
              </TelechargerPDFButton>
            </ButtonsStack>
          </Grid>
        </Grid>
      </MessageAlert>
    )
  }

  return (
    <Stack>
      {confirmDialog}
      {showAlerteMissionPrecisions && alertePrecisionsMission()}
      {showAlerteDemandeModification && alerteDemandeModification()}
      {showAlerteFicheValidee && alerteFicheValidee()}
      {showAlerteEnCourDeSaisie && alerteEnCourDeSaisie()}
      {showAlerteRapportValide && alerteRapportValide()}
      <Card>
        <CardHeader title={`Fiche de ${libelleConclusion}`} />
        <CardContent>
          <FicheRapportMedecinForm
            dossier={dossier}
            mission={mission}
            profil={profil}
            dommagePredefini={makeDommages()}
            libelleConclusion={libelleConclusion}
            typeConclusion={typeConclusion}
          />
        </CardContent>
      </Card>
      <DocumentsConclusion dossier={dossier} mission={mission} />
      {showAlerteFicheValidee && alerteFicheValidee()}
    </Stack>
  )
}
