import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import { AreaLoading, ReadOnlyTextField } from 'plateforme/components'
import { LoadingProps } from 'plateforme/services/utils'
import MissionMedecin from 'medecin/store/types/missionMedecin'

type LieuRDVMissionPartProps = {
  mission?: MissionMedecin
}

export default function LieuRDVMissionPart({ mission, loading }: LieuRDVMissionPartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={391} />
  }
  return <LieuRDVMissionPartLoaded mission={mission} />
}

function LieuRDVMissionPartLoaded({ mission }: LieuRDVMissionPartProps) {
  // error:
  if (!mission) {
    throw new Error(`Erreur de chargement des données`)
  }

  // rendu:
  // si pas de rdv mission alors pas d'affichage de block

  if (!mission.rdv) {
    return null
  }
  return (
    <Card>
      <CardHeader title="Adresse du rendez-vous de la mission" />
      <CardContent>
        <ReadOnlyTextField
          fullWidth
          rows={3}
          multiline
          id="rdv-mission"
          value={mission?.rdv?.adresseComplete}
          label="Adresse du rendez-vous"
        />
      </CardContent>
    </Card>
  )
}
