import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import HabilitationMedecin, { HabilitationFilter, HabilitationSortType } from '../types/habilitationMedecin'

export const habilitationMedecinApi = createApi({
  reducerPath: 'habilitationMedecinApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    /* getTop100HabilitationActiveMedecin: builder.query<HabilitationResponse, void>({
      query: () => 'medecin/habilitation?active=1&perPage=100&sortOrder=DESC&sortField=date_debut',
    }), */
    getListeHabilitationsMedecin: builder.query<
      PageRecherche<HabilitationMedecin>,
      PageRechercheRequest<HabilitationFilter, HabilitationSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'medecin/habilitation',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
  }),
})

export const { useGetListeHabilitationsMedecinQuery } = habilitationMedecinApi
