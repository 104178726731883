import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import MissionEntreprise from 'assureur/store/types/missionEntreprise'
import { RootState } from 'plateforme/store'

export interface SelectedMessagesMission {
  codeDossier?: string
  mission?: MissionEntreprise
  indexMission?: number
  indexRapport?: number
  totalLu?: number
  totalNonLu?: number
}

export const selectedMessagesMissionSliceName = 'selectedMessagesMission'

const selectedMessagesMissionSlice = createSlice({
  name: selectedMessagesMissionSliceName,
  initialState: {
    selectedMessagesMission: undefined,
  } as { selectedMessagesMission?: SelectedMessagesMission },
  reducers: {
    setSelectedMessagesMission: (state, action: PayloadAction<SelectedMessagesMission | undefined>) => {
      state.selectedMessagesMission = action.payload
    },
  },
})

export const { setSelectedMessagesMission } = selectedMessagesMissionSlice.actions

export const getSelectedMessagesMission = (state: RootState) => state?.selectedMessagesMission.selectedMessagesMission

export default selectedMessagesMissionSlice.reducer
