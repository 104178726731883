import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface ParametragesAvisTechniquePartProps {
  readOnly?: boolean
}

export default function ParametragesAvisTechniquePart({ readOnly }: ParametragesAvisTechniquePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Paramétrages avis techniques sur pièces" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="parametrage.expertiseSurPiece"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="expertiseSurPiece"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Possibilité de faire des avis techniques sur pièces"
                  />
                  <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
