import React from 'react'
import DelaisCommonPart from './DelaisCommonPart/DelaisCommonPart'

interface DelaiNonReponseComplementsPartProps {
  readOnly?: boolean
}

export default function DelaiNonReponseComplementsPart({ readOnly }: DelaiNonReponseComplementsPartProps) {
  return (
    <DelaisCommonPart
      readOnly={readOnly}
      controllerName="parametrage.delaisRelance.DELAI_VALIDATION_DEMANDE_MODIFICATION"
      title="Délai relance médecin non réponse suite compléments / ajustements"
    />
  )
}
