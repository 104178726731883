import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import DelaisCommonRow from './DelaisCommonRow'

type DelaisCommonProps = {
  title: string
  controllerName: string
  readOnly?: boolean
}

export default function DelaisCommonPart({ title, controllerName, readOnly }: DelaisCommonProps) {
  const { watch } = useFormContext()

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Expertise unilatérale</TableCell>
                    <TableCell>Expertise conjointe</TableCell>
                    <TableCell>Ass. Exp judiciaire</TableCell>
                    <TableCell>Ass. Arbitrage</TableCell>
                    {watch('parametrage.expertiseSurPiece') && <TableCell>Avis technique sur pièces</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {watch('parametrage.naturesEvenements.BIRC') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.BIRC`}
                      rowTitle="BADINTER IRCA"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.BNIR') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.BNIR`}
                      rowTitle="BADINTER NON IRCA"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.RCGE') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.RCGE`}
                      rowTitle="RC GENERALE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.RCMD') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.RCMD`}
                      rowTitle="RC MEDICALE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.ASSC') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.ASSC`}
                      rowTitle="ASS. CONDUCTEUR"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.PREV') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.PREV`}
                      rowTitle="PREVOYANCE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.ACCV') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.ACCV`}
                      rowTitle="ACCIDENT DE LA VIE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.FAUT') && (
                    <DelaisCommonRow
                      controllerName={`${controllerName}.FAUT`}
                      rowTitle="FAUTE INEXCUSABLE"
                      readOnly={readOnly}
                    />
                  )}
                  {watch('parametrage.naturesEvenements.AUTR') && (
                    <DelaisCommonRow controllerName={`${controllerName}.AUTR`} rowTitle="AUTRE" readOnly={readOnly} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
