import React from 'react'
import { useParams } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import { Box } from '@mui/material'
import { FallbackError } from 'plateforme/components'
import NavigationDossierPart from 'plateforme/parts/NavigationDossierPart'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import { useGetDossierByCodeQuery } from 'medecin/store/apis/dossierMedecinApi'
import TabsConsultationDossierMedecinPart from 'medecin/parts/consultationDossier/TabsConsultationDossierMedecinPart'
import { derniereMissionAssociee } from 'medecin/services/dossierMedecin.services'
import { useGetListeMessagesQuery } from 'medecin/store/apis/messageMedecinApi'
import { messagesMissionsNonLusParDossierFiltre } from 'medecin/services/messageMedecin.services'

export default function ConsultationDossierMedecinPage() {
  const { codeDossier } = useParams()
  const handleError = useErrorHandler()
  if (codeDossier === undefined) {
    throw new Error('Aucun code dossier défini')
  }

  const {
    data: utilisateur,
    error: utilisateurError,
    isError: isUtilisateurError,
    isLoading: isUtilisateurLoading,
    isFetching: isUtilisateurFetching,
  } = useGetUtilisateurActifQuery()

  // handle error of useGetUtilisateurActifQuery:
  if (isUtilisateurError) {
    handleError({
      errorApi: utilisateurError,
      title: 'Erreur chargement des données du profil actif',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }

  const {
    data: dossier,
    error: dossierError,
    isError: isDossierError,
    isLoading: isDossierLoading,
    isFetching: isDossierFetching,
  } = useGetDossierByCodeQuery({ codeDossier }, { refetchOnMountOrArgChange: true })

  // handle error of GetDossierByIdQuery:
  if (isDossierError) {
    handleError({
      errorApi: dossierError,
      title: 'Erreur chargement des données du dossier',
      api: 'GetDossierByIdQuery',
    } as FallbackError)
  }
  const filter = messagesMissionsNonLusParDossierFiltre(codeDossier, utilisateur?.profilActif?.code ?? '')
  const totalMessagesNonLus = useGetListeMessagesQuery({ filter, onlyTotal: 1 })?.data?.totalItems ?? 0

  const mission = derniereMissionAssociee(dossier)
  const profilUtilisateur = utilisateur?.profilActif
  const libelleReferenceDossier = mission?.libelle
  const complementReferenceDossier = dossier?.entreprise?.libelle

  const loading = isDossierLoading || isDossierFetching || isUtilisateurLoading || isUtilisateurFetching

  return (
    <>
      <NavigationDossierPart
        libelleReferenceDossier={libelleReferenceDossier}
        complementReferenceDossier={complementReferenceDossier}
        isLoading={loading}
      />
      <Box marginTop={2}>
        <TabsConsultationDossierMedecinPart
          totalMessagesNonLus={totalMessagesNonLus}
          dossier={dossier}
          derniereMissionAssociee={mission}
          profilUtilisateur={profilUtilisateur}
          loading={loading}
        />
      </Box>
    </>
  )
}
