import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface ChoixLieuRDVPartProps {
  readOnly?: boolean
}

export default function ChoixLieuRDVPart({ readOnly }: ChoixLieuRDVPartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Choix du lieu de rendez-vous de la mission" />
      <CardContent>
        <Grid item xs={12}>
          <Controller
            name="parametrage.rdv"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                <FormControlLabel
                  id="rdv"
                  value={value}
                  control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                  label="Activation du choix"
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}
