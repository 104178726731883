import * as React from 'react'
import {
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { AreaLoading, VoirIconButton, NoRowsOverlay } from 'plateforme/components'
import HistoriqueConsultations from 'plateforme/store/types/historiqueConsultation'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { StatutDossier } from 'plateforme/store/types/dossier'

interface TableauDerniersDossiersPartProps {
  historiqueConsultations?: HistoriqueConsultations[]
  loading?: boolean
  hrefConsultationDossier: (codeDossier?: string) => string
  typePartenaire: TypePartenaire
}

function libelleDernierStatutMission(libelleStatut?: string, statutDossier?: string): string {
  return statutDossier && statutDossier === StatutDossier.PURGE ? 'Dossier purgé' : capitalize(libelleStatut ?? '')
}

export default function TableauDerniersDossiersPart({
  historiqueConsultations,
  loading,
  hrefConsultationDossier,
  typePartenaire,
}: TableauDerniersDossiersPartProps) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  if (loading) {
    return <AreaLoading height={350} />
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const totalConsultations = historiqueConsultations?.length ?? 0

  return (
    <div>
      <Card>
        <CardHeader title="Derniers dossiers consultés" />
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '40%' }}>Dossier</TableCell>
                  <TableCell sx={{ width: '30%' }}>
                    {typePartenaire === TypePartenaire.Medecin ? 'Entreprise' : 'Médecin'}
                  </TableCell>
                  <TableCell sx={{ width: '20%' }}>Statut de la dernière mission</TableCell>
                  <TableCell sx={{ width: '10%' }} align="center" />
                </TableRow>
              </TableHead>
              {totalConsultations > 0 ? (
                <TableBody>
                  {historiqueConsultations
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: HistoriqueConsultations) => (
                      <TableRow key={row.codeDossier}>
                        <TableCell sx={{ width: '40%' }}>{row.libelleDossier}</TableCell>
                        <TableCell sx={{ width: '30%' }}>{row.libellePartenaire}</TableCell>
                        <TableCell sx={{ width: '20%' }}>
                          {libelleDernierStatutMission(row.labelStatutDerniereMission, row.statutDossier)}
                        </TableCell>
                        <TableCell sx={{ width: '10%', paddingRight: 4 }} align="right">
                          {row.statutDossier !== StatutDossier.PURGE && (
                            <VoirIconButton href={hrefConsultationDossier(row.codeDossier)} tooltip="Voir le dossier" />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <NoRowsOverlay />
              )}
            </Table>
          </TableContainer>
          {totalConsultations > 0 && (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10]}
              count={totalConsultations}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}
