import * as React from 'react'
import { ChangerProfilIcon, MenuBaseButton, MenuBaseButtonProps } from 'plateforme/components'

export interface ChangerProfilMenuButtonProps extends MenuBaseButtonProps {
  withCompareArrows?: boolean
}

export default function ChangerProfilMenuButton(props: ChangerProfilMenuButtonProps) {
  const { icon, tooltip, withCompareArrows, ...other } = props
  return (
    <MenuBaseButton
      icon={icon ?? <ChangerProfilIcon withCompareArrows={withCompareArrows} color="primary" />}
      tooltip={tooltip ?? 'Changer de profil'}
      {...other}
    />
  )
}
