/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/query'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import SelectInput, { SelectOption } from './SelectInput'
import AutocompleteInput, { AutocompleteInputProps } from './AutocompleteInput'

export type AutocompleteWithQueryProps<TData> = Omit<AutocompleteInputProps, 'options'> & {
  orderBy?: keyof SelectOption
  orderMode?: 'asc' | 'dsc'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParam?: any
  isLoadingExternal?: boolean
  useQuery: UseQuery<
    QueryDefinition<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
      string,
      TData
    >
  >
  dataTransformer?: (data: TData) => SelectOption[]
}

export default function AutocompleteWithQuery<TData>({
  // Le tri est par defaut sur le champ code
  orderBy = 'code',
  // Le tri est par defaut ascendant
  orderMode = 'asc',
  queryParam,
  // On ne charge rien depuis l'exterieur par defaut
  isLoadingExternal = false,
  useQuery,
  dataTransformer,
  ...restProps
}: AutocompleteWithQueryProps<TData>) {
  const { data, isLoading, isFetching, isError, isSuccess } = useQuery(queryParam ?? {})

  if (isLoadingExternal || isLoading || isFetching || isError) {
    return (
      <SelectInput
        {...restProps}
        fullWidth
        optionsLoader={{
          isLoading,
          isError,
        }}
        value=""
        options={[]}
      />
    )
  }

  if (!isSuccess) {
    throw new Error('erreur inattendue')
  }

  const transformed = (data && dataTransformer && dataTransformer(data)) ?? []
  const sorted = [...transformed].sort((a, b) => a[orderBy].localeCompare(b[orderBy]) * (orderMode === 'asc' ? 1 : -1))

  return <AutocompleteInput {...restProps} options={sorted} />
}
