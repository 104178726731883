import React from 'react'
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import { CheckCircle, QueryBuilder } from '@mui/icons-material'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import {
  determineDocumentsRapport,
  determineLibelleRapport,
  isRapportCarence,
  isRapportConclusionDefinitive,
  isRapportConclusionProvisoire,
  isValidationPrimaire,
  isValideDefinitivement,
} from 'plateforme/services/rapport.services'
import DocumentsTablePart from 'plateforme/parts/DocumentsTablePart'
import { formatDateFR } from 'plateforme/services/dates.services'
import { ButtonsStack, MessageAlert } from 'plateforme/components'
import { determineShowPrecisionsMission } from 'plateforme/services/mission.services'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import MissionAdmin from 'admin/store/types/missionAdmin'
import FicheRapportAdminPart from './FicheRapportAdminPart'

interface TabRapportEntreprisePartProps {
  dossier: DossierAdmin
  mission: MissionAdmin
  typeConclusion: TypeRapport.CONCLUSION_PROVISOIRE | TypeRapport.CONCLUSION_DEFINITIVE | TypeRapport.CARENCE
}

export default function TabRapportAdminPart({ dossier, mission, typeConclusion }: TabRapportEntreprisePartProps) {
  const { demandesModifications, rapport } = mission

  const documents = determineDocumentsRapport(mission)

  const derniereDemandeDeModification =
    !demandesModifications || demandesModifications?.length < 1
      ? undefined
      : demandesModifications.at(demandesModifications.length - 1)

  const derniereDemandeDeModificationEnCours =
    derniereDemandeDeModification && !derniereDemandeDeModification?.dateValidation
      ? derniereDemandeDeModification
      : undefined

  const missionCloturee = mission?.statut === 'CLOTUREE'
  const missionTerminee = missionCloturee || isValideDefinitivement(rapport)

  const nbDocuments = rapport?.documents?.length ?? 0
  const hasRapport = rapport?.documents?.some(
    (d) => d.typeDocument === 'RC' || d.typeDocument === 'RP' || d.typeDocument === 'RD'
  )

  const mustHaveRapport = isRapportConclusionProvisoire(rapport) || isRapportConclusionDefinitive(rapport)
  const isRapportFulfilled = !mustHaveRapport || hasRapport
  const nbDocumentsAnnexe = hasRapport ? nbDocuments - 1 : nbDocuments

  const showAlerteMissionPrecisions = determineShowPrecisionsMission(mission)
  function alertePrecisionsMission() {
    return (
      <MessageAlert title="Précisions sur la mission" backgroundColor="white" paddingLeft={2}>
        {mission?.precisions?.split('\n').map((line) => (
          <p style={{ margin: 0 }}>{line}</p>
        ))}
      </MessageAlert>
    )
  }

  const showAlerteDemandeModification = derniereDemandeDeModificationEnCours
  function alerteDemandeModification() {
    return (
      <MessageAlert severity="warning" backgroundColor="white" withGutter paddingLeft={2}>
        <>
          <strong>Demande de modification : </strong>
          {derniereDemandeDeModificationEnCours?.commentaire}
        </>
      </MessageAlert>
    )
  }

  const showAlerteFicheValidee = !missionTerminee && isValidationPrimaire(rapport)
  function alerteFicheValidee() {
    return (
      <MessageAlert severity={isRapportFulfilled ? 'info' : 'error'} backgroundColor="white" withGutter paddingLeft={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <strong>La fiche a été validée : </strong>
            en cours de saisie depuis le {formatDateFR(rapport?.dateCreation)}
          </Grid>
          <Grid item xs={12} lg={6}>
            <ul style={{ marginTop: -2 }}>
              <li>{hasRapport ? '1' : 'Aucun'} document &quot;rapport&quot;</li>
              <li>
                {nbDocumentsAnnexe > 0 ? nbDocumentsAnnexe : 'Aucun'} document{nbDocumentsAnnexe > 1 ? 's ' : ' '}
                &quot;annexe&quot;
              </li>
            </ul>
          </Grid>
        </Grid>
      </MessageAlert>
    )
  }

  const showAlerteEnCourDeSaisie = !missionTerminee && !isValidationPrimaire(rapport)
  function alerteEnCourDeSaisie() {
    return (
      <MessageAlert iconCenter backgroundColor="white" paddingLeft={2}>
        <Grid container paddingY={0.5} alignItems="center">
          <Grid item xs={12} md={6}>
            <strong>
              {isRapportCarence(typeConclusion)
                ? 'Rapport de carence de la mission : '
                : 'Conclusions de la mission : '}
            </strong>
            {derniereDemandeDeModificationEnCours
              ? `en demande de modification depuis le ${formatDateFR(derniereDemandeDeModificationEnCours?.date)}`
              : `en cours de saisie depuis le ${formatDateFR(rapport?.dateCreation)}`}
          </Grid>
        </Grid>
      </MessageAlert>
    )
  }

  const showAlerteRapportValide = missionTerminee
  function alerteRapportValide() {
    return (
      <MessageAlert iconCenter backgroundColor="white" paddingLeft={2}>
        <Grid container paddingY={0.5} alignItems="center">
          <Grid item xs={12} md={6}>
            <strong>Le rapport a été validé : </strong>
            le {formatDateFR(rapport?.dateValidationMedecin)}
          </Grid>
          <Grid item xs={12} lg={6}>
            <ButtonsStack>
              {hasRapport ? (
                <>
                  <CheckCircle sx={{ marginRight: 1 }} color="secondary" />
                  La fiche est générée
                </>
              ) : (
                <>
                  <QueryBuilder sx={{ marginRight: 1 }} />
                  La fiche est en cours de génération
                </>
              )}
            </ButtonsStack>
          </Grid>
        </Grid>
      </MessageAlert>
    )
  }

  return (
    <Stack>
      {showAlerteMissionPrecisions && alertePrecisionsMission()}
      {showAlerteDemandeModification && alerteDemandeModification()}
      {showAlerteFicheValidee && alerteFicheValidee()}
      {showAlerteEnCourDeSaisie && alerteEnCourDeSaisie()}
      {showAlerteRapportValide && alerteRapportValide()}
      <Card>
        <CardHeader title={`Fiche de ${determineLibelleRapport(typeConclusion)}`} />
        <CardContent>
          <FicheRapportAdminPart dossier={dossier} mission={mission} typeConclusion={typeConclusion} />
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Documents rapport et annexe" />
        <CardContent>
          <DocumentsTablePart documents={documents} dossier={dossier} nbLigneParPage={15} readOnly />
        </CardContent>
      </Card>
    </Stack>
  )
}
