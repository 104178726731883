import React from 'react'
import { Grid, Stack } from '@mui/material'
import EvenementPart from 'plateforme/parts/DonneesDetailleesParts/EvenementPart'
import ExpertisePart from 'plateforme/parts/DonneesDetailleesParts/ExpertisePart'
import PersonneAExaminerPart from 'plateforme/parts/DonneesDetailleesParts/PersonneAExaminerPart'
import AutreMedecinPart from 'plateforme/parts/DonneesDetailleesParts/AutreMedecinPart'
import LieuExpertisePart from 'plateforme/parts/DonneesDetailleesParts/LieuExpertisePart'
import { LoadingProps } from 'plateforme/services/utils'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'
import ReferenceDossierMedecinPart from './ReferenceDossierMedecinPart'

type TabDonneesDetailleesPartProps = {
  dossier?: DossierMedecin
  mission?: MissionEntreprise
}

export default function TabDonneesDetailleesMedecinPart({
  dossier,
  mission,
  loading,
}: TabDonneesDetailleesPartProps & LoadingProps) {
  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start">
      <Grid item md={12} lg={6}>
        <Stack>
          <ReferenceDossierMedecinPart dossier={dossier} mission={mission} loading={loading} />
          <EvenementPart dossier={dossier} loading={loading} />
          <ExpertisePart dossier={dossier} mission={mission} loading={loading} />
          <AutreMedecinPart dossier={dossier} loading={loading} />
          <LieuExpertisePart dossier={dossier} loading={loading} />
        </Stack>
      </Grid>
      <Grid item md={12} lg={6}>
        <Stack>
          <PersonneAExaminerPart dossier={dossier} loading={loading} />
        </Stack>
      </Grid>
    </Grid>
  )
}
