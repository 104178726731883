import { TableCell, TableRow } from '@mui/material'
import { SelectInput } from 'plateforme/components'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FICHE_TYPES } from './ParametrageFichesConclusionsPart'

interface ParametrageFichesConclusionsRowProps {
  rowTitle: string
  controllerName: string
  readOnly?: boolean
}

export default function ParametrageFichesConclusionsRow({
  rowTitle,
  controllerName,
  readOnly,
}: ParametrageFichesConclusionsRowProps) {
  const { control, watch } = useFormContext()

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {rowTitle}
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`parametrage.${controllerName}.expertiseUnilaterale`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInput
              fullWidth
              id={`parametrage.${controllerName}.expertiseUnilaterale`}
              label="Type de fiche"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              options={FICHE_TYPES}
              fieldError={error}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`parametrage.${controllerName}.expertiseConjointe`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInput
              fullWidth
              id={`${controllerName}.expertiseConjointe`}
              label="Type de fiche"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              options={FICHE_TYPES}
              fieldError={error}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`parametrage.${controllerName}.expertiseJudiciaire`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInput
              fullWidth
              id={`${controllerName}.expertiseJudiciaire`}
              label="Type de fiche"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              options={FICHE_TYPES}
              fieldError={error}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <Controller
          name={`parametrage.${controllerName}.arbitrage`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <SelectInput
              fullWidth
              id={`${controllerName}.arbitrage`}
              label="Type de fiche"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              options={FICHE_TYPES}
              fieldError={error}
              readOnly={readOnly}
            />
          )}
        />
      </TableCell>
      {watch('parametrage.expertiseSurPiece') && (
        <TableCell component="th" scope="row">
          <Controller
            name={`parametrage.${controllerName}.avisTechnique`}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <SelectInput
                fullWidth
                id={`${controllerName}.avisTechnique`}
                label="Type de fiche"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                options={FICHE_TYPES}
                fieldError={error}
                readOnly={readOnly}
              />
            )}
          />
        </TableCell>
      )}
    </TableRow>
  )
}
