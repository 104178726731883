import { SelectOption } from 'plateforme/components'

export default interface MedecinEntreprise extends SelectOption {
  code: string
  codeSIREN?: string
  codeACPR?: string
  codeRPPS?: string
  libelle?: string
  irca?: boolean
  expertiseSurPiece?: boolean
  numeroHabilitation?: string
  statut?: string
  labelStatut?: string
  lieuxExercice: LieuExercice[]
  mailContact?: string
  commentaireActivite?: string
  dateInscription?: Date | null
}

export interface MedecinPlateformeEntreprise extends SelectOption {
  code: string
  codeSIREN?: string
  codeACPR?: string
  codeRPPS?: string
  libelle?: string
  irca?: boolean
  expertiseSurPiece?: boolean
  numeroHabilitation?: string
  lieuxExercice: LieuExercice[]
  mailContact?: string
  commentaireActivite?: string
}

export interface LieuExercice {
  principal: boolean
  adresseComplete: string
  codeSIRET?: string
  telephone: string
}

export interface MedecinEntrepriseFilter {
  nomMedecin?: string
  codeMedecin?: string
  habilitationExistante?: 0 | 1
  irca?: 0 | 1
  onlyTotal?: 0 | 1
  codePostal?: string
  habilitationActive?: 0 | 1
  habilitable?: 0 | 1
}

export enum MedecinEntrepriseSortType {
  CODE_MEDECIN_SORT = 'code_medecin',
  NOM_MEDECIN_SORT = 'nom_medecin',
  STATUT_SORT = 'statut',
}

export interface MedecinEntrepriseRequest {
  codeMedecin: string
}
