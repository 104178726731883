import { TypeMessageNotification } from 'plateforme/store/types/referentiel'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { ReferenceEntreprise } from 'medecin/store/types/dossierMedecin'
import { SortParam } from 'plateforme/store/types/pageRecherche'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'
import { ReferenceFacture, ReferenceMedecin, ReferenceMission } from './missionEntreprise'
import { ReferenceDossier } from './dossierEntreprise'

export type MessageEntreprise = {
  code: string
  type: TypeMessageNotification
  labelType: string
  date: Date
  codeMedecin?: ReferenceMedecin
  medecin?: ReferenceMedecin
  dossier?: ReferenceDossier
  mission?: ReferenceMission
  entreprise?: ReferenceEntreprise
  documents?: DocumentTeleverse[]
  facture?: ReferenceFacture
  objet?: string
  message?: string
  expediteur?: TypePartenaire
  lu: 0 | 1
}

export enum ExpediteurType {
  ENTREPRISE = 'ENTREPRISE',
  MEDECIN = 'MEDECIN',
}

export type MessageMissionEntreprise = {
  codeDocument: string
  code: string
  expediteur: ExpediteurType
  date: string
  message?: string
  lu: 0 | 1
}

export interface MessageDuMedecinVersAssureur extends MessageEntreprise {
  type: 'MEL_MED'
  medecin: ReferenceMedecin
  message: string
  objet: string
}

export function isMessageDuMedecinVersAssureur(m: MessageEntreprise): m is MessageDuMedecinVersAssureur {
  return m.type === 'MEL_MED'
}

export interface MessageEntrepriseFilter {
  types?: TypeMessageNotification[]
  typesExclus?: TypeMessageNotification[]
  codeMedecin?: string
  codeDossier?: string
  codeMission?: string
  lu?: 0 | 1
  tous?: 0 | 1
  dateDebut?: string
  dateFin?: string
}

export interface PageRechercheMessageMissionRequest<Filter, SortType> {
  codeDossier?: string
  codeMission?: string
  perPage?: number
  sort?: SortParam<SortType>
  page?: string
  filter?: Filter
}

export interface MessageMissionEntrepriseFilter {
  dateDebut?: string
  dateFin?: string
}

export enum MessageEntrepriseSortType {
  DATE_CREATION_SORT = 'date_creation',
}

export interface MessageRequest {
  codeDossier: string
  codeMission: string
}

export interface AcquitterMessageEntrepriseRequest {
  codeDossier?: string
  codeMission?: string
  types?: TypeMessageNotification[]
  typesExclus?: TypeMessageNotification[]
  codesMessages?: string[]
  codeMedecin?: string
}

export interface MessageMissionRequest {
  codeDossier: string
  codeMission: string
  message: string
  codeDocument?: string
}
