import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { DemandeModification } from 'plateforme/store/types/demandeModification'
import { MotifBlocage, StatutMessagerie, StatutMission, StatutSuiviMission } from 'plateforme/store/types/mission'
import { TypeRapportConseil } from 'plateforme/store/types/rapportConclusion'
import { DossierRequest, ReferenceDossier } from './dossierEntreprise'
import Facture from './factureEntreprise'
import RapportConclusionEntreprise from './rapportConlusionEntreprise'
import { LieuExercice } from './habilitationEntreprise'

export default interface MissionEntreprise {
  code: string
  refMissionEntreprise?: string
  refMissionMedecin?: string
  refMissionAnterieure?: string
  precisions?: string
  libelle?: string
  dossier?: ReferenceDossier
  medecin?: ReferenceMedecin
  statut?: StatutMission
  motifBlocage?: MotifBlocage
  labelMotifBlocage?: string
  commentaireBlocage?: string
  labelStatut?: string
  statutSuivi?: StatutSuiviMission
  labelStatutSuivi?: string
  descriptionStatut?: string
  commentaireCloture?: string
  dateExamenPrevue?: Date
  commentaireRefus?: string
  demandesModifications: [DemandeModification]
  rapport?: RapportConclusionEntreprise
  factures?: [Facture]
  documents?: DocumentTeleverse[]
  annulable: boolean
  cloturable: boolean
  arretable: boolean
  declarableSansSuite: boolean
  modificationDemandable: boolean
  factureTraitable: boolean
  dateProposition?: Date
  dateReponseMedecin?: Date
  dateMiseAJour?: Date
  dateMiseAJourSuiviMission?: Date
  dateValidationMedecin?: Date
  dateCloture?: Date
  dateDemandeModification?: Date
  handicapGrave?: boolean
  typeRapportConseilPro?: TypeRapportConseil
  typeRapportConseilDef?: TypeRapportConseil
  messagerieActive?: StatutMessagerie
  rdv?: MissionRDV
}

export interface ReferenceMedecin {
  code?: string
  codeRPPS?: string
  codeSIREN?: string
  libelle?: string
  lieuxExercice?: LieuExercice[]
}

export interface SuiviMissionEntrepriseRequest extends MissionRequest {
  action?: SuiviMissionEntrepriseAction
  commentaire?: string
}

export interface MissionRequest extends DossierRequest {
  codeMedecin?: string
  codeMission?: string
  generationOrdreMission?: boolean
  handicapGrave?: boolean
  refMissionEntreprise?: string
  refMissionAnterieure?: string
  precisions?: string
  rdv?: MissionRDVRequest
}

export interface ReferenceMission {
  code?: string
  refMissionEntreprise?: string
  refMissionMedecin?: string
  libelle?: string
  medecin?: ReferenceMedecin
}

export interface ReferenceDocument {
  code: string
  refDocumentEntreprise: string
  nomDocument: string
  typeDocument: string
}

export interface ReferenceFacture {
  code: string
  numeroFacture: string
}

export interface MissionRDVRequest {
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
}

export interface MissionRDV {
  adresseComplete?: string
  adresse1?: string
  adresse2?: string
  adresse3?: string
  codePostal?: string
  commune?: string
  pays?: string
}

export enum StatutRapport {
  EN_COURS = 'EN_COURS',
  VALIDE = 'VALIDE',
  VALIDE_DEFINITIVEMENT = 'VALIDE_DEFINITIVEMENT',
  VALIDE_DEFINITIVEMENT_EN_COURS = 'VALIDE_DEFINITIVEMENT_EN_COURS',
}

export enum SuiviMissionMedecinAction {
  ACCEPTER = 'ACCEPTER',
  REFUSER = 'REFUSER',
  EN_COURS = 'EN_COURS',
  BLOQUER = 'BLOQUER',
  PLANIFIER_EXAMEN = 'PLANIFIER_EXAMEN',
}

export enum SuiviMissionEntrepriseAction {
  ARRETER = 'ARRETER',
  ANNULER = 'ANNULER',
  CLOTURER = 'CLOTURER',
  DECLARER_SANS_SUITE = 'DECLARER_SANS_SUITE',
  DEMANDER_MODIFICATION = 'DEMANDER_MODIFICATION',
}
