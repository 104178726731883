import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from '@mui/material'
import { EditTextField } from 'plateforme/components'

interface HandicapGravePartProps {
  readOnly?: boolean
}

export default function ParametrageMissionPart({ readOnly }: HandicapGravePartProps) {
  const { control } = useFormContext()

  return (
    <Card>
      <CardHeader title="Paramétrage des missions" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="parametrage.handicapGrave"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <FormControl fullWidth disabled={readOnly} error={!!error} variant="standard">
                  <FormControlLabel
                    id="handicapGrave"
                    value={value}
                    control={<Switch onChange={onChange} disabled={readOnly} onBlur={onBlur} checked={value} />}
                    label="Mission de type handicap grave"
                  />
                  <FormHelperText sx={{ marginLeft: '-35px' }}>{error?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.precisionsMissionMax"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="precisionsMissionMax"
                  type="number"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label={`Taille max du champ "précisions" d'une mission (0 pour désactiver la fonctionnalité)`}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="parametrage.commentaireDemandeModificationMissionMax"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <EditTextField
                  id="commentaireDemandeModificationMissionMax"
                  type="number"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  fieldError={error}
                  readOnly={readOnly}
                  label={`Taille max du champ "commentaire" lors d'une demande de compléments`}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
