import * as React from 'react'
import { Tooltip } from '@mui/material'
import { Download } from '@mui/icons-material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export interface TelechargerIconButtonProps extends LoadingButtonProps {
  tooltip?: NonNullable<React.ReactNode>
  tooltipPlacement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
}

export default function TelechargerIconButton(props: TelechargerIconButtonProps) {
  const { tooltip, tooltipPlacement, 'aria-label': label, sx: otherSx, ...other } = props
  const placement = tooltipPlacement ?? 'right'
  const tooltipMargin = placement === 'right' ? { marginLeft: '-8px !important' } : { marginRight: '-8px !important' }
  return (
    <Tooltip
      title={tooltip === true ? 'Télécharger' : tooltip ?? false}
      placement={placement}
      open={tooltip ? undefined : false}
      PopperProps={{ sx: { '& .MuiTooltip-tooltip': tooltipMargin } }}
    >
      {/* La div n'est ici que pour tricher, car sinon la tooltip ne s'affiche pas quand le bouton est disable */}
      <div>
        <LoadingButton
          color="secondary"
          aria-label={label ?? 'télécharger'}
          sx={{ minWidth: '46px !important', ...otherSx }}
          {...other}
        >
          <Download sx={{ marginTop: '2px', marginBottom: '-2px' }} />
        </LoadingButton>
      </div>
    </Tooltip>
  )
}
