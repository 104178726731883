import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Grid } from '@mui/material'
import {
  AnnulerButton,
  EditTextField,
  PhoneNumberInput,
  SauvegarderButton,
  SelectInputReferentiel,
} from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { trimToUndefined } from 'plateforme/services/utils'
import { parametrageEntrepriseHref } from 'admin/AdminApp'
import { usePostAdminEntrepriseMutation } from 'admin/store/apis/gestionEntrepriseApi'
import { InscrireEntrepriseRequest } from 'admin/store/types/profilEntrepriseAdmin'

interface InscrireEntrepriseFormProps {
  onClose: VoidFunction
}

type InscrireEntrepriseData = {
  codeACPR: string | null
  codeSIREN: string | null
  libelle: string | null
  adresse1: string | null
  adresse2: string | null
  adresse3: string | null
  codePostal: string | null
  commune: string | null
  pays: string | null
  mail: string | null
  telephone: string | undefined
  parametrage?: ParametrageEntrepriseData | null
}

type ParametrageEntrepriseData = {
  mailReseauMedecin?: string | null
}

export default function InscrireEntrepriseForm({ onClose }: InscrireEntrepriseFormProps) {
  const initialValues: InscrireEntrepriseData = {
    codeACPR: '',
    codeSIREN: '',
    libelle: '',
    adresse1: '',
    adresse2: '',
    adresse3: '',
    codePostal: '',
    commune: '',
    pays: 'FR',
    mail: '',
    telephone: '',
    parametrage: {
      mailReseauMedecin: '',
    },
  }

  const {
    control,
    setError,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })

  const [postAdminEntreprise, { error: errorPut }] = usePostAdminEntrepriseMutation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  // Mapping Errors in form:
  useErrorFormMapper(errorPut as IQueryErrorResponse, setError, getValues)

  const makeParametrage = (parametrage?: ParametrageEntrepriseData | null) => {
    if (parametrage?.mailReseauMedecin === '') {
      return undefined
    }
    return parametrage
  }

  const onSubmit = async () => {
    const data = getValues() as InscrireEntrepriseData
    const body = {
      codeACPR: trimToUndefined(data.codeACPR),
      codeSIREN: trimToUndefined(data.codeSIREN),
      libelle: trimToUndefined(data.libelle),
      adresse1: trimToUndefined(data.adresse1),
      adresse2: trimToUndefined(data.adresse2),
      adresse3: trimToUndefined(data.adresse3),
      codePostal: trimToUndefined(data.codePostal),
      commune: trimToUndefined(data.commune),
      pays: trimToUndefined(data.pays),
      mail: trimToUndefined(data.mail),
      telephone: trimToUndefined(data.telephone),
      parametrage: makeParametrage(data.parametrage),
    } as InscrireEntrepriseRequest

    await postAdminEntreprise(body)
      .unwrap()
      .then((response) => {
        enqueueSnackbar(`L'entreprise a été créée`, { variant: 'success' })
        navigate(parametrageEntrepriseHref(response.code as string))
      })
      .catch(() => {
        enqueueSnackbar(`La création de l'entreprise a échoué`, { variant: 'error' })
      })
  }

  return (
    <form id="inscrire-entreprise">
      <Grid container paddingTop={0}>
        <Grid item xs={6}>
          <Controller
            name="codeACPR"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Code ACPR"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="codeSIREN"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Numéro de SIREN"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="libelle"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Raison sociale"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="adresse1"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Adresse 1"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="adresse2"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Adresse 2"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="adresse3"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Adresse 3"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="codePostal"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Code postal"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="commune"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Ville"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="pays"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <SelectInputReferentiel
                id="pays"
                label="Pays"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                referentielName="pays"
                fullWidth
                fieldError={error}
                dataFormatter={(code, label) => {
                  return `${label}`
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="mail"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fieldError={error}
                label="Adresse mail entreprise"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="telephone"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <PhoneNumberInput
                id="telephone"
                label="Téléphone entreprise"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="parametrage.mailReseauMedecin"
            control={control}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                fieldError={error}
                label="Adresse mail réseau médecin"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
          <Grid item>
            <AnnulerButton onClick={onClose}>Annuler</AnnulerButton>
          </Grid>
          <Grid item>
            <SauvegarderButton disabled={!isValid} onClick={onSubmit}>
              Créer l&apos;entreprise
            </SauvegarderButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
