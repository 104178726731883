import React from 'react'

import { AreaLoading } from 'plateforme/components'
import { Stack } from '@mui/material'
import Dossier from 'admin/store/types/dossierAdmin'
import FactureMissionAdminCard from './FactureMissionAdminCard'

type TabFacturePartProps = {
  dossier?: Dossier
  loading?: boolean
}

export default function TabFactureAdminPart({ dossier, loading }: TabFacturePartProps) {
  return (
    <Stack>
      {loading ? (
        <AreaLoading height={540} />
      ) : (
        dossier &&
        dossier?.missions
          ?.filter((m) => (m.factures?.length ?? 0) > 0)
          .map((m) => (
            <Stack key={m.code}>
              <FactureMissionAdminCard mission={m} />
            </Stack>
          ))
      )}
    </Stack>
  )
}
