import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useErrorHandler } from 'react-error-boundary'
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  AreaLoading,
  ButtonsStack,
  FallbackError,
  FichierIcon,
  ListeButton,
  NoRowsOverlay,
  VoirIconButton,
} from 'plateforme/components'
import { formatDateTimeFR } from 'plateforme/services/dates.services'
import { LoadingProps } from 'plateforme/services/utils'
import PageRecherche from 'plateforme/store/types/pageRecherche'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { useGetListeMessagesQuery } from 'medecin/store/apis/messageMedecinApi'
import { setNotificationCriteria } from 'medecin/store/slices/searchCriteriaSlice/searchCriteriaSlice'
import { listeNotificationsMessagesHref } from 'medecin/MedecinApp'
import { MessageVersAssureur } from 'medecin/store/types/notificationsMedecin'
import { MessageMedecin, MessageMedecinFilter } from 'medecin/store/types/messageMedecin'
import { dernieresNotificationsMessagesFiltre } from 'medecin/services/messageMedecin.services'
import MessageMedecinDetails from './MessageMedecinDetails'

export default function DernieresNotificationsMessageMedecinPart({ loading }: LoadingProps) {
  const handleError = useErrorHandler()
  const filter = dernieresNotificationsMessagesFiltre()
  const {
    data: paginatedMessages,
    isLoading: isLoadingMessages,
    isError: isErrorMessages,
    error: errorMessages,
  } = useGetListeMessagesQuery({
    perPage: 10,
    filter,
  })
  if (isErrorMessages) {
    handleError({
      errorApi: errorMessages,
      title: 'Erreur chargement des notifications',
      api: 'useGetListeDerniersMessagesQuery',
    } as FallbackError)
  }

  if (loading || isLoadingMessages || !paginatedMessages) {
    return <AreaLoading height={350} />
  }
  return <DernieresNotificationsMessageMedecinPartLoaded paginatedMessages={paginatedMessages} filter={filter} />
}

function DernieresNotificationsMessageMedecinPartLoaded({
  paginatedMessages,
  filter,
}: {
  paginatedMessages: PageRecherche<MessageMedecin>
  filter: MessageMedecinFilter
}) {
  const messages = paginatedMessages.items
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()

  const handleClickNotifications = () => {
    dispatch(setNotificationCriteria(filter))
    navigate(listeNotificationsMessagesHref)
  }

  return (
    <>
      {confirmDialog}
      <Card>
        <CardHeader title="Mes messages" />
        <CardContent>
          <ButtonsStack withGutter>
            <ListeButton onClick={handleClickNotifications}>Tous mes messages</ListeButton>
          </ButtonsStack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%' }}>Date / heure</TableCell>
                  <TableCell sx={{ width: '20%' }}>Entreprise</TableCell>
                  <TableCell sx={{ width: '40%' }}>Objet</TableCell>
                  <TableCell sx={{ width: '10%' }}>Pièce jointe</TableCell>
                  <TableCell sx={{ width: '10%' }} align="center" />
                </TableRow>
              </TableHead>
              {messages.length > 0 ? (
                <TableBody>
                  {(messages as MessageVersAssureur[]).map((m) => (
                    <TableRow key={m.code}>
                      <TableCell sx={{ width: '20%' }}>{formatDateTimeFR(m.date)}</TableCell>
                      <TableCell sx={{ width: '20%' }}>{m.entreprise?.libelle}</TableCell>
                      <TableCell sx={{ width: '40%' }}>{m.objet ?? '--'}</TableCell>
                      <TableCell sx={{ width: '10%' }}>
                        {m.documents && m.documents.length > 0 && <FichierIcon fontSize="medium" />}
                      </TableCell>
                      <TableCell sx={{ width: '10%', paddingRight: 4 }} align="right">
                        <VoirIconButton
                          onClick={() =>
                            confirm({
                              maxWidth: 'lg',
                              fullWidth: true,
                              withForm: true,
                              form: <MessageMedecinDetails messageMedecin={m} onClose={closeConfirmDialog} />,
                              confirmMsg: '',
                              title: 'Détail du message',
                            })
                          }
                          tooltip="Voir le message"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <NoRowsOverlay />
              )}
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  )
}
