import React from 'react'
import { Grid, Stack } from '@mui/material'
import { LoadingProps } from 'plateforme/services/utils'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import InformationSyntheseAdminPart from './InformationSyntheseAdminPart'
import DocumentsSyntheseAdminPart from './DocumentsSyntheseAdminPart'
import SuiviSyntheseAdminPart from './SuiviSyntheseAdminPart'

type TabSyntheseEntreprisePartProps = {
  dossier?: DossierAdmin
}

export default function TabSyntheseAdminPart({ dossier, loading }: TabSyntheseEntreprisePartProps & LoadingProps) {
  return (
    <Grid container>
      <Grid item md={12} lg={6}>
        <Stack>
          <InformationSyntheseAdminPart dossier={dossier} loading={loading} />
          <DocumentsSyntheseAdminPart dossier={dossier} loading={loading} />
        </Stack>
      </Grid>
      <Grid item md={12} lg={6}>
        <Stack>
          <SuiviSyntheseAdminPart dossier={dossier} loading={loading} />
        </Stack>
      </Grid>
    </Grid>
  )
}
