import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import IndicateurActiviteAdmin, { IndicateurActiviteAdminFilter } from '../types/indicateurActiviteAdmin'

export const indicateurActiviteAdminApi = createApi({
  reducerPath: 'indicateurActiviteAdminApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  endpoints: (builder) => ({
    getIndicateurActiviteAdmin: builder.query<IndicateurActiviteAdmin, IndicateurActiviteAdminFilter>({
      query: (params) => ({
        url: 'admin/indicateur-activite',
        params,
      }),
    }),
  }),
})

export const { useGetIndicateurActiviteAdminQuery } = indicateurActiviteAdminApi
