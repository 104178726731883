import React from 'react'
import { Box, Typography } from '@mui/material'
import { StatutIcon } from 'plateforme/components'

export interface StatutMedecinChipProps {
  labelStatut?: string
}

export default React.memo(StatutMedecinChip)

function StatutMedecinChip({ labelStatut }: StatutMedecinChipProps) {
  return (
    <Box
      sx={{
        width: '120px',
        padding: '10px 20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '70px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <StatutIcon statutColor="#f17913" size="sm" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography margin={0} marginLeft={2} variant="subtitle2" color="text.primary" gutterBottom>
          {labelStatut}
        </Typography>
      </Box>
    </Box>
  )
}
