import HabilitationMedecin from 'medecin/store/types/habilitationMedecin'
import HabilitationEntreprise from 'assureur/store/types/habilitationEntreprise'
import HabilitationEntrepriseAdmin from 'admin/store/types/habilitationEntrepriseAdmin'

type HabilitationPlateforme = HabilitationMedecin | HabilitationEntreprise | HabilitationEntrepriseAdmin

export enum StatutsHabilitation {
  ACTIVE = 'ACTIVE',
  ACTIVE_AU_FUTUR = 'ACTIVE_AU_FUTUR',
  INACTIVE = 'INACTIVE',
}

export default HabilitationPlateforme
