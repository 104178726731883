import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import FluxTechniqueAdmin, {
  FluxTechniqueAdminFilter,
  FluxTechniqueAdminSortType,
} from 'admin/store/types/fluxTechniqueAdmin'

export const fluxTechniqueAdminApi = createApi({
  reducerPath: 'fluxTechniqueAdminApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['FluxTechniqueEntreprise'],
  endpoints: (builder) => ({
    getListeFluxTechniquesAdmin: builder.query<
      PageRecherche<FluxTechniqueAdmin>,
      PageRechercheRequest<FluxTechniqueAdminFilter, FluxTechniqueAdminSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => ({
        url: 'admin/flux-technique',
        params: {
          ...filter,
          ...sort,
          perPage,
          page,
          onlyTotal,
        },
      }),
    }),
  }),
})

export const { useGetListeFluxTechniquesAdminQuery } = fluxTechniqueAdminApi
