import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'

import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import { medecinMiddlewares, medecinReducers, medecinUtils } from 'medecin/store/medecinStore'
import { assureurMiddlewares, assureurReducers, assureurUtils } from 'assureur/store/assureurStore'
import { adminMiddlewares, adminReducers, adminUtils } from 'admin/store/adminStore'
import profilReducer from './slices/profilSlice'
import { utilisateurApi } from './apis/utilisateurApi'
import { listeActualitesApi } from './apis/actualiteApi'
import { version, versionApi } from './apis/versionsApi'
import { historiqueConsultationApi } from './apis/historiqueConsultationApi'
import { referentielApi } from './apis/referentielApi'
/* Définir par whitelist les noeuds du state à persister dans le local storage */
/* TODO définir dans chaque plateforme les whitelist et les concaténer ici (peut être inutile) */
const persistConfig = {
  key: 'transmed',
  version: 1,
  storage,
  whitelist: ['profil', 'searchCriteria', 'searchCriteriaEntreprise', 'searchCriteriaAdmin'],
}

/* Définition des reducers communs à toute les plateformes */
export const plateformeReducers = {
  /* --- sync --- */
  profil: profilReducer,

  /* --- async -> rtk.query --- */
  [utilisateurApi.reducerPath]: utilisateurApi.reducer,
  [listeActualitesApi.reducerPath]: listeActualitesApi.reducer,
  [historiqueConsultationApi.reducerPath]: historiqueConsultationApi.reducer,
  [referentielApi.reducerPath]: referentielApi.reducer,
  [version.reducerPath]: version.reducer,
  [versionApi.reducerPath]: versionApi.reducer,
}

/* Définition des middlewares communs à toute les plateformes */
export const plateformeMiddlewares = [
  utilisateurApi.middleware,
  listeActualitesApi.middleware,
  historiqueConsultationApi.middleware,
  referentielApi.middleware,
  version.middleware,
  versionApi.middleware,
]

/* Définition des utils communs à toute les plateformes */
export const plateformeUtils = [
  utilisateurApi.util,
  listeActualitesApi.util,
  historiqueConsultationApi.util,
  referentielApi.util,
  version.util,
  versionApi.util,
]

export const rootUtils = [...plateformeUtils, ...assureurUtils, ...medecinUtils, ...adminUtils]

/* Chaque plateforme définit ses reducers ici */
const rootReducers = combineReducers({
  ...plateformeReducers,
  ...medecinReducers,
  ...assureurReducers,
  ...adminReducers,
})

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducers),
  /* adding the api middleware enables caching, invalidation, polling and other features of `rtk-query` */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      /* Chaque plateforme définit ses middlewares ici (lié `rtk-query`) */
      .concat(plateformeMiddlewares)
      .concat(medecinMiddlewares)
      .concat(assureurMiddlewares)
      .concat(adminMiddlewares),

  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducers>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

const storeWrapper = { store, persistor }
export default storeWrapper
