import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import DossierPlateforme from 'plateforme/store/types/dossier'
import { LoadingProps } from 'plateforme/services/utils'

type AutreMedecinPartProps = {
  dossier?: DossierPlateforme
}

export default function AutreMedecinPart({ dossier, loading }: AutreMedecinPartProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={424} />
  }
  return <AutreMedecinPartLoaded dossier={dossier} />
}

function AutreMedecinPartLoaded({ dossier }: AutreMedecinPartProps) {
  // error:
  if (!dossier) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { expertise } = dossier

  // render:
  if (!expertise) {
    return null
  }
  return expertise.autreMedecin ? (
    <Card>
      <CardHeader title="Autre médecin" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <ReadOnlyTextField
              id="expertise-autre-medecin-libelle"
              label="Identité autre médecin"
              value={expertise.autreMedecin.libelle}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyTextField
              id="expertise-autre-medecin-adresse-complete"
              label="Adresse"
              value={expertise.autreMedecin.adresseComplete}
              fullWidth
              minRows={3}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <ReadOnlyEmailField
              id="expertise-autre-medecin-mail"
              label="Adresse mail"
              value={expertise.autreMedecin.mail}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="expertise-autre-medecin-telephone-mobile"
              label="Tél. portable"
              value={expertise.autreMedecin.telephoneMobile}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="expertise-autre-medecin-telephone-fixe"
              label="Tél. fixe"
              value={expertise.autreMedecin.telephoneFixe}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : null
}
