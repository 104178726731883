import * as React from 'react'
import { Box, Fab, useScrollTrigger, Zoom } from '@mui/material'
import { KeyboardArrowUp } from '@mui/icons-material'

/* point de déclenchement de l'affichage td bouton de retour  */
const scrollTriggerThreshold = 400

interface Props {
  children?: React.ReactElement
}

function ScrollTopRoundButton(props: Props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: scrollTriggerThreshold,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#menu-app-bar')

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children ?? (
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUp />
          </Fab>
        )}
      </Box>
    </Zoom>
  )
}

export default ScrollTopRoundButton
