import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface ProfilState {
  id?: string
}

const initialState: ProfilState = {
  id: undefined,
}

export const profilSlice = createSlice({
  name: 'profil',
  initialState,
  reducers: {
    chooseProfil: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    reset: (state, _action: PayloadAction) => {
      state.id = undefined
    },
  },
})

export const { chooseProfil, reset } = profilSlice.actions

export const getProfilSelectionne = (state: RootState) => state.profil?.id

export default profilSlice.reducer
