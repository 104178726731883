import * as React from 'react'
import parse from 'html-react-parser'
import { Grid, Stack } from '@mui/material'
import { AnnulerButton, ButtonsStack } from 'plateforme/components'
import Actualite from 'plateforme/store/types/actualite'

interface ConsulterActualiteProps {
  actualite?: Actualite
  onClose: VoidFunction
}

export default function ConsulterActualitePart({ actualite, onClose }: ConsulterActualiteProps) {
  return (
    <Grid container paddingTop={0}>
      <Grid item xs={12}>
        <Stack direction="column" spacing={1} alignItems="left">
          {parse(`${actualite?.contenu}`)}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <ButtonsStack>
          <AnnulerButton onClick={onClose}>Fermer</AnnulerButton>
        </ButtonsStack>
      </Grid>
    </Grid>
  )
}
