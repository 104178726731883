import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Stack } from '@mui/material'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import { useGetUtilisateurActifQuery } from 'plateforme/store/apis/utilisateurApi'
import {
  AnnulerButton,
  ButtonsStack,
  DatePickerInput,
  MessageAlert,
  EditTextField,
  SauvegarderButton,
  SelectInputReferentiel,
} from 'plateforme/components'
import { formatDateISO, isDateBeforeNow } from 'plateforme/services/dates.services'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { MotifBlocage, StatutSuiviMission } from 'plateforme/store/types/mission'
import { usePostSuiviMissionMutation } from 'medecin/store/apis/dossierMedecinApi'
import MissionEntreprise, { SuiviMissionRequest, SuiviMissionMedecinAction } from 'medecin/store/types/missionMedecin'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import { rapportModifiable } from 'medecin/services/rolesMedecin.services'
import { trimToUndefined } from 'plateforme/services/utils'

interface SuiviMissionFormProps {
  dossier: DossierMedecin
  mission: MissionEntreprise
}

export interface SuiviMissionFilterData {
  refMissionMedecin?: string
  isDossierEncours?: boolean
  isDossierBloque?: boolean
  isExamenPrevu?: boolean
  dateExamenPrevue: Date | null
  motifBlocage?: string
  commentaire?: string
}

export default function SuiviMissionForm({ dossier, mission }: SuiviMissionFormProps) {
  // get props:
  const { code: codeDossier } = dossier
  const { code: codeMission } = mission

  if (!codeDossier || !codeMission) {
    throw new Error('erreur inattendu')
  }

  // used hooks:
  const [postSuiviMission, { isLoading: isLoadingSauvegarder, isError: isErrorSauvegarder, error: errorSauvegarder }] =
    usePostSuiviMissionMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { data: utilisateur } = useGetUtilisateurActifQuery()
  const profilUtilisateur = utilisateur?.profilActif

  // Les valeurs initiales du formulaire :
  const initialValues = {
    isDossierEncours: mission.statutSuivi === StatutSuiviMission.EN_COURS,
    isDossierBloque: mission.statutSuivi === StatutSuiviMission.BLOQUE,
    isExamenPrevu: mission.statutSuivi === StatutSuiviMission.EXAMEN_PREVU,
    dateExamenPrevue: mission.dateExamenPrevue ?? null,
    motifBlocage: mission.motifBlocage ?? '',
    commentaire: mission.commentaireBlocage,
  }

  // Form hook  :
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
    watch,
    getValues,
    setValue,
    setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })

  // mapping errors to form:
  useErrorFormMapper(errorSauvegarder as IQueryErrorResponse, setError, getValues)

  const onSubmit = async (data: SuiviMissionFilterData) => {
    let suiviMissionRequest: SuiviMissionRequest | undefined

    // passer la mission à en cours:
    if (data?.isDossierEncours) {
      suiviMissionRequest = { codeDossier, codeMission, action: SuiviMissionMedecinAction.EN_COURS }
    }

    // bloquer la mission:
    if (data?.isDossierBloque) {
      suiviMissionRequest = {
        codeDossier,
        codeMission,
        action: SuiviMissionMedecinAction.BLOQUER,
        motifBlocage: trimToUndefined(data.motifBlocage),
        commentaire: trimToUndefined(data.commentaire),
      }
    }

    // ajouter un examen prevu:
    if (data?.isExamenPrevu) {
      suiviMissionRequest = {
        codeDossier,
        codeMission,
        action: SuiviMissionMedecinAction.PLANIFIER_EXAMEN,
        dateExamenPrevue: formatDateISO(data?.dateExamenPrevue),
      } as SuiviMissionRequest
    }

    if (suiviMissionRequest === undefined) {
      /* Ne rien faire si aucune sélection */
      return
    }

    await postSuiviMission(suiviMissionRequest)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Le statut de la mission a été modifié avec succès', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('La modification du statut de la mission a échoué', { variant: 'error' })
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form-suivi-mission">
      <Grid container alignItems="center" paddingLeft="10px">
        <Grid item xs={12}>
          <Controller
            name="isDossierEncours"
            control={control}
            defaultValue={initialValues.isDossierEncours}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <FormControl error={isErrorSauvegarder}>
                <FormGroup>
                  <FormControlLabel
                    value={value}
                    control={
                      <Checkbox
                        name="isDossierEncours"
                        onBlur={onBlur}
                        checked={!!value}
                        onChange={() => {
                          if (!value) {
                            setValue('isDossierBloque', false)
                            setValue('isExamenPrevu', false)
                            setValue('dateExamenPrevue', null)
                          }
                          onChange(!value)
                        }}
                      />
                    }
                    label="Mission en cours"
                  />
                </FormGroup>
                {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="isDossierBloque"
            control={control}
            defaultValue={initialValues.isDossierBloque}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <FormControl error={isErrorSauvegarder}>
                <FormGroup>
                  <FormControlLabel
                    value={value}
                    control={
                      <Checkbox
                        name="isDossierBloque"
                        checked={!!value}
                        onBlur={onBlur}
                        onChange={() => {
                          if (!value) {
                            setValue('isDossierEncours', false)
                            setValue('isExamenPrevu', false)
                            setValue('dateExamenPrevue', null)
                          }
                          onChange(!value)
                        }}
                      />
                    }
                    label="Bloquée"
                  />
                </FormGroup>
                {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Controller
            name="motifBlocage"
            control={control}
            defaultValue={initialValues.motifBlocage}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <SelectInputReferentiel
                id="motif-blocage"
                label="Motif de blocage"
                value={value}
                onBlur={onBlur}
                onChange={(e) => {
                  setValue('commentaire', undefined)
                  onChange(e)
                }}
                referentielName="motifsBlocage"
                fullWidth
                disabled={!watch('isDossierBloque', initialValues.isDossierBloque)}
                fieldError={error}
              />
            )}
          />
        </Grid>
        {watch('motifBlocage') === MotifBlocage.AUTRE && (
          <>
            <Grid item xs={0} sm={4} display={{ xs: 'none !important', lg: 'flex !important' }}>
              &nbsp;
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller
                name="commentaire"
                control={control}
                defaultValue={initialValues.commentaire}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="commentaire"
                    label="Commentaire"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    multiline
                    minRows={3}
                    fieldError={error}
                    disabled={!watch('isDossierBloque', initialValues.isDossierBloque)}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Controller
            name="isExamenPrevu"
            control={control}
            defaultValue={initialValues.isExamenPrevu}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <FormControl error={isErrorSauvegarder}>
                <FormGroup>
                  <FormControlLabel
                    value={value}
                    control={
                      <Checkbox
                        checked={!!value}
                        onBlur={onBlur}
                        onChange={() => {
                          if (!value) {
                            setValue('isDossierEncours', false)
                            setValue('isDossierBloque', false)
                          }
                          onChange(!value)
                        }}
                      />
                    }
                    label="Examen prévu le"
                  />
                </FormGroup>
                {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Controller
            name="dateExamenPrevue"
            control={control}
            defaultValue={initialValues.dateExamenPrevue}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <DatePickerInput
                InputProps={{
                  id: 'id-date-debut',
                  fullWidth: true,
                }}
                label="Date"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                disabled={!watch('isExamenPrevu', initialValues.isExamenPrevu)}
                fieldError={error}
              />
            )}
          />
        </Grid>
        {/* si date d'examen < date du jour et qu'on a pas des conclusions prov, déf ou des carences en cours de saisie */}
        {initialValues.dateExamenPrevue &&
          isDateBeforeNow(initialValues.dateExamenPrevue) &&
          !rapportModifiable(mission, TypeRapport.CONCLUSION_DEFINITIVE, profilUtilisateur) &&
          !rapportModifiable(mission, TypeRapport.CONCLUSION_PROVISOIRE, profilUtilisateur) &&
          !rapportModifiable(mission, TypeRapport.CARENCE, profilUtilisateur) && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={12}>
                <MessageAlert severity="warning">Date d&apos;examen dépassée.</MessageAlert>
              </Stack>
            </Grid>
          )}
        {isDirty && (
          <Grid item xs={12}>
            <ButtonsStack>
              <AnnulerButton onClick={() => reset()}>Annuler</AnnulerButton>
              <SauvegarderButton
                type="submit"
                form="form-suivi-mission"
                disabled={
                  watch('isDossierEncours') === false &&
                  watch('isDossierBloque') === false &&
                  watch('isExamenPrevu') === false
                }
                loading={isLoadingSauvegarder}
              >
                Sauvegarder
              </SauvegarderButton>
            </ButtonsStack>
          </Grid>
        )}
      </Grid>
    </form>
  )
}
