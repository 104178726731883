import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import DossierAdmin from 'admin/store/types/dossierAdmin'
import { MissionWithIndexes, determineLibelleStatutMissionAccordion } from 'plateforme/services/mission.services'
import MissionAdmin from 'admin/store/types/missionAdmin'
import WorkflowMissionAdminPart from './WorkflowMissionAdminPart'

export interface SuiviMissionAccordionProps {
  dossier: DossierAdmin
  missionWithIndexes: MissionWithIndexes
  expanded?: boolean
  onChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void
}

export default function SuiviMissionAccordion({
  dossier,
  missionWithIndexes,

  expanded,
  onChange,
}: SuiviMissionAccordionProps) {
  const { mission, indexMission, indexRapport } = missionWithIndexes

  const title = `Mission${indexMission ? ` n°${indexMission}` : ''} ${determineLibelleStatutMissionAccordion(
    mission,
    indexRapport
  )} - ${mission.medecin?.libelle}`
  const prefix = `panel-${mission.code}`

  return (
    <Accordion expanded={expanded} onChange={onChange(mission.code)}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: '#fff' }} />}
        aria-controls={`${prefix}-content`}
        id={`${prefix}`}
      >
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#fff' }}>
        <WorkflowMissionAdminPart dossier={dossier} mission={mission as MissionAdmin} />
      </AccordionDetails>
    </Accordion>
  )
}
