import * as React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, InputBase, Paper, PaperProps, styled, Stack } from '@mui/material'
import { RechercheIcon } from 'plateforme/components'

const RechercheContainer = styled(Paper)(() => ({
  display: 'flex',
  minWidth: 180,
  maxWidth: 600,
  height: 36,
}))

const RechercheButton = styled(Button)(({ theme }) => ({
  minWidth: 50,
  height: 36,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  padding: theme.spacing(1),
}))

export default function RechercheMenuInput(
  props: PaperProps & { onRechercheSubmit: (motCle: string) => void; onAfterSuccess: VoidFunction }
) {
  const { onRechercheSubmit, onAfterSuccess, sx: otherSx } = props

  const { handleSubmit, control, reset } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: { motCle: '' },
  })

  const onSubmit = async (data: { motCle?: string }) => {
    // suivant le type de partenaire ? le dispatch avec setDossierEntreprise ... ou setDossierMedecin ... ?
    onRechercheSubmit(data?.motCle ?? '')
    reset()
    onAfterSuccess()
  }

  return (
    <RechercheContainer sx={{ ...otherSx }}>
      <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-menu" style={{ width: '100%' }}>
        <Controller
          name="motCle"
          control={control}
          render={({ field: { onBlur, onChange, value } }) => (
            <Stack direction="row">
              {/* champ de recherche */}
              <InputBase
                placeholder="Recherche …"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ 'aria-label': 'Recherche' }}
              />
              {/* bouton de recherche (loupe) */}
              <RechercheButton variant="contained" color="primary" aria-label="Rechercher" type="submit">
                <RechercheIcon />
              </RechercheButton>
            </Stack>
          )}
        />
      </form>
    </RechercheContainer>
  )
}
