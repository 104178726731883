import React from 'react'

import { useGetTop10HistoriqueConsultationsQuery } from 'plateforme/store/apis/historiqueConsultationApi'
import TableauDerniersDossiersPart from 'plateforme/parts/TableauDerniersDossiersPart'
import { toEntrepriseHref } from 'plateforme/App'
import { consultationDossierPath } from 'assureur/EntrepriseApp'
import { TypePartenaire } from 'plateforme/store/types/utilisateur'

/* Page d'acceuil entreprise, tableau des derniers dossiers consultés */

export default function DerniersDossiersEntreprisePart() {
  // TODO: à  adapter useGetTop10HistoriqueConsultationsQuery pour le cas entreprise
  const { data: historiqueConsultations, isLoading, isFetching } = useGetTop10HistoriqueConsultationsQuery()
  const hrefConsultationDossierEntreprise = (codeDossier?: string) => {
    return toEntrepriseHref(consultationDossierPath).replace(':codeDossier', codeDossier ?? '')
  }

  return (
    <TableauDerniersDossiersPart
      historiqueConsultations={historiqueConsultations}
      loading={isLoading || isFetching}
      hrefConsultationDossier={hrefConsultationDossierEntreprise}
      typePartenaire={TypePartenaire.Entreprise}
    />
  )
}
