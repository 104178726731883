import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { AreaLoading, Prompt } from 'plateforme/components'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { LoadingProps, trimToRemove, trimToUndefined } from 'plateforme/services/utils'
import Referentiel from 'plateforme/store/types/referentiel'
import { determineAutreMedecinPossible } from 'plateforme/services/dossier.services'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import { getSelectedMedecin, setSelectedMedecin } from 'assureur/store/slices/selectedMedecin/selectedMedecinSlice'
import {
  SuiviDossierAction,
  SuiviDossierRequest,
  usePostSuiviDossierMutation,
  usePutModifierDossierMutation,
} from 'assureur/store/apis/dossierAssureurApi'
import DossierEntreprise, {
  EtapeCreationParam,
  ModificationDossierRequest,
  TypeLieuExpertise,
} from 'assureur/store/types/dossierEntreprise'
import { consultationDossierHref } from 'assureur/EntrepriseApp'
import { getErrorDossier, setErrorDossier } from 'assureur/store/slices/dossier/errorDossierSlice'
import ExpertiseForm from './ExpertiseForm'
import LieuExpertiseForm from './LieuExpertise/LieuExpertiseForm'
import AutreMedecinForm from './AutreMedecinForm'
import EditionDossierEntrepriseButtons from '../EditionDossierEntrepriseButtons'

type ExpertiseEtLieuExpertiseFormProps = {
  dossier?: DossierEntreprise
  profilEntreprise?: ProfilEntreprise
  referentiel?: Referentiel
  onContinue: VoidFunction
}

type ExpertiseEtLieuExpertiseFormValues = {
  expertise: {
    cadreExpertise: string | null
    garantieApplicable: string
    domaineContractuel: string | null
    commentaire: string
    isMedecinTransMED: boolean
    autreMedecin: {
      codeMedecin: string
      libelle: string
      adresse1: string
      adresse2: string
      adresse3: string
      codePostal: string
      commune: string
      pays: string | null
      telephoneFixe: string
      telephoneMobile: string
      mail: string
    }
    prejudiceEnAggravation: boolean
    typeLieuExpertise: TypeLieuExpertise
    adresse1: string
    adresse2: string
    adresse3: string
    codePostal: string
    commune: string
    pays: string | null
    telephoneFixe: string
    telephoneMobile: string
    mail: string
  }
  consentement: boolean | null
  refConsentement: string
}

export default function ExpertiseEtLieuExpertiseForm({
  dossier,
  profilEntreprise,
  referentiel,
  loading,
  onContinue,
}: ExpertiseEtLieuExpertiseFormProps & LoadingProps) {
  // loading:
  if (loading) {
    return <AreaLoading height={450} />
  }
  return (
    <ExpertiseEtLieuExpertiseFormLoaded
      dossier={dossier}
      profilEntreprise={profilEntreprise}
      referentiel={referentiel}
      onContinue={onContinue}
    />
  )
}

function ExpertiseEtLieuExpertiseFormLoaded({
  dossier,
  profilEntreprise,
  referentiel,
  onContinue,
}: ExpertiseEtLieuExpertiseFormProps) {
  // error:
  if (!dossier || !dossier.editable || !profilEntreprise || !referentiel) {
    throw new Error(`Erreur de chargement des données`)
  }

  // props:
  const { code: codeDossier, expertise, consentement, refConsentement } = dossier
  const isAutreMedecinTransMED = !!expertise?.autreMedecin?.codeMedecin

  // default values:
  const defaultValues: ExpertiseEtLieuExpertiseFormValues = {
    expertise: {
      cadreExpertise: expertise?.cadreExpertise ?? null,
      garantieApplicable: expertise?.garantieApplicable ?? '',
      domaineContractuel: expertise?.domaineContractuel?.code ?? null,
      isMedecinTransMED: isAutreMedecinTransMED,
      autreMedecin: isAutreMedecinTransMED
        ? {
            codeMedecin: '',
            libelle: '',
            adresse1: '',
            adresse2: '',
            adresse3: '',
            codePostal: '',
            commune: '',
            pays: 'FR',
            mail: '',
            telephoneMobile: '',
            telephoneFixe: '',
          }
        : {
            codeMedecin: '',
            libelle: expertise?.autreMedecin?.libelle ?? '',
            adresse1: expertise?.autreMedecin?.adresse1 ?? '',
            adresse2: expertise?.autreMedecin?.adresse2 ?? '',
            adresse3: expertise?.autreMedecin?.adresse3 ?? '',
            codePostal: expertise?.autreMedecin?.codePostal ?? '',
            commune: expertise?.autreMedecin?.commune ?? '',
            pays: expertise?.autreMedecin?.pays ?? 'FR',
            mail: expertise?.autreMedecin?.mail ?? '',
            telephoneMobile: expertise?.autreMedecin?.telephoneMobile ?? '',
            telephoneFixe: expertise?.autreMedecin?.telephoneFixe ?? '',
          },
      commentaire: expertise?.commentaire ?? '',
      prejudiceEnAggravation: expertise?.prejudiceEnAggravation ?? false,
      typeLieuExpertise: expertise?.typeLieuExpertise ?? TypeLieuExpertise.CABINET,
      adresse1: expertise?.adresse1 ?? '',
      adresse2: expertise?.adresse2 ?? '',
      adresse3: expertise?.adresse3 ?? '',
      codePostal: expertise?.codePostal ?? '',
      commune: expertise?.commune ?? '',
      pays: expertise?.pays ?? 'FR',
      telephoneFixe: expertise?.telephoneFixe ?? '',
      telephoneMobile: expertise?.telephoneMobile ?? '',
      mail: expertise?.mail ?? '',
    },
    consentement: consentement ?? null,
    refConsentement: refConsentement ?? '',
  }

  // hooks:
  const [putModifierDossier, { error: errorSauvegarder, isLoading: loadingSauvegarder }] =
    usePutModifierDossierMutation()
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  })
  const [postSuiviDossier] = usePostSuiviDossierMutation()
  const {
    setError,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = methods

  const dispatch = useDispatch()
  // NOTE: initialisation de la state autreMedecin
  useEffect(() => {
    if (expertise?.autreMedecin?.codeMedecin !== undefined) {
      dispatch(
        setSelectedMedecin({
          code: expertise.autreMedecin.codeMedecin,
          libelle: expertise.autreMedecin.libelle,
          mailContact: expertise.autreMedecin.mail,
          adresseComplete: expertise.autreMedecin.adresseComplete,
          telephoneFixe: expertise.autreMedecin.telephoneFixe,
          telephoneMobile: expertise.autreMedecin.telephoneMobile,
        })
      )
    } else {
      dispatch(
        setSelectedMedecin({
          code: undefined,
          libelle: undefined,
          mailContact: undefined,
          adresseComplete: undefined,
          telephoneFixe: undefined,
          telephoneMobile: undefined,
        })
      )
    }
  }, [expertise?.autreMedecin, dispatch])
  const selectedMedecin = useSelector(getSelectedMedecin)

  const { enqueueSnackbar } = useSnackbar()
  const dataErrorDossier = useSelector(getErrorDossier)
  const errorToBeMapped = (errorSauvegarder || dataErrorDossier.errorDossier) as IQueryErrorResponse
  useErrorFormMapper(errorToBeMapped as IQueryErrorResponse, setError, getValues)
  const navigate = useNavigate()

  // format:
  const cadreExpertise = watch('expertise.cadreExpertise')
  const autreMedecinPossible = determineAutreMedecinPossible(cadreExpertise, referentiel)
  const formId = 'form-expertise-dossier'

  // leave form:
  const onLeave = () => {
    reset()
    navigate(consultationDossierHref(codeDossier))
  }

  // save form and leave: :
  const onSubmitAndLeave = async () => {
    await onSubmit(onLeave)
  }

  // save form and continue: :
  const onSubmitAndContinue = async () => {
    await onSubmit(onContinue)
  }

  // save form: :
  const onSubmit = async (onAfterSuccess: VoidFunction) => {
    const formValues = getValues() as ExpertiseEtLieuExpertiseFormValues
    const expertiseFormValues = formValues.expertise

    let autreMedecin
    if (autreMedecinPossible) {
      if (formValues.expertise.isMedecinTransMED) {
        autreMedecin = selectedMedecin?.code ? { codeMedecin: trimToUndefined(selectedMedecin?.code) } : undefined
      } else {
        autreMedecin = {
          libelle: trimToUndefined(expertiseFormValues.autreMedecin.libelle),
          adresse1: trimToUndefined(expertiseFormValues.autreMedecin.adresse1),
          adresse2: trimToUndefined(expertiseFormValues.autreMedecin.adresse2),
          adresse3: trimToUndefined(expertiseFormValues.autreMedecin.adresse3),
          codePostal: trimToUndefined(expertiseFormValues.autreMedecin.codePostal),
          commune: trimToUndefined(expertiseFormValues.autreMedecin.commune),
          pays: trimToUndefined(expertiseFormValues.autreMedecin.pays),
          mail: trimToUndefined(expertiseFormValues.autreMedecin.mail),
          telephoneMobile: trimToUndefined(expertiseFormValues.autreMedecin.telephoneMobile),
          telephoneFixe: trimToUndefined(expertiseFormValues.autreMedecin.telephoneFixe),
        }
      }
    }

    const isAutreMedecinEmpty =
      autreMedecin === undefined ||
      Object.entries(autreMedecin).filter(([k, v]) => !(k === 'pays' && v === 'FR') && v !== undefined).length === 0
    const isExpertiseAuCabinet = expertiseFormValues.typeLieuExpertise === TypeLieuExpertise.CABINET
    const lieuExpertiseAutreFormValues = isExpertiseAuCabinet
      ? {}
      : {
          adresse1: trimToUndefined(expertiseFormValues.adresse1),
          adresse2: trimToUndefined(expertiseFormValues.adresse2),
          adresse3: trimToUndefined(expertiseFormValues.adresse3),
          codePostal: trimToUndefined(expertiseFormValues.codePostal),
          commune: trimToUndefined(expertiseFormValues.commune),
          pays: trimToUndefined(expertiseFormValues.pays),
          telephoneFixe: trimToUndefined(expertiseFormValues.telephoneFixe),
          telephoneMobile: trimToUndefined(expertiseFormValues.telephoneMobile),
          mail: trimToUndefined(expertiseFormValues.mail),
        }
    const body = {
      etape: EtapeCreationParam.EXPERTISE,
      expertise: {
        cadreExpertise: trimToUndefined(expertiseFormValues.cadreExpertise),
        garantieApplicable: trimToUndefined(expertiseFormValues.garantieApplicable),
        domaineContractuel: trimToUndefined(expertiseFormValues.domaineContractuel),
        commentaire: trimToUndefined(expertiseFormValues.commentaire),
        prejudiceEnAggravation: expertiseFormValues.prejudiceEnAggravation ? 1 : 0,
        typeLieuExpertise: expertiseFormValues.typeLieuExpertise,
        autreMedecin: isAutreMedecinEmpty ? undefined : autreMedecin,
        ...lieuExpertiseAutreFormValues,
      },
      consentement: formValues.consentement,
      refConsentement: trimToRemove(formValues.refConsentement),
    } as ModificationDossierRequest

    const controleBody = {
      codeDossier,
      action: SuiviDossierAction.CONTROLER,
      etapes: [EtapeCreationParam.EVENEMENT, EtapeCreationParam.EXPERTISE],
    } as SuiviDossierRequest

    return putModifierDossier({ codeDossier, ...body })
      .unwrap()
      .then(() => {
        postSuiviDossier(controleBody)
          .unwrap()
          .then(() => dispatch(setErrorDossier({ status: 200, data: undefined })))
          .catch((error) => {
            dispatch(setErrorDossier(error))
          })
        enqueueSnackbar('Les modifications ont été enregistrées avec succès', { variant: 'success' })
        // NOTE: reset the form and specially the state isDirty, to unblock navigation between tabs in case success
        reset(
          {
            ...getValues(),
          },
          {
            keepValues: true,
          }
        )
        onAfterSuccess()
      })
      .catch((error) => {
        dispatch(setErrorDossier(error))
        enqueueSnackbar('La sauvegarde des modifications a échoué', { variant: 'error' })
      })
  }

  // render:
  return (
    <FormProvider {...methods}>
      <form id={formId} name={formId}>
        <Prompt
          title="Actualiser l'Application TRANSMED ?"
          msgConfirmation="Les modifications que vous avez apportées ne seront peut-être pas enregistrées."
          when={isDirty}
        />
        <Stack>
          <ExpertiseForm dossier={dossier} profilEntreprise={profilEntreprise} referentiel={referentiel} />
          {autreMedecinPossible && <AutreMedecinForm dossier={dossier} />}
          <LieuExpertiseForm dossier={dossier} />
          <EditionDossierEntrepriseButtons
            dossier={dossier}
            onLeave={onLeave}
            onSubmitAndLeave={onSubmitAndLeave}
            onSubmitAndContinue={onSubmitAndContinue}
            loading={loadingSauvegarder}
          />
        </Stack>
      </form>
    </FormProvider>
  )
}
