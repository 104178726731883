import React from 'react'
import { Grid, Slider, Stack, Typography } from '@mui/material'
import { DOMMAGES_SEPTIEME_MARKS } from 'plateforme/constantes'
import { EditTextField } from 'plateforme/components'

interface ReadOnlyDommageSliderRangeProps {
  title?: string
  widthTitle?: string
  min?: number
  max?: number
  commentaire?: string
}

export default function ReadOnlyDommageSliderRange({
  title,
  widthTitle = '20%',
  min,
  max,
  commentaire,
}: ReadOnlyDommageSliderRangeProps) {
  return (
    <Grid item display="flex" flexDirection="row" alignItems="center">
      <Typography marginLeft={2} width={widthTitle} color="primary" variant="subtitle2">
        {`${title} :`}
      </Typography>
      <Stack display="flex" flexDirection="column" width="80%">
        <Slider
          disabled
          value={[min ?? 0, max ?? 0]}
          step={0.5}
          max={7}
          min={0}
          marks={DOMMAGES_SEPTIEME_MARKS}
          valueLabelDisplay="auto"
          sx={{ paddingBottom: 2 }}
        />
        <EditTextField fullWidth minRows={3} multiline label="Commentaire" value={commentaire} readOnly />
      </Stack>
    </Grid>
  )
}
