import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useDebouncedCallback } from 'use-debounce'
import { Alert, Grid, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { EditTextField } from 'plateforme/components'
import { supprimerAccents } from 'plateforme/services/utils'

import { MedecinPlateformeEntreprise } from 'assureur/store/types/medecinEntreprise'
import {
  getShowSelectedMedecin,
  setShowSelectedMedecin,
} from 'assureur/store/slices/selectedMedecin/selectedMedecinSlice'
import { useDispatch, useSelector } from 'react-redux'
import { LOCALE, MSG_ERROR_CHARGEMENT_DES_DONNEES } from 'plateforme/constantes'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query'
import HabilitationEntreprise from 'assureur/store/types/habilitationEntreprise'
import MedecinEntrepriseCard from '../MedecinEntrepriseCard'

type InfoResponse = HabilitationEntreprise | MedecinPlateformeEntreprise

type SearchMedecinsProps = {
  withHabilitationActive?: boolean
  cardsNavigable?: boolean
  chipCardHidden?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParam?: any
  useQuery: UseQuery<
    QueryDefinition<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any
    >
  >
}

export interface MedecinFilterData {
  nomMedecin?: string
  codeMedecin?: string
  codePostal?: string
  habilitationActive?: 0 | 1
}

export default function RechercheMedecins({
  withHabilitationActive = false,
  cardsNavigable = true,
  chipCardHidden = false,
  useQuery,
  queryParam,
}: SearchMedecinsProps) {
  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })
  const [getResultatRecherche, setResultatRecherche] = useState<InfoResponse[]>([])
  const dispatch = useDispatch()
  const watchedData = watch()
  const watchNomMedecin = watch('nomMedecin')
  const watchCodePostal = watch('codePostal')
  const showSelectedMedecin = useSelector(getShowSelectedMedecin)
  let medecinsWithHabilitations: HabilitationEntreprise[]

  if (watchNomMedecin && watchCodePostal && watchNomMedecin?.length === 0 && watchCodePostal?.length === 0) {
    setResultatRecherche([])
  }

  // Les valeurs initiales du formulaire :
  const initialValues: MedecinFilterData = {
    nomMedecin: '',
    codePostal: '',
  }

  const { data: medecins, isLoading, isFetching, isError, isSuccess } = useQuery(queryParam ?? {})

  if (withHabilitationActive && medecins) {
    medecinsWithHabilitations = medecins.filter((m: HabilitationEntreprise) => m.statut === 'ACTIVE')
  }

  const filterCodePostalDigit = (options: InfoResponse[], codePostal: string) => {
    return options.filter((option) =>
      option.lieuxExercice.some((lieu) =>
        lieu?.adresseComplete
          .split('\n')
          .pop()
          ?.toLocaleLowerCase(LOCALE)
          ?.startsWith(codePostal.toLocaleLowerCase(LOCALE))
      )
    )
  }

  const filterCommune = (options: InfoResponse[], commune: string) => {
    return options.filter((option) =>
      option.lieuxExercice.some((lieu) =>
        lieu?.adresseComplete.split('\n').pop()?.toLocaleLowerCase(LOCALE)?.includes(commune.toLocaleLowerCase(LOCALE))
      )
    )
  }

  const filterLibelleSirenRppsNumeroHabilitation = (options: InfoResponse[], nomMedecin: string) => {
    return options.filter(
      (option) =>
        supprimerAccents(option?.libelle?.toLocaleLowerCase(LOCALE) ?? '').includes(supprimerAccents(nomMedecin)) ||
        option.codeSIREN?.toLocaleLowerCase(LOCALE).includes(nomMedecin) ||
        option.codeRPPS?.toLocaleLowerCase(LOCALE).includes(nomMedecin) ||
        option.numeroHabilitation?.toLocaleLowerCase(LOCALE).includes(nomMedecin)
    )
  }

  const filterByNameAndCp = (options: InfoResponse[], nomMedecin: string, codePostal: string) => {
    const isCodePostalDigit = /^\d+$/.test(codePostal)

    if (nomMedecin && codePostal.length >= 2) {
      // Filtre par libelle ou codeSIREN ou codeRPPS ou numeroHabilitation
      const resultFiltreNomMedecin = filterLibelleSirenRppsNumeroHabilitation(options, nomMedecin)

      // Si le code postal et un digit, on ajoute un filtre avec un startsWith
      if (isCodePostalDigit) {
        return filterCodePostalDigit(resultFiltreNomMedecin, codePostal)
      }

      // Sinon on ajoute un filtre sur la commune
      return filterCommune(resultFiltreNomMedecin, codePostal)
    }

    if (nomMedecin) {
      return filterLibelleSirenRppsNumeroHabilitation(options, nomMedecin)
    }

    if (codePostal.length >= 2) {
      if (isCodePostalDigit) {
        return filterCodePostalDigit(options, codePostal)
      }

      return filterCommune(options, codePostal)
    }
    return []
  }

  const checkCodePostal = (consentement: string) => {
    if (consentement.length === 1) {
      return 'Doit contenir au moins 2 caractères'
    }
    return true
  }

  const onSubmit = async (data: MedecinFilterData) => {
    const medecinsToFilter = withHabilitationActive ? medecinsWithHabilitations : medecins
    if (medecinsToFilter !== undefined) {
      setResultatRecherche(
        filterByNameAndCp(
          medecinsToFilter,
          data.nomMedecin?.toLocaleLowerCase(LOCALE) as string,
          data.codePostal?.toLocaleLowerCase(LOCALE) as string
        )
      )
    }
    dispatch(setShowSelectedMedecin(true))
  }

  // Save if this function is called and then not called again within 1000ms
  // eslint-disable-next-line
  const debouncedSearch = useDebouncedCallback(() => {
    if (watchNomMedecin?.length >= 1 || watchCodePostal?.length >= 2) {
      handleSubmit(onSubmit)()
    }
    if (watchNomMedecin?.length === 0 || watchCodePostal?.length === 0) {
      setResultatRecherche([])
    }
  }, 800)

  useDeepCompareEffect(() => {
    debouncedSearch()
  }, [watchedData])

  return (
    <>
      <Grid container>
        <Grid item xs={6} sm={12} md={6}>
          <Controller
            name="nomMedecin"
            control={control}
            defaultValue={initialValues.nomMedecin}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                id="id-nom-medecin"
                label={`Nom du médecin ou code RPPS/SIREN ou numéro d'habilitation`}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                fieldError={error}
                InputProps={{
                  autoComplete: 'cc-csc',
                  endAdornment: <SearchIcon />,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <Controller
            name="codePostal"
            rules={{
              validate: (value: string) => checkCodePostal(value),
            }}
            control={control}
            defaultValue={initialValues.codePostal}
            render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
              <EditTextField
                id="id-code-postal"
                label="Code postal ou ville du lieu d'exercice"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                fullWidth
                fieldError={error}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {showSelectedMedecin && (
        <Grid container>
          <Grid item container xs={12} paddingBottom={2.5}>
            <Typography marginLeft={2} variant="subtitle2" color="text.secondary">
              {`Liste des médecins (${getResultatRecherche.length})`}
            </Typography>
          </Grid>
          {isError && !isSuccess && !isLoading && !isFetching ? (
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              {MSG_ERROR_CHARGEMENT_DES_DONNEES}
            </Alert>
          ) : (
            <Grid container width="100%">
              {getResultatRecherche.map((medecin: InfoResponse) => (
                <Grid item xs={12} lg={6} key={medecin.code}>
                  <MedecinEntrepriseCard
                    key={medecin.code}
                    medecin={medecin}
                    navigable={cardsNavigable}
                    chipCardHidden={chipCardHidden}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}
