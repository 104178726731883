import React from 'react'
import { useSnackbar } from 'notistack'
import {
  capitalize,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  AnnulerButton,
  CommentaireIconButton,
  NoRowsOverlay,
  ReadOnlyTextField,
  SupprimerIconButton,
  TelechargerIconButton,
} from 'plateforme/components'
import { AuthDownloadAction, AuthDownloadDocumentDossierRequest } from 'plateforme/store/types/utilisateur'
import { toTelechargementDocumentDossierHref } from 'plateforme/App'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import { usePostAuthDownloadMutation } from 'plateforme/store/apis/utilisateurApi'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { formatDateFR, formatDateTimeFR } from 'plateforme/services/dates.services'
import { formatMontant } from 'plateforme/services/montant.services'
import dateOfStatutFacture from 'plateforme/services/factures.services'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import { useDeleteFactureMutation } from 'medecin/store/apis/dossierMedecinApi'
import Facture, { StatutFacture } from 'medecin/store/types/factureMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'

type FactureMissionCardProps = {
  dossier: DossierMedecin
  mission: MissionEntreprise
}

export default function FactureMissionCard({ dossier, mission }: FactureMissionCardProps) {
  const { code: codeDossier } = dossier
  const { code: codeMission } = mission
  const totalFactures = mission?.factures?.length ?? 0

  const { enqueueSnackbar } = useSnackbar()
  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()
  const [deleteFacture, { isLoading: isLoadingDelete }] = useDeleteFactureMutation()

  const [authDownload, { isLoading: isLoadingAuthDownload }] = usePostAuthDownloadMutation()

  const onSupprimerFacture = (facture: Facture) => () => {
    // Vous souhaitez annuler la facture "référence facture" d'un montant de "montant ttc" euros TTC : l'information de l'annulation sera transmise à l'entreprise. Vous pourrez déposer une nouvelle facture sur la mission"
    // et en bas de la message à droite on fait apparaître deux boutons : "Confirmer l'annulation de la facture" et "Retour" qui permet de revenir à l'état antérieur.
    confirm({
      confirmMsg: `Vous souhaitez annuler la facture «${
        facture.numeroFacture
      }» d’un montant d'honoraires de ${formatMontant(
        facture.montantHonoraires?.montantTTC
      )} TTC : l’information d’annulation sera transmise à l’entreprise. Vous pourrez déposer une nouvelle facture sur la mission`,
      variant: 'delete',
      onYes: async () => {
        if (dossier && dossier?.code && codeMission) {
          deleteFacture({ codeDossier: dossier?.code, codeMission, codeFacture: facture.code })
            .unwrap()
            .then(() =>
              enqueueSnackbar(`La facture a été supprimée avec succès`, {
                variant: 'success',
              })
            )
            .catch(() => {
              enqueueSnackbar(`La suppression de la facture a échoué`, { variant: 'error' })
            })
        } else {
          throw new Error('impossible de récupérer la mission')
        }
      },
    })
  }

  const onDownload: (doc: DocumentTeleverse) => Promise<void> = async (doc: DocumentTeleverse) => {
    if (!codeDossier) {
      throw new Error('erreur inattendu')
    }

    const request: AuthDownloadDocumentDossierRequest = {
      action: AuthDownloadAction.TELECHARGER_DOCUMENT_DOSSIER,
      codeDossier,
      codeDocument: doc.code,
    }

    await authDownload(request)
      .unwrap()
      .then(() => {
        const href = toTelechargementDocumentDossierHref(codeDossier, doc.code)
        window.open(href, '_self')
      })
      .catch(() => {
        enqueueSnackbar(`Le téléchargement de la facture a échoué`, { variant: 'error' })
      })
  }

  const showCommentaire = (facture: Facture) => {
    confirm({
      title: 'Commentaire',
      maxWidth: 'lg',
      confirmMsg: facture.commentaireDemandeModification,
      withForm: true,
      form: (
        <Grid container paddingTop={0}>
          <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
            <Grid item>
              <AnnulerButton onClick={() => closeConfirmDialog()}>Fermer</AnnulerButton>
            </Grid>
          </Grid>
        </Grid>
      ),
    })
  }

  const montantHonoraire = (value?: number) => (
    <Stack direction="row" margin={0} padding={0} spacing={0}>
      <Typography variant="body2" color="readOnly.main" minWidth={82}>
        {'Honoraires : '}
      </Typography>
      <Typography variant="body2" color="text.primary">
        {`${formatMontant(value)}`}
      </Typography>
    </Stack>
  )

  const montantFrais = (value?: number) =>
    value !== undefined ? (
      <Stack direction="row" margin={0} padding={0} spacing={0}>
        <Typography variant="body2" color="readOnly.main" minWidth={42}>
          {'Frais : '}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {`${formatMontant(value)}`}
        </Typography>
      </Stack>
    ) : (
      ''
    )

  return (
    <>
      {confirmDialog}
      <Card>
        <CardHeader
          title={`Mission ${mission.refMissionEntreprise ?? mission.code} ${
            mission.refMissionMedecin ? ` / ${mission.refMissionMedecin}` : ''
          }`}
        />
        <CardContent>
          <Stack spacing={2}>
            <Grid container sx={{ pb: 3 }}>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-date-acceptation-mission"
                  value={formatDateFR(mission.dateReponseMedecin)}
                  label="Date d'acceptation de la mission"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-date-cloture-mission"
                  value={formatDateFR(mission.dateValidationMedecin)}
                  label="Date de validation du rapport"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-ref-mission"
                  value={mission.refMissionEntreprise}
                  label="Référence mission entreprise"
                />
              </Grid>
              <Grid item xs={6}>
                <ReadOnlyTextField
                  fullWidth
                  id="id-type-mission"
                  value={mission.refMissionMedecin}
                  label="Référence mission médecin"
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date facture</TableCell>
                    <TableCell>Numéro facture</TableCell>
                    <TableCell>Montant HT</TableCell>
                    <TableCell>Montant TVA</TableCell>
                    <TableCell>Montant TTC</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell sx={{ width: 100 }} />
                  </TableRow>
                </TableHead>
                {totalFactures > 0 ? (
                  <TableBody>
                    {mission?.factures?.map((facture) => (
                      <TableRow key={facture.code}>
                        <TableCell component="th" scope="row">
                          {formatDateFR(facture.dateFacture)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {facture.numeroFacture}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantHT)}
                          {montantFrais(facture.montantFrais?.montantHT)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantTVA)}
                          {montantFrais(facture.montantFrais?.montantTVA)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {montantHonoraire(facture.montantHonoraires?.montantTTC)}
                          {montantFrais(facture.montantFrais?.montantTTC)}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ maxWidth: '200px' }}>
                          <Stack direction="row" alignContent="center" alignItems="center">
                            {`${capitalize(facture.labelStatut ?? '')} le ${formatDateTimeFR(
                              dateOfStatutFacture(facture),
                              'à'
                            )} `}
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ width: '250px' }}>
                          <Grid direction="row" justifyContent="flex-end" container>
                            <Grid item>
                              {facture?.commentaireDemandeModification && (
                                <CommentaireIconButton onClick={() => showCommentaire(facture)} />
                              )}
                            </Grid>
                            <Grid item minWidth="74px">
                              {facture.statut === StatutFacture.DEPOSEE && mission.facturable && (
                                <SupprimerIconButton
                                  loading={isLoadingDelete}
                                  onClick={onSupprimerFacture(facture)}
                                  tooltip="Supprimer"
                                />
                              )}
                            </Grid>

                            <Grid item>
                              <TelechargerIconButton
                                onClick={() => onDownload(facture.document)}
                                loading={isLoadingAuthDownload}
                                tooltip
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <NoRowsOverlay />
                )}
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}
