import React from 'react'
import { Stack } from '@mui/material'
import DocumentsPart from 'plateforme/parts/DocumentsPart'
import { determineDocumentsDossier } from 'plateforme/services/dossier.services'
import { DocumentPanelType } from 'plateforme/store/types/documentUpload'
import DossierAdmin from 'admin/store/types/dossierAdmin'

type TabDocumentsPartProps = {
  dossier?: DossierAdmin
  loading?: boolean
}

export default function EditionDocumentsDossierEntreprisePart({ dossier, loading }: TabDocumentsPartProps) {
  // format:
  const documents = determineDocumentsDossier(dossier)

  // rendu:
  return (
    <Stack>
      <DocumentsPart
        title="Nouveaux documents"
        buttonText="Ajouter des documents au dossier"
        dossier={dossier}
        documents={documents}
        loading={loading}
        typeDocumentPanel={DocumentPanelType.DOSSIER_ENTREPRISE}
        readOnly
        documentDownloadable={false}
      />
    </Stack>
  )
}
