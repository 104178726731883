import * as React from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import AdresseIcon from '../icons/AdresseIcon'

export default function AdresseButton(props: LoadingButtonProps) {
  const { color, children, variant, ...other } = props
  return (
    <LoadingButton
      variant={variant ?? 'contained'}
      color={color ?? 'primary'}
      startIcon={<AdresseIcon />}
      loadingPosition="start"
      {...other}
    >
      {children}
    </LoadingButton>
  )
}
