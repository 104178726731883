import React from 'react'
import { Alert, AlertTitle, Grid } from '@mui/material'
import MissionPlateforme, { StatutMessagerie } from 'plateforme/store/types/mission'

type MessageBulleListVideProps = {
  mission?: MissionPlateforme
}

export default function MessageBulleListVide({ mission }: MessageBulleListVideProps) {
  return (
    <Grid container sx={{ overflow: 'auto', paddingRight: -2.5 }} paddingRight={2}>
      <Grid item xs={12} paddingBottom={0.5}>
        <Alert severity="info" sx={{ width: '100%', height: '100%' }} elevation={1}>
          <AlertTitle> Aucun message pour cette mission</AlertTitle>
          {mission?.messagerieActive === StatutMessagerie.ACTIVE &&
            'Pour démarrer une nouvelle conversation veuillez taper votre premier message ci-dessous.'}
        </Alert>
      </Grid>
    </Grid>
  )
}
