import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormControl, FormHelperText, Slider } from '@mui/material'

interface DommageFieldSliderProps {
  name: string
  code: string
  marks: Array<{ value: number; label: string }>
  min?: number
  max?: number
  readOnly?: boolean
  disabled?: boolean
}

export default function DommageFieldSlider({
  name,
  code,
  marks,
  min,
  max,
  readOnly,
  disabled,
}: DommageFieldSliderProps) {
  const { control, clearErrors, resetField } = useFormContext()

  return (
    <Box paddingX={1.5}>
      <Controller
        name={`${name}.valeur`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Controller
            name={`${name}.min`}
            control={control}
            render={({ fieldState: { error: errorMin } }) => (
              <Controller
                name={`${name}.max`}
                control={control}
                render={({ fieldState: { error: errorMax } }) => (
                  <FormControl
                    disabled={readOnly || disabled}
                    error={!!(error ?? errorMin ?? errorMax)}
                    variant="standard"
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                  >
                    <Slider
                      defaultChecked={undefined}
                      defaultValue={undefined}
                      disabled={readOnly || disabled}
                      id={code}
                      value={value}
                      onChange={(e) => {
                        clearErrors([`${name}.valeur`, `${name}.min`, `${name}.max`])
                        resetField(`${name}.valeur`)
                        onChange(e)
                      }}
                      step={0.5}
                      min={min}
                      max={max}
                      marks={marks}
                      valueLabelDisplay="auto"
                      color={error ?? errorMin ?? errorMax ? 'error' : 'primary'}
                      sx={{
                        '& .MuiSlider-thumb': {
                          display: value === null ? 'none' : 'flex',
                        },
                      }}
                    />
                    <FormHelperText sx={{ marginLeft: '2px' }}>
                      {error?.message ?? errorMin?.message ?? errorMax?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            )}
          />
        )}
      />
    </Box>
  )
}
