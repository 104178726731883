import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Card, CardContent, CardHeader, Grid, Snackbar } from '@mui/material'
import {
  ButtonsStack,
  ChercherButton,
  DateTimePickerInput,
  EditTextField,
  RechargerButton,
  SelectOption,
} from 'plateforme/components'
import { NotificationAdminFilter } from 'admin/store/types/notificationsAdmin'
import useErrorFormMapper, { IQueryErrorResponse } from 'plateforme/hooks/useErrorFormMapper'
import { formatDateTimeISO } from 'plateforme/services/dates.services'
import { trimToUndefined } from 'plateforme/services/utils'
import AutocompleteMedecin from 'admin/components/AutocompleteAdminMedecin'
import AutocompleteEntreprise from 'admin/components/AutocompleteAdminEntreprise'
import { TypeMessageNotification } from 'plateforme/store/types/referentiel'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNotificationCriteria,
  setNotificationCriteria,
} from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'

type SearchNotificationsProps = {
  search: (criteria: NotificationAdminFilter) => void
  searchError: IQueryErrorResponse
}

export interface NotificationsFilterData {
  objet: string | null
  dateDebut: Date | null
  dateFin: Date | null
  codeExpediteur: SelectOption | null
  codeEntreprise: SelectOption | null
}

export default function RechercheNotificationsMessageAdminPart({ search, searchError }: SearchNotificationsProps) {
  // get default values from localStorage
  const defaultNotificationCriteria = useSelector(getNotificationCriteria)
  const dispatch = useDispatch()

  // Les valeurs initiales du formulaire :
  const initialValues: NotificationsFilterData = {
    objet: defaultNotificationCriteria?.objet ?? '',
    dateDebut: defaultNotificationCriteria?.dateDebut ? new Date(defaultNotificationCriteria?.dateDebut) : null,
    dateFin: defaultNotificationCriteria?.dateFin ? new Date(defaultNotificationCriteria?.dateFin) : null,
    codeExpediteur: defaultNotificationCriteria?.codeExpediteur
      ? ({ label: defaultNotificationCriteria?.codeExpediteur } as SelectOption)
      : null,
    codeEntreprise: defaultNotificationCriteria?.codeEntreprise
      ? ({ label: defaultNotificationCriteria?.codeEntreprise } as SelectOption)
      : null,
  }

  const resetValues: NotificationsFilterData = {
    objet: '',
    dateDebut: null,
    dateFin: null,
    codeExpediteur: null,
    codeEntreprise: null,
  }

  const {
    handleSubmit,
    control,
    setError,
    getValues,
    formState: { isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: initialValues,
  })
  useErrorFormMapper(searchError, setError, getValues)

  // state management
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const onSubmit = async (data: NotificationsFilterData) => {
    const filtre = {
      dateDebut: formatDateTimeISO(data.dateDebut),
      dateFin: formatDateTimeISO(data.dateFin),
      types: ['MEL_MED'],
      codeEntreprise: trimToUndefined(data.codeEntreprise?.code),
      codeExpediteur: trimToUndefined(data.codeExpediteur?.code),
      objet: trimToUndefined(data.objet),
    } as NotificationAdminFilter
    search(filtre)
    dispatch(setNotificationCriteria(filtre))
  }

  const handleClickInitSearch = () => {
    const filtre = {
      dateDebut: undefined,
      dateFin: undefined,
      types: ['MEL_MED' as TypeMessageNotification],
      codeEntreprise: undefined,
      codeExpediteur: undefined,
      objet: undefined,
    } as NotificationAdminFilter

    reset(resetValues)
    dispatch(setNotificationCriteria(filtre))
    search(filtre)
  }

  // Snackbar handlers :
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <Card>
      <CardHeader title="Recherche avancée" />
      <CardContent sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onSubmit)} id="form-recherche-notifications">
          <Grid container>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="objet"
                control={control}
                defaultValue={initialValues.objet}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="objet"
                    label="Objet"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="dateDebut"
                control={control}
                defaultValue={initialValues.dateDebut}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-debut',
                      fullWidth: true,
                    }}
                    label="Date de début"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="dateFin"
                control={control}
                defaultValue={initialValues.dateFin}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <DateTimePickerInput
                    InputProps={{
                      id: 'date-fin',
                      fullWidth: true,
                    }}
                    label="Date de fin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="codeExpediteur"
                control={control}
                defaultValue={initialValues.codeExpediteur}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteMedecin
                    id="expediteur"
                    label="Expéditeur"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    withColors
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                name="codeEntreprise"
                control={control}
                defaultValue={initialValues.codeEntreprise}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <AutocompleteEntreprise
                    id="entreprise"
                    label="Destinataire"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    withNoSelectionItem
                    fieldError={error}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsStack>
                <RechargerButton color="primary" variant="outlined" onClick={handleClickInitSearch}>
                  Réinitialiser
                </RechargerButton>
                <ChercherButton
                  variant="contained"
                  type="submit"
                  form="form-recherche-notifications"
                  disabled={!isValid}
                >
                  Rechercher
                </ChercherButton>
              </ButtonsStack>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Erreur serveur lors de la recherche des habilitations
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  )
}
