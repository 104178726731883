import { totalEntreprisesReferencesRequest } from 'admin/services/dossierAdmin.services'
import { useGetListeReferentielAdminEntrepriseQuery } from 'admin/store/apis/gestionEntrepriseApi'
import { totalMedecinsReferencesRequest } from 'admin/store/slices/searchCriteriaSlice/searchCriteriaAdminSlice'
import { useGetAdminMedecinsQuery } from 'admin/store/apis/gestionMedecinApi'

export default function useIndicateursDeBordAdmin() {
  // Nombre des entreprises référencées
  const totalEntreprisesReferences = totalEntreprisesReferencesRequest()
  const { data: listEntreprisesReferences, refetch: refetchTotalEntreprisesReferences } =
    useGetListeReferentielAdminEntrepriseQuery(totalEntreprisesReferences)
  const compteurEntreprisesReferences = listEntreprisesReferences?.totalItems as number
  const filterEntreprisesReferences = totalEntreprisesReferences.filter

  const { data: listMedecinsReferences } = useGetAdminMedecinsQuery(totalMedecinsReferencesRequest())
  const compteurMedecinsReferences = listMedecinsReferences?.totalItems as number

  return {
    compteurEntreprisesReferences,
    filterEntreprisesReferences,
    refetchTotalEntreprisesReferences,
    compteurMedecinsReferences,
  }
}
