import React from 'react'
import { useSnackbar } from 'notistack'
import { DocumentPanelType } from 'plateforme/store/types/documentUpload'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import DocumentTeleverse from 'plateforme/store/types/documentTeleverse'
import DocumentsPart from 'plateforme/parts/DocumentsPart'
import { ReferentielTypeDocument, TypeDocumentTag } from 'plateforme/store/types/referentiel'
import { determineDocumentsRapport, isValideDefinitivement } from 'plateforme/services/rapport.services'
import { TypeRapport } from 'plateforme/store/types/rapportConclusion'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionMedecin from 'medecin/store/types/missionMedecin'
import { useDeleteConclusionDocumentMutation } from 'medecin/store/apis/dossierMedecinApi'

type DocumentsConclusionProps = {
  dossier: DossierMedecin
  mission: MissionMedecin
  loading?: boolean
}

export default function DocumentsConclusion({ dossier, mission, loading }: DocumentsConclusionProps) {
  // props:
  const { code: codeDossier } = dossier
  const { code: codeMission, rapport } = mission

  // hooks:
  const { enqueueSnackbar } = useSnackbar()
  const { confirm, confirmDialog } = useConfirmDialog()
  const [deleteRapportDocument, { isLoading: loadingDelete }] = useDeleteConclusionDocumentMutation()

  // format:
  const documents = determineDocumentsRapport(mission)
  let anyTags: TypeDocumentTag[]
  switch (rapport?.typeRapport) {
    case TypeRapport.CONCLUSION_PROVISOIRE: {
      anyTags = ['CONCLUSION_PROVISOIRE_RAPPORT', 'CONCLUSION_PROVISOIRE_ANNEXE']
      break
    }
    case TypeRapport.CONCLUSION_DEFINITIVE: {
      anyTags = ['CONCLUSION_DEFINITIVE_RAPPORT', 'CONCLUSION_DEFINITIVE_ANNEXE']
      break
    }
    case TypeRapport.CARENCE: {
      anyTags = ['CARENCE_RAPPORT', 'CARENCE_ANNEXE']
      break
    }
    default:
      anyTags = []
      break
  }

  const tagsFilter = (ref: ReferentielTypeDocument) =>
    anyTags.some((tag) => ref.tags.includes(tag)) && ref.tags.includes('MEDECIN')

  const rapportModifiable = mission?.rapportModifiable

  // dialogue avec formulaire:
  const onDeleteDocument = async (doc: DocumentTeleverse) => {
    return confirm({
      maxWidth: 'lg',
      confirmMsg: `Êtes-vous sûr de vouloir supprimer le document «${doc.nomDocument}» ?`,
      variant: 'delete',
      onYes: async () => {
        const codeDocument = doc.code
        if (!codeDossier || !codeMission || !codeDocument) {
          throw new Error('erreur inattendu')
        }
        deleteRapportDocument?.({
          codeDossier,
          codeMission,
          codeDocument,
        })
          .unwrap()
          .then(() => enqueueSnackbar('Le document a été supprimé avec succès', { variant: 'success' }))
          .catch(() => {
            enqueueSnackbar('La suppression de rapport a échoué', { variant: 'error' })
          })
      },
    })
  }

  // rendu:
  return (
    <>
      {confirmDialog}
      <DocumentsPart
        title="Documents rapport et annexe"
        buttonText={`Ajouter des documents ${
          rapport?.typeRapport === TypeRapport.CARENCE ? 'à la carence' : 'aux conclusions'
        }`}
        dossier={dossier}
        mission={mission}
        documents={documents}
        loading={loading}
        readOnly={!rapport || !rapportModifiable || isValideDefinitivement(rapport)}
        typeDocumentPanel={DocumentPanelType.RAPPORT}
        typeDocumentFilter={tagsFilter}
        onDeleteDocument={onDeleteDocument}
        isLoadingDelete={loadingDelete}
      />
    </>
  )
}
