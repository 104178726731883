import { Grid, styled } from '@mui/material'

/* style de la grid qui va contenir les éléments (bloc fond blanc, etc) */
const TableauDeBordGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  spacing: theme.spacing(5),
  display: 'flex',
  flexWrap: 'wrap',
  width: 'auto',
  height: 'auto',
  border: 0,
}))

export default TableauDeBordGrid
