import React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip, styled } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface DommageCheckboxProps {
  name: string
  control: Control<FieldValues, object>
  label?: string
  disabled?: boolean
}

const StyledFormGroup = styled(FormGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const getInfoTitle = (codeDommage: string) => {
  switch (codeDommage) {
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_PROFESSIONNELLES':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SCOLAIRES_UNIVERSITAIRES_OU_DE_FORMATION':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_AGREMENT':
    case 'REPERCUSSION_DES_SEQUELLES_SUR_LES_ACTIVITES_SEXUELLES':
    case 'FRAIS_FUTURS_A_CARACTERE_CERTAIN_ET_PREVISIBLE':
    case 'FRAIS_MEDICAUX_ET_PARAMEDICAUX':
    case 'DOMMAGE_ESTHETIQUE_TEMPORAIRE':
    case 'PREJUDICE_ANGOISSE_MORT_IMMINENTE':
    case 'AMENAGEMENT_DU_DOMICILE':
    case 'AMENAGEMENT_DU_VEHICULE':
      return <>Commentaire possible</>

    case 'INSTITUTIONNALISATION':
    case 'PREJUDICE_ETABLISSEMENT':
    case 'FRAIS_APPAREILLAGE':
      return (
        <>
          Commentaire possible <br />
          Sélectionnable si handicap grave est activé
        </>
      )

    case 'DATES_HOSPITALISATION_IMPUTABLES':
    case 'DATES_ARRETS_DE_TRAVAIL_IMPUTABLES':
    case 'GENE_TEMPORAIRE_TOTALE':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_1':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_2':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_3':
    case 'GENE_TEMPORAIRE_PARTIELLE_CLASSE_4':
      return <>Période(s) à préciser</>

    case 'ATTEINTE_A_INTEGRITE_PHYSIQUE_ET_PSYCHIQUE':
    case 'DOMMAGE_ESTHETIQUE_PERMANENT':
    case 'SOUFFRANCES_ENDUREES':
      return <>Valeur à préciser</>

    case 'TIERCE_PERSONNE_DEFINITIVE':
    case 'TIERCE_PERSONNE_TEMPORAIRE':
      return <>Type(s) et période(s) à préciser</>

    default:
      return null
  }
}

export default function DommageCheckbox({ name, control, label, disabled }: DommageCheckboxProps) {
  const infoTitle = getInfoTitle(name)
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <FormControl error={error !== undefined}>
          <StyledFormGroup>
            <FormControlLabel
              disabled={disabled}
              label={label}
              sx={{ minHeight: 30 }}
              control={
                <Checkbox
                  checked={value}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  sx={{ padding: 0, marginX: 1.5, display: 'flex', alignItems: 'flex-start' }}
                />
              }
            />
            {infoTitle && (
              <Tooltip title={infoTitle}>
                <InfoOutlinedIcon color="primary" />
              </Tooltip>
            )}
          </StyledFormGroup>
        </FormControl>
      )}
    />
  )
}
