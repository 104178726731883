import Keycloak, { KeycloakInitOptions } from 'keycloak-js'
import { AuthClientError, AuthClientEvent, AuthClientTokens } from '@react-keycloak/core/lib/types'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { AnyAction } from '@reduxjs/toolkit'
import { getProfilSelectionne, reset } from '../store/slices/profilSlice'
import { RootState } from '../store'

const APP_CONFIG = JSON.parse(sessionStorage.getItem('appconfig') || '{}')
const logoutUrl = `${window.location.origin}/extranet/logout`

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak = new Keycloak({
  url: APP_CONFIG.u,
  realm: APP_CONFIG.r,
  clientId: APP_CONFIG.c,
})

export const initOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  enableLogging: true,
  checkLoginIframe: false,
  pkceMethod: 'S256',
}

export const initOptionsIdp: KeycloakInitOptions = {
  onLoad: 'check-sso',
  enableLogging: true,
}

export const eventHandler = (_event: AuthClientEvent, _error?: AuthClientError | undefined) => {
  // console.log('onKeycloakEvent', event, error)
}

export const tokenHandler = (_tokens: AuthClientTokens) => {
  // console.log('onKeycloakTokens', tokens)
}

export function determinerIDP(): string | null {
  // step 1 : if idp is not available in localStorage, we check in URL
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('idp')
}

export function logout(dispatch: (action: AnyAction) => void) {
  // NOTE: get IDP param to manage logout correctly
  dispatch(reset)
  localStorage.removeItem('persist:transmed')
  return keycloak.logout({ redirectUri: logoutUrl })
}

export function prepareHeaders(
  headers: Headers,
  { getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
) {
  return prepareHeadersWithState(headers, getState())
}

export function prepareHeadersWithState(headers: Headers, state: unknown) {
  // Si keycloak est initialisé et authentifié alors on transmet le token et le profil sélectionné à l'API dans des headers
  if (keycloak && keycloak.authenticated) {
    headers.set('authorization', `Bearer ${keycloak.token}`)
    headers.set('X-Profile-ID', getProfilSelectionne(state as RootState) || '')
  }

  return headers
}
