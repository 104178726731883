import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material'
import { AnnulerButton, EditTextField, InformationIconButton } from 'plateforme/components'
import Referentiel, { ReferentielDommage } from 'plateforme/store/types/referentiel'
import useConfirmDialog from 'plateforme/hooks/useConfirmDialog'
import { determineShowPreconisationHandicapGrave } from 'plateforme/services/mission.services'
import ProfilEntreprise from 'plateforme/store/types/profilEntreprise'
import DossierEntreprise from 'assureur/store/types/dossierEntreprise'
import RapportConclusionEntreprise from 'assureur/store/types/rapportConlusionEntreprise'

type ParametresMissionPartProps = {
  dossier?: DossierEntreprise
  rapportPrecedent?: RapportConclusionEntreprise
  codeMedecin?: string
  referentiel?: Referentiel
  profilEntreprise?: ProfilEntreprise
}

export default function ParametresMissionPart({
  dossier,
  rapportPrecedent,
  codeMedecin,
  referentiel,
  profilEntreprise,
}: ParametresMissionPartProps) {
  const { control } = useFormContext()

  const showReferenceMissionAnterieur = !dossier?.missions?.some((m) => m.medecin?.code === codeMedecin)
  const showLettreMission = profilEntreprise?.parametrage?.lettreMission
  const showHandicapGrave = determineShowPreconisationHandicapGrave(profilEntreprise, dossier)
  const showPrecisionsMission = (profilEntreprise?.parametrage?.precisionsMissionMax ?? 0) > 0

  const tooltipDomaineContractuel =
    dossier?.expertise?.domaineContractuel === undefined
      ? undefined
      : 'Non sélectionnable car une mission spécifique est définie sur le dossier'
  const tooltipFicheSimplifiee =
    dossier?.typeFicheConclusionPrevisible !== 'SIMP'
      ? undefined
      : 'Non sélectionnable car la fiche de conclusion sera simplifiée'
  const tooltipHandicapGrave = tooltipDomaineContractuel ?? tooltipFicheSimplifiee
  const enableHandicapGrave = !tooltipHandicapGrave

  const { confirm, closeConfirmDialog, confirmDialog } = useConfirmDialog()

  const handicapGraveDommages = () => {
    return Object.keys(rapportPrecedent?.dommages ?? [])
      .map((code) => referentiel?.dommagePredefini?.find((df) => df.code === code))
      .filter((dmg) => dmg !== undefined)
      .map((dmg) => dmg as ReferentielDommage)
      .filter((dmg) => dmg.handicapGrave)
  }

  const needRemoveHandicapGraveDommages = () => {
    return handicapGraveDommages().length > 0
  }

  const showHandicapGraveCommentaire = () => {
    confirm({
      title: 'Informations',
      maxWidth: 'md',
      confirmMsg: ``,
      withForm: true,
      form: (
        <Grid container paddingTop={0} width="500px">
          <Grid item xs={12}>
            La désactivation empêchera, lors de la création du rapport, la recopie des dommages suivants :
            <ul style={{ margin: 0, paddingLeft: 24 }}>
              {handicapGraveDommages()?.map((dommage) => (
                <li>{dommage.label}</li>
              ))}
            </ul>
          </Grid>
          <Grid justifyContent="flex-end" container item xs={12} columnSpacing={2}>
            <Grid item>
              <AnnulerButton onClick={() => closeConfirmDialog()}>Fermer</AnnulerButton>
            </Grid>
          </Grid>
        </Grid>
      ),
    })
  }

  return (
    <Card>
      <CardHeader title="Paramètres de la mission" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="refMissionEntreprise"
              control={control}
              render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                <EditTextField
                  id="refMissionEntreprise"
                  label="Votre référence mission"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  fullWidth
                  fieldError={error}
                />
              )}
            />
          </Grid>
          {showReferenceMissionAnterieur && (
            <Grid item xs={12}>
              <Controller
                name="refMissionAnterieure"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="refMissionAnterieure"
                    label="Référence mission antérieure"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                  />
                )}
              />
            </Grid>
          )}
          {showLettreMission && (
            <Grid item xs={12}>
              <Controller
                name="generationOrdreMission"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <FormControl error={!!error} variant="standard">
                    <FormControlLabel
                      id="generationOrdreMission"
                      value={value}
                      control={<Switch onBlur={onBlur} onChange={onChange} checked={value} />}
                      label="Génération de l'ordre de mission TransMED par la plateforme pour cette mission"
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          )}
          {showHandicapGrave && (
            <Grid item xs={12}>
              <Stack direction="row" spacing={0}>
                {confirmDialog}
                <Controller
                  name="handicapGrave"
                  control={control}
                  render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                    <FormControl error={!!error} variant="standard">
                      <Tooltip title={tooltipHandicapGrave}>
                        <div>
                          <FormControlLabel
                            id="handicapGrave"
                            value={value}
                            control={
                              <Switch
                                onBlur={onBlur}
                                onChange={onChange}
                                checked={value}
                                disabled={!enableHandicapGrave}
                              />
                            }
                            label="Mission de type handicap grave"
                          />
                        </div>
                      </Tooltip>
                      <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
                {needRemoveHandicapGraveDommages() && (
                  <InformationIconButton onClick={() => showHandicapGraveCommentaire()} sx={{ marginLeft: -1 }} />
                )}
              </Stack>
            </Grid>
          )}
          {showPrecisionsMission && (
            <Grid item xs={12}>
              <Controller
                name="precisions"
                control={control}
                render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
                  <EditTextField
                    id="precisions"
                    label="Précisions sur la mission"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    fullWidth
                    fieldError={error}
                    minRows={4}
                    multiline
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
