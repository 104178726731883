import React from 'react'
import { Gestionnaire } from 'assureur/store/types/dossierEntreprise'
import { useGetGestionnairesQuery } from 'assureur/store/apis/gestionnaireEntrepriseApi'
import SelectInputWithQuery, { SelectInputWithQueryProps } from './SelectInputWithQuery'
import { SelectOption } from './SelectInput'

type SelectInputGestionnaireProps = Omit<
  SelectInputWithQueryProps<void>,
  'useQuery' | 'dataTransformer' | 'options'
> & {
  codeService?: string
}

export default function SelectInputGestionnaire({ codeService, ...restProps }: SelectInputGestionnaireProps) {
  const dataTransformer = (data: Gestionnaire[]) => {
    return data.map((e: Gestionnaire) => {
      return {
        code: e.idUtilisateur?.toString(),
        label:
          !e.nom && !e.prenom ? `Non identifié (${e.idUtilisateur?.toString()})` : `${e.nom || ''} ${e.prenom || ''}`,
      } as SelectOption
    })
  }

  return (
    <SelectInputWithQuery
      {...restProps}
      useQuery={useGetGestionnairesQuery}
      queryParam={{ codeService }}
      dataTransformer={dataTransformer}
    />
  )
}
