import { StatutsHabilitation } from 'plateforme/store/types/habilitation'
import { PageRechercheRequest, SortParam } from 'plateforme/store/types/pageRecherche'

export default interface HabilitationMedecin {
  code?: string
  codeSIREN?: string
  codeACPR?: string
  libelle?: string
  numeroHabilitation?: string
  intervenantOccasionnel?: boolean
  expertiseSurPiece?: boolean
  dateDebut?: Date
  dateFin?: Date
  statut?: StatutsHabilitation
  labelStatut?: string
  dateCreation: Date
  dateMiseAJour: Date
}

export interface HabilitationFilter {
  codeMedecin?: string
  numeroHabilitation?: string
  codeEntreprise?: string
  intervenantOccasionnel?: 0 | 1
  expertiseSurPiece?: 0 | 1
  active?: 0 | 1
  activeFutur?: 0 | 1
  dateActive?: string
  dateCreationDebut?: string
  dateCreationFin?: string
}

export enum HabilitationSortType {
  ENTREPRISE_SORT = 'entreprise',
  DATE_CREATION_SORT = 'date_creation',
  NUMERO_HABILITATION_SORT = 'numero_habilitation',
  DATE_DEBUT_SORT = 'date_debut',
  DATE_FIN_SORT = 'date_fin',
  STATUT_SORT = 'statut',
}
export const top100HabilitationRequest = () =>
  ({
    filter: { activeFutur: 1 },
    sort: { sortType: HabilitationSortType.DATE_DEBUT_SORT, sortOrder: 'DESC' } as SortParam<HabilitationSortType>,
    perPage: 100,
  } as PageRechercheRequest<HabilitationFilter, HabilitationSortType>)

export const hasHabilitationActiveRequest = () =>
  ({
    filter: { active: 1 },
    onlyTotal: 1,
  } as PageRechercheRequest<HabilitationFilter, HabilitationSortType>)

export const hasHabilitationInactiveRequest = () =>
  ({
    filter: { active: 0 },
    onlyTotal: 1,
  } as PageRechercheRequest<HabilitationFilter, HabilitationSortType>)
