import * as React from 'react'
import { Button, ButtonProps } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default function ChercherButton(props: ButtonProps) {
  const { children, variant, color, ...other } = props
  return (
    <Button color={color ?? 'primary'} variant={variant ?? 'contained'} startIcon={<SearchIcon />} {...other}>
      {children}
    </Button>
  )
}
