import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from 'plateforme/security/keycloak'
import transmedApiUrl from 'plateforme/store/apis/transmedApi'
import PageRecherche, { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { ReferentielMedecin } from 'plateforme/store/types/profilMedecin'
import ProfilMedecinAdmin, {
  AdminMedecinRequest,
  MedecinAdminFilter,
  MedecinAdminSortType,
  SuiviMedecinRequest,
} from '../types/profilMedecinAdmin'

export const gestionMedecinApi = createApi({
  reducerPath: 'gestionMedecinApi',
  baseQuery: fetchBaseQuery({ baseUrl: transmedApiUrl, prepareHeaders }),
  tagTypes: ['GestionMedecin'],
  endpoints: (builder) => ({
    getAdminMedecins: builder.query<
      PageRecherche<ProfilMedecinAdmin>,
      PageRechercheRequest<MedecinAdminFilter, MedecinAdminSortType>
    >({
      query: ({ perPage, page, filter, sort, onlyTotal }) => {
        return {
          url: 'admin/medecin',
          params: {
            ...filter,
            ...sort,
            perPage,
            page,
            onlyTotal,
          },
        }
      },
    }),
    getReferentielAdminMedecin: builder.query<ReferentielMedecin[], void>({
      query: () => 'admin/medecin-referentiel',
    }),
    getAdminMedecinByCode: builder.query<ProfilMedecinAdmin, AdminMedecinRequest>({
      query: ({ codeMedecin }) => `admin/medecin/${codeMedecin}`,
      providesTags: (result, error, { codeMedecin }) => [{ type: 'GestionMedecin', id: codeMedecin }],
    }),
    postAdminMedecin: builder.mutation<ProfilMedecinAdmin, ProfilMedecinAdmin>({
      query: (body) => {
        return {
          url: 'admin/medecin',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['GestionMedecin'],
    }),
    putAdminMedecin: builder.mutation<ProfilMedecinAdmin, ProfilMedecinAdmin>({
      query(data) {
        const { code, ...body } = data
        return {
          url: `admin/medecin/${code}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result, error, { code }) => (error ? [] : [{ type: 'GestionMedecin', code }]),
    }),
    postAdminStatutMedecin: builder.mutation<ProfilMedecinAdmin, SuiviMedecinRequest>({
      query(data) {
        const { code, ...body } = data
        return {
          url: `admin/medecin/${code}/suivi`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result, error, { code }) => (error ? [] : [{ type: 'GestionMedecin', code }]),
    }),
    getAdminReferentielMedecin: builder.query<ReferentielMedecin[], void>({
      query: () => 'admin/medecin-referentiel',
    }),
  }),
})

export const {
  useGetReferentielAdminMedecinQuery,
  useGetAdminMedecinsQuery,
  useGetAdminMedecinByCodeQuery,
  usePostAdminMedecinMutation,
  usePutAdminMedecinMutation,
  usePostAdminStatutMedecinMutation,
  useGetAdminReferentielMedecinQuery,
} = gestionMedecinApi
