import React from 'react'
import { StepIconProps, styled } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

const CustomStepIconRoot = styled('div')(() => ({
  color: '#91969d',
  display: 'flex',
  '& .CustomStepIcon-completedIcon': {
    color: '#3556a7',
  },
}))

export default function StepIconComponent(props: StepIconProps) {
  const { completed, className } = props

  return (
    <CustomStepIconRoot className={className}>
      {completed ? <CheckCircleIcon className="CustomStepIcon-completedIcon" /> : <RadioButtonUncheckedIcon />}
    </CustomStepIconRoot>
  )
}
