import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'plateforme/store'
import { NotificationAdminFilter } from 'admin/store/types/notificationsAdmin'
import { ReferentielEntrepriseFilter } from 'admin/store/types/profilEntrepriseAdmin'
import { IndicateurActiviteAdminFilter } from 'admin/store/types/indicateurActiviteAdmin'
import { MedecinAdminFilter, MedecinAdminSortType } from 'admin/store/types/profilMedecinAdmin'
import { PageRechercheRequest } from 'plateforme/store/types/pageRecherche'
import { DossierAdminFilter } from 'admin/store/types/dossierAdmin'
import { FluxTechniqueAdminFilter } from 'admin/store/types/fluxTechniqueAdmin'
import { endOfYesterday, formatISO, startOfYesterday } from 'date-fns'

export interface SearchCriteria {
  dossierCriteria?: DossierAdminFilter
  notificationsCriteria?: NotificationAdminFilter
  entrepriseReferenceCriteria?: ReferentielEntrepriseFilter
  medecinReferenceCriteria?: MedecinAdminFilter
  indicateurActiviteCriteria?: IndicateurActiviteAdminFilter
  fluxTechniqueCriteria?: FluxTechniqueAdminFilter
}

export const searchCriteriaSliceName = 'searchCriteriaAdmin'

const searchCriteriaSlice = createSlice({
  name: searchCriteriaSliceName,
  initialState: {
    dossierCriteria: undefined,
    notificationsCriteria: undefined,
    entrepriseReferenceCriteria: undefined,
    medecinReferenceCriteria: undefined,
    indicateurActiviteCriteria: undefined,
  } as SearchCriteria,
  reducers: {
    setDossierCriteria: (state, action: PayloadAction<DossierAdminFilter>) => {
      state.dossierCriteria = action.payload
    },
    setNotificationCriteria: (state, action: PayloadAction<NotificationAdminFilter>) => {
      state.notificationsCriteria = action.payload
    },
    setEntrepriseReferenceCriteria: (state, action: PayloadAction<ReferentielEntrepriseFilter>) => {
      state.entrepriseReferenceCriteria = action.payload
    },
    setMedecinReferenceCriteria: (state, action: PayloadAction<MedecinAdminFilter>) => {
      state.medecinReferenceCriteria = action.payload
    },
    setEntrepriseReferenceMotCleCriteria: (state, action: PayloadAction<string>) => {
      state.entrepriseReferenceCriteria = {
        motCle: action.payload === '' ? undefined : action.payload,
        statuts: undefined,
        dateCreationDebut: undefined,
      }
    },
    setIndicateurActiviteCriteria: (state, action: PayloadAction<IndicateurActiviteAdminFilter>) => {
      state.indicateurActiviteCriteria = action.payload
    },
    setFluxTechniqueCriteria: (state, action: PayloadAction<FluxTechniqueAdminFilter>) => {
      state.fluxTechniqueCriteria = action.payload
      if (!action.payload.dateDebut) {
        state.fluxTechniqueCriteria.dateDebut = formatISO(startOfYesterday())
      }
      if (!action.payload.dateFin) {
        state.fluxTechniqueCriteria.dateFin = formatISO(endOfYesterday())
      }
    },
  },
})

export const {
  setDossierCriteria,
  setNotificationCriteria,
  setEntrepriseReferenceCriteria,
  setMedecinReferenceCriteria,
  setEntrepriseReferenceMotCleCriteria,
  setIndicateurActiviteCriteria,
  setFluxTechniqueCriteria,
} = searchCriteriaSlice.actions

export const getSearchCriteria = (state: RootState) => state?.searchCriteriaAdmin
export const getDossierCriteria = (state: RootState) => state?.searchCriteriaAdmin.dossierCriteria
export const getNotificationCriteria = (state: RootState) => state?.searchCriteriaAdmin.notificationsCriteria
export const getEntrepriseReferenceCriteria = (state: RootState) =>
  state?.searchCriteriaAdmin?.entrepriseReferenceCriteria
export const getMedecinReferenceCriteria = (state: RootState) => state?.searchCriteriaAdmin?.medecinReferenceCriteria
export const getIndicateurActiviteCriteria = (state: RootState) => state?.searchCriteriaAdmin.indicateurActiviteCriteria
export const getFluxTechniqueCriteria = (state: RootState) => state?.searchCriteriaAdmin.fluxTechniqueCriteria

export default searchCriteriaSlice.reducer

export const totalMedecinsReferencesRequest = () =>
  ({
    onlyTotal: 1,
  } as PageRechercheRequest<MedecinAdminFilter, MedecinAdminSortType>)
