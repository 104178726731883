import { createTheme, LinkProps } from '@mui/material'
import { frFR } from '@mui/material/locale'
import LinkComponent from './LinkBehavior'

declare module '@mui/material/styles' {
  interface Palette {
    tile: Palette['primary']
    readOnly: PaletteOptions['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tile?: PaletteOptions['primary']
    readOnly?: PaletteOptions['primary']
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tile: true
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tile: true
  }
}
declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    tile: true
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    readOnly: true
  }
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    error: true
  }
}

const baseTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3556a7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#37c6bf',
      contrastText: '#fff',
    },
    error: {
      main: '#e74242',
    },
    warning: {
      main: '#efae4e',
    },
    info: {
      main: '#a68cbe',
      contrastText: '#fff',
    },
    success: {
      main: '#4db251',
      contrastText: '#fff',
    },
    tile: {
      main: '#fff',
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#f6f6f6',
      light: '#fff',
    },
    background: {
      default: '#eae9f1',
      paper: '#fff',
    },
    readOnly: {
      main: '#858484',
    },
  },
  typography: {
    fontFamily: 'Roboto,"Helvetica Neue",sans-serif',
    h1: {
      fontWeight: 'bold',
      fontSize: 20,
      fontSmooth: 'auto',
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 17,
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 18,
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    h4: {
      fontSize: 18,
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 18,
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 16,
      fontWeight: '500',
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: 16,
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      fontSmooth: 'auto',
      fontWeight: '500',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: 17,
      fontWeight: '400',
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 'bold',
      fontSmooth: 'auto',
      textTransform: 'none',
      lineHeight: 1.5,
    },
    button: {
      fontSize: 14,
      fontSmooth: 'auto',
      fontWeight: '500',
      textTransform: 'none',
      lineHeight: 1.5,
    },
  },
})

const theme = createTheme(
  {
    ...baseTheme,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '8px',
              backgroundColor: '#e7e7e7',
              border: '1px solid #cacaca',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '8px',
              backgroundColor: '#b7a4cb',
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkComponent,
        } as LinkProps,
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            color: baseTheme.palette.info.contrastText,
            backgroundColor: baseTheme.palette.info.main,
            marginBottom: `${baseTheme.spacing(2.5)} !important`,
            borderRadius: `${baseTheme.spacing(0.5)} !important`,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(2, 2, 3, 2),
            borderBottomLeftRadius: `${baseTheme.spacing(0.5)} !important`,
            borderBottomRightRadius: `${baseTheme.spacing(0.5)} !important`,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: '51px !important',
            WebkitFontSmoothing: 'auto',
            MozOsxFontSmoothing: 'auto',
          },
          content: {
            marginTop: `${baseTheme.spacing(1.5)} !important`,
            marginBottom: `${baseTheme.spacing(1.5)} !important`,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardInfo: {
            backgroundColor: '#f7eef9',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            width: 'auto',
          },
          colorPrimary: {
            backgroundColor: '#f5f5f5',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: {
            boxShadow: baseTheme.shadows[2],
            borderColor: 'transparent',
            '&:hover': {
              borderColor: 'transparent',
            },
          },
        },
      },
      /* Permet de faire le lien avec react-router */
      MuiButtonBase: {
        defaultProps: {
          LinkComponent,
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.background.paper,
            display: 'flex',
            flexWrap: 'wrap',
            width: 'auto',
            height: 'auto',
            border: 0,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.info.main,
            color: baseTheme.palette.info.contrastText,
            width: '100%',
            height: 'auto',
            border: 0,
            margin: 0,
            padding: baseTheme.spacing(1.5, 2.5),
            WebkitFontSmoothing: 'auto',
            MozOsxFontSmoothing: 'auto',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(2),
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(0, 2, 2.5, 2),
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(0, 2, 2, 2),
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: baseTheme.shadows[3],
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.multiline === true && {
              alignItems: 'flex-start',
            }),
            /* ...((ownerState.readOnly === false || ownerState.readOnly === undefined) && {
            backgroundColor: 'rgba(0,50,255,0.06)',
          }), */
            fontSize: 14,
            '&.MuiFilledInput-root': {
              minHeight: 60,
              backgroundColor: '#f2f2f2 !important',
            },
            '&.MuiFilledInput-root:hover': {
              backgroundColor: '#ebebeb !important',
            },
          }),
        },
      },
      MuiGrid: {
        defaultProps: {
          columnSpacing: baseTheme.spacing(3.5),
          rowSpacing: baseTheme.spacing(2.5),
        },
        styleOverrides: {
          root: {
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '8px',
              backgroundColor: '#e7e7e7',
              border: '1px solid #cacaca',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '8px',
              backgroundColor: '#b7a4cb',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            WebkitFontSmoothing: 'auto',
            MozOsxFontSmoothing: 'auto',
          },
          asterisk: {
            paddingLeft: baseTheme.spacing(0.5),
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            WebkitFontSmoothing: 'auto',
            MozOsxFontSmoothing: 'auto',
          },
          input: {
            '&::placeholder': {
              opacity: 0.55,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            marginRight: '5px',
          },
        },
      },
      MuiStack: {
        defaultProps: {
          spacing: baseTheme.spacing(2.5),
          direction: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          iconContainer: {
            paddingRight: baseTheme.spacing(1.5),
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: baseTheme.shadows[2],
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: 48,
            '&:last-child td, &:last-child tr': {
              border: 0,
            },
          },
          head: {
            height: 56,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(0, 0, 0, 3),
          },
          head: {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            color: 'grey',
            fontWeight: '500',
            textTransform: 'none',
            WebkitFontSmoothing: 'auto',
            MozOsxFontSmoothing: 'auto',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderBottom: '1px solid rgba(0,0,0,.12);',
            '&.MuiTabs-indicator': {
              display: 'flex',
              justifyContent: 'center',
            },
            '&.MuiTabs-indicatorSpan': {
              maxWidth: 40,
              width: '100%',
            },
          },
          scrollButtons: {
            opacity: 0.48,
            '&.Mui-disabled': {
              opacity: 0.15,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: '150px',
            minHeight: '48px',
            textTransform: 'none',
            fontFamily: baseTheme.typography.fontFamily,
            fontSize: '14px',
            fontWeight: '500',
            padding: baseTheme.spacing(0, 3),
            color: '#2f2f2f',
            opacity: '0.6',
            '&:focus': {
              opacity: '1',
            },
            '&.Mui-selected': {
              color: '#fff',
              backgroundColor: '#3556A7',
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            marginBottom: baseTheme.spacing(-2.5),
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
    },
  },
  frFR
)

export default theme
