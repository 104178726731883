import * as React from 'react'
import parse from 'html-react-parser'
import { Card, CardContent, Divider } from '@mui/material'
import { AreaLoading } from 'plateforme/components'
import { useGetListeActualitesQuery } from 'plateforme/store/apis/actualiteApi'
import Actualite from 'plateforme/store/types/actualite'
import { LoadingProps } from 'plateforme/services/utils'
import { addSeconds, isAfter } from 'date-fns'

enum StatutActualite {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export default function ActualitesPart({ loading }: LoadingProps) {
  const { data: actualites, isLoading, isFetching } = useGetListeActualitesQuery()

  if (loading || isLoading || isFetching) {
    return <AreaLoading height={150} />
  }

  const statutFromActualite = (actualite: Actualite): string => {
    const now = addSeconds(new Date(), 1)
    const isDebutAuFutur = isAfter(new Date(actualite.dateDebut), now)

    if (!isDebutAuFutur && (actualite?.dateFin === undefined || new Date(actualite?.dateFin) > now)) {
      return StatutActualite.ACTIVE
    }

    return StatutActualite.INACTIVE
  }

  return (
    <Card>
      {actualites
        ?.filter((actualite: Actualite) => statutFromActualite(actualite) === StatutActualite.ACTIVE)
        .map((actualite: Actualite, index: number) => (
          <CardContent key={actualite.id}>
            {parse(`${actualite.contenu}`)}
            {(index + 1 < actualites?.length ?? 0) && <Divider sx={{ paddingTop: 3, marginX: -2, marginBottom: -1 }} />}
          </CardContent>
        ))}
    </Card>
  )
}
