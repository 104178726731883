import React from 'react'
import { Box, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

export interface InformationChipProps {
  indicateur?: string
  variant?: Variant
  colorText?: string
  backgroundColor?: string
  border?: string
  borderColor?: string
}

export default function InformationChip({
  indicateur,
  variant,
  colorText,
  backgroundColor,
  border,
  borderColor,
}: InformationChipProps) {
  return (
    <Box
      sx={{
        padding: '5px 20px',
        backgroundColor: `${backgroundColor ?? '#f0f0f0'}`,
        borderRadius: '70px',
        border: border ?? 'none',
        borderColor: borderColor ?? 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography margin={0} variant={variant} color={colorText ?? 'text.primary'} gutterBottom>
          {indicateur}
        </Typography>
      </Box>
    </Box>
  )
}
