import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { SerializedError } from '@reduxjs/toolkit'

const errorMessage = (cause: FetchBaseQueryError | SerializedError | undefined): string | undefined => {
  if (cause !== null && cause !== undefined) {
    if ('status' in cause) {
      return 'error' in cause ? cause.error : JSON.stringify(cause.data)
    }
    return cause.message ? cause.message : 'Message inaccessible.'
  }
  return ''
}

export default errorMessage
