import React from 'react'
import { FieldError } from 'react-hook-form'
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
  FormHelperTextProps,
  SxProps,
  Theme,
} from '@mui/material'

export type CheckboxInputProps = Omit<FormControlProps, 'control' | 'sx'> &
  Omit<FormControlLabelProps, 'control' | 'sx'> &
  Omit<CheckboxProps, 'control' | 'sx'> &
  Omit<FormHelperTextProps, 'control' | 'sx'> & {
    fieldError?: FieldError
    formControlSx?: SxProps<Theme>
    checkboxSx?: SxProps<Theme>
    errorSx?: SxProps<Theme>
  }

export default function CheckboxInput({
  id,
  name,
  label,
  labelPlacement,
  checked,
  onChange,
  onBlur,
  fieldError,
  formControlSx,
  checkboxSx,
  errorSx,
  required,
}: CheckboxInputProps) {
  return (
    <FormControl error={fieldError !== undefined} sx={formControlSx}>
      <FormGroup>
        <FormControlLabel
          labelPlacement={labelPlacement}
          label={label}
          control={
            <>
              {required && (
                <Box color="red" marginLeft={-1}>
                  *
                </Box>
              )}
              <Checkbox
                id={id}
                name={name}
                checked={checked}
                onBlur={onBlur}
                onChange={onChange}
                sx={{ padding: 0, marginX: 1.5, ...checkboxSx }}
                required={required}
              />
            </>
          }
        />
      </FormGroup>
      {fieldError?.message && <FormHelperText sx={errorSx}>{fieldError?.message}</FormHelperText>}
    </FormControl>
  )
}
