import React from 'react'
import { EditTextField } from 'plateforme/components'
import { Controller, useFormContext } from 'react-hook-form'

interface DommageFieldCommentaireProps {
  name: string
  code: string
  readOnly?: boolean
  disabled?: boolean
}

export default function DommageFieldCommentaire({ name, code, readOnly, disabled }: DommageFieldCommentaireProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={`${name}.commentaire`}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <EditTextField
          disabled={disabled}
          fullWidth
          minRows={3}
          multiline
          id={code}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          label="Commentaire"
          fieldError={error}
          readOnly={readOnly}
        />
      )}
    />
  )
}
