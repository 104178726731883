import { formatDateFR } from './dates.services'
import HabilitationPlateforme, { StatutsHabilitation } from '../store/types/habilitation'

export function infoStatut(habilitation: HabilitationPlateforme) {
  const { dateDebut, statut } = habilitation
  const libelleDateDebut = dateDebut ? formatDateFR(dateDebut) : ''

  switch (statut) {
    case StatutsHabilitation.ACTIVE:
      return ''
    case StatutsHabilitation.INACTIVE:
      return ''
    case StatutsHabilitation.ACTIVE_AU_FUTUR:
      return `à partir du ${libelleDateDebut}`
    default:
      return ''
  }
}

export function infoActive(statut?: StatutsHabilitation) {
  switch (statut) {
    case StatutsHabilitation.ACTIVE:
      return `Active`
    case StatutsHabilitation.ACTIVE_AU_FUTUR:
      return `Active`
    case StatutsHabilitation.INACTIVE:
      return `Inactive`
    default:
      return ''
  }
}

export function colorIconHabilitation(statut?: StatutsHabilitation) {
  switch (statut) {
    case StatutsHabilitation.ACTIVE:
      return '#4db251'
    case StatutsHabilitation.ACTIVE_AU_FUTUR:
      return '#507fd1'
    default:
      return '#e74242'
  }
}

export function infoPeriodeActive(habilitation: HabilitationPlateforme) {
  const { dateDebut, dateFin, statut, intervenantOccasionnel } = habilitation
  const intervenant = intervenantOccasionnel ? '(occasionnel)' : ''
  let libelle = ''

  const libelleDateDebut = dateDebut ? formatDateFR(dateDebut) : ''
  const libelleDateFin = dateFin ? `jusqu'au ${formatDateFR(dateFin)}` : ''

  switch (statut) {
    case StatutsHabilitation.ACTIVE:
      libelle = `Depuis le ${libelleDateDebut} ${libelleDateFin}`
      break

    case StatutsHabilitation.ACTIVE_AU_FUTUR:
      libelle = `À partir du ${libelleDateDebut} ${libelleDateFin}`
      break

    default:
      libelle = ''
      break
  }

  return libelle && libelle.length > 0 ? `${libelle} ${intervenant}` : ''
}
