import React from 'react'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import { AreaLoading, ReadOnlyEmailField, ReadOnlyTextField } from 'plateforme/components'
import DossierMedecin from 'medecin/store/types/dossierMedecin'
import MissionEntreprise from 'medecin/store/types/missionMedecin'

type ReferenceDossierMedecinPartProps = {
  dossier?: DossierMedecin
  mission?: MissionEntreprise
  loading?: boolean
}

function ReferenceDossierMedecinPart({ dossier, mission, loading }: ReferenceDossierMedecinPartProps) {
  // props:
  const { contactEntreprise } = dossier || {}
  const { libelle } = mission || {}

  // chargement:
  if (loading) {
    return <AreaLoading height={230} />
  }

  // erreur:
  if (!dossier || !mission || !contactEntreprise) {
    throw new Error(`Erreur de chargement des données`)
  }

  // rendu:
  return (
    <Card>
      <CardHeader title="Référence dossier" />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="id-ref-entreprise"
              label="Référence dossier entreprise"
              value={libelle ?? ''}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="id-contact-entreprise-libelle"
              label="Contact entreprise"
              value={contactEntreprise.libelle}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyEmailField
              id="id-contact-entreprise-mail"
              label="Adresse mail contact entreprise"
              value={contactEntreprise.mail}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyTextField
              id="id-contact-entreprise-telephone"
              label="Tél. contact entreprise"
              value={contactEntreprise.telephone}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ReferenceDossierMedecinPart
